import React from "react";

import "./App.css";
import "./App.scss";
import SideBar from "./app/components/sideBar";
import { ConfigurationProvider } from "./app/context/configurationContext";

import Layout from "./app/layout/layout";
import RouterProtected from "./app/router/routerProtected";
import { ThemeProvider } from "@emotion/react";
import theme from './theme'
import { PrestadorProvider } from "./app/context/prestadorContext";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ConfigurationProvider>
        <PrestadorProvider>
          <RouterProtected>
            <Layout />
          </RouterProtected>
        </PrestadorProvider>
      </ConfigurationProvider>
    </ThemeProvider>
  );
}

export default App;
