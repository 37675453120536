import React from 'react'

import Typography from '@mui/material/Typography'

const CustomLabel = ({style, children}) => {
    return (
        <Typography variant="h4" 
                    gutterBottom 
                    component="div"
                    style={style} >
                      {children}
        </Typography>
    )
}

export default CustomLabel