import { Dialog, DialogContent, Grid, Paper } from "@mui/material";
import React, { useEffect } from "react";
import CustomLabel from "../../common/customLabel";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import Clear from "@mui/icons-material/Clear";

import {
  dialogContentDetallePrestador,
  gridContainerDetallePrestador,
  labelNombreDetallePrestador,
  labelEspecialidadDetallePrestador,
  labelContactoDetallePrestador,
  iconTelefonoDetallePrestador,
  iconCorreoDetallePrestador,
  iconProfileDetallePrestador,
  paperDetalleContainerDetallePrestador,
  paperImgContainerDetallePrestador,
  cerrarModalRegistro,
} from "../../../global/styles";

import CardPrestador from "./cardPrestador";

import colors from "../../../../config/colors";

const DialogDetallePrestador = ({
  open,
  handlerCloseModal,
  prestador,
  detalle,
  regimenes
}) => {

  return (
    <Dialog
      open={open}
      onClose={handlerCloseModal}
      maxWidth='sm'
      PaperProps={{
        style: {
          borderRadius: 15,
        },
      }}
    >
      <DialogContent style={{ ...dialogContentDetallePrestador }}>
        <Grid item xs={12}>
          <Clear
            style={{
              ...cerrarModalRegistro,
              color: colors.primary,
              right: 4,
              top: 3,
              fontSize: 30,
            }}
            onClick={handlerCloseModal}
          />
        </Grid>
        <Grid
          container
          style={{
            ...gridContainerDetallePrestador,
          }}
        >
          <Grid item xs={9}>
            <Grid container>
              <Grid item xs={12}>
                <CustomLabel
                  style={{
                    ...labelNombreDetallePrestador,
                  }}
                >
                  {prestador.nombre}
                </CustomLabel>
              </Grid>
              <Grid item xs={12}>
                <CustomLabel
                  style={{
                    ...labelEspecialidadDetallePrestador,
                  }}
                >
                  {prestador.especialidad}
                </CustomLabel>
              </Grid>
              {prestador.telefono ? (
                <Grid item xs={12} marginTop={1}>
                  <CustomLabel
                    style={{
                      ...labelContactoDetallePrestador,
                    }}
                  >
                    <PhoneIcon
                      fontSize='medium'
                      style={{
                        ...iconTelefonoDetallePrestador,
                      }}
                    />
                    {prestador.telefono}
                  </CustomLabel>
                </Grid>
              ) : null}

              {prestador.correo ? (
                <Grid item xs={12}>
                  <CustomLabel
                    style={{
                      ...labelContactoDetallePrestador,
                    }}
                  >
                    <EmailIcon
                      fontSize='medium'
                      style={{
                        ...iconCorreoDetallePrestador,
                      }}
                    />
                    {prestador.correo}
                  </CustomLabel>
                </Grid>
              ) : null}
              {
                regimenes ?  (
                  <Grid item xs={12} marginBottom={10}>
                    <CustomLabel
                      style={{
                        ...labelContactoDetallePrestador,
                        color: colors.primary,
                        fontWeight: 600
                      }}
                      >
                      Regimenes disponibles:
                    </CustomLabel>
                    <Grid container>
                      {
                        regimenes.map(x => (
                            <Grid item md={12}>
                              <CustomLabel
                                style={{
                                  ...labelContactoDetallePrestador,
                                  color:colors.gray,
                                  fontWeight:300,
                                  fontSize:12
                                }}
                               >
                                {x.descripcion}
                              </CustomLabel>
                            </Grid>
                        ))
                      }
                    </Grid>
                  </Grid>
                ) : null
              }
            </Grid>
          </Grid>
          <Grid item xs={3} marginBottom={14}>
            <Paper
              style={{ ...paperImgContainerDetallePrestador }}
              elevation={7}
            >
              {prestador.tipoPrestador === "Medico" ? (
                <PersonIcon
                  style={{
                    ...iconProfileDetallePrestador,
                  }}
                />
              ) : (
                <BusinessIcon
                  style={{
                    ...iconProfileDetallePrestador,
                  }}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
        <Paper
          elevation={10}
          style={{
            ...paperDetalleContainerDetallePrestador,
          }}
        >
          <CustomLabel
            style={{
              color: colors.gray,
              fontSize: 20,
              fontWeight: "bold",
              marginTop: 2,
              textAlign: "center",
            }}
          >
            {prestador.tipoPrestador === "Medico" ? "CENTROS" : "MEDICOS"}
          </CustomLabel>
          {detalle && detalle.length > 0 ? (
            detalle.map((item) => (
              <div key={item.codigoPrestador} style={{ paddingInline: 28 }}>
                <CardPrestador prestador={item} />
                <hr />
              </div>
            ))
          ) : (
            <CustomLabel
              style={{
                color: colors.grayLight,
                fontSize: 20,
                fontWeight: "bold",
                marginTop: 120,
                textAlign: "center",
              }}
            >
              No hay información disponible.
            </CustomLabel>
          )}
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default DialogDetallePrestador;
