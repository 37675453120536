import React, { useState, useEffect } from 'react'
import CustomLabel from '../../components/common/customLabel'
import useSetTitlePage from '../../hooks/useSetTitlePage'
import colors from '../../../config/colors'
import { Grid, Divider, FormControl, InputLabel, Select, MenuItem, Button, TextField, FormControlLabel, Checkbox, Tooltip } from '@mui/material'
import CustomTable from '../../components/common/customTable'
import { labelTitlePrestador, botonBuscarPrestador, inputRegistro } from '../../global/styles'
import * as apiService from '../../../api/index'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import ModalLoading from '../../components/common/modalLoading'
import { useNavigate } from 'react-router-dom'
import CustomInput from '../../components/common/customInput'
import DialogBusquedaCentro from '../../components/consulta/consumo/dialogBusquedaCentro'
import { useSnackbar } from 'notistack'
import { convertDateFormat, createIdTag } from '../../utils/helpers'

const HistorialConsumo = () => {
	const infoAfiliado = useSelector((state) => state.afiliado.infoAfiliado)
	var navigate = useNavigate()
	const [lista, setLista] = useState()
	const [loading, setLoading] = useState(false)
	const [tipoAutorizaciones, setTipoAutorizaciones] = useState([])
	const [tipoAutorizacionField, setTipoAutorizacionField] = useState('')
	const [fechaInicioField, setFechaInicioField] = useState(null)
	const [fechaFinField, setFechaFinField] = useState(new Date())
	const [altocostoField, setAltoCostoField] = useState(false)
	const [nucleoSelect, setNucleoSelect] = useState([]);
	const [prestadorField, setPrestadorField] = useState('')
	const [nombreInput, setNombreInput] = useState('')
	const [codigoSelected, setCodigoSelected] = useState()
	const [openModal, setOpenModal] = useState(false)
	const [totalRegistrosDisponibles, setTotalRegistrosDisponibles] = useState(0)

	const handlerCloseModal = () => {
		setOpenModal(false)
	}

	const handlerOpenModal = () => {
		setOpenModal(true)
	}

	const { closeSnackbar, enqueueSnackbar } = useSnackbar()

	const handlerFechaInicioField = ({target}) => {
		const selectedDate = new Date(target.value);
		const today = new Date();
	
		if (selectedDate.getTime() > today.getTime()) {
		  mostrarMensaje("No está permitido seleccionar fechas futuras", 'error');
		  setFechaInicioField(today); 
		} else {
		  setFechaInicioField(target.value); 
		}
	  };

	const handlerFechaFinField = ({target}) => {
		if(new Date(target.value) > new Date()){
			mostrarMensaje("No esta permitido el seleccionar fechas futuras",'error')
			setFechaFinField(new Date())
		}
		else{
			setFechaFinField(target.value)
		}
		
	}
	const handlerAltoCostoField = ({ target }) => setAltoCostoField(target.checked)

	useEffect(() => {
		if (localStorage.getItem('autenticado') == 'false') navigate('../../login', { replace: true })
		setLoading(true)
		let datosOrdenados = [];

		datosOrdenados.push({
			codigo: infoAfiliado.codigoAfiliado,
			descripcion: `${infoAfiliado.nombres} ${infoAfiliado.apellidos}`
		})
		let currentDate = new Date();
		let sixMonthsAgo = new Date();
			sixMonthsAgo.setMonth(currentDate.getMonth() - 6);


		setCodigoSelected(infoAfiliado.codigoAfiliado)
		apiService.afiliado.getAfiliado(infoAfiliado.codigoAfiliado)
		.then(res => {
			if(res.data.result.nucleoFamiliar.participantes)
			{
				if(infoAfiliado.tipoAfiliacion.toLowerCase() === 'titular')
				{
					res.data.result.nucleoFamiliar.participantes.map(x => {
						if(x.mayorEdad === false)
						{
							datosOrdenados.push({
								codigo: x.codigoAfiliado,
								descripcion: `${x.nombres} ${x.apellidos}`
							})
						}
					})	
				}
			}
			setNucleoSelect(datosOrdenados)
			setLoading(false)
			
		}).catch(err => console.error(err))
		obtenerConsumos(infoAfiliado.codigoAfiliado, 0, false, sixMonthsAgo, currentDate)
		obtenerTipoAutorizacion();
		return () => {
			setLista([])
			setTipoAutorizacionField('')
			setTipoAutorizaciones([])
		}
	}, [])

	useEffect(() => {
		if (nombreInput == '') {
			setPrestadorField('')
		}
	}, [nombreInput])

	const obtenerConsumos = (codigoAfiliado, tipoAutorizacion, altoCosto, fechaDesde, fechaHasta, idPrestador, numeroPagina=0, registroPorPagina=5) => {
		setLoading(true)
		 apiService.consumo
			.getAllConsumo(codigoAfiliado, tipoAutorizacion, altoCosto, convertDateFormat(fechaDesde), convertDateFormat(fechaHasta), idPrestador,  numeroPagina, registroPorPagina)
			.then((response) => {
				setTotalRegistrosDisponibles(response.data.result.cantidadRegistros)
				const data = response.data.result.data;
				if(nombreInput)
				{
					
					const array = [];
					data.map((a) => (a.fecha = format(new Date(a.fecha), 'dd-MM-yyyy HH:mm')))
					
					data.map((a) => {
						if(a.solicitanteDesc.includes(nombreInput))
						{
							array.push(a)
						}
					});
					
					setLista(array)
				}
				else
				{
					data.map((a) => (a.fecha = format(new Date(a.fecha), 'dd-MM-yyyy HH:mm')))
					setLista(data)
				}
				setLoading(false)

				if (data.length === 0) {
					enqueueSnackbar('No se encontraron consumos en la consulta.', {
						variant: 'warning',
						action: (key) => (
							<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
								X
							</Button>
						)
					})
				}
			})
			.catch((err) => {
				enqueueSnackbar('Favor intente más tarde.', {
					variant: 'error',
					action: (key) => (
						<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
							X
						</Button>
					)
				})
				setLoading(false)
				console.error(err)
			})
	}

	const obtenerTipoAutorizacion = async() => {
		await apiService.consumo
			.getAllTipoAutorizacion()
			.then((response) => {
				response.data.result.unshift({ codigo: '', descripcion: 'NINGUNO' })
				setTipoAutorizaciones(response.data.result)
				setLoading(false)
			})
			.catch((err) =>{ 
				console.error(err)
				setLoading(false)
			})
	}

	const handlerBusquedaConsumo = () => {
		if(validateDateLimit(fechaInicioField, fechaFinField)){
			obtenerConsumos(
				codigoSelected,
				tipoAutorizacionField,
				altocostoField,
				fechaInicioField,
				fechaFinField,
				prestadorField.codigoPrestador
			)
		}
		else {
			mostrarMensaje("El rango de fecha indicado excede el límite de 180 días, para consultas mayor a 180 días puede dirigirse a uno de nuestros centros de servicios a usuario.", "warning")
		}
	}

	const handlerSelectedItem = (item) => {
		setPrestadorField(item)
		setNombreInput(item.nombre)
		handlerCloseModal()
	}

	const TitleHistorialConsumo = (
		<>
			<CustomLabel style={{ ...labelTitlePrestador }}>Historial de autorizaciones</CustomLabel>
			<Divider />
			<br />
			<br />
		</>
	)

	useSetTitlePage(TitleHistorialConsumo)

	const columnas = [
		{
			id: 'solicitanteDesc',
			label: 'Centro',
			minWidth: 170
		},
		{
			id: 'tipoAutDesc',
			label: 'Tipo servicio',
			minWidth: 170
		},
		{
			id: 'fecha',
			label: 'Fecha',
			minWidth: 170
		},
	]

	const mostrarMensaje = (mensaje, tipoMensaje) => {
		enqueueSnackbar(mensaje, {
			variant: tipoMensaje,
			action: (key) => (
				<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
					X
				</Button>
			)
		})
	}

	const validateDateLimit = (dateFrom, dateTo) => {
		let isValid = true;

		const startDate = new Date(dateFrom);
		const endDate = new Date(dateTo);
		const monthsDiff = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
	  
		if (monthsDiff > 6) {
		  isValid = false;
		}
	  
		return isValid;
	};

	const handlerPage = (pagina, registroPorPagina) =>{
	
		const params = {
			codigoAfiliado: codigoSelected,
			tipoAutorizacion: tipoAutorizacionField,
			altoCosto: altocostoField,
			fechaDesde: fechaInicioField,
			fechaHasta: fechaFinField,
			idPrestador:prestadorField.codigoPrestador || 0,
			numeroPagina: pagina,
			registroPorPagina: registroPorPagina
		}
		
		obtenerConsumos(...Object.values(params))
	}

	return (
		<>
			<Grid container spacing={4} >
				<Grid item md={3} xs={12}>
					<InputLabel
						style={{
							fontFamily: 'Montserrat',
							fontSize: 16,
							backgroundColor: 'white'
						}}
					>
						Tipo de autorización
					</InputLabel>
					<FormControl fullWidth>
						<Select
							labelId='select-tipo-solicitud'
							value={tipoAutorizacionField}
							label='Tipo Autorización'
							onChange={(event) => setTipoAutorizacionField(event.target.value)}
							style={{
								...inputRegistro,
								backgroundColor: 'white',
								height: 62
							}}
						>
							{tipoAutorizaciones.map((item) => (
								<MenuItem id={createIdTag("menuItem", item.descripcion)} key={item.codigo} value={item.codigo}>
									{item.descripcion}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item md={3} xs={12}>
					<InputLabel
						style={{
							fontFamily: 'Montserrat',
							fontSize: 16,
							backgroundColor: 'white'
						}}
					>
						Fecha desde
					</InputLabel>
					<FormControl
						className='formcontrol'
						fullWidth
					>
						<TextField
							id="FechaHastaId" 
							type="date"
							onChange={handlerFechaInicioField}
							value={fechaInicioField}
						/>
					</FormControl>
				</Grid>
				<Grid item md={3} xs={12}>
				<InputLabel
						style={{
							fontFamily: 'Montserrat',
							fontSize: 16,
							backgroundColor: 'white'
						}}
					>
						Fecha hasta
					</InputLabel>
					<FormControl
						className='formcontrol'
						fullWidth
					>
						<TextField
							id="fechaDesdeId" 
							type="date"
							onChange={handlerFechaFinField}
							value={fechaFinField}
						/>
					</FormControl>
				</Grid>
				<Grid item md={3} xs={12}>
					<FormControl>
						<FormControlLabel
							id="check-alto-costo"
							value='start'
							control={<Checkbox value={altocostoField} onChange={handlerAltoCostoField} style={{ color: colors.primary }} />}
							label={
								<label
									style={{
										color: colors.gray,
										fontFamily: 'Montserrat',
										fontSize: 20
									}}
								>
									Alto costo
								</label>
							}
							labelPlacement='start'
						/>
					</FormControl>
				</Grid>
				<Grid>
				</Grid>
				<Grid item md={3} xs={12}>
					<Tooltip title='Hacer doble clic para abrir el listado de centros.'>
						<CustomInput
							style={{ ...inputRegistro, backgroundColor: 'white' }}
							placeholder='Nombre'
							value={nombreInput}
							onDoubleClick={handlerOpenModal}
							onChange={(event) => setNombreInput(event.target.value)}
						/>
					</Tooltip>
				</Grid>
				<Grid item md={3} xs={12}>
					<FormControl fullWidth>
						<InputLabel
							id='nucleoFamiliar'
							style={{

								fontFamily: 'Montserrat',
								fontSize: 20,
								top: codigoSelected ? -15 : 0,
								backgroundColor: 'white'
							}}
						>
							Miembro de núcleo familiar
						</InputLabel>
						<Select
							labelId='tipoAutorizaciones'
							value={codigoSelected}
							defaultValue={infoAfiliado.codigoAfiliado}
							label='Tipo Autorización'
							onChange={(event) => setCodigoSelected(event.target.value)}
							style={{
								...inputRegistro,
								backgroundColor: 'white',
								height: 62
							}}
						>
							{nucleoSelect.map((item) => (
								<MenuItem key={item.codigo} value={item.codigo}>
									{item.descripcion}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item md={3} xs={12}>
					<Button id="btn-buscar-autorizacion" fullWidth variant='contained' style={{ ...botonBuscarPrestador, height:60, marginTop:0 }} onClick={handlerBusquedaConsumo}>
						Buscar
					</Button>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12} marginTop={3}>
					<CustomTable columns={columnas} rows={lista} handlerPage={handlerPage} totalDisponibles={totalRegistrosDisponibles} />
				</Grid>
			</Grid>
			<ModalLoading open={nucleoSelect ? false : true} onCloseHandler={() => setLoading(false)} />
			<ModalLoading open={loading} onCloseHandler={() => setLoading(false)} />
			<DialogBusquedaCentro open={openModal} handlerCloseModal={handlerCloseModal} handlerSelectedItem={handlerSelectedItem} />
		</>
	)
}

export default HistorialConsumo
