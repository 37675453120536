import http from '../../config/http'
import { TODOS, DROP_TIPO_AUTORIZACION } from '../endpoints'

const getAllConsumo = (codigoAfiliado, tipoAutorizacion, altoCosto, fechaDesde = null, fechaHasta = null, idPrestador = 0, numeroPagina=0, registroPorPagina=5) => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}
	const filtros = {
		contrato: codigoAfiliado,
		tipo: tipoAutorizacion === '' ? 0 : tipoAutorizacion,
		altoCosto: altoCosto,
		fechaDesde: fechaDesde,
		fechaHasta: fechaHasta,
		idPrestador: idPrestador === '' ? 0 : idPrestador,
		numeroDePagina:numeroPagina,
		registrosPorPagina: registroPorPagina
	}
	return http.post(`HistorialConsumo/${TODOS}`, filtros, configuration)
}

const getAllTipoAutorizacion = () => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}
	return http.get(`HistorialConsumo/${DROP_TIPO_AUTORIZACION}`, configuration)
}

export { getAllConsumo, getAllTipoAutorizacion }
