import React, { useState, useEffect } from "react";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import {
  Grid,
  FormControl,
  TextField,
  Button,
  InputLabel,
} from "@mui/material";
import { botonBuscarPrestador } from "../../global/styles";
import CustomTable from "../../components/common/customTable";
import UseIconHooks from "../../hooks/useIconHooks";
import colors from "../../../config/colors";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import "./turnos.css";
import CustomLabel from "../../components/common/customLabel";
import { labelTitlePrestador } from "../../global/styles";
import { Divider } from "@mui/material";
import useSetTitlePage from "../../hooks/useSetTitlePage";
import DialogNuevaCita from "../../components/citas/dialogNuevaCita";

import { useNavigate } from "react-router-dom";

const Citas = () => {
  const [value, setValue] = useState(new Date());
  const [lista, setLista] = useState([]);

  var navigate = useNavigate();

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handlerCloseModal = (_) => setOpen(false);
  const [open, setOpen] = useState(false);

  const columnas = [
    {
      id: "afiliado",
      label: "Afiliado",
      minWidth: 170,
    },
    {
      id: "sucursal",
      label: "Sucursal",
      minWidth: 170,
    },
    {
      id: "tipoServicio",
      label: "Tipo de Servicio",
      minWidth: 170,
    },
    {
      id: "fecha",
      label: "Fecha",
      minWidth: 170,
    },
    {
      id: "hora",
      label: "Hora",
      minWidth: 170,
    },
  ];

  const acciones = [
    {
      icono: UseIconHooks("visibility", {
        style: { color: colors.primary },
      }),
      metodo: (value) => {
        // setPrestadorActual(value);
        // apiService.prestador
        // 	.getDetallePrestador(value.codigoPrestador, value.tipoPrestador)
        // 	.then((response) => {
        // 		setDetallePrestador(response.data.result);
        // 		setOpen(true);
        // 	})
        // 	.catch((err) => console.log(err));
      },
      label: "Ver detalle",
    },
  ];

  const titleTurnos = (
    <>
      <CustomLabel style={{ ...labelTitlePrestador }}>Citas</CustomLabel>
      <Divider />
      <br />
      <br />
    </>
  );

  useSetTitlePage(titleTurnos);

  useEffect(() => {
    if (localStorage.getItem("autenticado") == "false")
      navigate("../../login", { replace: true });
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={10} xs={12}></Grid>
        <Grid item md={2} xs={12}>
          <Button
            fullWidth
            variant='contained'
            style={{ ...botonBuscarPrestador }}
            onClick={() => setOpen(true)}
          >
            Nueva Cita
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <FormControl
            className='formcontrol'
            fullWidth
            style={{
              border: "solid 2px",
              borderColor: colors.primary,
              borderRadius: 10,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label='Desde'
                inputFormat='MM/dd/yyyy'
                value={value}
                onChange={handleChange}
                style={{
                  width: "100%",
                  fontFamily: "Montserrat",
                  fontSize: 20,
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl
            fullWidth
            className='formcontrol'
            style={{
              border: "solid 2px",
              borderColor: colors.primary,
              borderRadius: 10,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label='Hasta'
                inputFormat='MM/dd/yyyy'
                value={value}
                onChange={handleChange}
                style={{
                  width: "100%",
                  fontFamily: "Montserrat",
                  fontSize: 20,
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>

        <Grid item md={4} xs={12}>
          <Button
            fullWidth
            variant='contained'
            style={{ ...botonBuscarPrestador }}
          >
            Buscar
          </Button>
        </Grid>

        <Grid item xs={12} marginTop={3}>
          <CustomTable columns={columnas} acciones={acciones} rows={lista} />
        </Grid>
      </Grid>
      <DialogNuevaCita open={open} handlerCloseModal={handlerCloseModal} />
    </>
  );
};

export default Citas;
