import React, { useState, useEffect } from 'react'
import CustomLabel from '../../components/common/customLabel'
import { Grid, Paper, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material'
import profilePicture from '../../../assets/user.png'
import colors from '../../../config/colors'
import useSetTitlePage from '../../hooks/useSetTitlePage'
import { validarCorreoElectronico,formatToMobile, createIdTag } from '../../utils/helpers';
import {
	imgAfiliadoProfile,
	containerTitleProfile,
	labelRegistro,
	labelDatosPersonalesProfile,
	containerDatosPersonalesProfile,
	inputRegistro,
	customInputLabelProfile,
	gridTitleContainerProfile,
	gridNucleoContainerProfile,
	labelNucleoFamiliarProfile,
	paperContainerEditableProfile,
	labelEditableProfile
} from '../../global/styles'

import UseIconHooks from '../../hooks/useIconHooks'
import CustomInput from '../../components/common/customInput'
import TabDatoAfiliacion from '../../components/profile/tabDatoAfiliacion'
import CustomSave from '../../components/common/customSave'
import DialogNucleoFamiliar from '../../components/profile/dialogNucleoFamiliar'
import { useSelector, useDispatch } from 'react-redux'
import { setInfoPerfilUsuario } from '../../redux/features/afiliado/afiliadoSlice'
import * as apiService from '../../../api'
import { useForm } from 'react-hook-form'
import CustomConfirmModal from '../../components/common/customConfirmModal'
import { useSnackbar } from 'notistack'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import ModalLoading from '../../components/common/modalLoading'
import CambioCorreoClave from '../cambioCorreo/cambioCorreoClave'
import useSetIsLogin from '../../hooks/useSetIsLogin'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useProfilePhoto } from '../../context/profilePhotoContext'
import UploadPhotoBox from '../../components/profile/uploadPhotoBox'

const Profile = () => {
	var navigate = useNavigate()
	const dispatch = useDispatch()
	const infoAfiliado = useSelector((state) => state.afiliado.infoAfiliado)
	const [disabledDireccionUpdate, setDisabledDireccionUpdate] = useState(false)
	const [telefonoActualizado, setTelefonoActualizado] = useState(false)
	const [direccionActualizado, setDireccionActualizado] = useState(false)
	const { setAuthLogOut } = useSetIsLogin()
	const {profilePhoto, uploadProfilePicture} = useProfilePhoto();
	const [uploadPhotoModal, setUploadPhotoModal] = useState(false)
	const [AfiliadoSeleccionado, setAfiliadoSeleccionado] = useState()
	
	useEffect(() => {

		if (localStorage.getItem('autenticado') == 'false') navigate('../../login', { replace: true })

		obtenerRegiones()
		obtenerMunicipios()
		obtenerProvincias()
		obtenerFotoPerfil()
	
		return () => {
			reiniciarFields()
			setOpenNucleo(false)
			setNucleo([])
			setRegiones([])
			setMunicipios([])
			setProvincias([])
			setModoEdicion(false)
			setOpenConfirmModal(false)
			setTitleConfirmModal('')
			setProvinciaBusqueda([])
			setMunicipioBusqueda([])
		}
	}, [])

	const [openNucleo, setOpenNucleo] = useState(false)
	const [datosAfiliacion, setDatosAfiliacion] = useState(useSelector((state) => state.afiliado.infoPerfilUsuario))
	const handlerCloseNucleo = () => setOpenNucleo(false)
	const [nucleo, setNucleo] = useState([])
	const handlerNucleoFamiliar = () => {
		setNucleo([...datosAfiliacion.nucleoFamiliar.participantes])
		setOpenNucleo(true)
	}

	const [cambioCorreoModal, setCambioCorreoModal] = useState(false)
	const obtenerFotoPerfil = () => {
		const image = localStorage.getItem('selectedImage');
		if(image)
		{
			setTitleHome(title)
		}
		
	}
	
	const title = (
			<>
				<Grid 
					container
					style={{
						...gridTitleContainerProfile,
						backgroundColor: infoAfiliado.tipoAfiliacion !== 'Titular' ? colors.gray : colors.primary
					}}
				>
					<Grid item xs={12} md={2} style={{ marginBottom: 17 }}>
						<Grid  id="btn-add-profile-photo" sx={{ left: { xs: 150, md: 450 } , position:'absolute'}} onClick={() => { 
							setUploadPhotoModal(true)
							setAfiliadoSeleccionado(infoAfiliado.codigoAfiliado)
						}
					}>
						<AddCircleIcon style={{fontSize:30, cursor: 'pointer', }}/>
							
					</Grid>
						
						<img src={!profilePhoto ? profilePicture : `data:image/png;base64,${profilePhoto}`} style={{ ...imgAfiliadoProfile, borderRadius: 150, marginLeft: 25, objectFit:'cover', width: 150, height: 150}} />
						
					</Grid>
					<Grid item xs={12} md={10} style={{ marginBottom: 10, paddingLeft: 50 }}>
						<div style={{ ...containerTitleProfile }}>
							<Grid container>
								<Grid item xs={12}>
									<CustomLabel style={{ ...labelRegistro, color: 'white' }}>
										{datosAfiliacion.datosPersonales ? datosAfiliacion.datosPersonales.nombres : null}{' '}
										{datosAfiliacion.datosPersonales ? datosAfiliacion.datosPersonales.apellidos : null}
									</CustomLabel>
								</Grid>
								<Grid item xs={12} style={{ marginTop: -10 }}>
									<CustomLabel style={{ fontSize: 20 }}>
										{datosAfiliacion.datosPersonales ? datosAfiliacion.datosPersonales.tipoAfiliacion : null} | No. de afiliado{' '}
										{datosAfiliacion.datosPersonales ? datosAfiliacion.datosPersonales.codigoAfiliado : null}
									</CustomLabel>
								</Grid>
	
								{infoAfiliado.tipoAfiliacion === 'Titular' ? (
									<Grid
										item
										xs={12}
										style={{
											...gridNucleoContainerProfile
										}}
									>
										<div onClick={handlerNucleoFamiliar}>
											<Grid container>
												<Grid item xs={1}>
													{UseIconHooks('group', {
														fontSize: 'large',
														style: { marginTop: -6 }
													})}
												</Grid>
												<Grid item xs={11}>
													<CustomLabel style={{ fontSize: 20 }}>
														<label
															style={{
																...labelNucleoFamiliarProfile
															}}
														>
															Nucleo familiar
														</label>
													</CustomLabel>
												</Grid>
											</Grid>
										</div>
									</Grid>
								) : null}
							</Grid>
						</div>
					</Grid>
				</Grid>
			</>
		)

	const [titleHome, setTitleHome] = useState(title)

	const { enqueueSnackbar, closeSnackbar } = useSnackbar()

	const { setTitle } = useSetTitlePage(titleHome)

	const [regiones, setRegiones] = useState([])

	const obtenerRegiones = () => {
		apiService.drops
			.getAllRegiones()
			.then((response) => setRegiones(response.data.result))
			.catch((err) => console.log(err))
	}

	const [municipios, setMunicipios] = useState([])
	const obtenerMunicipios = () => {
		apiService.drops
			.getAllMunicipios()
			.then((response) => setMunicipios(response.data.result))
			.catch((err) => console.log(err))
	}

	const [provincias, setProvincias] = useState([])
	const obtenerProvincias = () => {
		apiService.drops
			.getAllProvincias()
			.then((response) => setProvincias(response.data.result))
			.catch((err) => console.log(err))
	}

	const [provinciaBusqueda, setProvinciaBusqueda] = useState([])
	const [municipioBusqueda, setMunicipioBusqueda] = useState([])
	const [modoEdicion, setModoEdicion] = useState(false)
	const habilitarModoEdicion = (_) => setModoEdicion(true)
	const deshabilitarModoEdicion = (_) => setModoEdicion(false)

	const {
		register,
		formState: { errors }
	} = useForm({ mode: 'onChange' })

	const [regionField, setRegionField] = useState('')
	const [provinciaField, setProvinciaField] = useState('')
	const [municipioField, setMunicipioField] = useState('')
	const [calleField, setCalleField] = useState('')
	const [noCasaField, setNoCasaField] = useState('')
	const [apartamentoField, setApartamentoField] = useState('')
	const [edificioField, setEdificioField] = useState('')
	const [sectorField, setSectorField] = useState('')
	const [isValidPassword, setIsValidPassword] = useState(false)
	const [openConfirmModal, setOpenConfirmModal] = useState(false)
	const handlerOpenConfirmModal = (_) => setOpenConfirmModal(true)
	const handlerCloseConfirmModal = (_) => setOpenConfirmModal(false)
	const [titleConfirmModal, setTitleConfirmModal] = useState('')

	const yesHandlerMethod = () => {

		setRegionField(datosAfiliacion.datosContacto.idRegion ? datosAfiliacion.datosContacto.idRegion : '')
		setProvinciaField(datosAfiliacion.datosContacto.idProvincia ? Number(datosAfiliacion.datosContacto.idProvincia) : '')
		setMunicipioField(datosAfiliacion.datosContacto.idMunicipio ? Number(datosAfiliacion.datosContacto.idMunicipio) : '')
		setCorreoField(datosAfiliacion.datosContacto.correoElectronico)
		setTelefonoResidencialField(datosAfiliacion.datosContacto.telefonoResidencial)
		setTelefonoCelularField(datosAfiliacion.datosContacto.telefonoCelular)
		setCalleField(datosAfiliacion.datosContacto.calle)
		setNoCasaField(datosAfiliacion.datosContacto.noCasa)
		setApartamentoField(datosAfiliacion.datosContacto.apartamento)
		setEdificioField(datosAfiliacion.datosContacto.edificio)
		setSectorField(datosAfiliacion.datosContacto.sector)
		handlerCloseConfirmModal()
		habilitarModoEdicion()
	}

	const noHandlerMethod = () => {
		handlerCloseConfirmModal()
		deshabilitarModoEdicion()
	}

	const [correoField, setCorreoField] = useState('')
	const [telefonoCelularField, setTelefonoCelularField] = useState('')
	const [telefonoResidencialField, setTelefonoResidencialField] = useState('')

	const reiniciarFields = () => {
		setCorreoField('')
		setTelefonoCelularField('')
		setTelefonoResidencialField('')
		setRegionField('')
		setProvinciaField('')
		setMunicipioField('')
		setCalleField('')
		setNoCasaField('')
		setApartamentoField('')
		setEdificioField('')
		setSectorField('')
	}

	const resetMethod = () => {
		deshabilitarModoEdicion()
		reiniciarFields()
	}

	const [municipioDisabled, setMunicipioDisabled] = useState(true)
	const [provinciaDisabled, setProvinciaDisabled] = useState(true)
	

	const saveMethod = () => {
		var validarEdicion = false
		if (modoEdicion) {

			apiService.bitacora.InsBitacora(3, infoAfiliado.codigoAfiliado.toString(),"WEB",null, null, 7)
	
			if (correoField !== datosAfiliacion.datosContacto.correoElectronico) {
				if(validarCorreoElectronico(correoField) === true)
				{
				 	setCambioCorreoModal(true);
				}
				else
				{
					enqueueSnackbar(`Favor de agregar un correo electronico válido`, {
						variant: "warning",
						action: (key) => (
						<Button
							style={{ color: "white", fontWeight: "bold" }}
							onClick={() => closeSnackbar(key)}
						>
							X
						</Button>
						),
					});
				}
			}
			if (
				telefonoCelularField !== datosAfiliacion.datosContacto.telefonoCelular ||
				telefonoResidencialField !== datosAfiliacion.datosContacto.telefonoResidencial
			) {
				setTelefonoActualizado(true)
				validarEdicion = true
				const telefonos = {
					TelefonoCelular: telefonoCelularField,
					TelefonoResidencial: telefonoResidencialField
				}
				apiService.afiliado
					.postUpdateTelefonos(datosAfiliacion.datosPersonales.codigoAfiliado, telefonos)
					.then((response) => {
						if (response.data.result.codigoMensaje) {
							var datos = {
								datosAfiliacion: { ...datosAfiliacion.datosAfiliacion },
								datosContacto: { ...datosAfiliacion.datosContacto },
								datosPersonales: { ...datosAfiliacion.datosPersonales },
								nucleoFamiliar: { ...datosAfiliacion.nucleoFamiliar }
							}
							datos.datosContacto.telefonoCelular = telefonoCelularField
							datos.datosContacto.telefonoResidencial = telefonoResidencialField
							setDatosAfiliacion(datos)
						}
					})
					.catch((err) => console.log(err))
			}

			if (infoAfiliado.tipoAfiliacion === 'Titular') {
				if (
					regionField !== datosAfiliacion.datosContacto.idRegion ||
					provinciaField !== datosAfiliacion.datosContacto.idProvincia ||
					municipioField !== datosAfiliacion.datosContacto.idMunicipio ||
					calleField !== datosAfiliacion.datosContacto.calle ||
					noCasaField !== datosAfiliacion.datosContacto.noCasa ||
					apartamentoField !== datosAfiliacion.datosContacto.apartamento ||
					edificioField !== datosAfiliacion.datosContacto.edificio ||
					sectorField !== datosAfiliacion.datosContacto.sector
				) {
				
					validarEdicion = true
					if (municipioField !== '' && provinciaField !== '') {
						setDireccionActualizado(true)
					
						apiService.afiliado
							.postUpdateDireccion(infoAfiliado.codigoAfiliado, {
								idRegion: regionField,
								idProvincia: provinciaField,
								idMunicipio: municipioField,
								calle: calleField,
								noCasa: noCasaField,
								apartamento: apartamentoField,
								edificio: edificioField ? edificioField : ' ',
								sector: sectorField
							})
							.then((response) => {
								
								if (response.data.result.codigoMensaje) {
			
									var datos = {
										datosAfiliacion: { ...datosAfiliacion.datosAfiliacion },
										datosContacto: { ...datosAfiliacion.datosContacto },
										datosPersonales: { ...datosAfiliacion.datosPersonales },
										nucleoFamiliar: { ...datosAfiliacion.nucleoFamiliar }
									}

									datos.datosContacto.idRegion = regionField
									datos.datosContacto.idProvincia = provinciaField
									datos.datosContacto.idMunicipio = municipioField
									datos.datosContacto.calle = calleField
									datos.datosContacto.noCasa = noCasaField
									datos.datosContacto.apartamento = apartamentoField
									datos.datosContacto.edificio = edificioField
									datos.datosContacto.sector = sectorField
									setDatosAfiliacion(datos)
								}
							})
							.catch((err) => console.log(err))
					} else {
						enqueueSnackbar(`La provincia y municipio no pueden estar vacios.`, {
							variant: 'warning',
							action: (key) => (
								<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
									X
								</Button>
							)
						})
					}
				}
			}

			if (!validarEdicion) {
				enqueueSnackbar('Para editar debe de realizar algun cambio en los campos de contacto y localizacion', {
					variant: 'warning',
					action: (key) => (
						<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
							X
						</Button>
					)
				})
			}
		}
	}

	const llamarModoEdificion = () => {
		setTitleConfirmModal('¿Desea editar la información de contacto?')
		handlerOpenConfirmModal()
	}

	const modoEdificionNoDisponible = () => {
		enqueueSnackbar('Solo el titular puede editar la dirección del nucleo.', {
			variant: 'warning',
			action: (key) => (
				<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
					X
				</Button>
			)
		})
	}

	useEffect(() => {
		setProvinciaBusqueda(provincias.filter((a) => a.codigoPadre === Number(regionField)))
		setProvinciaDisabled(false)
	}, [regionField])

	useEffect(() => {
		setMunicipioBusqueda(municipios.filter((p) => p.codigoPadre === provinciaField))
		setMunicipioDisabled(false)
	}, [provinciaField])

	useEffect(() => {
		if(isValidPassword === true)
		{
			setLoadingModal(true)
			//Actualizar correo electronico
			if (correoField !== datosAfiliacion.datosContacto.correoElectronico) {
				
				apiService.autentificacion
				.SolicitudCambioCorreo(datosAfiliacion.datosPersonales.codigoAfiliado,correoField, false)
				.then((response) => {
					if (response.data.result.codigoMensaje) {
					var datos = {
						datosAfiliacion: { ...datosAfiliacion.datosAfiliacion },
						datosContacto: { ...datosAfiliacion.datosContacto },
						datosPersonales: { ...datosAfiliacion.datosPersonales },
						nucleoFamiliar: { ...datosAfiliacion.nucleoFamiliar },
					};
					datos.datosContacto.correoElectronico = correoField;
					setDatosAfiliacion(datos);
					enqueueSnackbar(`${response.data.result.mensaje}`, {
						variant: "success",
						action: (key) => (
						<Button
							style={{ color: "white", fontWeight: "bold" }}
							onClick={() => closeSnackbar(key)}
						>
							X
						</Button>
						),
					});
					
					}
				})
				.catch((err) => {
					
					enqueueSnackbar(`Ha ocurrido un error para procesar el cambio de correo`, {
						variant: "error",
						action: (key) => (
						<Button
							style={{ color: "white", fontWeight: "bold" }}
							onClick={() => closeSnackbar(key)}
						>
							X
						</Button>
						),
					});
					console.log(err)
				}	
				
				
				).finally( () => {
					if(datosAfiliacion){
						setCambioCorreoModal(false);
						setAuthLogOut()
						setLoadingModal(false)
						navigate('../../login', { replace: true })
						enqueueSnackbar(`Favor de verificar su bandeja de entrada y confirmar el cambio de correo electrónico`, {
							variant: "success",
							action: (key) => (
							<Button
								style={{ color: "white", fontWeight: "bold" }}
								onClick={() => closeSnackbar(key)}
							>
								X
							</Button>
							),
						});
						
					}
					
				})
				
			}
		}
	},[isValidPassword])

	const handlerMetodoNucleoFamiliar =  (item) => {

		 if (!item.mayorEdad) {
		setLoadingModal(true)
		let profile;
		 apiService.afiliado
			.getAfiliado(item.codigoAfiliado)
			.then((response) => {
				setDatosAfiliacion(response.data.result)
				apiService.CRMService.GetFotoPerfil(item.codigoAfiliado)
				.then(res => {
					renderTitleHome(res.data, item, response)
				})
				.catch(err => {
					renderTitleHome('', item, response)
				})
			})
			.catch((err) => console.log(err))
		}else{
			enqueueSnackbar( 'No se puede acceder al perfil de dependientes mayores de edad', {
				variant: 'warning',
				action: (key) => (
				  <Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
					X
				  </Button>
				)
			  })
		}
	}

	const renderTitle = () => {

		const title = (
			<>
				<Grid 
					container
					style={{
						...gridTitleContainerProfile,
						backgroundColor: infoAfiliado.tipoAfiliacion !== 'Titular' ? colors.gray : colors.primary
					}}
				>
					<Grid item xs={12} md={2} style={{ marginBottom: 17 }}>
						<Grid  sx={{ left: { xs: 150, md: 450 } , position:'absolute'}} onClick={() => { 
							setUploadPhotoModal(true)
							setAfiliadoSeleccionado(infoAfiliado.codigoAfiliado)
						}
					}>
						<AddCircleIcon style={{fontSize:30, cursor: 'pointer', }}/>
							
					</Grid>
						
						<img src={!profilePhoto ? profilePicture : `data:image/png;base64,${profilePhoto}`} style={{ ...imgAfiliadoProfile, borderRadius: 150, marginLeft: 25, objectFit:'cover', width: 150, height: 150}} />
						
					</Grid>
					<Grid item xs={12} md={10} style={{ marginBottom: 10, paddingLeft: 50 }}>
						<div style={{ ...containerTitleProfile }}>
							<Grid container>
								<Grid item xs={12}>
									<CustomLabel style={{ ...labelRegistro, color: 'white' }}>
										{datosAfiliacion.datosPersonales ? datosAfiliacion.datosPersonales.nombres : null}{' '}
										{datosAfiliacion.datosPersonales ? datosAfiliacion.datosPersonales.apellidos : null}
									</CustomLabel>
								</Grid>
								<Grid item xs={12} style={{ marginTop: -10 }}>
									<CustomLabel style={{ fontSize: 20 }}>
										{datosAfiliacion.datosPersonales ? datosAfiliacion.datosPersonales.tipoAfiliacion : null} | No. de afiliado{' '}
										{datosAfiliacion.datosPersonales ? datosAfiliacion.datosPersonales.codigoAfiliado : null}
									</CustomLabel>
								</Grid>
	
								{infoAfiliado.tipoAfiliacion === 'Titular' ? (
									<Grid
										item
										xs={12}
										style={{
											...gridNucleoContainerProfile
										}}
									>
										<div onClick={handlerNucleoFamiliar}>
											<Grid container>
												<Grid item xs={1}>
													{UseIconHooks('group', {
														fontSize: 'large',
														style: { marginTop: -6 }
													})}
												</Grid>
												<Grid item xs={11}>
													<CustomLabel style={{ fontSize: 20 }}>
														<label
															style={{
																...labelNucleoFamiliarProfile
															}}
														>
															Nucleo familiar
														</label>
													</CustomLabel>
												</Grid>
											</Grid>
										</div>
									</Grid>
								) : null}
							</Grid>
						</div>
					</Grid>
				</Grid>
			</>
		)

		setTitle(title)
	}

	const cargarFotoPerfil = (event) => {
		uploadProfilePicture(event,AfiliadoSeleccionado );
		renderTitle()
	}

	useEffect(() => {
		renderTitle()
	},[profilePhoto])
	const renderTitleHome = (image, item, response) => {

		const titleHome = (
			<>
				<Grid
					container
					style={{
						...gridTitleContainerProfile,
						backgroundColor: response.data.result.datosPersonales.tipoAfiliacion !== 'Titular' ? colors.gray : colors.primary
					}}
				>

				<Grid item xs={12} md={2} style={{ marginBottom: 17 }}>
					<Grid  sx={{ left: { xs: 150, md: 450 } , position:'absolute'}} onClick={() =>  
						{
							setUploadPhotoModal(true)
							setAfiliadoSeleccionado(item.codigoAfiliado)
						}}>
						<AddCircleIcon style={{fontSize:30, cursor: 'pointer'}}/>	
					</Grid>
			
					<img src={!image ? profilePicture : `data:image/png;base64,${image}`} style={{ ...imgAfiliadoProfile, borderRadius: 150, marginLeft: 25, objectFit:'cover', width: 150, height: 150}} />

				</Grid>
				
					<Grid item xs={12} md={10} style={{ marginBottom: 10, paddingLeft: 50 }}>
						<div style={{ ...containerTitleProfile }}>
							<Grid container>
								<Grid item xs={12}>
									<CustomLabel style={{ ...labelRegistro, color: 'white' }}>
										{response.data.result.datosPersonales ? response.data.result.datosPersonales.nombres : null}{' '}
										{response.data.result.datosPersonales ? response.data.result.datosPersonales.apellidos : null}
									</CustomLabel>
								</Grid>
								<Grid item xs={12} style={{ marginTop: -10 }}>
									<CustomLabel style={{ fontSize: 20 }}>
										{response.data.result.datosPersonales ? response.data.result.datosPersonales.tipoAfiliacion : null} | No. de afiliado{' '}
										{response.data.result.datosPersonales ? response.data.result.datosPersonales.codigoAfiliado : null}
									</CustomLabel>
								</Grid>
								<Grid
									item
									xs={12}
									style={{
										...gridNucleoContainerProfile
									}}
								>
									<div onClick={handlerNucleoFamiliar}>
										<Grid container>
											<Grid item xs={1}>
												{UseIconHooks('group', {
													fontSize: 'large',
													style: { marginTop: -6 }
												})}
											</Grid>
											<Grid item xs={11}>
												<CustomLabel style={{ fontSize: 20 }}>
													<label
														style={{
															...labelNucleoFamiliarProfile
														}}
													>
														Nucleo familiar
													</label>
												</CustomLabel>
											</Grid>
										</Grid>
									</div>
								</Grid>
							</Grid>
						</div>
					</Grid>
				</Grid>
			</>
		)

		setTitle(titleHome)
		dispatch(setInfoPerfilUsuario(response.data.result))
		handlerCloseNucleo()
		closeHandlerLoadingModal()

	}

	const [loadingModal, setLoadingModal] = useState(false)
	const closeHandlerLoadingModal = (_) => setLoadingModal(false)

	useEffect(() => {
		apiService.afiliado
			.getDatosContactos(infoAfiliado.codigoAfiliado)
			.then((response) => {
				var datos = {
					datosAfiliacion: { ...datosAfiliacion.datosAfiliacion },
					datosContacto: { ...response.data },
					datosPersonales: { ...datosAfiliacion.datosPersonales },
					nucleoFamiliar: { ...datosAfiliacion.nucleoFamiliar }
				}
				setDatosAfiliacion(datos)
				setModoEdicion(false)
			})
			.catch((err) => console.log(err))
		setTelefonoActualizado(false)
		setDireccionActualizado(false)
	}, [telefonoActualizado, direccionActualizado])

	return (
		<>
			<div style={{width: '95%'}} >
				<Grid container style={{ marginTop: 10 }} >
					<Grid item xs={12}>
						<CustomLabel style={{ ...labelDatosPersonalesProfile }}>Datos personales</CustomLabel>
					</Grid>
					<Grid item xs={12}>
						<Paper style={{ ...containerDatosPersonalesProfile }} elevation={8}>
							<Grid container padding={1} spacing={2}>
								<Grid item md={6} xs={12}>
									<CustomInput
										id="input-nombres"
										label='Nombres'
										style={{ ...inputRegistro }}
										labelStyle={{ ...customInputLabelProfile }}
										value={
											datosAfiliacion.datosPersonales ? (datosAfiliacion.datosPersonales.nombres ? datosAfiliacion.datosPersonales.nombres : '') : ''
										}
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<CustomInput
										id="input-apellidos"
										label='Apellidos'
										style={{ ...inputRegistro }}
										labelStyle={{ ...customInputLabelProfile }}
										value={
											datosAfiliacion.datosPersonales
												? datosAfiliacion.datosPersonales.apellidos
													? datosAfiliacion.datosPersonales.apellidos
													: ''
												: ''
										}
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<CustomInput
										id="input-sexo"
										label='Sexo'
										style={{ ...inputRegistro }}
										labelStyle={{ ...customInputLabelProfile }}
										value={datosAfiliacion.datosPersonales ? (datosAfiliacion.datosPersonales.sexo ? datosAfiliacion.datosPersonales.sexo : '') : ''}
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<CustomInput
										id="input-estado-civil"
										label='Estado civil'
										style={{ ...inputRegistro }}
										labelStyle={{ ...customInputLabelProfile }}
										value={
											datosAfiliacion.datosPersonales
												? datosAfiliacion.datosPersonales.estadoCivil
													? datosAfiliacion.datosPersonales.estadoCivil
													: ''
												: ''
										}
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<CustomInput
										id="input-edad"
										label='Edad'
										style={{ ...inputRegistro }}
										labelStyle={{ ...customInputLabelProfile }}
										value={datosAfiliacion.datosPersonales ? (datosAfiliacion.datosPersonales.edad ? datosAfiliacion.datosPersonales.edad : '') : ''}
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<CustomInput
										id="input-fecha-nacimiento"
										label='Fecha de nacimiento'
										style={{ ...inputRegistro }}
										labelStyle={{ ...customInputLabelProfile }}
										value={
											datosAfiliacion.datosPersonales
												? datosAfiliacion.datosPersonales.fechaNacimiento
													? format(new Date(datosAfiliacion.datosPersonales.fechaNacimiento), 'yyyy-MM-dd')
													: ''
												: ''
										}
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<CustomInput
										id="input-nacionalidad"
										label='Nacionalidad'
										style={{ ...inputRegistro }}
										labelStyle={{ ...customInputLabelProfile }}
										value={
											datosAfiliacion.datosPersonales
												? datosAfiliacion.datosPersonales.nacionalidad
													? datosAfiliacion.datosPersonales.nacionalidad
													: ''
												: ''
										}
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<CustomInput
										id="documento-identidad"
										label='Doc. identidad'
										style={{ ...inputRegistro }}
										labelStyle={{ ...customInputLabelProfile }}
										value={
											datosAfiliacion.datosPersonales
												? datosAfiliacion.datosPersonales.documentoIdentidad
													? datosAfiliacion.datosPersonales.documentoIdentidad
													: ''
												: ''
										}
									/>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
				<Grid container style={{ marginTop: 10 }}>
					<Grid item xs={12}>
						<CustomLabel style={{ ...labelDatosPersonalesProfile }}>Datos de afiliación</CustomLabel>
					</Grid>
					<Grid item xs={12}>
						<Paper style={{ ...containerDatosPersonalesProfile }} elevation={8}>
							<Grid container padding={1} spacing={2}>
								<TabDatoAfiliacion />
							</Grid>
						</Paper>
					</Grid>
				</Grid>
				{datosAfiliacion.datosPersonales && !datosAfiliacion.datosPersonales.regimen.includes('PENSIONADOS') && (
					<Grid container style={{ marginTop: 10 }}>
						<Grid item xs={12}>
							<CustomLabel style={{ ...labelDatosPersonalesProfile }}>Datos de contacto y localización</CustomLabel>
						</Grid>
						<Grid item xs={12}>
							<Paper
								style={{
									...containerDatosPersonalesProfile,
									backgroundColor: 'rgb(177, 177, 177)'
								}}
								elevation={8}
							>
								<Grid container padding={1} spacing={2}>
									<Grid item xs={12}>
										<Paper
											elevation={0}
											style={{
												...paperContainerEditableProfile,
												backgroundColor: colors.primary
											}}
										>
											<CustomLabel
												style={{
													...labelEditableProfile,
													backgroundColor: colors.primary
												}}
											>
												Editable
											</CustomLabel>
										</Paper>
									</Grid>
									<Grid item md={4} xs={12}>
										{modoEdicion ? (
											<CustomInput
												id='input-telefono-celular'
												label='Teléfono celular'
												style={{ ...inputRegistro, backgroundColor: 'white' }}
												labelStyle={{
													...customInputLabelProfile,
													color: 'white'
												}}
												value={telefonoCelularField ? formatToMobile(telefonoCelularField) : ''}
												onChange={(event) => setTelefonoCelularField(formatToMobile(event.target.value).replace(/-/g, ''))}
											/>
										) : (
											<CustomInput
												id='input-telefono-celular'
												label='Teléfono celular'
												style={{ ...inputRegistro, backgroundColor: 'white' }}
												onClick={llamarModoEdificion}
												labelStyle={{
													...customInputLabelProfile,
													color: 'white'
												}}
												value={
													datosAfiliacion.datosContacto
														? datosAfiliacion.datosContacto.telefonoCelular
															? datosAfiliacion.datosContacto.telefonoCelular
															: ''
														: ''
												}
											/>
										)}
									</Grid>
									<Grid item md={4} xs={12}>
										{modoEdicion ? (
											<CustomInput
												id='input-telefono-residencial'
												label='Teléfono residencial'
												style={{ ...inputRegistro, backgroundColor: 'white' }}
												labelStyle={{
													...customInputLabelProfile,
													color: 'white'
												}}
												value={telefonoResidencialField ? formatToMobile(telefonoResidencialField) : ''}
												onChange={(event) => setTelefonoResidencialField(formatToMobile(event.target.value).replace(/-/g, ''))}
											/>
										) : (
											<CustomInput
												id='input-telefono-residencial'
												label='Teléfono residencial'
												style={{ ...inputRegistro, backgroundColor: 'white' }}
												onClick={llamarModoEdificion}
												labelStyle={{
													...customInputLabelProfile,
													color: 'white'
												}}
												value={
													datosAfiliacion.datosContacto
														? datosAfiliacion.datosContacto.telefonoResidencial
															? datosAfiliacion.datosContacto.telefonoResidencial
															: ''
														: ''
												}
											/>
										)}
									</Grid>
								
										 <Grid item md={4} xs={12}>
                   {modoEdicion ? (
                    <CustomInput
					  id="input-correo"
                      label='Correo electrónico'
                      style={{ ...inputRegistro, backgroundColor: "white" }}
                      labelStyle={{
                        ...customInputLabelProfile,
                        color: "white",
                      }}
                      value={correoField}
                      onChange={(event) => 	setCorreoField(event.target.value)}	
                    />
                  ) : (
                    <CustomInput
					  id="input-correo"
                      label='Correo electrónico'
                      style={{ ...inputRegistro, backgroundColor: "white" }}
                      onClick={llamarModoEdificion}
                      labelStyle={{
                        ...customInputLabelProfile,
                        color: "white",
                      }}
                      value={
                        datosAfiliacion.datosContacto
                          ? datosAfiliacion.datosContacto.correoElectronico
                            ? datosAfiliacion.datosContacto.correoElectronico
                            : ""
                          : ""
                      }
                    />
                  )}
                 
									</Grid>
									{modoEdicion && !disabledDireccionUpdate ? (
										<Grid item md={4} xs={12} marginTop={'2%'}>
											<FormControl fullWidth>
												<InputLabel
													id='input-region'
													style={{
														customInputLabelProfile,
														color: 'white',
														fontFamily: 'Montserrat',
														fontWeight: 'bold',
														fontSize: 20,
														top: regionField ? -18 : -42
													}}
													
												>
													Región
												</InputLabel>
												<Select
													style={{
														boxShadow: ' 0 3px 10px rgb(0 0 0 / 0.2)',
														borderRadius: 10,
														backgroundColor: 'white',
														fontFamily: 'Montserrat'
													}}
													labelId='select-region'
													
													label='region'
													{...register('region', {
														required: 'Debe seleccionar la región.'
													})}
													value={regionField}
													onChange={(event) => {
														setRegionField(event.target.value)
														setProvinciaField('')
														setMunicipioField('')
													}}
												>
													{regiones.map((item) => (
														<MenuItem id={createIdTag("item", item.descripcion)} key={item.codigo} value={item.codigo}>
															{item.descripcion}
														</MenuItem>
													))}
												</Select>
												{errors.region && <span style={{ color: 'red' }}> {errors.region.message} </span>}
											</FormControl>
										</Grid>
									) : (
										<Grid item md={4} xs={12}>
											<CustomInput
												id='input-region'
												label='Región'
												style={{ ...inputRegistro, backgroundColor: 'white' }}
												onClick={disabledDireccionUpdate ? modoEdificionNoDisponible : llamarModoEdificion}
												labelStyle={{
													...customInputLabelProfile,
													color: 'white'
												}}
												value={
													datosAfiliacion.datosContacto ? (datosAfiliacion.datosContacto.region ? datosAfiliacion.datosContacto.region : '') : ''
												}
											/>
										</Grid>
									)}

									{modoEdicion && !disabledDireccionUpdate ? (
										<Grid item md={4} xs={12} marginTop={'2%'}>
											<FormControl fullWidth>
												<InputLabel
													id='input-provincia'
													style={{
														customInputLabelProfile,
														color: 'white',
														fontFamily: 'Montserrat',
														fontWeight: 'bold',
														fontSize: 20,
														top: provinciaField ? -18 : -42
													}}
												>
													Provincia
												</InputLabel>
												<Select
													style={{
														boxShadow: ' 0 3px 10px rgb(0 0 0 / 0.2)',
														borderRadius: 10,
														backgroundColor: 'white',
														fontFamily: 'Montserrat'
													}}
													labelId='select-provincia'
													label='provincia'
													{...register('provincia', {
														required: 'Debe seleccionar la Provincia.'
													})}
													value={provinciaField}
													onChange={(event) => {
														setProvinciaField(event.target.value)
														setMunicipioField('')
													}}
													disabled={provinciaDisabled}
												>
													{provinciaBusqueda.map((item) => (
														<MenuItem id={createIdTag("item",item.descripcion)} key={item.codigo} value={item.codigo}>
															{item.descripcion}
														</MenuItem>
													))}
												</Select>
												{errors.provincia && <span style={{ color: 'red' }}> {errors.provincia.message} </span>}
											</FormControl>
										</Grid>
									) : (
										<Grid item md={4} xs={12}>
											<CustomInput
												label='Provincia'
												style={{ ...inputRegistro, backgroundColor: 'white' }}
												onClick={disabledDireccionUpdate ? modoEdificionNoDisponible : llamarModoEdificion}
												labelStyle={{
													...customInputLabelProfile,
													color: 'white'
												}}
												value={
													datosAfiliacion.datosContacto
														? datosAfiliacion.datosContacto.provincia
															? datosAfiliacion.datosContacto.provincia
															: ''
														: ''
												}
											/>
										</Grid>
									)}

									{modoEdicion && !disabledDireccionUpdate ? (
										<Grid item md={4} xs={12} marginTop={'2%'}>
											<FormControl fullWidth>
												<InputLabel
													id='input-municipio'
													style={{
														customInputLabelProfile,
														color: 'white',
														fontFamily: 'Montserrat',
														fontWeight: 'bold',
														fontSize: 20,
														top: municipioField ? -18 : -42
													}}
												>
													Municipio
												</InputLabel>
												<Select
													style={{
														boxShadow: ' 0 3px 10px rgb(0 0 0 / 0.2)',
														borderRadius: 10,
														backgroundColor: 'white',
														fontFamily: 'Montserrat'
													}}
													labelId='select-municipio'
													label='municipio'
													{...register('municipio', {
														required: 'Debe seleccionar la Municipio.'
													})}
													value={municipioField}
													onChange={(event) => setMunicipioField(event.target.value)}
													disabled={municipioDisabled}
												>
													{municipioBusqueda.map((item) => (
														<MenuItem id={createIdTag("item",item.descripcion)} key={item.codigo} value={item.codigo}>
															{item.descripcion}
														</MenuItem>
													))}
												</Select>
												{errors.municipio && <span style={{ color: 'red' }}> {errors.municipio.message} </span>}
											</FormControl>
										</Grid>
									) : (
										<Grid item md={4} xs={12}>
											<CustomInput
												label='Municipio'
												style={{ ...inputRegistro, backgroundColor: 'white' }}
												onClick={disabledDireccionUpdate ? modoEdificionNoDisponible : llamarModoEdificion}
												labelStyle={{
													...customInputLabelProfile,
													color: 'white'
												}}
												value={
													datosAfiliacion.datosContacto
														? datosAfiliacion.datosContacto.municipio
															? datosAfiliacion.datosContacto.municipio
															: ''
														: ''
												}
											/>
										</Grid>
									)}
									{modoEdicion && !disabledDireccionUpdate ? ( // calle, numero de casa, edificio , apartamento , sector
										<>
											<Grid item md={6} xs={12}>
												<CustomInput
													id='input-calle'
													label='Calle'
													style={{ ...inputRegistro, backgroundColor: 'white' }}
													labelStyle={{
														...customInputLabelProfile,
														color: 'white'
													}}
													value={calleField ? calleField : ''}
													onChange={(event) => setCalleField(event.target.value)}
												/>
											</Grid>
											<Grid item md={6} xs={12}>
												<CustomInput
													id='input-sector'
													label='Sector'
													style={{ ...inputRegistro, backgroundColor: 'white' }}
													labelStyle={{
														...customInputLabelProfile,
														color: 'white'
													}}
													value={sectorField ? sectorField : ''}
													onChange={(event) => setSectorField(event.target.value)}
												/>
											</Grid>
											<Grid item md={4} xs={12}>
												<CustomInput
													id="input-no-casa"
													label='Numero de casa'
													style={{ ...inputRegistro, backgroundColor: 'white' }}
													labelStyle={{
														...customInputLabelProfile,
														color: 'white'
													}}
													value={noCasaField ? noCasaField : ''}
													onChange={(event) => setNoCasaField(event.target.value)}
												/>
											</Grid>
											<Grid item md={4} xs={12}>
												<CustomInput
													id="input-apartamento"
													label='Apartamento'
													style={{ ...inputRegistro, backgroundColor: 'white' }}
													labelStyle={{
														...customInputLabelProfile,
														color: 'white'
													}}
													value={apartamentoField ? apartamentoField : ''}
													onChange={(event) => setApartamentoField(event.target.value)}
												/>
											</Grid>
											<Grid item md={4} xs={12}>
												<CustomInput
													id='input-edificio'
													label='Edificio'
													style={{ ...inputRegistro, backgroundColor: 'white' }}
													labelStyle={{
														...customInputLabelProfile,
														color: 'white'
													}}
													value={edificioField ? edificioField : ''}
													onChange={(event) => setEdificioField(event.target.value)}
												/>
											</Grid>
										</>
									) : (
										<Grid item xs={12}>
											<CustomInput
												id='input-direccion'
												label='Dirección'
												style={{ ...inputRegistro, backgroundColor: 'white' }}
												onClick={disabledDireccionUpdate ? modoEdificionNoDisponible : llamarModoEdificion}
												labelStyle={{
													...customInputLabelProfile,
													color: 'white'
												}}
												value={
													datosAfiliacion.datosContacto
														? datosAfiliacion.datosContacto.direccion
															? datosAfiliacion.datosContacto.direccion
															: ''
														: ''
												}
											/>
										</Grid>
									)}
								</Grid>
							</Paper>
						</Grid>
						<Grid item xs={12} marginTop={1}>
							<div
								id={"btn-guardar"}
								style={{
									display: 'flex',
									justifyContent: 'center'
								}}
							>
								<CustomSave
									onPressReset={resetMethod}
									onPressSave={saveMethod}
									styleSave={{
										color: modoEdicion ? colors.primary : colors.grayLight
									}}
								/>
							</div>
						</Grid>
					</Grid>
				)}
			</div>

			<CustomConfirmModal
				open={openConfirmModal}
				closeHandler={handlerCloseConfirmModal}
				mensaje={titleConfirmModal}
				yesHandler={yesHandlerMethod}
				noHandler={noHandlerMethod}
			/>

			<DialogNucleoFamiliar
				open={openNucleo}
				nucleo={nucleo}
				handlerClose={handlerCloseNucleo}
				onPress={(item) => handlerMetodoNucleoFamiliar(item)}
			/>
			{
				cambioCorreoModal === false ? ''
				: (
					<CambioCorreoClave 
						tipoCuenta = {2}
						noContraro ={datosAfiliacion}
						showModal={true}
						isValidPassword = {(value) => setIsValidPassword(value)}
						correoNuevo={ datosAfiliacion.datosContacto.correoElectronico}
						documento={datosAfiliacion.datosPersonales.documentoIdentidad}
						cancel={setCambioCorreoModal}
					/>
				)
			}

			<UploadPhotoBox 
				open={uploadPhotoModal} 
				evento={(result) => cargarFotoPerfil(result)} 
				closed={(result) => setUploadPhotoModal(result)} 
				codigoAfiliado={AfiliadoSeleccionado}
			/>
			<ModalLoading open={loadingModal} onCloseHandler={closeHandlerLoadingModal} />
		</>
	)
}

export default Profile
