import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import configurationSlice from "./features/configuration/configurationSlice";
import notificacionSlice from "./features/notificacion/notificacionSlice";
import afiliadoSlice from "./features/afiliado/afiliadoSlice";

export const store = configureStore({
  reducer: {
    configuration: configurationSlice,
    notificacion: notificacionSlice,
    afiliado: afiliadoSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
