import { Routes, Route, Navigate } from 'react-router-dom'
import InterceptorProfile from '../components/profile/interceptorProfile'
import AdminPage from '../pages/admin/admin'
import CambioClave from '../pages/confirmacion/cambioClave'
import ConfirmacionCorreo from '../pages/confirmacion/confirmacionCorreo'
import ConfirmacionPago from '../pages/confirmacion/confirmacionPago'
import Cobertura from '../pages/consulta/cobertura'
import HistorialConsumo from '../pages/consulta/historialConsumo'
import Prestador from '../pages/consulta/prestador'
import Home from '../pages/home/Home'
import Login from '../pages/login/Login'
import Pagos from '../pages/pagos/pagos'
import Profile from '../pages/profile/profile'
import Solicitudes from '../pages/solicitudes/solicitudes'
import Citas from '../pages/turnos/citas'
import SolicitudAfiliacion from '../pages/formulario/solicitudAfiliacion'
import InterceptorAnonimo from '../components/interceptors/interceptorAnonimo'
import PagoMovil from '../pages/pagos/movil/pagoMovil'
import ClubBeneficiarios from "../pages/club/clubBeneficiarios"
import CambioCorreo from "../pages/cambioCorreo/cambioCorreo"
import ReversoCorreo from "../pages/cambioCorreo/reversoCorreo"
import ConfirmarAfiliado from "../pages/confirmacion/confirmarAfiliado";
import Carne from '../pages/carne/carne'

const MainRoute = () => {
	return (
		<Routes>
			<Route path='/' element={<Home />} />
			<Route
				path='profile'
				element={
					<InterceptorProfile>
						<Profile />
					</InterceptorProfile>
				}
			/>
			<Route path='solicitudes' element={<Solicitudes />} />
			<Route path='club' element={<ClubBeneficiarios />} />
			<Route path='consulta'>
				<Route
					path='prestadores'
					element={
						<InterceptorAnonimo>
							<Prestador />
						</InterceptorAnonimo>
					}
				/>
				<Route path='consumos' element={<HistorialConsumo />} />
				<Route
					path='cobertura'
					element={
						<InterceptorAnonimo>
							<Cobertura />
						</InterceptorAnonimo>
					}
				/>
			</Route>
			<Route path='login' element={<Login />} />
			<Route path='admin' element={<AdminPage />} />
			<Route path='solicitud'>
				<Route path='confirmacioncorreo/:codigoAfiliado' element={<ConfirmacionCorreo />} />
				<Route path='cambioClave/:Token' element={<CambioClave />} />
				<Route path='confirmacionPago/:ResponseCode' element={<ConfirmacionPago />} />
				<Route path='CambioCorreo/:Token' element={<CambioCorreo />} />
				<Route path='ReversoCorreo/:Token' element={<ReversoCorreo />} />
        <Route  path="confirmarAfiliado/:Token" element={<ConfirmarAfiliado />} />
			</Route>
			<Route path='pagos'>
				<Route path='' element={<Pagos />} />
				<Route path='movil/:Token' element={<PagoMovil />} />
			</Route>

			<Route path='citas' element={<Citas />} />
			<Route path='formulario'>
				<Route path='solicitudAfiliacion' element={<SolicitudAfiliacion />} />
			</Route>
			<Route path='carne' element={<Carne />} />
			<Route path="*" element={<Navigate to="/" replace />}  />
		</Routes>
	)
}



export default MainRoute();
