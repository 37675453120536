import React, { useState } from "react";

const TextEllipsis = ({
  originalText,
  textLimit,
  hasShowMoreButton,
  buttonHideText,
  buttonShowText,
  buttonStyle,
}) => {
  const [fullText, setFullText] = useState(originalText);
  const [showAllText, setShowAllText] = useState(false);
  const [ellipsisText, setEllipsisText] = useState(() => {
    return originalText.length > textLimit
      ? `${originalText.substring(0, textLimit)}...`
      : fullText;
  });

  const toggleShowText = () => {
    setShowAllText(!showAllText);
  };

  const getTextToShow = () => {
    if (hasShowMoreButton && originalText.length > textLimit) {
      return (
        <>
          {showAllText ? fullText : ellipsisText}{" "}
          <a
            onClick={toggleShowText}
            style={{ cursor: "pointer", ...buttonStyle }}
          >
            {showAllText ? buttonHideText : buttonShowText}
          </a>
        </>
      );
    }
    return ellipsisText;
  };

  return getTextToShow();
};

export default TextEllipsis;
