import { createContext, useContext, useEffect, useState } from 'react';
import * as apiServices from '../../api';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';


const ProfilePhotoContext = createContext();

export const ProfilePhotoProvider = ({children}) => {
  const [profilePhoto, setProfilePhoto] = useState(null);
  const infoAfiliado = useSelector((state) => state.afiliado.infoAfiliado);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
 
  const updateProfilePhoto = (codigoAfiliado) => {
    if(codigoAfiliado != undefined)
    {
      apiServices.CRMService.GetFotoPerfil(codigoAfiliado)
      .then(res => {
          setProfilePhoto(res.data)
      })
      .catch(err => console.log(err))
    }
  };

  const uploadProfilePicture = (event, codigoAfiliado) => {
      const file = event.target.files[0];
        if (file) {
          enqueueSnackbar('Se estará cargando la foto de perfil.', {
            variant: 'info',
            action: (key) => (
                <Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
                    X
                </Button>
            )
        })
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            apiServices.CRMService.PostFotoPerfil(file, codigoAfiliado)
            .then(res => {
              enqueueSnackbar('Carga realizada.', {
                variant: 'success',
                action: (key) => (
                    <Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
                        X
                    </Button>
                )
            })
              setProfilePhoto(file)
              window.location.reload(true);
            })
            .catch(err => {
                enqueueSnackbar('Ha ocurrido un error durante el proceso de carga de foto de perfil.', {
                  variant: 'error',
                  action: (key) => (
                      <Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
                          X
                      </Button>
                  )
              })
            })
          };
      }
  }

  return (
    <ProfilePhotoContext.Provider value={{ profilePhoto, updateProfilePhoto, uploadProfilePicture }}>
      {children}
    </ProfilePhotoContext.Provider>
  );
};


export const useProfilePhoto = () => {
  return useContext(ProfilePhotoContext);
};