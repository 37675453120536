import React, { useState, useEffect, useRef } from 'react'

import { Divider, Grid, Paper } from '@mui/material'

import colors from '../../../config/colors'

import useSetTitlePage from '../../hooks/useSetTitlePage'

import { labelTitlePrestador } from '../../global/styles'

import CustomLabel from '../../components/common/customLabel'

import * as apiService from '../../../api'

import UseIconHooks from '../../hooks/useIconHooks'

import ModalLoading from '../../components/common/modalLoading'

import { format } from 'date-fns'

//import { useSnackbar } from "notistack";
import { useParams } from 'react-router-dom'

import { useNavigate } from 'react-router-dom'
import PagoItem from '../../components/pagos/pagoItem'
import CustomConfirmModal from '../../components/common/customConfirmModal'
import { useSelector } from 'react-redux'

const Pagos = () => {
	// const codigoAfiliado = 13252560;
	const [session,setSession] = useState("")
	const infoAfiliado = useSelector((state) => state.afiliado.infoAfiliado)
	const [pagos, setPagos] = useState([])
	const [loading, setLoading] = useState(false)
	const [openConfirmModal, setOpenConfirmModal] = useState(false)

	const handlerCloseConfirmModal = () => {
		setOpenConfirmModal(false)
		setPagoActual({})
	}

	const [messageConfirmModel, setMessageConfirmModel] = useState('')
	const [pagoActual, setPagoActual] = useState({})

	const noHandlerMethod = () => {
		handlerCloseConfirmModal()
	}

	const yesHandlerMethod = () => {
		handlerRealizarPago(pagoActual)
	}

	const formPago = useRef(null)

	// const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const [montoTotal, setMontoTotal] = useState('')
	const [factura, setFactura] = useState('')
	const [ordenCompra, setOrdenCompra] = useState('')

	const columnas = [
		{
			id: 'numeroFactura',
			label: 'Numero Factura',
			minWidth: 170
		},
		{
			id: 'estatusFactura',
			label: 'Estatus',
			minWidth: 170
		},
		{
			id: 'fechaFacturacion',
			label: 'Fecha Facturacion',
			minWidth: 170
		},
		{
			id: 'fechaLimitePago',
			label: 'Fecha Limite Pago',
			minWidth: 170
		},
		{
			id: 'montoTotal',
			label: 'Monto Total',
			minWidth: 170,
			format: (value) => '$ ' + value.toLocaleString('en-US')
		}
	]

	const acciones = [
		{
			icono: UseIconHooks('visibility', {
				style: { color: colors.primary }
			}),
			metodo: (value) => {
				alert('ver detalle')

				//  setPrestadorActual(value);
				apiService.prestador
					.getDetallePrestador(value.codigoPrestador, value.tipoPrestador)
					.then((response) => {
						//  setDetallePrestador(response.data.result);
						// setOpen(true);
					})
					.catch((err) => console.log(err))
			},
			label: 'Ver detalle'
		},
		{
			icono: UseIconHooks('money', {
				style: { color: colors.primary }
			}),
			metodo: (value) => {
				//  alert("pago");
				setFactura(value.numeroFactura)
				setMontoTotal(value.montoTotal)
				apiService.pagos
					.getOrdenCompraGenerada(value.numeroFactura, value.montoTotal, infoAfiliado.codigoAfiliado)
					.then((response) => {
						setOrdenCompra(response.data.numeroOrden)
						formPago.current.submit()
					})
					.catch((err) => console.log())
			},
			label: 'Realizar pago'
		}
	]

	let params = useParams()

	var navigate = useNavigate()

	useEffect(() => {
		if (localStorage.getItem('autenticado') == 'false') navigate('../../login', { replace: true })
		/*
    if (params.ResponseCode) {
      const ResponseCode = params.ResponseCode.split("=")[1];

      if (ResponseCode) {
        ;
        apiService.pagos
          .getCatalogoRespuestaCarnet()
          .then((response) => {
            ;
            const mensaje = response.data.filter(
              (a) => a.codigo === ResponseCode
            )[0].descripcion;
            if (ResponseCode == "00") {
              enqueueSnackbar(`${mensaje}`, {
                variant: "success",
                action: (key) => (
                  <Button
                    style={{ color: "white", fontWeight: "bold" }}
                    onClick={() => closeSnackbar(key)}
                  >
                    Cerrar
                  </Button>
                ),
              });
            } else {
              enqueueSnackbar(`${mensaje}`, {
                variant: "warning",
                action: (key) => (
                  <Button
                    style={{ color: "white", fontWeight: "bold" }}
                    onClick={() => closeSnackbar(key)}
                  >
                    Cerrar
                  </Button>
                ),
              });
            }
          })
          .catch((err) => console.log(err));
      }
    }
*/
		setLoading(true)
		apiService.pagos
			.getAllFacturaPendientePago(infoAfiliado.codigoAfiliado)
			.then((response) => {
				response.data.map((item) => {
					item.fechaFacturacion = format(new Date(item.fechaFacturacion), 'yyyy-MM-dd')
					item.fechaLimitePago = format(new Date(item.fechaLimitePago), 'yyyy-MM-dd')
				})
				setPagos(response.data)
				setLoading(false)
			})
			.catch((err) => console.log(err))
	}, [])

	const TitlePagos = (
		<>
			<CustomLabel style={{ ...labelTitlePrestador }}>Gestión de pagos</CustomLabel>
			<Divider />
			<br />
			<br />
		</>
	)

	useSetTitlePage(TitlePagos)

	const handlerRealizarPago = (value) => {
		setFactura(value.numeroFactura)
		setMontoTotal(value.montoTotal)
		apiService.pagos
			.getOrdenCompraGenerada(value.numeroFactura, value.montoTotal, infoAfiliado.codigoAfiliado)
			.then((response) => {
				setOrdenCompra(response.data.numeroOrden)
				const body = {
					ordenId: response.data.numeroOrden.toString(),
					transactionId: value.numeroFactura.toString(),
					amount: value.montoTotal.toString()
				  }
				  apiService.pagos.postGenerarSession(body,infoAfiliado.codigoAfiliado)
				  .then(response => {
					if (response.data.result)
					{
					  setSession(response.data.result.session)
					  setTimeout(() => {
						formPago.current.submit()
					  },100)
					 
					}
				   
				  })
				  .catch(err => {
					console.log(err)
				  })
			})
			.catch((err) => console.log())
	}

	const abrirConfirmModal = (pago) => {
		setPagoActual(pago)
		setMessageConfirmModel('¿Desea realizar este pago?')
		setOpenConfirmModal(true)
	}

	return (
		<>
			{' '}
			<form ref={formPago} action={process.env.REACT_APP_CARDNET_URL} method='post'>
				<Grid container>
					<Grid item xs={12}>
						<CustomLabel
							style={{
								fontFamily: 'Montserrat',
								fontSize: 25,
								fontWeight: 'bold'
							}}
						>
							Listado de facturas
						</CustomLabel>
					</Grid>
					<Grid item xs={12}>
						{pagos && pagos.length !== 0 ? (
							<>
								<Paper
									elevation={2}
									style={{
										height: 450,
										width: '100%',
										padding: 15,
										overflow: 'scroll',
										overflowX: 'hidden'
									}}
								>
									{pagos.map((pago) => (
										<Paper
											elevation={5}
											key={pago.numeroFactura.toString()}
											style={{
												cursor: 'pointer'
											}}
											onClick={() => abrirConfirmModal(pago)}
										>
											<PagoItem
												numeroFactura={pago.numeroFactura}
												estatus={pago.estatusFactura}
												fechaFactura={pago.fechaFacturacion}
												fechaLimitePago={pago.fechaLimitePago}
												montoTotal={pago.montoTotal}
											/>
										</Paper>
									))}
								</Paper>
							</>
						) : (
							<CustomLabel
								style={{
									fontFamily: 'Montserrat',
									fontSize: 18,
									fontWeight: 'bold',
									marginTop: 20
								}}
							>
								No cuenta con factura(s) pendiente(s).
							</CustomLabel>
						)}
					</Grid>
					{/*
                    <Grid item xs={12} marginTop={3}>
            <CustomTable columns={columnas} acciones={acciones} rows={pagos} />
          </Grid>
          
          */}
				</Grid>

				<ModalLoading open={loading} onCloseHandler={() => setLoading(false)} />

				<CustomConfirmModal
					open={openConfirmModal}
					closeHandler={handlerCloseConfirmModal}
					mensaje={messageConfirmModel}
					yesHandler={yesHandlerMethod}
					noHandler={noHandlerMethod}
				/>

			<p>
          		<input name='SESSION' value={session} type='hidden' />
        	</p>

			</form>
		</>
	)
}

export default Pagos
