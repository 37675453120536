import React, { useState, useEffect } from "react";

import { Dialog, DialogContent, Grid, Button } from "@mui/material";

import {
  dialogContentDetallePrestador,
  inputRegistro,
} from "../../../global/styles";

import CustomTable from "../../common/customTable";
import CustomInput from "../../common/customInput";

import * as apiService from "../../../../api/";
import CustomLabel from "../../common/customLabel";
import colors from "../../../../config/colors";
import { useSnackbar } from "notistack";

const DialogBusquedaCentro = ({
  open,
  handlerCloseModal,
  handlerSelectedItem,
}) => {
  const columnas = [
    {
      id: "nombre",
      label: "Nombre",
      minWidth: 150,
    },
    {
      id: "especialidad",
      label: "Especialidad",
      minWidth: 120,
    },
    {
      id: "direccion",
      label: "Direccion",
      minWidth: 150,
    },
    {
      id: "telefono",
      label: "Telefono",
      minWidth: 100,
    },
    {
      id: "regimen",
      label: "Regimen",
      minWidth: 100,
    },
    {
      id: "municipio",
      label: "Municipio",
      minWidth: 100,
    },
  ];

  const [prestadores, setPrestadores] = useState([]);
  const [nombreField, setNombreField] = useState("");

  const handlerNombreField = ({ target }) => setNombreField(target.value);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    apiService.prestador
      .getAllPrestadores("", "", nombreField, "", "")
      .then((response) => setPrestadores(response.data.result))
      .catch((err) => {
        enqueueSnackbar("Favor intente mas tarde.", {
          variant: "error",
          action: (key) => (
            <Button
              style={{ color: "white", fontWeight: "bold" }}
              onClick={() => closeSnackbar(key)}
            >
              X
            </Button>
          ),
        });
      });
  }, [nombreField]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setNombreField("");
          setPrestadores([]);
          handlerCloseModal();
        }}
        maxWidth='lg'
        PaperProps={{
          style: {
            borderRadius: 15,
          },
        }}
      >
        <DialogContent
          style={{ ...dialogContentDetallePrestador, padding: 15 }}
        >
          <Grid item xs={12}>
            <CustomLabel
              style={{ fontFamily: "Montserrat", color: colors.gray }}
            >
              Listado de centros
            </CustomLabel>
          </Grid>
          <Grid item xs={12}>
            <CustomInput
              style={{ ...inputRegistro, backgroundColor: "white" }}
              placeholder='Nombre'
              value={nombreField}
              onChange={handlerNombreField}
            />
          </Grid>
          <Grid item xs={12} marginTop={3}>
            <CustomTable
              columns={columnas}
              rows={prestadores}
              handlerSelectedItem={(item) => {
                handlerSelectedItem(item);
                setNombreField("");
                setPrestadores([]);
              }}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogBusquedaCentro;
