
const CustomButton = ({sx, type, onClick, children, disabled = false}) => {
    return(
        <button style={sx} type={type} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    )

}

export default CustomButton;