import { createTheme } from "@material-ui/core";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: 'solid 2px #3DAE2B',  // Set the border here directly
          borderRadius: 10,  // Border radius for the input
            
          // Apply hover state for the outline
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 'solid 1px #3DAE2B',
          },
            
          // Apply focus state for the outline
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'solid 1px #3DAE2B',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            border: 'solid 2px #3DAE2B',
            borderRadius: 10,
            
            // Apply hover state for the outline
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'solid 1px #3DAE2B',
            },
            
            // Apply focus state for the outline
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'solid 1px #3DAE2B',
            },
          },
        },
      },
    },
  },
});

  export default theme;