import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import { dialogContentDetallePrestador } from "../global/styles";
import CustomLabel from "../components/common/customLabel";
import colors from "../../config/colors";

const DialogSessionTimeout = ({ open, handlerCloseModal }) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handlerCloseModal}
        maxWidth='md'
        PaperProps={{
          style: {
            borderRadius: 15,
          },
        }}
      >
        <DialogContent style={{ ...dialogContentDetallePrestador }}>
          <div style={{ padding: 25 }}>
            <CustomLabel
              style={{
                fontFamily: "Montserrat",
                fontSize: 35,
                color: colors.primary,
                fontWeight: "bold",
              }}
            >
              <label>
                Esta sesión esta próximo a vencerse, si no realiza alguna
                actividad se cerrara la sesión.
              </label>
            </CustomLabel>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogSessionTimeout;
