import http from "../../config/http";
import { removeAccentsAndSpecialChars, shortenName, base64ToBlob } from "../../app/utils/helpers";

import { 
    GET_TIPOS_REEMBOLSOS,
    GET_BANCOS,
    GET_DOCUMENTO_TIPO_REEMBOLSO,
    GET_ESTADO_AUTOMATICO_CRM,
    POST_SOLICITUDREEMBOLSO,
    UPDATE_SOLICITUD_REEMBOLSO
} from '../endpoints';

  //Endpoint para la consulta de los tipos de reembolso Utility/ConsTipoReembolso
  const GetTiposReembolsos = () => {
    let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}
    return http.get(`utility/${GET_TIPOS_REEMBOLSOS}`,configuration);
}

// Endpoint para la consulta de los bancos Utility/Bancos
const GetBancos = () => {

    let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}
    return http.get(`utility/${GET_BANCOS}`,configuration);
}

// Endpoint que acepta un parametro para obtener los documentos requeridos por el tipo de reembolsos utility/ConsDocumentosTipoReembolsos
const GetDocumentosTipoReembolsos = (_tipoReembolso) => {
    let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}

    return http.get(`utility/${GET_DOCUMENTO_TIPO_REEMBOLSO}?tipoReembolso=${_tipoReembolso}`,configuration)
}

// Endpoint que acepta dos parametros para obtener el estado en CRM
const GetEstadoAutomaticoCRM = (_estado, _asuntoCRM) => {

    return http.get(`utility/${GET_ESTADO_AUTOMATICO_CRM}?estado=${_estado}&asuntoCRM=${_asuntoCRM}`)
}


const postSolicitudesReembolsoV2 =  (_contrato, _solicitante, _correoSolicitante, _telefonoSolicitante, _tipoReembolso,_fechaServicio, _montoReclamado, _centroSalud, _medico, _especialidad , _motivo, _noCuenta, _tipoCuenta, _banco, _nombreTitularCuenta, _tipoDocumentoTitularCuenta, _documentoTitularCuenta, _idTipoReembolso,_codigoSolicitante, image) => {
  
    const outputArray = [];
  const nameCount = {};
  
  image.forEach((item, index) => {
    let baseName = removeAccentsAndSpecialChars(item.type);
    baseName = shortenName(baseName, 20);

    if (nameCount[baseName]) {
        nameCount[baseName] += 1;
    } else {
        nameCount[baseName] = 1;
    }

    const count = nameCount[baseName];
    const newName = count > 1 ? `${baseName}_${count}` : baseName;
    const fileExtension = item.name.split('.').pop();
    const formattedName = `${newName}.${fileExtension}`;

    const blob = base64ToBlob(item.content, item.fileType);
    const newFile = new File([blob], formattedName, { type: item.fileType });
    outputArray.push(newFile);
    });

    const bodyFormData = new FormData();
    outputArray.forEach(item => {
        bodyFormData.append('images', item);
    });

    bodyFormData.append("solicitante",_solicitante)
    bodyFormData.append("correoSolicitante",_correoSolicitante)
    bodyFormData.append("telefonoSolicitante",_telefonoSolicitante)
    bodyFormData.append("tipoReembolso",_tipoReembolso)
    bodyFormData.append("fechaServicio",_fechaServicio)
    bodyFormData.append("montoReclamado",_montoReclamado)
    bodyFormData.append("centroSalud",_centroSalud)
    bodyFormData.append("medico",_medico)
    bodyFormData.append("especialidad",_especialidad)
    bodyFormData.append("motivo",_motivo)
    bodyFormData.append("idTipoReembolso",_idTipoReembolso)
    bodyFormData.append("noCuenta",_noCuenta)
    bodyFormData.append("tipoCuenta",_tipoCuenta)
    bodyFormData.append("banco",_banco)
    bodyFormData.append("nombreTitularCuenta",_nombreTitularCuenta)
    bodyFormData.append("tipoDocumentoTitularCuenta", _tipoDocumentoTitularCuenta)
    bodyFormData.append("documentoTitularCuenta",_documentoTitularCuenta)
    bodyFormData.append("codigoSolicitante",`${_codigoSolicitante}`)
     
    return http.post(`CRM/v2/${POST_SOLICITUDREEMBOLSO}?contrato=${_contrato}`,bodyFormData, {
        headers: { "Content-Type": "multipart/form-data",  Authorization: 'Bearer ' + localStorage.getItem('token')  }
    })

}

const updateSolicitudReembolsoV2 =  (_numeroTicket, _solicitante, _correoSolicitante, _telefonoSolicitante, _tipoReembolso,_fechaServicio, _montoReclamado, _centroSalud, _medico, _especialidad , _motivo, _noCuenta, _tipoCuenta, _banco, _nombreTitularCuenta,_tipoDocumentoTitularCuenta, _documentoTitularCuenta,_idTipoReembolso,_codigoSolicitante,image) => {
    
    const outputArray = [];
    const nameCount = {};
    
    image.forEach((item, index) => {
      let baseName = removeAccentsAndSpecialChars(item.type);
      baseName = shortenName(baseName, 20);
  
      if (nameCount[baseName]) {
          nameCount[baseName] += 1;
      } else {
          nameCount[baseName] = 1;
      }
  
      const count = nameCount[baseName];
      const newName = count > 1 ? `${baseName}_${count}` : baseName;
      const fileExtension = item.name.split('.').pop();
      const formattedName = `${newName}.${fileExtension}`;
  
      const blob = base64ToBlob(item.content, item.fileType);
      const newFile = new File([blob], formattedName, { type: item.fileType });
      outputArray.push(newFile);
      });
  
      const bodyFormData = new FormData();
      outputArray.forEach(item => {
          bodyFormData.append('images', item);
      });
    
    bodyFormData.append("solicitante",_solicitante)
    bodyFormData.append("correoSolicitante",_correoSolicitante)
    bodyFormData.append("telefonoSolicitante",_telefonoSolicitante)
    bodyFormData.append("tipoReembolso",_tipoReembolso)
    bodyFormData.append("fechaServicio",_fechaServicio)
    bodyFormData.append("montoReclamado",_montoReclamado)
    bodyFormData.append("centroSalud",_centroSalud)
    bodyFormData.append("medico",_medico)
    bodyFormData.append("especialidad",_especialidad)
    bodyFormData.append("motivo",_motivo)
    bodyFormData.append("idTipoReembolso",_idTipoReembolso)
    bodyFormData.append("noCuenta",_noCuenta)
    bodyFormData.append("tipoCuenta",_tipoCuenta)
    bodyFormData.append("banco",_banco)
    bodyFormData.append("nombreTitularCuenta",_nombreTitularCuenta)
    bodyFormData.append("tipoDocumentoTitularCuenta", _tipoDocumentoTitularCuenta)
    bodyFormData.append("documentoTitularCuenta",_documentoTitularCuenta)
    bodyFormData.append("codigoSolicitante",`${_codigoSolicitante}`)
    
    return http.post(`CRM/v2/${UPDATE_SOLICITUD_REEMBOLSO}?numeroTicket=${_numeroTicket}`,bodyFormData, {
        headers: { "Content-Type": "multipart/form-data",  Authorization: 'Bearer ' + localStorage.getItem('token')  }
    })
}


export {
    GetTiposReembolsos,
    GetBancos,
    GetDocumentosTipoReembolsos,
    GetEstadoAutomaticoCRM,
    postSolicitudesReembolsoV2,
    updateSolicitudReembolsoV2
};