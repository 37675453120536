import axios from 'axios'

import {
	AUTENTICAR,
	CONSULTA_NACIONALIDADES,
	TITULAR_CREAR,
	CONSULTA_JCE,
	CONSULTA_CEDULA,
	CONSULTA_PASAPORTE,
	CONSULTA_TIPOS_TELEFONO,
	CONSULTA_MUNICIPIOS,
	CONSULTA_TIPO_DOCUMENTO_PROBATORIO_SERVICIO,
	CONSULTA_TIPO_DOCUMENTO_PROBATORIO_REGIMEN,
	GEOLOCATION,
	DROP_DOCUMENTOS_REQUERIDOS
} from '../endpoints'

/*
axios.create({
    baseURL: process.env.REACT_APP_API_OPERACIONES,
  });
  */
const baseURL = process.env.REACT_APP_API_OPERACIONES

export const postAutenticarUsuario = (credenciales) => {
	return axios.post(`${baseURL}usuario/${AUTENTICAR}`, credenciales)
}

export const getConsultaNacionalidad = () => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token-operaciones') }
	}
	return axios.get(`${baseURL}utility/${CONSULTA_NACIONALIDADES}`, configuration)
}

// Eliminar
export const getConsultaJce = (cedula) => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token-operaciones') }
	}
	return axios.get(`${baseURL}ciudadano/${CONSULTA_JCE}?cedula=${cedula}`, configuration)
}

export const postCrearSolicitudTitular = (body) => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token-operaciones') }
	}
	return axios.post(`${baseURL}solicitud/${TITULAR_CREAR}`, body, configuration)
}

export const getAllPlanDisponible = () => {
	return [
		/*
    {
      codigo: 1,
      descripcion: "Especial",
    },
    {
      codigo: 2,
      descripcion: "Avanzado",
    },
    {
      codigo: 3,
      descripcion: "Maximo",
    },
    {
      codigo: 4,
      descripcion: "Premium",
    },
    {
      codigo: 5,
      descripcion: "Pensionados y jubilados",
    },
    */
		{
			codigo: 4,
			descripcion: 'SeNaSa Larimar'
		}
	]
}

export const getConsultaCedula = (cedula) => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token-operaciones') }
	}
	return axios.get(`${baseURL}ciudadano/${CONSULTA_CEDULA}?cedula=${cedula}`, configuration)
}

export const getConsultaPasaporte = (pasaporte) => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token-operaciones') }
	}
	return axios.get(`${baseURL}ciudadano/${CONSULTA_PASAPORTE}?pasaporte=${pasaporte}`, configuration)
}

export const getConsultaTipoTelefono = () => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token-operaciones') }
	}
	return axios.get(`${baseURL}utility/${CONSULTA_TIPOS_TELEFONO}`, configuration)
}

export const getConsultaMunicipios = () => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token-operaciones') }
	}
	return axios.get(`${baseURL}utility/${CONSULTA_MUNICIPIOS}`, configuration)
}

export const getConsultaTipoDocumentoProbatorioServicio = (idRegimen, nacional, extranjero, titular) => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token-operaciones') }
	}
	return axios.get(
		`${baseURL}utility/${CONSULTA_TIPO_DOCUMENTO_PROBATORIO_SERVICIO}?idRegimen=${idRegimen}&nacional=${nacional}&extranjero=${extranjero}&titular=${titular}  `,
		configuration
	)
}

export const getConsultaTipoDocumentoProbatorioRegimen = (idRegimen) => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token-operaciones') }
	}
	return axios.get(`${baseURL}utility/${CONSULTA_TIPO_DOCUMENTO_PROBATORIO_REGIMEN}?idRegimen=${idRegimen}`, configuration)
}

export const getConsultarCiudadano = (body) => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token-operaciones') }
	}
	return axios.post(`${baseURL}ciudadano`, body, configuration)
}

export const EnviarFactura = (body) =>
	axios.post(`pas/crear/factura?documento=${body.document}&tipoDocumento=${body.docType}&idUsuario=${body.userId}`, body)

export const CrearFactura = (body) =>
axios.post(`pas/crear/factura?documento=${body.document}&tipoDocumento=${body.docType}&idUsuario=${body.userId}`, body)

export const CreatPoliza = (body) => {
	return axios.post(`pas/crear/poliza?idSolicitud=${body.solicitudId}&idUsuario=${body.userId}`, body)
}

export const UploadDocumentoSolicitud = (body) => axios.post(`solicitud/envia/documento`, body)

export const CrearSolicitud = (body) => axios.post(`solicitud/crear`, body)

export const CrearCiudadano = (body) => axios.post(`ciudadano/crear`, body)

export const CrearAfiliacion = (body) => axios.post(`afiliacion/crear`, body)

export const setUserGeolocation = (geolocation) =>
axios.get(`utility/obtener/localizacion?latitud=${geolocation.latitude}&longitud=${geolocation.longitude}`)

export const getTiposDocumentosRequeridos = (body) => axios.post(`utility/consulta/documentos/requeridos`, body)

export const cargarDocumento = (body) => {
	const formData = objectToFormData(body)
	return axios.post(`solicitud/envia/documento`, formData)
}

const objectToFormData = (body) => {
	const formData = new FormData()
	Object.keys(body).forEach((key) => formData.append(key, body[key]))
	return formData
}
