import React, { useState, useEffect, useContext } from 'react'
import CustomLabel from '../../components/common/customLabel'
import useSetTitlePage from '../../hooks/useSetTitlePage'
import colors from '../../../config/colors'
import { Grid, Button, Divider, FormControl, Autocomplete, TextField, FormHelperText, useMediaQuery, InputLabel, Select, MenuItem } from '@mui/material'
import CustomTable from '../../components/common/customTable'
import UseIconHooks from '../../hooks/useIconHooks'
import { labelTitlePrestador, botonBuscarPrestador, inputRegistro } from '../../global/styles'
import * as apiService from '../../../api/index'
import DialogDetallePrestador from '../../components/consulta/prestador/dialogDetallePrestador'
import ModalLoading from '../../components/common/modalLoading'
import { useSnackbar } from 'notistack'
import { ConfigurationContext } from '../../context/configurationContext'
import {  useNavigate } from "react-router-dom";
import { PrestadorContext } from '../../context/prestadorContext'
import {columnasPrestador} from '../../collection/listas'
import { useSelector } from 'react-redux'
import StarIcon from '@mui/icons-material/Star';
import { createIdTag } from '../../utils/helpers'

const Prestador = () => {
	const { idPrestador,setIdPrestador, nombrePrestador, setNombrePrestador} = useContext(PrestadorContext);
	const [especialistas, setEspecialistas] = useState([])
	const [tipoPrestadores, setTipoPrestadores] = useState([])
	const [regimenes, setRegimenes] = useState([])
	const [planesComplementarios, setPlanesComplementarios] = useState([])
	const [municipios, setMunicipios] = useState([])
	const [provincias, setProvincias] = useState([])
	const [especialista, setEspecialista] = useState(0)
	const [tipoPrestador, setTipoPrestador] = useState(0)
	const [nombre, setNombre] = useState('')
	const [regimen, setRegimen] = useState(0)
	const [planComplementario,setPlanComplementario] = useState(0);
	const [municipio, setMunicipio] = useState('')
	const [provincia, setProvincia] = useState('');
	const [lista, setLista] = useState()
	const [open, setOpen] = useState(false)
	const [municipiosFiltrados, setMunicipiosFiltrados] = useState([]);
	const [prestadorActual, setPrestadorActual] = useState({
		codigoPrestador: null,
		nombre: null,
		especialidad: null,
		direccion: null,
		telefono: null,
		correo: null,
		tipoPrestador: null
	})
	const [detallePrestador, setDetallePrestador] = useState([])
	const [provinciaError, setProvinciaError] = useState(false);
	const [municipioError, setMunicipioError] = useState(false);
	const [loading, setLoading] = useState(false)
	const [totalRegistrosDisponibles, setTotalRegistrosDisponibles] = useState(0)
	const navigate = useNavigate();
	const handlerEspecialista = ( value ) =>{ 
		setEspecialista(value)
	}
	const handlerTipoPrestador = (value ) => {
		setTipoPrestador(value)
		setEspecialista(0)
	}
	const handlerRegimen = (value ) => setRegimen(value)

	const handlerProvincia = (value ) => {
		setMunicipio('');
		setProvincia(value);
		const provinciaFiltrada = provincias.find(x => x.codigo === value);

		if (provinciaFiltrada) {
			const filteredMunicipios = municipios.filter(
				municipio => municipio.codigoPadre === provinciaFiltrada.codigo
			);
			setMunicipiosFiltrados(filteredMunicipios);
		} else {
			setMunicipiosFiltrados([]);
		}
	};

	const handlerNombre = ({ target }) => setNombre(target.value)
	const handlerCloseModal = (_) => setOpen(false)

	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	const { handlerIsLoginPage,getAlertaPersonalizada, mostrarMensajeAlerta } = useContext(ConfigurationContext)

	const [RegimenProveedor, setRegimenProveedor] = useState();
	const infoAfiliado = useSelector((state) => state.afiliado.infoAfiliado)
	
	const acciones = [
		{
			icono: UseIconHooks('visibility', {
				style: { color: colors.primary }
			}),
			metodo: (value) => {
				setPrestadorActual(value)
				apiService.prestador
					.getDetallePrestador(value.codigoPrestador, value.tipoPrestador)
					.then((response) => {
						setDetallePrestador(response.data.result)
						setOpen(true)
						tipoPrestadores.map(x => {
							if(x.descripcion === value.especialidad.toUpperCase())
							{
								apiService.prestador.getRegimenPrestador(value.codigoPrestador,x.codigo)
								.then((response) => {
									setRegimenProveedor(response.data.result)
								})
								.catch(err => console.error(err))
							}
						})
						
					})
					.catch((err) => console.error(err))
			},
			label: 'Ver detalle'
		},
		{
			icono: UseIconHooks('money', {
				style: { color: colors.primary }
			}),
			metodo: (value) => verCoberturasProveedor(value),
			label: 'Ver coberturas'
		}
	]

	const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));
	const isMd = useMediaQuery((theme) => theme.breakpoints.down('md'));

	useEffect(() => {
		handlerIsLoginPage(false)
		getAllProvincias();
		getAllEspecialidades();
		getAllTipoPrestadores();
		getAllMunicipios();
		validarParametrosDesdeCobertura();
		getAlertaPersonalizada();
		obtenerPlanes();
		obtenerRegimenAfiliadoLogin();
	}, [])

	useEffect(() => {
		if(open === false)
		{
			setRegimenProveedor('')
		}
	},[open])

	const mostrarMensaje = (nota, tipo) => {

		enqueueSnackbar( nota, {
		  variant: tipo,
		  action: (key) => (
			<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
			  X
			</Button>
		  )
		})
  
    }
  
	const validateRequiredFields = () => {
		let isValid = true;
	
		setProvinciaError(false);
		setMunicipioError(false);
	
		if (provincia === '') {
			setProvinciaError(true);
			isValid = false;
		}
	
		if (municipio === '') {
			setMunicipioError(true);
			isValid = false;
		}
	
		return isValid;
	};

	const handlerSearchPrestador = (e) => {
		setLoading(true)
		e.preventDefault();
		if(nombre){
			buscarPrestador({Nombre:nombre})
		}
		else if(validateRequiredFields()){
			const params = {
				IdTipoCentro: tipoPrestador || 0,
				Nombre: nombre || "",
				IdEspecialidad: especialista === ""? 0 : especialista,
				IdRegimen: planComplementario ? 9 : regimen || 0,
				IdMunicipio: municipio,
				IdProvincia: provincia,
				IdPrestador: 0,
				IdPlan: planComplementario || 0,
			}
			buscarPrestador(params)
		} else {
			setLoading(false);
		}
	}

	const getAllProvincias = async() => {
		await apiService.drops.getAllProvincias()
		.then((res) =>{
			setProvincias(res.data.result)
		})
		.catch((err) => console.error(err))
	}

	const getAllEspecialidades = async() => {
		await apiService.drops.getAllEspecialidades()
		.then((response) => {
			response.data.result.unshift({
				idEspecialidad: '',
				nombreEspecialidad: 'TODAS LAS ESPECIALIDADES'
			})
			setEspecialistas(response.data.result)
		})
		.catch((err) => console.error(err))
	}

	const getAllTipoPrestadores = async() => {
		await apiService.drops
			.getAllTipoPrestadores()
			.then((response) => {
				response.data.result.unshift({
					codigo: '',
					descripcion: 'TODOS LOS TIPOS DE PRESTADORES'
				})
				setTipoPrestadores(response.data.result)
			})
			.catch((err) => console.error(err))
	}

	const getAllMunicipios = async() => {
		await apiService.drops.getAllMunicipios()
		.then((response) => {
			response.data.result.unshift({
				codigo: '',
				descripcion: 'TODOS LOS MUNICIPIOS'
			})
			setMunicipios(response.data.result)
		})
		.catch((err) => console.error(err))
	}

	const TitlePrestador = (
		<>
			<CustomLabel style={{ ...labelTitlePrestador }}>Red de Prestadores de Servicios de Salud</CustomLabel>
			<Divider />
			<br />
			<br />
		</>
	)

	useSetTitlePage(TitlePrestador)

	const buscarPrestador = async(params) => {
		await apiService.prestador
		.getAllPrestadores(params)
		.then((response) => {
			if (response.data.result.length === 0) 
			{
				setLista([])
				mostrarMensaje(mostrarMensajeAlerta("prestador-consulta-nofound"),'warning')
				
			}
			else
			{	
				setTotalRegistrosDisponibles(response.data.result.cantidadRegistros)
				setLista(response.data.result.data,response.data.result.data)
			}
			setLoading(false)
		})
		.catch((err) => {
			setLoading(false)
			mostrarMensaje('Favor intente más tarde.','error')
			console.error(err)
		})
	}

	const validarParametrosDesdeCobertura = () => {
		if(idPrestador){
			setNombre(nombrePrestador)
			const IdPrestador = idPrestador
			buscarPrestador({IdPrestador})
			setIdPrestador(0)
			setNombrePrestador('')
		}
	}

	const handlerPage = (NumeroDePagina, RegistroPorPagina) =>{
		buscarPrestador({NumeroDePagina, RegistroPorPagina})
	}

	const validateScreenSizeLarge = (isXs, isMd) => {
		let ret = true;

		if(isXs){
			ret = false;
		}
		if(isMd){
			ret = false;
		}

		return ret;
	}

	const validateScreenSizeSmall = (isXs, isMd) => {
		let ret = false;

		if(isXs){
			ret = true;
		}
		if(isMd){
			ret = true;
		}

		return ret;
		
	}

	const verCoberturasProveedor = (value) => {
		setIdPrestador(value?.codigoPrestador)
        setNombrePrestador(value?.nombre)
        navigate(`/consulta/cobertura`);
	}

	const obtenerPlanes = async() => {
		const Regimen = infoAfiliado.regimen
		await apiService.drops.getAllRegimenes()
		.then((response) => {
			let result = response.data.result;
				if(Regimen !== "VOLUNTARIOS COLECTIVOS" ){
					result = result.filter(x => x.descripcion !== "VOLUNTARIOS COLECTIVOS")
				}
			setRegimenes(result)
		}).catch((err) => console.error(err))
	}

	const obtenerPlanComplementario = async(idRegimen) => {
		await apiService.cobertura.getPlanesPorRegimen(idRegimen)
		.then((response) => {
			filtrarPlanesVoluntarios(response.data.result, idRegimen)
			
		}).catch((err) => console.error(err))	
	}

	const obtenerRegimenAfiliadoLogin = () => {

		const Regimen = infoAfiliado.regimen
		let regimen = 0

		switch (Regimen) {
			case "SUBSIDIADO":
				regimen = 1;
			  break;	
			case "CONTRIBUTIVO":
				regimen = 2
			break;
			case "VOLUNTARIOS COLECTIVOS":
				regimen = 5
		}
		setRegimen(regimen)
		obtenerPlanComplementario(regimen)
		
	}

	const filtrarPlanesVoluntarios = (value, plan) => {

		const codigoAfiliado = infoAfiliado.codigoAfiliado

		let data = value;
		
		if(infoAfiliado.regimen === "VOLUNTARIOS COLECTIVOS" && plan === 5){
			apiService.afiliado.getNucleoCarnet(codigoAfiliado)
			.then((res) => {
				if(res.data.result[0].planes_Nombre){
					data = value.find(x => x.descripcion === res.data.result[0].planes_Nombre)
				}
				else{
					data = []
				}
			})
			.catch((err) => console.error(err))
			.finally(() => {setPlanesComplementarios([data])})

		}else{
			setPlanesComplementarios(data);
		}
		
	}

	const validarPlanContratado = (value) => {
		const { regimen } = infoAfiliado;
	
		if (value !== regimen) {
			return value;
		}
	
		return (
			<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: 0, padding: 0}}>
				<div>
					{value} 
				</div>
				<div style={{ display: "flex", alignItems: "center", paddingLeft:100 }}>
					<h5 style={{margin: 0, padding: 0}}>MI PLAN</h5> 
					<StarIcon style={{fontSize:25, marginLeft:5}}/>
				</div>
			</div>
		);
	};

	return (
		<>
		<form onSubmit={handlerSearchPrestador}>
			<Grid container spacing={2}>
				<Grid xs={12} margin={0} padding={0} paddingLeft={2}>
					<h3 style={{color: colors.gray}}>Filtros</h3>
				</Grid>
				<Grid item md={3} xs={12}>
					<InputLabel
						id='label_tipoPrestador'
						style={{
						width: "100%",
						fontFamily: "Montserrat",
						fontSize: 20,
						
						}}
					>
						Tipo de prestador
					</InputLabel>
					<FormControl fullWidth id='tipo-prestador'>
						<Autocomplete
							id="select-tipo-prestador"
							options={tipoPrestadores} 
							getOptionLabel={(option) => option.descripcion || ''} 
							value={tipoPrestador ? tipoPrestadores.find(item => item.codigo === tipoPrestador) : null} 
							onChange={(event, newValue) => {
								handlerTipoPrestador(newValue ? newValue.codigo : ''); 
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder='Seleccione'
									InputProps={{
										...params.InputProps,
										disableUnderline: true,
									}}
								/>
							)}
						/>
					</FormControl>
				</Grid>
				<Grid item md={3} xs={12} >
					<InputLabel
							id='label_nombrePrestador'
							style={{
							width: "100%",
							fontFamily: "Montserrat",
							fontSize: 20,
							
							}}
						>
						Nombre de prestador
					</InputLabel>
					<FormControl fullWidth id='nombre-prestador' >
						<TextField
							id="field-nombre-prestador"
							placeholder='Digite el nombre'
							InputProps={{
								disableUnderline: true
							}}
							value={nombre}
							onChange={handlerNombre}
						/>
					</FormControl>
				</Grid>
				<Grid item md={3} xs={12}>
					<InputLabel
							id='label_Plan'
							style={{
							width: "100%",
							fontFamily: "Montserrat",
							fontSize: 20,
							}}
						>
						Plan
					</InputLabel>
					<FormControl fullWidth id='regimenes'>
						<Select
							labelId='select-regimen'
							value={regimen}
							onChange={(event) => {
								if(event.target.value !== 0){
									handlerRegimen(event.target.value); 
									obtenerPlanComplementario(event.target.value);
									setPlanComplementario(0);
								}
								else{

									handlerRegimen('');
								}
							}}
							style={{
								...inputRegistro,
								backgroundColor: 'white',
								height: 62
							}}
						>
							{regimenes.map((item) => (
								<MenuItem id={createIdTag("select", item.descripcion)} key={item.codigo} value={item.codigo}>
									{validarPlanContratado(item.descripcion)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				{
					regimen === 1 || regimen === 6 
					? null
					:
					regimen ?
					<Grid item md={3} xs={12}>
						<InputLabel
								id='label_Plan'
								style={{
								width: "100%",
								fontFamily: "Montserrat",
								fontSize: 20,
								}}
							>
							Plan complementario
						</InputLabel>
						<FormControl fullWidth>
						<Select
							labelId='planComplementario'
							value={planComplementario}
							defaultValue={0}
							onChange={(event) => setPlanComplementario(event.target.value)}
							style={{
								...inputRegistro,
								backgroundColor: 'white',
								height: 62,
							}}
						>
							{
								Array.isArray(planesComplementarios) && planesComplementarios.length > 0 ? (
									planesComplementarios.map((item) => (
									<MenuItem id={createIdTag("select", item.descripcion)} key={item.codigo} value={item.codigo}>
										{validarPlanContratado(item.descripcion)}
									</MenuItem>
									))
								) : (
									<></>
								)
							}
							
						</Select>
					</FormControl>
					</Grid>
				: null
				}
				
				<Grid item md={3} xs={12}>
						<InputLabel
								id='label_Provincia'
								style={{
								width: "100%",
								fontFamily: "Montserrat",
								fontSize: 20,
								
								}}
							>
							Provincia
						</InputLabel>
					<FormControl fullWidth id='provincia-prestador' error={provinciaError}>
						<Autocomplete
							id={'select-provincia'}
							options={provincias} 
							getOptionLabel={(option) => option.descripcion || ''} 
							value={provincia ? provincias.find(item => item.codigo === provincia) : null} 
							onChange={(event, newValue) => {
								handlerProvincia(newValue ? newValue.codigo : ''); 
							}}
							renderInput={(params) => (
								<TextField
									required={provinciaError}
									{...params}
									placeholder='Seleccione'
									InputProps={{
										...params.InputProps,
										disableUnderline: true
									}}
								/>
							)}
						/>
						{provinciaError && <FormHelperText sx={{color:'red'}}>Este campo es requerido</FormHelperText>}
					</FormControl>
				</Grid>
				{
					tipoPrestador ?
						<Grid item md={3} xs={12} >
							<InputLabel
								id='label_especialidad'
								style={{
								width: "100%",
								fontFamily: "Montserrat",
								fontSize: 20,
								
								}}
							>
								Especialidad
							</InputLabel>
							<FormControl fullWidth id='especialidad'>
								<Autocomplete
									id='select-especialidad'
									options={especialistas} 
									getOptionLabel={(option) => option.nombreEspecialidad || ''} 
									value={especialista ? especialistas.find(item => item.idEspecialidad === especialista) : null} 
									onChange={(event, newValue) => {
										handlerEspecialista(newValue ? newValue.idEspecialidad : ''); 
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											placeholder='Seleccione'
											InputProps={{
												...params.InputProps,
												disableUnderline: true,
											}}
										/>
									)}
								/>
							</FormControl>
						</Grid>
						:
						<Grid md={3}></Grid>
				}
				{
					validateScreenSizeLarge(isXs, isMd) && (
						<Grid 
							item 
							md={6} 
							xs={12} 
							marginTop={4}  
							container  
							justifyContent="center" 
							alignItems="center"

						>
							<Grid width={328}>
								<Button 
								id="btn-buscar-prestador"
								fullWidth  
								type="submit" 
								variant='contained' 
								style={{ ...botonBuscarPrestador, marginTop: 0, height: 60 }}
								>
								Buscar
								</Button>
							</Grid>
						</Grid>
					)
				}
				
				{
					provincia ?
							<Grid item md={3} xs={12}>
								<InputLabel
									id='label_municipio'
									style={{
									width: "100%",
									fontFamily: "Montserrat",
									fontSize: 20,
								}}
								>
									Municipio
								</InputLabel>
								<FormControl fullWidth>
									<Autocomplete
										id="select-municipio"
										disabled={provincia === ''} 
										options={municipiosFiltrados} 
										getOptionLabel={(option) => option.descripcion || ''} 
										value={municipio ? municipiosFiltrados.find(item => item.codigo === municipio) : null} 
										onChange={(event, newValue) => {
											setMunicipio(newValue ? newValue.codigo : ''); 
										}}
										renderInput={(params) => (
											<TextField
												required={municipioError}
												{...params}
												placeholder='Seleccione'
												InputProps={{
													...params.InputProps,
													disableUnderline: true,
												}}

											/>
										)}
									/>
									{municipioError && <FormHelperText sx={{color:'red'}}>Este campo es requerido</FormHelperText>}
								</FormControl>
							</Grid>
				 	: 
						<Grid md={3}></Grid>
				}
				{
					validateScreenSizeSmall(isXs, isMd) && (
						<Grid 
							item 
							md={!provincia && tipoPrestador === 0 ? 12 : 6} 
							xs={12} 
							marginTop={4}   
							container  
							justifyContent="center" 
							alignItems="center"
						>

							<Grid width={328}>
								<Button 
								id="btn-buscar-prestador"
								fullWidth  
								type="submit" 
								variant='contained' 
								style={{ ...botonBuscarPrestador, marginTop: 0, height: 60 }}
								>
								Buscar
								</Button>
							</Grid>
						</Grid>
					)
					 
				}
			</Grid>
		</form>
			
			<Grid container spacing={3}>
				<Grid item xs={12} marginTop={3}>
					<CustomTable columns={columnasPrestador} acciones={acciones} rows={lista} handlerPage={handlerPage} totalDisponibles={totalRegistrosDisponibles}/>
				</Grid>
			</Grid>
			<ModalLoading open={loading} onCloseHandler={() => setLoading(false)} />
			<DialogDetallePrestador open={open} handlerCloseModal={handlerCloseModal} prestador={prestadorActual} detalle={detallePrestador} regimenes={RegimenProveedor}/>
		</>
	)
}

export default Prestador
