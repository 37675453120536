import React from "react";
import CustomLabel from "../../common/customLabel";
import { Grid } from "@mui/material";

import {
  labelHeaderCardPrestador,
  labelFieldCardPrestador,
} from "../../../global/styles";

const CardPrestador = ({ prestador }) => {
  return (
    <Grid container marginTop={2}>
      <Grid item xs={12}>
        <CustomLabel
          style={{
            ...labelHeaderCardPrestador,
            fontSize: 20,
          }}
        >
          {prestador.nombre}
        </CustomLabel>
      </Grid>
      <Grid item xs={12}>
        <CustomLabel
          style={{
            ...labelFieldCardPrestador,
          }}
        >
          {prestador.especialidad}
        </CustomLabel>
      </Grid>
      <Grid item xs={12}>
        <CustomLabel
          style={{
            ...labelFieldCardPrestador,
          }}
        >
          {prestador.direccion}
        </CustomLabel>
      </Grid>
      <Grid item xs={12}>
        <CustomLabel
          style={{
            ...labelFieldCardPrestador,
          }}
        >
          {prestador.telefono}
        </CustomLabel>
      </Grid>
      <Grid item xs={12}>
        <CustomLabel
          style={{
            ...labelFieldCardPrestador,
          }}
        >
          {prestador.correo}
        </CustomLabel>
      </Grid>
    </Grid>
  );
};

export default CardPrestador;
