import CustomLabel from "./customLabel";

import {
  styleContainerBarra,
  styleContainerValueBarra,
  labelValorActualBarra,
} from "../../global/styles";

import NumberFormat from "react-number-format";
import colors from "../../../config/colors";

const CustomBarra = ({
  maximo,
  actual,
  minHeight,
  styleContainer,
  styleValue,
  styleLabel,
}) => {
  let porcentajeActual = (actual / maximo) * 100;

  return (
    <>
      <div
        style={{
          ...styleContainerBarra,
          minHeight: minHeight,
          ...styleContainer,
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <div
          style={{
            ...styleContainerValueBarra,
            width: `${porcentajeActual}%`,
            minWidth: 155,
            minHeight: minHeight,
            ...styleValue,
          }}
        >
          <CustomLabel style={{ ...labelValorActualBarra, ...styleLabel }}>
            <NumberFormat
              value={actual}
              thousandSeparator={true}
              displayType={"text"}
              renderText={(value, props) => value}
            />
          </CustomLabel>
        </div>
        <div style={{display: maximo === actual ? "none": "flex", marginLeft: 20}} >
        <CustomLabel style={{ ...labelValorActualBarra, ...styleLabel, color: colors.primary }}>
        <NumberFormat
              value={maximo}
              thousandSeparator={true}
              displayType={"text"}
              renderText={(value, props) => value}
            />
          </CustomLabel>
        </div>
      </div>
    </>
  );
};

export default CustomBarra;
