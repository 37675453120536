import React, { useEffect } from "react";
import UseSetIsLogin from "../../hooks/useSetIsLogin";
import { useParams } from "react-router-dom";
import CustomLabel from "../../components/common/customLabel";
import colors from "../../../config/colors";
import { CircularProgress, Modal, Button } from "@mui/material";
import * as apiService from "../../../api";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";

const ConfirmacionCorreo = () => {
  const { setAuthLogOut } = UseSetIsLogin();
  let params = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let navigate = useNavigate();

  useEffect(() => {
    setAuthLogOut();
    // console.log(params);
    //params.codigoAfiliado.split("=")[1]
    apiService.autentificacion
      .getActivarCuenta(params.codigoAfiliado.split("=")[1])
      .then((response) => {
        const resultado = response.data.result;
        if (resultado.codigoMensaje === 1009) {
          enqueueSnackbar(
            `${resultado.mensaje}, favor de iniciar sesion con su cuenta.`,
            {
              variant: "success",
              action: (key) => (
                <Button
                  style={{ color: "white", fontWeight: "bold" }}
                  onClick={() => closeSnackbar(key)}
                >
                  X
                </Button>
              ),
            }
          );
          navigate("/login");
        } else {
          enqueueSnackbar(
            `${resultado.mensaje}, favor de intentar mas tarde.`,
            {
              variant: "error",
              action: (key) => (
                <Button
                  style={{ color: "white", fontWeight: "bold" }}
                  onClick={() => closeSnackbar(key)}
                >
                  X
                </Button>
              ),
            }
          );
        }
      })
      .catch((err) => {
        enqueueSnackbar("Favor intente mas tarde.", {
          variant: "error",
          action: (key) => (
            <Button
              style={{ color: "white", fontWeight: "bold" }}
              onClick={() => closeSnackbar(key)}
            >
              X
            </Button>
          ),
        });
        console.log(err);
      });
  }, []);
  return (
    <>
      <Modal open={true}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: colors.grayFondo,
            border: `1px solid ${colors.primary}`,
            boxShadow: 15,
            p: 4,
            textAlign: "center",
          }}
        >
          <div
            style={{
              padding: 25,
              display: "flex",
              flexDirection: "column",
              backgroundColor: colors.grayFondo,
              alignItems: "center",
            }}
          >
            <CustomLabel
              style={{
                fontFamily: "montserrat",
                color: colors.primary,
                fontSize: 45,
              }}
            >
              <label>Confirmando activación de cuenta</label>
            </CustomLabel>
            <CircularProgress style={{ color: colors.primary }} />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmacionCorreo;
