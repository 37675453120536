import http from "../../config/http";
import {
  GENERAR_ORDEN_COMPRA,
  ACTUALIZAR_ORDEN_COMPRA,
  FACTURA_PENDIENTE_PAGO,
  GENERAR_SESSION
} from "../endpoints";

const getAllFacturaPendientePago = (codigoAfiliado) => {
  let configuration = {
    headers: {"Authorization": "Bearer " + localStorage.getItem("token")}
  }
  return http.get(
    `Pago/${FACTURA_PENDIENTE_PAGO}?CodigoAfiliado=${codigoAfiliado}`,configuration
  );
};

const getOrdenCompraGenerada = (numeroFactura, Monto, codigoAfiliado) => {
  let configuration = {
    headers: {"Authorization": "Bearer " + localStorage.getItem("token")}
  }
  return http.get(
    `Pago/${GENERAR_ORDEN_COMPRA}?NumeroFactura=${numeroFactura}&Monto=${Monto}&CodigoAfiliado=${codigoAfiliado}`,configuration
  );
};

const postOrdenCompraActualizar = (body) => {
  let configuration = {
    headers: {"Authorization": "Bearer " + localStorage.getItem("token")}
  }
  return http.post(`Pago/${ACTUALIZAR_ORDEN_COMPRA}`, body,configuration);
};

const postGenerarSession = (body,codigoAfiliado,isMobile = false) => {
  let configuration = {
    headers: {"Authorization": "Bearer " + localStorage.getItem("token")}
  }
  return http.post(`Pago/${GENERAR_SESSION}?codigoAfiliado=${codigoAfiliado}&isMobile=${isMobile}`, body,configuration);
};


export {
  getAllFacturaPendientePago,
  getOrdenCompraGenerada,
  postOrdenCompraActualizar,
  postGenerarSession
};
