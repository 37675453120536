import http from "../../config/http";
import { removeAccentsAndSpecialChars, shortenName, base64ToBlob,convertDateFormat } from "../../app/utils/helpers";

import { 
    POST_SOLICITUD_AUTORIZACION,
    POST_SOLICITUDAFILIACIONDEPENDIENTE,
    POST_CARGARDOCUMENTO,
    POST_SOLICITUDTRASPASO,
    GET_CONSULTA_SOLICITUDES,
    POST_CREARSOLICITUDAFILIACION,
    POST_FOTO_PERFIL,
	GET_FOTO_PERFIL,
    DEL_FOTO_PERFIL,
    GET_CONSULTA_SOLICITUDES_POR_DOCUMENTO
} from '../endpoints';



const postSolicitudPreAutorizacion = (_nss, _email, _centro, _tipoAutorizacion, _descripcion, _contrato) => {

    let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}

    const body = {
        nss: _nss,
        email: _email,
        tipoAutorizacion: _tipoAutorizacion,
        centroMedico: _centro,
        descripcion: _descripcion,
    }
    
    return http.post(`CRM/${POST_SOLICITUD_AUTORIZACION}?contrato=${_contrato}`,body,configuration)
}

const postSolicitudPreAutorizacionV2 = (_nss, _email, _centro, _tipoAutorizacion, _descripcion, _contrato, _codigoSolicitante, image) => {

  const outputArray = [];
  const nameCount = {};
  
  image.forEach((item, index) => {
    let baseName = removeAccentsAndSpecialChars(item.type);
    baseName = shortenName(baseName, 20);

    if (nameCount[baseName]) {
        nameCount[baseName] += 1;
    } else {
        nameCount[baseName] = 1;
    }

    const count = nameCount[baseName];
    const newName = count > 1 ? `${baseName}_${count}` : baseName;
    const fileExtension = item.name.split('.').pop();
    const formattedName = `${newName}.${fileExtension}`;

    const blob = base64ToBlob(item.content, item.fileType);
    const newFile = new File([blob], formattedName, { type: item.fileType });
    outputArray.push(newFile);
});

const bodyFormData = new FormData();
outputArray.forEach(item => {
    bodyFormData.append('images', item);
});

      
    bodyFormData.append("nss",_nss)
    bodyFormData.append("email",_email)
    bodyFormData.append("tipoAutorizacion",_tipoAutorizacion)
    bodyFormData.append("centroMedico",_centro)
    bodyFormData.append("descripcion",_descripcion)
    bodyFormData.append("codigoSolicitante", _codigoSolicitante)
    
    return http.post(`CRM/v2/${POST_SOLICITUD_AUTORIZACION}?contrato=${_contrato}`,bodyFormData,{
        headers: { "Content-Type": "multipart/form-data",  Authorization: 'Bearer ' + localStorage.getItem('token')  }
    })
}


const postSolicitudAfiliacionDependiente = (_cedula, _nombre, _apellido, _email, _telefono, _celular, _institucion, _contrato ) => {
    let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}

    const body = {
        cedula: _cedula,
        nombre: _nombre,
        apellido: _apellido,
        email: _email,
        telefono: _telefono,
        celular: _celular,
        institucion: _institucion,
    }
    
    return http.post(`CRM/${POST_SOLICITUDAFILIACIONDEPENDIENTE}?contrato=${_contrato}`,body, configuration)
}

const postSolicitudTraspaso = (_cedula, _nss, _telefono, _celular, _email, _contrato) => {
    let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}

    const body = {
        cedula: _cedula,
        nss: _nss,
        telefono: _telefono,
        celular: _celular,
        email: _email,
    }

    return http.post(`CRM/${POST_SOLICITUDTRASPASO}?contrato=${_contrato}`,body, configuration)
}

const getConsultaSolicitudes = (_cedula) => {
    
    let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}

    return http.get(`CRM/${GET_CONSULTA_SOLICITUDES}?cedula=${_cedula}`, configuration);

}


const postCargarDocumento = (_ticketName, image) => {

    var bodyFormData = new FormData()
    image.forEach(item => {
        bodyFormData.append('images',item)
    })

    return http.post(`CRM/${POST_CARGARDOCUMENTO}?ticketName=${_ticketName}`,bodyFormData,{
        headers: { "Content-Type": "multipart/form-data",  Authorization: 'Bearer ' + localStorage.getItem('token')  }
    })
}

const postSolicitudesAfiliacionTitular = (_cedula, _telefono, _nombre, _apellido, _email, _regimen, _contrato ) => {
    let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}

    const body = {
        cedula: _cedula,
        telefono: _telefono,
        nombre: _nombre,
        apellido: _apellido,
        email: _email,
        regimen: _regimen,
    }
    
    return http.post(`CRM/${POST_CREARSOLICITUDAFILIACION}?contrato=${_contrato}`,body,configuration )
}

const PostFotoPerfil = (image, codigoAfiliado) => {

    var bodyFormData = new FormData()
  
    bodyFormData.append('images',image)

    return http.post(`CRM/${POST_FOTO_PERFIL}?codigoAfiliado=${codigoAfiliado}`,bodyFormData,{
        headers: { "Content-Type": "multipart/form-data", Authorization: 'Bearer ' + localStorage.getItem('token') }
    })
}

const GetFotoPerfil = (codigoAfiliado) => {

    return http.get(`CRM/${GET_FOTO_PERFIL}?codigoAfiliado=${codigoAfiliado}`,{
        headers: { "Content-Type": "multipart/form-data", Authorization: 'Bearer ' + localStorage.getItem('token')  }
    })
    
   
}

const delFotoPerfil = (codigoAfiliado) => {

    let configuration = {
     headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
 }
   
     return http.delete(`CRM/${DEL_FOTO_PERFIL}?codigoAfiliado=${codigoAfiliado}`,configuration)
     
    
 }

 const postConsultaSolicitudesPorDocumento = (_tipoDocumento, _documento, _tipoSolicitud = "", _estado="", _numeroCaso="", _fechaDesde, _fechaHasta) => {
    
    const body = {
        tipoDocumento : _tipoDocumento,
        documento : _documento.toString(),
        tipoSolicitud: _tipoSolicitud,
        estado: _estado,
        numeroCaso: _numeroCaso,
        fechaDesde: convertDateFormat(_fechaDesde),
        fechaHasta: convertDateFormat(_fechaHasta)
    }

    let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}

	return http.post(`CRM/${GET_CONSULTA_SOLICITUDES_POR_DOCUMENTO}`,body, configuration);
};

const postConsultaSolicitudesPorDocumentoV2 = (_tipoDocumento, _documento, _tipoSolicitud = "", _estado="", _numeroCaso="", _fechaDesde="", _fechaHasta="", _numeroPagina=0, _registroPorPagina=5) => {
    
    const body = {
        tipoDocumento : _tipoDocumento,
        documento : _documento.toString(),
        tipoSolicitud: _tipoSolicitud,
        estado: _estado,
        numeroCaso: _numeroCaso,
        fechaDesde: convertDateFormat(_fechaDesde),
        fechaHasta: convertDateFormat(_fechaHasta),
        numeroDePagina: _numeroPagina,
        registrosPorPagina:_registroPorPagina
    }

    let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}

	return http.post(`CRM/${GET_CONSULTA_SOLICITUDES_POR_DOCUMENTO}`,body, configuration);
};

export {
    postSolicitudPreAutorizacion,
    postSolicitudAfiliacionDependiente,
    postCargarDocumento,
    postSolicitudTraspaso,
    getConsultaSolicitudes,
    postSolicitudesAfiliacionTitular,
    PostFotoPerfil,
    GetFotoPerfil,
    delFotoPerfil,
    postSolicitudPreAutorizacionV2,
    postConsultaSolicitudesPorDocumento,
    postConsultaSolicitudesPorDocumentoV2
};