import React, { useEffect, useState } from "react";
import { Card, Box, CardContent, Grid, Button } from "@mui/material";
import { IconButton } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import profilePicture from "../../assets/user.png";
import CustomLabel from "./common/customLabel";
import { Link } from "react-router-dom";
import {
  imgProfileCard,
  labelNombreProfileCard,
  labelInfoProfileCard,
  botonVerPerfilProfileCard,
  botonLogoutProfileCard,
} from "../global/styles";
import { useSelector } from "react-redux";
import { useProfilePhoto } from "../context/profilePhotoContext";

const ProfileCard = ({ handleClosePopover, onPressExit }) => {
  const infoAfiliado = useSelector((state) => state.afiliado.infoAfiliado);
  const [selectedFile, setSelectedFile] = useState(null)
  const {profilePhoto} = useProfilePhoto();

  const obtenerFotoPerfil = () => {
		const image = localStorage.getItem('selectedImage');
    
		if(image)
		{
			setSelectedFile(image)
			
		}
	}

  useEffect(() => {

    if(!selectedFile)
    {
      obtenerFotoPerfil()
    }
  
  }, [])

  return (
    <>
      <Card sx={{ display: "flex", maxWidth: 400 }}>
        <Box sx={{ display: "flex" }}>
          <CardContent>
            <Grid container>
              <Grid xs={3}>
                <img
                  src={!profilePhoto ? profilePicture : `data:image/png;base64,${profilePhoto}`}
                  width={100}
                  height={100}
                  style={{ ...imgProfileCard, objectFit:'cover'}}
                />
              </Grid>
              <Grid xs={9} flexDirection='column' padding={2}>
                <CustomLabel
                  style={{
                    ...labelNombreProfileCard,
                  }}
                >
                  <label>
                    {infoAfiliado.nombres} {infoAfiliado.apellidos}
                  </label>
                </CustomLabel>
                <CustomLabel style={{ ...labelInfoProfileCard }}>
                { infoAfiliado.tipoAfiliacion.toLowerCase() === 'titular' ? infoAfiliado.tipoAfiliacion : 'Dependiente' }  {infoAfiliado.regimen}
                </CustomLabel>
                <CustomLabel style={{ ...labelInfoProfileCard }}>
                  No. de afiliado: <label> {infoAfiliado.codigoAfiliado}</label>
                </CustomLabel>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Link
                    onClick={handleClosePopover}
                    to='/profile'
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      id="btn-ver-perfil"
                      variant='contained'
                      size='large'
                      style={{ ...botonVerPerfilProfileCard }}
                      sx={{ fontSize: { xs: 15, md: 15 } }}

                    >
                      Ver perfil
                    </Button>
                  </Link>

                  <IconButton
                    id="btn-logout"
                    size='medium'
                    style={{ ...botonLogoutProfileCard }}
                    onClick={onPressExit}
                  >
                    <ExitToApp />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Box>
      </Card>
    </>
  );
};

export default ProfileCard;
