import http from "../../config/http";
import {
  AUTENTICAR,
  REGISTRAR,
  ACTIVAR_CUENTA,
  LOGIN,
  SOLICITAR_CAMBIO_CLAVE,
  CAMBIA_CLAVE,
  GET_PREGUNTAS_SEGURIDAD,
  GET_SOLICITUD_CAMBIO_CORREO
} from "../endpoints";

const getValidarIdentificacion = (tipoDocumento, documento) => {
  return http.get(
    `Autenticacion/${AUTENTICAR}?TipoDocumento=${tipoDocumento}&Documento=${documento}`
  );
};

const postRegistrarCuenta = (registro, reenvioCorreo) => {
  return http.post(`Autenticacion/${REGISTRAR}?reenvioCuenta=${reenvioCorreo}`, registro);
};

const getActivarCuenta = (codigoAfiliado) => {
  return http.get(
    `Autenticacion/${ACTIVAR_CUENTA}?CodigoAfiliado=${codigoAfiliado}`
  );
};

const postLogin = (credenciales) => {
  return http.post(`Autenticacion/${LOGIN}`, credenciales);
};

const postSolicitarCambioClave = (credenciales) => {
  return http.post(`Autenticacion/${SOLICITAR_CAMBIO_CLAVE}`, credenciales);
};

const getCambioClave = (token, clave) => {
  return http.get(
    `Autenticacion/${CAMBIA_CLAVE}?Token=${token}&Clave=${clave}`
  );
};

const setJwt = (token) => {
  localStorage.setItem("token", token);
};

const getJwt = (_) => {
  return localStorage.getItem("token");
};

const removeJwt = (_) => {
  localStorage.removeItem("token");
};

const getPreguntasDeSeguridad = (codigoAfiliado) => {
  return http.get(`Autenticacion/${GET_PREGUNTAS_SEGURIDAD}?CodigoAfiliado=${codigoAfiliado}`); 
}

const SolicitudCambioCorreo = (CodigoAfiliado,Correo, reverso, token = null ) => {
  let configuration;

  if(token !== null)
  {
    configuration = {
      headers: { Authorization: 'Bearer ' + token }
    }
    return http.get(`autenticacion/${GET_SOLICITUD_CAMBIO_CORREO}?CodigoAfiliado=${CodigoAfiliado}&Correo=${Correo}&Reverso=${reverso}`,configuration); 
  }

  configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}
 
  return http.get(`autenticacion/${GET_SOLICITUD_CAMBIO_CORREO}?CodigoAfiliado=${CodigoAfiliado}&Correo=${Correo}&Reverso=${reverso}`,configuration); 
}

export {
  getValidarIdentificacion,
  postRegistrarCuenta,
  getActivarCuenta,
  postLogin,
  setJwt,
  getJwt,
  removeJwt,
  postSolicitarCambioClave,
  getCambioClave,
  getPreguntasDeSeguridad,
  SolicitudCambioCorreo
};
