import React, {  useState } from 'react'

import Grid from '@mui/material/Grid'
import InputBase from '@mui/material/InputBase'
import InputLabel from '@mui/material/InputLabel'
import Fab from '@mui/material/Fab'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Slide from '@mui/material/Slide'

import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIos from '@mui/icons-material/ArrowBackIos'
import Clear from '@mui/icons-material/Clear'

import fondo from '../../../assets/registro.png'
import CustomLabel from '../common/customLabel'
import colors from '../../../config/colors'

import { useForm } from 'react-hook-form'

import {
	dialogContentRegistro,
	contenedorDialogContentRegistro,
	labelValidarRegistro,
	selectRegistro,
	inputRegistro,
	labelDatosPersonalesRegistro,
	botonPreviousPage,
	botonNextPage,
	cerrarModalRegistro
} from '../../global/styles'

import * as apiServices from '../../../api'

import { useSnackbar } from 'notistack'

import { format } from 'date-fns'

import NumberFormat from 'react-number-format'
import PreguntaSeguridad from '../login/preguntaSeguridad'
import { documentos } from '../../collection/listas'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const RegistroModal = ({ open, handleClose, closeHandlerLoadingModal, openHandlerLoadingModal }) => {
	const [listaRespuesta, setListaRespuesta] = useState([])
	const [cantidadIntentos, setCantidadIntentos] = useState(0)
	const [btnNextDisabled, setBtnNextDisabled] = useState(true)

	const validarRespuestaPregunta = () => {
		let valido = false
		for (let i = 0; i < listaRespuesta.length; i++) {
			const preguntaOriginal = infoAfiliado.preguntasSeguridad.filter((a) => a.id === listaRespuesta[i].idPregunta)[0]
			let respuesta = preguntaOriginal.respuesta

			if (preguntaOriginal.idTipoPregunta === 1) {
				let fecha = listaRespuesta[i].respuesta
				listaRespuesta[i].respuesta = format(fecha, 'yyyy-MM-dd')
			}

			if (respuesta != listaRespuesta[i].respuesta) {
				valido = false
				break
			} else {
				valido = true
			}
		}
		return valido
	}

	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	const [infoAfiliado, setInfoAfiliado] = useState({
		codigoAfiliado: null,
		nombres: null,
		apellidos: null,
		preguntasSeguridad: []
	})

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors, isValid }
	} = useForm({ mode: 'onChange' })

	const [tipoDocumento, setTipoDocumento] = useState('')
	const [nombres, setNombres] = useState('')
	const [apellidos, setApellidos] = useState('')

	const [page, setPage] = useState(1)

	const [reenvioCorreo, setReenvioCorreo] = useState(false);

	const nextPage = (data) => {
		if (page === 1) {
			openHandlerLoadingModal()
			apiServices.autentificacion
				.getValidarIdentificacion(data.tipoDocumento, data.noDocumento)
				.then((response) => {
					closeHandlerLoadingModal()
					const resultado = response.data.result
					if (resultado.codigoMensaje === 1003 || resultado.codigoMensaje === 1004) {
						enqueueSnackbar(`${resultado.mensaje}`, {
							variant: 'error',
							action: (key) => (
								<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
									X
								</Button>
							)
						})
					} else if (resultado.codigoMensaje === 1001) {
						enqueueSnackbar(`${resultado.mensaje}, favor de ingresar sesión en la ventana de login con sus credenciales.`, {
							variant: 'warning',
							action: (key) => (
								<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
									X
								</Button>
							)
						})
					} else if (resultado.codigoMensaje === 1002 || resultado.codigoMensaje === 1011) {
						enqueueSnackbar(`${resultado.mensaje}`, {
							variant: 'warning',
							action: (key) => (
								<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
									X
								</Button>
							)
						})
					} else if (resultado.codigoMensaje === 1012) {
						
						apiServices.autentificacion.getPreguntasDeSeguridad(Number(resultado.codigoAfiliado))
						.then(res => {
							
							if(res.data.result)
							{
								enqueueSnackbar(`Su registro expiro, favor de completar las preguntas de seguridad nuevamente.`, {
									variant: 'warning',
									action: (key) => (
										<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
											X
										</Button>
									)
								})
								setReenvioCorreo(true);
								
								setInfoAfiliado({
									codigoAfiliado: resultado.codigoAfiliado,
									nombres: resultado.nombres,
									apellidos: resultado.apellidos,
									preguntasSeguridad: res.data.result
								})

								setTipoDocumento(data.tipoDocumento)
								setListaRespuesta([])
								setBtnNextDisabled(true)
								if (page === 3) return
								setPage(page + 1)
							}
						}).catch(err => {
							enqueueSnackbar('Favor intente más tarde.', {
								variant: 'error',
								action: (key) => (
									<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
										X
									</Button>
								)
							})
							console.log(err)
						})
					} else {
						setInfoAfiliado({
							codigoAfiliado: resultado.codigoAfiliado,
							nombres: resultado.nombres,
							apellidos: resultado.apellidos,
							preguntasSeguridad: resultado.preguntasSeguridad
						})

						setTipoDocumento(data.tipoDocumento)
						setListaRespuesta([])
						setBtnNextDisabled(true)
						if (page === 3) return
						setPage(page + 1)
					}
				})
				.catch((err) => {
					enqueueSnackbar('Favor intente más tarde.', {
						variant: 'error',
						action: (key) => (
							<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
								X
							</Button>
						)
					})
					console.log(err)
				})
		} else if (page === 2) {
			if (listaRespuesta.length < 3) {
				return enqueueSnackbar(`Debe de contestar todas las preguntas.`, {
					variant: 'warning',
					action: (key) => (
						<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
							X
						</Button>
					)
				})
			}
			const validado = validarRespuestaPregunta()
			if (validado) {
				if (page === 3) return
				setPage(page + 1)
				setNombres(infoAfiliado.nombres)
				setApellidos(infoAfiliado.apellidos)
				setValue('nombres', infoAfiliado.nombres)
				setValue('apellidos', infoAfiliado.apellidos)
			} else {
				setCantidadIntentos((prev) => prev + 1)
				setListaRespuesta([])
				enqueueSnackbar(`La información proporcionada no es correcta. Intente nuevamente.`, {
					variant: 'warning',
					action: (key) => (
						<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
							X
						</Button>
					)
				})
			}
		} else if (page === 3) {
			if (data.password !== data.repeatPassword) {
				enqueueSnackbar(`Las contraseñas no coinciden.`, {
					variant: 'warning',
					action: (key) => (
						<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
							X
						</Button>
					)
				})
				
				return
			}

			if (data.correo !== data.repeatCorreo) {
				enqueueSnackbar(`Los correos no coinciden.`, {
					variant: 'warning',
					action: (key) => (
						<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
							X
						</Button>
					)
				})

				return
			}

			openHandlerLoadingModal()
			const registro = {
				codigoAfiliado: infoAfiliado.codigoAfiliado,
				clave: data.password,
				correo: data.correo
			}
			apiServices.autentificacion
				.postRegistrarCuenta(registro, reenvioCorreo)
				.then((response) => {
					const resultado = response.data.result

					if (resultado.codigoMensaje === 1010) {
						enqueueSnackbar(`${resultado.mensaje}, favor de iniciar sesión con su cuenta.`, {
							variant: 'warning',
							action: (key) => (
								<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
									X
								</Button>
							)
						})
						closeHandlerLoadingModal()
						handleClose()
						defaultStates()
						reset({
							noDocumento: ''
						})
					} else if (resultado.codigoMensaje === 1005) {
						enqueueSnackbar(`${resultado.mensaje}, recibira un correo para activar su cuenta.`, {
							variant: 'success',
							action: (key) => (
								<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
									X
								</Button>
							)
						})
						closeHandlerLoadingModal()
						handleClose()
						defaultStates()
						reset({
							noDocumento: ''
						})
					} else if (resultado.codigoMensaje === 1019) {
						enqueueSnackbar(`${resultado.mensaje}`, {
							variant: 'warning',
							action: (key) => (
								<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
									X
								</Button>
							)
						})
						closeHandlerLoadingModal()
					} else {
						enqueueSnackbar(`${resultado.mensaje}`, {
							variant: 'error'
						})
						closeHandlerLoadingModal()
					}
				})
				.catch((err) => {
					enqueueSnackbar('Favor intente más tarde.', {
						variant: 'error',
						action: (key) => (
							<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
								X
							</Button>
						)
					})
				  console.log(err)
				})
		}
	}

	const previousPage = () => {
		if (page === 1) return
		setListaRespuesta([])
		setPage(page - 1)
	}

	const [noDocumento, setNoDocumento] = useState('')

	const handleDocumento = (value) => {
		setNoDocumento(value)
		value !== '' ? setBtnNextDisabled(false) : setBtnNextDisabled(true)
	}

	const defaultStates = (_) => {
		setTipoDocumento('')
		setApellidos('')
		setNombres('')
		setPage(1)
		setInfoAfiliado({
			codigoAfiliado: null,
			nombres: null,
			apellidos: null
		})
	}

	const cerrarRegisterModal = (_) => {
		reset({
			noDocumento: '',
			password: '',
			repeatPassword: '',
			correo: ''
		})
		setNoDocumento('')
		setTipoDocumento(1)
		setFormatDocumento('###-#######-#')
		setMaskDocumento('_')
		handleClose()
		setPage(1)
		setListaRespuesta([])
		setTipoDocumento('')
		setInfoAfiliado({
			codigoAfiliado: null,
			nombres: null,
			apellidos: null,
			preguntasSeguridad: []
		})
	}

	const [formatDocumento, setFormatDocumento] = useState('###-#######-#')
	const [maskDocumento, setMaskDocumento] = useState('_')
	const handlerFormatDocumento = (tipo) => {
		if (tipo == 1) {
			setFormatDocumento('###-#######-#')
			setMaskDocumento('_')
		} else {
			setFormatDocumento('#############')
			setMaskDocumento('')
		}
	}

	return (
		<>
			<Dialog open={open} TransitionComponent={Transition} onClose={cerrarRegisterModal} fullWidth maxWidth='lg'>
				<DialogContent style={{ ...dialogContentRegistro }}>
					<div style={{ ...contenedorDialogContentRegistro }}>
						<Grid container>
							<Grid item xs={12} md={6} style={{ padding: 30 }}>
								{page === 1 && (
									<form>
										{' '}
										<Grid container>
											<Grid item xs={12} style={{ marginTop: '8%' }}>
												<CustomLabel style={{ ...labelValidarRegistro }}>Validar identificación</CustomLabel>
											</Grid>
											<Grid item xs={12} style={{ marginTop: '5%' }}>
												<InputLabel
													id='label-tipo-documento'
													style={{
														fontFamily: 'Montserrat',
														marginBottom: 10,
														color: colors.grayLight
													}}
												>
													Tipo de documento
												</InputLabel>
												<select
													style={{
														borderRadius: 10,
														...selectRegistro,
														...inputRegistro,
														height: 55,
														fontFamily: 'Montserrat'
													}}
													id='select-tipo-documento'
													{...register('tipoDocumento', {
														required: 'El tipo de documento es obligatorio'
													})}
													value={tipoDocumento}
													onChange={(event) => {
														setTipoDocumento(event.target.value)
														setNoDocumento('')
														handlerFormatDocumento(event.target.value)
													}}
												>
													{documentos.map((item) => (
														<option key={item.id} value={item.id}>
															{item.descripcion}
														</option>
													))}
												</select>
												{errors.tipoDocumento && <span style={{ color: 'red' }}> {errors.tipoDocumento.message} </span>}
											</Grid>
											<Grid item xs={12} marginTop={'3%'}>
												<NumberFormat
													value={noDocumento}
													format={formatDocumento}
													mask={maskDocumento}
													style={{ ...inputRegistro, height: 55 }}
													placeholder='Documento'
													{...register('noDocumento', {
														required: 'Por favor, ingrese su documento de identidad.'
													})}
													onValueChange={(values) => {
														handleDocumento(values.value)
														setValue('noDocumento', values.formattedValue)
													}}
													customInput={InputBase}
												/>
												{errors.noDocumento && <span style={{ color: 'red' }}> {errors.noDocumento.message} </span>}
											</Grid>
										</Grid>
									</form>
								)}

								{page === 2 && infoAfiliado.preguntasSeguridad.length > 0 && (
									<form>
										{' '}
										<PreguntaSeguridad
											infoAfiliado={infoAfiliado}
											listaRespuesta={listaRespuesta}
											setListaRespuesta={setListaRespuesta}
											cantidadIntentos={cantidadIntentos}
										/>
									</form>
								)}

								{page === 3 && (
									<form>
										{' '}
										<Grid container>
											<Grid item xs={12} marginTop='8%'>
												<CustomLabel style={{ ...labelDatosPersonalesRegistro }}>Datos Personales</CustomLabel>
											</Grid>
											<Grid item xs={12} marginTop='3%'>
												<InputBase
													style={{ ...inputRegistro }}
													placeholder='Nombres'
													{...register('nombres', {
														required: 'El campo Nombres es obligatorio'
													})}
													value={nombres}
												/>
												{errors.nombres && <span style={{ color: 'red' }}> {errors.nombres.message} </span>}
											</Grid>
											<Grid item xs={12} marginTop='3%'>
												<InputBase
													style={{ ...inputRegistro }}
													placeholder='Apellidos'
													{...register('apellidos', {
														required: 'El campo Apellidos es obligatorio'
													})}
													value={apellidos}
												/>
												{errors.apellidos && <span style={{ color: 'red' }}> {errors.apellidos.message} </span>}
											</Grid>
											<Grid item xs={12} marginTop='3%'>
												<InputBase
													style={{ ...inputRegistro }}
													placeholder='Correo electrónico'
													{...register('correo', {
														required: 'Por favor, indique su correo electrónico.',
														pattern: {
															value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
															message: 'Correo electronico invalido'
														}
													})}
												/>
												{errors.correo && <span style={{ color: 'red' }}> {errors.correo.message} </span>}
											</Grid>
											<Grid item xs={12} marginTop='3%'>
												<InputBase
													style={{ ...inputRegistro }}
													placeholder='confirme el correo electrónico'
													{...register('repeatCorreo', {
														required: 'Por favor, indique su correo electrónico.',
														pattern: {
															value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
															message: 'Correo electronico invalido'
														}
													})}
												/>
												{errors.repeatCorreo && <span style={{ color: 'red' }}> {errors.repeatCorreo.message} </span>}
											</Grid>
											<Grid container spacing={5}>
												<Grid item xs={12} md={6} marginTop='3%'>
													<Grid style={{ display: 'flex', alignItems: 'center', height: 44 }}>
														<InputBase
															style={{ ...inputRegistro }}
															placeholder='Contraseña'
															{...register('password', {
																required: 'Debe escribir la Contraseña.',
																minLength: {
																	value: 6,
																	message: 'El mínimo de caracteres para la contraseña es 6'
																}
															})}
															type={'password'}
															
														/>
													</Grid>
													<Grid mt={1}>
														{errors.password && <span style={{ color: 'red', marginTop: 1 }}> {errors.password.message} </span>}
													</Grid>
													
												</Grid>
												
												<Grid item xs={12} md={6} sx={{ marginTop: { xs: 0, md: '3%' }}}>
													<Grid style={{ display: 'flex', alignItems: 'center', height: 44 }}>
														<InputBase
															style={{ ...inputRegistro }}
															placeholder='Repetir contraseña'
															{...register('repeatPassword', {
																required: 'El campo Repetir contraseña es obligatorio',
																minLength: {
																	value: 6,
																	message: 'El mínimo de caracteres para la contraseña es 6'
																}
															})}
															type='password'
															
														/>
													</Grid>
													<Grid mt={1}>
														{errors.password && <span style={{ color: 'red', marginTop:1 }}> {errors.password.message} </span>}
													</Grid>
													
												</Grid>
											</Grid>
										</Grid>
									</form>
								)}

								<Grid container>
									<Grid item xs={6} marginTop={'5%'}>
										{page !== 1 && page !== 3 && (
											<Fab style={{ ...botonPreviousPage }} aria-label='arrow back' onClick={previousPage}>
												<ArrowBackIos />
											</Fab>
										)}
									</Grid>
									<Grid item xs={6} marginTop={'5%'}>
										<Fab
											style={{
												...botonNextPage,
												backgroundColor: isValid ? colors.primary : colors.grayLight
											}}
											aria-label='arrow foward'
											onClick={handleSubmit(nextPage)}
											disabled={!isValid}
										>
											<ArrowForwardIos />
										</Fab>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={6} sx={{ display: { xs: 'none', md: 'block' } }}>
								<img src={fondo} width='100.3%' height={620} />
								<Clear style={{ ...cerrarModalRegistro }} onClick={cerrarRegisterModal} />
							</Grid>
						</Grid>
					</div>
				</DialogContent>
			</Dialog>
		</>
	)
}

export default RegistroModal
