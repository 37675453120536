import React from 'react';
import CarneAfiliado from '../../components/carne/CarneAfiliado';
import { useEffect } from 'react';
import { useState } from 'react';
import * as apiService from '../../../api/'
import { useSelector } from 'react-redux';
import { Divider, Grid } from '@mui/material';
import useSetTitlePage from '../../hooks/useSetTitlePage';
import CustomLabel from '../../components/common/customLabel';
import {
  labelDatosPersonalesProfile,
} from "../../global/styles";
import ModalLoading from '../../components/common/modalLoading';
import { createIdTag, getColorPlanByIdTipoPlan } from '../../utils/helpers';

const Carne = () => {
  const [datosAfiliacion] = useState(useSelector((state) => state.afiliado))
  const [afiliado, setAfiliado] = useState();

  const TitleSolicitud = (
    <>
      <CustomLabel style={{ ...labelDatosPersonalesProfile }}>
        Carnet digital
      </CustomLabel>
      <Divider />
      <br />
      <br />
    </>
  );

  useSetTitlePage(TitleSolicitud);

  const getNucleoCarnet = async(codigoAfiliado) => {
    const response  = await apiService.afiliado.getNucleoCarnet(codigoAfiliado)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => console.log(err))

    return response
  }

  useEffect(async() => {
    const result = await getNucleoCarnet(datosAfiliacion.infoAfiliado.codigoAfiliado)
    if(result){
      const afiliadoOrdenado = result.filter(x => x.idAfiliado === datosAfiliacion.infoAfiliado.codigoAfiliado)
    
      if(afiliadoOrdenado[0].tipoAfiliado !== "TITULAR"){
        setAfiliado(afiliadoOrdenado)
      }
      else{
        setAfiliado(result)
      }
    }
  },[])

  return (
  <Grid>
      <Grid display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
        { afiliado ?
          afiliado.map(x => {
              return(
                <Grid ml={5} mb={2} id={createIdTag("carnet", x.idAfiliado.toString())}>
                  <CarneAfiliado
                    infoAfiliado={x}
                    regimen={x.regimen}
                    planComplementario={x.planes_Nombre}
                    idTipoPlan={getColorPlanByIdTipoPlan(1)}
                  />
                </Grid>
              )
          })
          :<ModalLoading open={true} />
        }
        
      </Grid>
  </Grid>
  );
};

export default Carne;
