import { useState, useEffect } from 'react'
import { Grid, Button, InputBase, InputLabel, TextField, FormControl, Stepper, Step, StepLabel, Box, Paper, Fab } from '@mui/material'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import CustomLabel from '../../components/common/customLabel'
import logoHorizontal from '../../../assets/logoHorizontal.png'

import colors from '../../../config/colors'

import {
	labelTitlePrestador,
	inputFormularioAfiliacion,
	customInputLabelProfile,
	botonBuscarPrestador,
	selectRegistro,
	inputRegistro,
	botonPreviousPage,
	botonNextPage
} from '../../global/styles'
import DialogDependiente from '../../components/formulario/solicitudAfiliacion/dialogDependiente'
import { useForm } from 'react-hook-form'

import NumberFormat from 'react-number-format'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import DesktopDatePicker from '@mui/lab/DesktopDatePicker'

import * as apiService from '../../../api'

import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

import UseSetIsLogin from '../../hooks/useSetIsLogin'

import ItemDependiente from '../../components/formulario/solicitudAfiliacion/itemDependiente'

import CustomConfirmModal from '../../components/common/customConfirmModal'

import ItemSintomaEnfermedad from '../../components/formulario/solicitudAfiliacion/itemSintomaEnfermedad'

import { makeStyles } from '@mui/styles'

import ModalLoading from '../../components/common/modalLoading'

import ModalConfirmacion from '../../components/formulario/solicitudAfiliacion/modalConfirmacion'

import { format } from 'date-fns'
import { createIdTag } from '../../utils/helpers'

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiStepIcon-root.Mui-active': {
			color: colors.primary,
			fontSize: 40
		},
		'& .MuiStepIcon-root.Mui-completed': {
			color: colors.primary,
			fontSize: 40
		},
		'& .Mui-disabled .MuiStepIcon-root': {
			color: colors.gray,
			fontSize: 40
		},
		'& .MuiStepConnector-line': {
			borderColor: colors.primary
		}
	}
}))

const SolicitudAfiliacion = () => {
	const [esMasculino, setEsMasculino] = useState(true)
	const [minFechaNacimiento, setMinFechaNacimiento] = useState(new Date())
	var navigate = useNavigate()
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	const [openDialog, setOpenDialog] = useState(false)
	const [maxDateField, setMaxDateField] = useState('')

	const handlerCloseDialog = () => {
		setOpenDialog(false)
		setDependienteActual(emptyDependiente)
	}
	const handlerOpenDialog = () => {
		const sexo = getValues('sexo')
		setDependienteActual(emptyDependiente)
		setModoModalDependiente(1)
		setEsMasculino(sexo === 'Masculino' ? true : false)
		setOpenDialog(true)
	}

	const [listaRespuestaSintoma, setListaRespuestaSintoma] = useState([])

	const handlerListaRespuestaSintoma = (index, item, value, esHijo) => {
		const listaActual = listaRespuestaSintoma
		if (listaActual.filter((a) => a.IdCatalogoSintoma === item.codigo).length > 0) {
			listaActual
				.filter((a) => a.IdCatalogoSintoma === item.codigo)
				.map((b) => {
					if (index === 1) {
						b.esUsted = value
					} else if (index === 2) {
						b.esPadre = value
					} else if (index === 3) {
						b.esHermano = value
					} else if (index === 4) {
						b.esAbuelo = value
					} else if (index === 5) {
						b.esTio = value
					} else if (index === 6) {
						b.esOtro = value
					} else if (index === 7 || index === 8) {
						if (esHijo) {
							b.codigoPadre = item.codigoPadre
						}
						b.campoAdicional = value
					} else if (index === 9) {
						b.esMadre = value
					}
				})
		} else {
			var elemento = {
				IdCatalogoSintoma: item.codigo,
				campoAdicional: index === 8 || index === 7 ? value : null,
				esUsted: false,
				esPadre: false,
				esMadre: false,
				esHermano: false,
				esAbuelo: false,
				esTio: false,
				esOtro: false,
				codigoPadre: esHijo ? item.codigoPadre : null
			}
			if (index === 1) {
				elemento.esUsted = value
			} else if (index === 2) {
				elemento.esPadre = value
			} else if (index === 3) {
				elemento.esHermano = value
			} else if (index === 4) {
				elemento.esAbuelo = value
			} else if (index === 5) {
				elemento.esTio = value
			} else if (index === 6) {
				elemento.esOtro = value
			} else if (index === 9) {
				elemento.esMadre = value
			}
			listaActual.push(elemento)
		}
		setListaRespuestaSintoma(listaActual)
	}

	const [openSimpleModal, setOpenSimpleModal] = useState(false)
	const handlerCloseSimpleModal = () => setOpenSimpleModal(false)
	const [mensajeSimpleModal, setMensajeSimpleModal] = useState('')

	const [openModalLoding, setOpenModalLoding] = useState(false)
	const handlerCloseOpenModal = () => setOpenModalLoding(false)

	const emptyDependiente = {
		apellidos: '',
		nacionalidad: '',
		noDocumento: '',
		nombres: '',
		parentesco: '',
		sexo: '',
		tipoDocumento: '',
		idNacionalidad: '',
		idParentesco: '',
		fechaNacimiento: null
	}

	const [dependienteActual, setDependienteActual] = useState(emptyDependiente)

	const { setAuthLogOut } = UseSetIsLogin()

	const [countActualizoPlan, setCountActualizoPlan] = useState(0)

	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors, isValid }
	} = useForm({ mode: 'onSubmit' })

	const [nacionalidades, setNacionalidades] = useState([])
	const [documentosRequeridos, setDocumentosRequeridos] = useState([])

	useEffect(() => {
		const body = {
			idServicio: 7,
			idParentesco: 0,
			mayorEdad: true,
			menorEdad: false,
			nacional: true,
			estudiante: false,
			discapacitado: false
		}

		apiService.operaciones
			.getTiposDocumentosRequeridos(body)
			.then((response) => {
				setDocumentosRequeridos(response.data.result)
			})
			.catch((err) => console.log(err))
	}, [documentosRequeridos])

	useEffect(() => {
		setAuthLogOut()
		apiService.drops
			.getAllNacionalidades()
			.then((response) => {
				setNacionalidades(response.data.result)
				setValue('nacionalidad', response.data.result.filter((a) => a.cdg === 36)[0].descripcion)
			})
			.catch((err) => console.log(err))

		const listaFormas = apiService.drops.getAllFormasPago()

		listaFormas.unshift({
			codigo: 0,
			descripcion: 'SELECCIONE LA FORMA DE PAGO'
		})
		setFormasPago(listaFormas)
		const listaFrecuencia = apiService.drops.getAllFrecuenciaPago()
		listaFrecuencia.unshift({
			codigo: 0,
			descripcion: 'SELECCIONE LA FRECUENCIA DE PAGO'
		})
		setFrecuenciasPago(listaFrecuencia)
		const listaPlanes = apiService.drops.getAllPlanDisponible()
		listaPlanes.unshift({ codigo: 0, descripcion: 'SELECCIONE EL PLAN' })
		setPlanes(listaPlanes)
		apiService.afiliado
			.getFormularioCatalogoSintomaEnfermedadNucleo()
			.then((response) => {
				const lista = response.data.result

				var nuevaLista = []
				var numeroLinea = 0
				lista.forEach((item) => {
					if (item.codigoPadre === null) {
						numeroLinea++
						var linea = {
							codigo: item.codigo,
							hasChild: false,
							codigoPadre: null,
							children: [],
							campoAdicional: item.campoAdicional,
							descripcion: item.descripcion,
							numeroOrden: numeroLinea
						}
						nuevaLista.push(linea)
					} else {
						nuevaLista
							.filter((a) => a.codigo === item.codigoPadre)
							.map((a) => {
								a.hasChild = true
								a.children.push({
									codigo: item.codigo,
									codigoPadre: item.codigoPadre,
									campoAdicional: item.campoAdicional,
									descripcion: item.descripcion
								})
							})
					}
				})
				setCatalogosSintomaEnfermedades(nuevaLista)
			})
			.catch((err) => console.log(err))

		let fecha = format(new Date(), 'yyyy-MM-dd')

		setMaxDateField(fecha)
		setMinFechaNacimiento(new Date(new Date().setFullYear(new Date().getFullYear() - 18)))
	}, [])

	const [nextDisabledStepper, setNextDisabledStepper] = useState(false)
	const [planField, setPlanField] = useState('0')
	const handlerPlanField = (event) => {
		setPlanField(event.target.value)
		if (event.target.value === '0') {
			setNextDisabledStepper(true)
			setCountActualizoPlan((prevState) => prevState + 1)
		} else {
			setNextDisabledStepper(false)
			setNewNextDisabled(false)
			setCountActualizoPlan((prevState) => prevState + 1)
		}
	}
	const [formaPagoField, setFormaPagoField] = useState(0)
	const handlerFormaPagoField = (event) => setFormaPagoField(event.target.value)
	const [frecuenciaPagoField, setFrecuenciaPagoField] = useState(0)
	const handlerFrecuenciaPagoField = (event) => setFrecuenciaPagoField(event.target.value)

	const [activeStep, setActiveStep] = useState(0)
	const [skipped, setSkipped] = useState(new Set())

	const steps = ['Plan', 'Datos personales', 'Datos de dependiente', 'Datos de pago', 'Declaración de Salud']

	const c = useStyles()

	useEffect(() => {
		if (activeStep === 0) {
			if (planField === '0') {
				setNextDisabledStepper(true)
				setNewNextDisabled(true)
			} else {
				setNextDisabledStepper(false)
				setNewNextDisabled(false)
			}
		}
	}, [planField, countActualizoPlan])

	useEffect(() => {
		if (activeStep === 3) {
			frecuenciaPagoField == 0 || formaPagoField == 0 ? setNextDisabledStepper(true) : setNextDisabledStepper(false)
		} else if (activeStep === 2) {
			setNextDisabledStepper(false)
		}
	}, [frecuenciaPagoField, formaPagoField, activeStep])

	const [formatDocumento, setFormatDocumento] = useState('###-#######-#')
	const [maskDocumento, setMaskDocumento] = useState('_')
	const [noDocumento, setNoDocumento] = useState('')
	const [tipoDocumento, setTipoDocumento] = useState('1')
	const [customError, setCustomError] = useState({
		message: '',
		hasError: false
	})

	const [listaDependientes, setListaDependientes] = useState([])

	const handlerAgregarDependiente = (dependiente) => {
		const datos = {
			apellidos: dependiente.apellidos,
			nacionalidad: dependiente.nacionalidad,
			noDocumento: dependiente.noDocumento,
			nombres: dependiente.nombres,
			parentesco: dependiente.parentesco,
			sexo: dependiente.sexo,
			tipoDocumento: dependiente.tipoDocumento,
			idNacionalidad: dependiente.idNacionalidad,
			idParentesco: dependiente.idParentesco,
			fechaNacimiento: dependiente.fechaNacimiento,
			index: modoModalDependiente === 1 ? listaDependientes.length : dependiente.index
		}

		var nuevaLista = listaDependientes
		if (modoModalDependiente === 1) {
			nuevaLista.push(datos)
		} else {
			nuevaLista.forEach((a) => {
				if (a.index === dependiente.index && a.noDocumento === dependiente.noDocumento) {
					a.apellidos = dependiente.apellidos
					a.nacionalidad = dependiente.nacionalidad
					a.noDocumento = dependiente.noDocumento
					a.nombres = dependiente.nombres
					a.parentesco = dependiente.parentesco
					a.sexo = dependiente.sexo
					a.tipoDocumento = dependiente.tipoDocumento
					a.idNacionalidad = dependiente.idNacionalidad
					a.idParentesco = dependiente.idParentesco
					a.fechaNacimiento = dependiente.fechaNacimiento
					a.index = dependiente.index
				}
			})
		}

		setListaDependientes(nuevaLista)
		handlerCloseDialog()
	}

	const handlerFormatDocumento = (tipo) => {
		if (tipo == 1) {
			setFormatDocumento('###-#######-#')
			setMaskDocumento('_')
			setValue('nacionalidad', nacionalidades.filter((a) => a.cdg === 36)[0].descripcion)
		} else {
			setFormatDocumento('#############')
			setMaskDocumento('')
		}
		setValue('noDocumento', '')
	}

	const validarFechaNacimiento = (value) => {
		if (value == null) {
			setCustomError({
				hasError: true,
				message: handlerMensajeCampoRequerido('Fecha de nacimiento')
			})
		} else {
			setCustomError({
				hasError: false,
				message: ''
			})
		}
	}

	const [fechaNacimiento, setFechaNacimiento] = useState(null)
	const handlerFechaNacimiento = (value) => {
		setFechaNacimiento(value)
		validarFechaNacimiento(value)
	}

	const documentos = [
		{
			id: 1,
			descripcion: 'Cédula'
		},
		{
			id: 2,
			descripcion: 'Pasaporte'
		}
	]

	const estadosCivil = [
		{
			id: 1,
			descripcion: 'Soltero'
		},
		{
			id: 2,
			descripcion: 'Casado'
		},
		{
			id: 3,
			descripcion: 'Unión libre'
		}
	]

	const sexos = ['Masculino', 'Femenino']

	const handlerSubmitSolicitud = (data) => {
		validarFechaNacimiento(fechaNacimiento)
		if (customError.hasError) return
		listaDependientes.map((a) => {
			a.tipoDocumento = Number(a.tipoDocumento)
		})
		const body = {
			...data,
			tipoDocumento: data.tipoDocumento === '1' ? 'Cédula' : 'Pasaporte',
			idTipoDocumento: Number(data.tipoDocumento),
			idEstadoCivil: estadosCivil.filter((a) => a.descripcion == data.estadoCivil)[0].id,
			idNacionalidad: nacionalidades.filter((a) => a.descripcion === data.nacionalidad)[0].cdg,
			fechaNacimiento,
			formaPago: formasPago.filter((a) => a.codigo === Number(formaPagoField))[0].descripcion,
			frecuenciaPago: frecuenciasPago.filter((a) => a.codigo === Number(frecuenciaPagoField))[0].descripcion,
			planSolicitado: planes.filter((a) => a.codigo === Number(planField))[0].descripcion,
			listDependientes: listaDependientes,
			listSintomasNucleo: listaRespuestaSintoma
		}

		setOpenModalLoding(true)

		apiService.afiliado
			.postFormularioSolicitudAfiliacion(body)
			.then((response) => {
				setOpenModalConfirmacion(true)
			})
			.catch((err) => console.log(err))
			.finally(() => {
				setOpenModalLoding(false)
			})
	}

	const handlerMensajeCampoRequerido = (campo) => {
		return `El campo ${campo} es requerido.`
	}

	const handlerGuardarItemDependiente = (dependiente, index) => {
		setModoModalDependiente(2)
		setDependienteActual({ ...dependiente, index })
		setOpenDialog(true)
	}

	const handlerEliminarItemDependiente = (dependiente, index) => {
		setDependienteActual({ ...dependiente })
		setMensajeSimpleModal('Desea eliminar el dependiente?')
		setOpenSimpleModal(true)
	}

	const yesHandlerCustomSimpleModal = () => {
		const nuevaLista = listaDependientes.filter((a) => a.index != dependienteActual.index)
		setListaDependientes(nuevaLista)
		setDependienteActual(emptyDependiente)
		setModoModalDependiente(1)
		handlerCloseSimpleModal()
	}

	const [countDeclaracionSalud, setCountDeclaracionSalud] = useState(0)

	const [modoModalDependiente, setModoModalDependiente] = useState(1)

	const isStepOptional = (step) => {
		return step === 1
	}

	const isStepSkipped = (step) => {
		return skipped.has(step)
	}

	const handleNext = (data) => {
		if (activeStep === 0) {
			if (getValues('nombres') === undefined || isValid === false) {
				setNewNextDisabled(true)
			}
		}

		if (activeStep === 1) {
			validarFechaNacimiento(fechaNacimiento)
			if (fechaNacimiento === null) return
			if (customError.hasError) return
		}

		let newSkipped = skipped
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values())
			newSkipped.delete(activeStep)
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1)
		setSkipped(newSkipped)
	}

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1)
		if (activeStep === 4) {
			setCountDeclaracionSalud((prevState) => prevState + 1)
		}
		if (activeStep !== 0) {
			setNewNextDisabled(false)
		}
	}

	const [formasPago, setFormasPago] = useState([])
	const [frecuenciasPago, setFrecuenciasPago] = useState([])
	const [planes, setPlanes] = useState([])
	const [catalogosSintomaEnfermedades, setCatalogosSintomaEnfermedades] = useState([])

	const [telefonoField, setTelefonoField] = useState('')
	const [telefonoOficinaField, setTelefonoOficinaField] = useState('')
	const [celularField, setCelularField] = useState('')

	const [newNextDisabled, setNewNextDisabled] = useState(true)

	useEffect(() => {
		if (nextDisabledStepper === false && isValid === false && activeStep === 0) {
			setNewNextDisabled(false)
		} else if (nextDisabledStepper === false && isValid === false) {
			setNewNextDisabled(true)
		} else if (nextDisabledStepper === true || isValid === false) {
			setNewNextDisabled(true)
		} else {
			setNewNextDisabled(false)
		}
	}, [isValid, nextDisabledStepper])

	const [openModalConfirmacion, setOpenModalConfirmacion] = useState(false)

	const handlerInValidSubmit = (data) => {
		if (fechaNacimiento === null) {
			setCustomError({
				hasError: true,
				message: handlerMensajeCampoRequerido('Fecha de nacimiento')
			})
		} else {
			setCustomError({
				hasError: false,
				message: ''
			})
		}
	}

	return (
		<>
			<Grid container style={{ paddingInline: 30 }}>
				<Grid
					item
					xs={12}
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<img src={logoHorizontal} style={{ width: 200 }} />
					<CustomLabel
						style={{
							...labelTitlePrestador,
							marginLeft: 0,
							fontSize: 30,
							marginTop: 10
						}}
					>
						SOLICITUD DE AFILIACIÓN PLANES ALTERNATIVOS DE SALUD
					</CustomLabel>
				</Grid>
				<Grid item xs={12} marginTop={2}>
					<Stepper activeStep={activeStep} className={c.root}>
						{steps.map((label, index) => {
							return (
								<Step key={label}>
									<StepLabel>
										{' '}
										<label
											style={{
												fontFamily: 'Montserrat',
												fontSize: 15
											}}
										>
											{label}
										</label>{' '}
									</StepLabel>
								</Step>
							)
						})}
					</Stepper>
					{activeStep === steps.length ? (
						<>
							<Grid
								container
								marginTop={2}
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center'
								}}
							>
								<Grid item xs={12} md={6}>
									<Button
										variant='contained'
										style={{
											...botonBuscarPrestador,
											fontFamily: 'Montserrat',
											height: 58,
											fontSize: 20
											//   backgroundColor: isValid ? colors.primary : colors.gray,
										}}
										//   disabled={!isValid}
										fullWidth
										onClick={handleSubmit(handlerSubmitSolicitud)}
									>
										Enviar solicitud
									</Button>
								</Grid>
							</Grid>

							{/*
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
              
              */}
						</>
					) : (
						<>
							{activeStep === 0 && (
								<>
									<Grid container style={{ padding: 10, columnGap: 5 }}>
										<Grid item xs={12}>
											<CustomLabel
												style={{
													fontFamily: 'Montserrat',
													fontWeight: 'bold',
													color: colors.primary
												}}
											>
												Plan
											</CustomLabel>
										</Grid>

										<Grid item xs={12} md={12}>
											<select
												style={{
													borderRadius: 10,
													...selectRegistro,
													...inputRegistro,
													boxShadow: 'none',
													height: 58,
													fontFamily: 'Montserrat'
												}}
												id='select-plan'
												value={planField}
												onChange={handlerPlanField}
											>
												{planes.map((item) => (
													<option id={createIdTag("option", item.descripcion)} key={item.codigo} value={item.codigo}>
														{item.descripcion}
													</option>
												))}
											</select>
											{errors.plan && <span style={{ color: 'red' }}> {errors.plan.message} </span>}
										</Grid>
									</Grid>
								</>
							)}

							{activeStep === 1 && (
								<>
									<Grid
										container
										style={{
											display: 'flex',
											rowGap: 10
										}}
									>
										<Grid
											item
											xs={12}
											marginTop={2}
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between'
											}}
										>
											<CustomLabel
												style={{
													...labelTitlePrestador,
													marginLeft: 0,
													fontSize: 30,
													padding: 5,
													color: colors.primary
												}}
											>
												Datos personales
											</CustomLabel>
											<CustomLabel
												style={{
													...labelTitlePrestador,
													marginLeft: 0,
													fontSize: 30,
													padding: 5,
													color: colors.primary
												}}
											>
												{planes.filter((a) => a.codigo == planField)[0].descripcion}
											</CustomLabel>
										</Grid>

										<Grid item xs={12} md={6}>
											<label style={{ ...customInputLabelProfile, color: 'black' }}>Nombres</label>
											<InputBase
												placeholder='Nombres'
												style={{ ...inputFormularioAfiliacion, height: 58, width: '99%' }}
												{...register('nombres', {
													required: handlerMensajeCampoRequerido('Nombres')
												})}
											/>
											{errors.nombres && <span style={{ color: 'red' }}> {errors.nombres.message} </span>}
										</Grid>
										<Grid item xs={12} md={6}>
											<label style={{ ...customInputLabelProfile, color: 'black' }}>Apellidos</label>
											<InputBase
												placeholder='Apellidos'
												style={{ ...inputFormularioAfiliacion, height: 58, width: '99%' }}
												{...register('apellidos', {
													required: handlerMensajeCampoRequerido('Apellidos')
												})}
											/>
											{errors.apellidos && <span style={{ color: 'red' }}> {errors.apellidos.message} </span>}
										</Grid>
										<Grid item xs={12} md={3} marginTop={-0.6}>
											<InputLabel
												id='label-tipo-documento'
												style={{
													fontFamily: 'Montserrat',
													marginBottom: 2,
													//  color: colors.grayLight,
													color: 'black',
													fontWeight: 'bold'
												}}
											>
												Tipo de documento
											</InputLabel>
											<select
												style={{
													borderRadius: 10,
													...selectRegistro,
													...inputRegistro,
													boxShadow: 'none',
													height: 58,
													fontFamily: 'Montserrat',
													width: '98%'
												}}
												id='select-tipo-documento'
												{...register('tipoDocumento', {
													required: handlerMensajeCampoRequerido('tipo de documento')
												})}
												value={tipoDocumento}
												onChange={(event) => {
													setTipoDocumento(event.target.value)
													setNoDocumento('')
													handlerFormatDocumento(event.target.value)
												}}
											>
												{documentos.map((item) => (
													<option id={createIdTag("option", item.descripcion)} key={item.id} value={item.id}>
														{item.descripcion}
													</option>
												))}
											</select>
											{errors.tipoDocumento && <span style={{ color: 'red' }}> {errors.tipoDocumento.message} </span>}
										</Grid>
										<Grid item xs={12} md={3}>
											<label style={{ ...customInputLabelProfile, color: 'black' }}>Documento</label>
											{tipoDocumento == '1' && (
												<NumberFormat
													value={noDocumento}
													format={formatDocumento}
													mask={maskDocumento}
													style={{ ...inputFormularioAfiliacion, height: 58, width: '98%' }}
													placeholder='Documento'
													{...register('noDocumento', {
														required: handlerMensajeCampoRequerido('Documento')
													})}
													onValueChange={(values) => {
														setNoDocumento(values.value)
														setValue('noDocumento', values.formattedValue)
													}}
													customInput={InputBase}
												/>
											)}
											{tipoDocumento == '2' && (
												<InputBase
													inputProps={{ maxLength: 30 }}
													placeholder='Documento'
													style={{ ...inputFormularioAfiliacion, height: 58, width: '98%' }}
													{...register('noDocumento', {
														required: handlerMensajeCampoRequerido('Documento')
													})}
												/>
											)}
											{errors.noDocumento && <span style={{ color: 'red' }}> {errors.noDocumento.message} </span>}
										</Grid>
										<Grid item xs={12} md={3}>
											<label style={{ ...customInputLabelProfile, color: 'black' }}>Fecha de nacimiento</label>
											<FormControl
												className='formcontrol'
												fullWidth
												style={{
													border: 'solid 2px',
													borderColor: colors.primary,
													borderRadius: 10,
													width: '97%'
												}}
											>
												<LocalizationProvider dateAdapter={AdapterDateFns}>
													<DesktopDatePicker
														label=''
														inputFormat='dd/MM/yyyy'
														maxDate={minFechaNacimiento}
														value={fechaNacimiento}
														onChange={handlerFechaNacimiento}
														
														style={{
															
															fontFamily: 'Montserrat',
															fontSize: 20
														}}
														inputProps={{ readOnly: true }}
														renderInput={(params) => <TextField {...params} />}
													/>
												</LocalizationProvider>
											</FormControl>
											{customError.hasError && <span style={{ color: 'red' }}> {customError.message} </span>}
										</Grid>
										<Grid item xs={12} md={3} marginTop={'-0.3%'}>
											<InputLabel
												id='label-sexo'
												style={{
													fontFamily: 'Montserrat',
													marginBottom: 2,
													color: 'black',
													fontWeight: 'bold'
												}}
											>
												Sexo
											</InputLabel>
											<select
												style={{
													borderRadius: 10,
													...selectRegistro,
													...inputRegistro,
													boxShadow: 'none',
													height: 58,
													fontFamily: 'Montserrat',
													width: '98%'
												}}
												id='select-sexo'
												{...register('sexo', {
													required: handlerMensajeCampoRequerido('Sexo')
												})}
											>
												{sexos.map((item) => (
													<option id={createIdTag("option",item)} key={item} value={item}>
														{item}
													</option>
												))}
											</select>
											{errors.sexo && <span style={{ color: 'red' }}> {errors.sexo.message} </span>}
										</Grid>
										<Grid item xs={12} md={3} marginTop={-0.3}>
											<InputLabel
												id='label-nacionalidad'
												style={{
													fontFamily: 'Montserrat',
													marginBottom: 2,
													color: 'black',
													fontWeight: 'bold'
												}}
											>
												Nacionalidad
											</InputLabel>
											<select
												style={{
													borderRadius: 10,
													...selectRegistro,
													...inputRegistro,
													boxShadow: 'none',
													height: 58,
													fontFamily: 'Montserrat',
													width: '98%'
												}}
												id='select-nacionalidad'
												{...register('nacionalidad')}
											>
												{nacionalidades.map((item) => (
													<option option={createIdTag("option", item.descripcion)} key={item.cdg} value={item.descripcion}>
														{item.descripcion}
													</option>
												))}
											</select>
										</Grid>
										<Grid item xs={12} md={3} marginTop={0.6}>
											<label style={{ ...customInputLabelProfile, color: 'black' }}>Ocupación</label>
											<InputBase
												placeholder='Ocupación'
												style={{ ...inputFormularioAfiliacion, height: 58, width: '98%' }}
												{...register('ocupacion')}
											/>
										</Grid>
										<Grid item xs={12} md={3} marginTop={0.6}>
											<label style={{ ...customInputLabelProfile, color: 'black' }}>Correo electrónico</label>
											<InputBase
												placeholder='Correo electrónico'
												style={{ ...inputFormularioAfiliacion, height: 58, width: '98%' }}
												{...register('correo', {
													required: handlerMensajeCampoRequerido('Correo electrónico'),
													pattern: {
														value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
														message: 'Correo electronico invalido'
													}
												})}
											/>
											{errors.correo && <span style={{ color: 'red' }}> {errors.correo.message} </span>}
										</Grid>
										<Grid item xs={12} md={3} marginTop={-0.3}>
											<InputLabel
												id='label-estado-civil'
												style={{
													fontFamily: 'Montserrat',
													marginBottom: 2,
													color: 'black',
													fontWeight: 'bold'
												}}
											>
												Estado civil
											</InputLabel>
											<select
												style={{
													borderRadius: 10,
													...selectRegistro,
													...inputRegistro,
													boxShadow: 'none',
													height: 58,
													fontFamily: 'Montserrat',
													width: '98%'
												}}
												id='select-estado-civil'
												{...register('estadoCivil')}
											>
												{estadosCivil.map((item) => (
													<option id={createIdTag("option", item.descripcion)} key={item.id} value={item.descripcion}>
														{item.descripcion}
													</option>
												))}
											</select>
										</Grid>
										<Grid item xs={12} md={6}>
											<label style={{ ...customInputLabelProfile, color: 'black' }}>Dirección</label>
											<InputBase
												placeholder='Dirección'
												style={{ ...inputFormularioAfiliacion, height: 58, width: '99%' }}
												{...register('direccion', {
													required: handlerMensajeCampoRequerido('Dirección')
												})}
											/>
											{errors.direccion && <span style={{ color: 'red' }}> {errors.direccion.message} </span>}
										</Grid>
										<Grid item xs={12} md={6}>
											<label style={{ ...customInputLabelProfile, color: 'black' }}>Sector</label>
											<InputBase
												placeholder='Sector'
												style={{ ...inputFormularioAfiliacion, height: 58, width: '99%' }}
												{...register('sector', {
													required: handlerMensajeCampoRequerido('Sector')
												})}
											/>
											{errors.sector && <span style={{ color: 'red' }}> {errors.sector.message} </span>}
										</Grid>
										<Grid item xs={12} md={3}>
											<label style={{ ...customInputLabelProfile, color: 'black' }}>Ciudad</label>
											<InputBase
												placeholder='Ciudad'
												style={{ ...inputFormularioAfiliacion, height: 58, width: '98%' }}
												{...register('ciudad', {
													required: handlerMensajeCampoRequerido('Ciudad')
												})}
											/>
											{errors.ciudad && <span style={{ color: 'red' }}> {errors.ciudad.message} </span>}
										</Grid>
										<Grid item xs={12} md={3}>
											<label style={{ ...customInputLabelProfile, color: 'black' }}>Teléfono</label>
											<NumberFormat
												format={'(###)-###-####'}
												mask={'_'}
												style={{ ...inputFormularioAfiliacion, height: 58, width: '98%' }}
												placeholder='Teléfono'
												{...register('telefono', {
													required: handlerMensajeCampoRequerido('Teléfono')
												})}
												value={telefonoField}
												onValueChange={(values) => {
										
													setTelefonoField(values.value)
													setValue('telefono', values.formattedValue)
												}}
												customInput={InputBase}
											/>
											{errors.telefono && <span style={{ color: 'red' }}> {errors.telefono.message} </span>}
										</Grid>
										<Grid item xs={12} md={3}>
											<label style={{ ...customInputLabelProfile, color: 'black' }}>Teléfono Oficina</label>
											<NumberFormat
												format={'(###)-###-####'}
												mask={'_'}
												style={{ ...inputFormularioAfiliacion, height: 58, width: '98%' }}
												placeholder='Teléfono oficina'
												{...register('oficina')}
												value={telefonoOficinaField}
												onValueChange={(values) => {
												
													setTelefonoOficinaField(values.value)
													setValue('oficina', values.formattedValue)
												}}
												customInput={InputBase}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<label style={{ ...customInputLabelProfile, color: 'black' }}>Celular</label>
											<NumberFormat
												format={'(###)-###-####'}
												mask={'_'}
												style={{ ...inputFormularioAfiliacion, height: 58, width: '98%' }}
												placeholder='Celular'
												{...register('celular', { required: false })}
												value={celularField}
												onValueChange={(values) => {
											
													setCelularField(values.value)
													setValue('celular', values.formattedValue)
												}}
												customInput={InputBase}
											/>
										</Grid>
									</Grid>
								</>
							)}

							{activeStep === 2 && (
								<>
									<Grid container style={{ marginTop: 20 }}>
										<Grid item xs={6}>
											<CustomLabel
												style={{
													...labelTitlePrestador,
													marginLeft: 0,
													fontSize: 30,
													padding: 5,
													color: colors.primary
												}}
											>
												Datos de dependientes
											</CustomLabel>
										</Grid>
										<Grid item xs={6}>
											<Button
												variant='contained'
												style={{
													...botonBuscarPrestador,
													fontFamily: 'Montserrat',
													backgroundColor: colors.primary,
													color: 'white',
													float: 'right',
													marginBottom: 15
												}}
												onClick={handlerOpenDialog}
											>
												Agregar dependiente
											</Button>
										</Grid>
										<Grid item xs={12} style={{ overflow: 'scroll', overflowX: 'hidden', height: 420 }}>
											{listaDependientes
												? listaDependientes.map((item, index) => (
														<ItemDependiente
															key={index}
															dependiente={item}
															handlerGuardarDependiente={() => handlerGuardarItemDependiente(item, index)}
															handlerEliminarDependiente={() => handlerEliminarItemDependiente(item, index)}
														/>
												  ))
												: null}
										</Grid>
									</Grid>
								</>
							)}

							{activeStep === 3 && (
								<>
									<Grid container style={{ padding: 10 }}>
										<Grid item xs={12}>
											<CustomLabel
												style={{
													fontFamily: 'Montserrat',
													fontWeight: 'bold',
													color: colors.primary
												}}
											>
												Datos de pago
											</CustomLabel>
										</Grid>

										<Grid item xs={12} md={6} style={{ paddingRight: 5 }}>
											<InputLabel
												id='label-formas'
												style={{
													fontFamily: 'Montserrat',
													marginBottom: 2,
													//  color: colors.grayLight,
													color: 'black',
													fontWeight: 'bold'
												}}
											>
												Formas
											</InputLabel>
											<select
												style={{
													borderRadius: 10,
													...selectRegistro,
													...inputRegistro,
													boxShadow: 'none',
													height: 58,
													fontFamily: 'Montserrat'
												}}
												id='select-formaspago'
												value={formaPagoField}
												onChange={handlerFormaPagoField}
											>
												{formasPago.map((item) => (
													<option id={createIdTag("option", item.descripcion)} key={item.codigo} value={item.codigo}>
														{item.descripcion}
													</option>
												))}
											</select>
											{errors.formaPago && <span style={{ color: 'red' }}> {errors.formaPago.message} </span>}
										</Grid>
										<Grid item xs={12} md={6}>
											<InputLabel
												id='label-frecuencia'
												style={{
													fontFamily: 'Montserrat',
													marginBottom: 2,
													//  color: colors.grayLight,
													color: 'black',
													fontWeight: 'bold'
												}}
											>
												Frecuencia
											</InputLabel>
											<select
												style={{
													borderRadius: 10,
													...selectRegistro,
													...inputRegistro,
													boxShadow: 'none',
													height: 58,
													fontFamily: 'Montserrat'
												}}
												id='select-frecuenciapago'
												value={frecuenciaPagoField}
												onChange={handlerFrecuenciaPagoField}
											>
												{frecuenciasPago.map((item) => (
													<option id={createIdTag("option", item.descripcion)} key={item.codigo} value={item.codigo}>
														{item.descripcion}
													</option>
												))}
											</select>
											{errors.frecuenciaPago && <span style={{ color: 'red' }}> {errors.frecuenciaPago.message} </span>}
										</Grid>
									</Grid>
								</>
							)}

							{activeStep === 4 && (
								<>
									<Grid container style={{ padding: 10 }}>
										<Grid item xs={12}>
											<CustomLabel
												style={{
													fontFamily: 'Montserrat',
													fontWeight: 'bold',
													color: colors.primary
												}}
											>
												¿Usted o algunos de sus familiares ha padecido de cualquiera de los siguientes síntomas y/o enfermedades?
											</CustomLabel>
										</Grid>
										<Paper elevation={5}>
											{catalogosSintomaEnfermedades &&
												catalogosSintomaEnfermedades.map((item) => (
													<ItemSintomaEnfermedad
														handlerListaRespuestaSintoma={handlerListaRespuestaSintoma}
														key={item.codigo}
														item={item}
														countDeclaracionSalud={countDeclaracionSalud}
														listaRespuestas={listaRespuestaSintoma}
													/>
												))}
										</Paper>
									</Grid>
								</>
							)}
						</>
					)}
					<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
						<Fab
							style={{
								...botonPreviousPage,
								backgroundColor: activeStep !== 0 ? colors.primary : colors.grayLight
							}}
							aria-label='arrow back'
							disabled={activeStep === 0}
							onClick={handleBack}
						>
							<ArrowBackIos />
						</Fab>
						<Box sx={{ flex: '1 1 auto' }} />
						{activeStep !== steps.length ? (
							<Fab
								style={{
									...botonNextPage,
									backgroundColor: !newNextDisabled ? colors.primary : colors.grayLight
								}}
								aria-label='arrow foward'
								onClick={handleSubmit(handleNext, handlerInValidSubmit)}
								disabled={nextDisabledStepper}
							>
								<ArrowForwardIos />
							</Fab>
						) : null}
					</Box>
				</Grid>
			</Grid>

			<ModalLoading open={openModalLoding} onCloseHandler={handlerCloseOpenModal} />

			<CustomConfirmModal
				open={openSimpleModal}
				closeHandler={handlerCloseSimpleModal}
				mensaje={mensajeSimpleModal}
				yesHandler={yesHandlerCustomSimpleModal}
				noHandler={handlerCloseSimpleModal}
			/>

			<DialogDependiente
				open={openDialog}
				handlerCloseModal={handlerCloseDialog}
				esMasculino={esMasculino}
				handlerAgregarDependiente={handlerAgregarDependiente}
				dependiente={dependienteActual}
				modoModalDependiente={modoModalDependiente}
				maxDateField={maxDateField}
			/>

			<ModalConfirmacion open={openModalConfirmacion} />
		</>
	)
}

export default SolicitudAfiliacion
