import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import LogoRedondo from "../../../assets/logoRedondo.png";

const ModalLoading = ({ open, onCloseHandler }) => {
  return (
    <Modal open={open} onClose={onCloseHandler}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          bgcolor: "#ffffffc7",
          // border: `1px solid ${colors.primary}`,
          boxShadow: 15,
          p: 4,
          textAlign: "center",
        }}
        className='container-loading'
      >
        <img className='jp-logo' src={LogoRedondo} alt='Jetpack' />
        <div className='ripples'></div>
      </Box>
    </Modal>
  );
};

export default ModalLoading;
