import React, {createContext, useState} from 'react'
import * as apiServices from '../../api';

export const ConfigurationContext = createContext({})

export const ConfigurationProvider = ({children}) => {
    const [isLoginPage, setIsLoginPage] = useState(false)
    const handlerIsLoginPage = bool => setIsLoginPage(bool)

    const [autenticado,setAutenticado] = useState(false)
    const handlerAutenticado = bool => setAutenticado(bool)

    const [alertasPersonalizadas, setAlertasPersonalizadas] = useState({})

    const getAlertaPersonalizada = async(id=0, nombre="") => {
        await apiServices.ajustes.getAlertaPersonalizada(id, nombre)
        .then(res => setAlertasPersonalizadas(res.data.result))
        .catch(err => console.error(err))
    }

    const mostrarMensajeAlerta = (codigoMensaje) => {
        return alertasPersonalizadas.find(x => x.nombre === codigoMensaje).mensaje
    }

    return (
        <ConfigurationContext.Provider
         value={{isLoginPage, 
                 handlerIsLoginPage, 
                 autenticado,
                 handlerAutenticado, 
                 getAlertaPersonalizada,
                 alertasPersonalizadas,
                 mostrarMensajeAlerta}}
        >
            {children}
        </ConfigurationContext.Provider>
    )
}