import { Button, Dialog, DialogContent, Grid } from "@mui/material";
import React, {useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import FormularioReembolso from "../../modal/formularioReembolso"
import colors from "../../../../config/colors";


const DialogSolicitudes = ({
  open,
  handlerCloseModal,
  data
}) => {

  const [showModal, setShowModal] = useState(false)

  const editarRegistro = () => {
    setShowModal(true);
  }

  const validarCamposReembolso = (value) => {
    let fields = <div></div>;

    if(value.estatusReembolso){
        if(value.estatusReembolsoFinal.toUpperCase() === "PROCEDE"){
          if(data.montoReembolsado)
          {
            fields = <div >
            <h3 >Monto rembolsado </h3>
            <h2 style={{fontWeight: 500,}}>{data.montoReembolsado}</h2>
            </div>
          }
        }
        else if(value.estatusReembolsoFinal.toUpperCase() === "NO PROCEDE"){
          if(data.respuestasGSAUP){
            fields = <div >
              <h3 >Respuesta: </h3>
              <h2 style={{fontWeight: 500,}}>{data.respuestasGSAUP}</h2>
            </div>
          }
        }
        else if(value.estatusReembolso.toUpperCase() === "DEVUELTO"){
          if(data.estatusReembolso){
              fields = <div >
                <h3 >Respuesta: </h3>
                <h2 style={{fontWeight: 500,}}>{data.estatusReembolso}</h2>
              </div>
          }
          
        }
    }
   

    return fields;
  }

  const esReembolso = (value) => {
    let fields = <div></div>;
    if(value){
      if( value.toLowerCase().includes('reembolso'))
        {
          if(data.estatusReembolso){
            fields =  <div >
            <h3 >Estado de reembolso: </h3>
            <h2 style={{fontWeight: 500,}}>{data.estatusReembolso}</h2>
          </div>
          }
         
        }
      
    }
   
    return fields;
  }
  
  return (
    <Dialog
      open={open}
      onClose={handlerCloseModal}
      sx={{ width: { xs: '100%', md: '100%' } }}
      maxWidth='sm'
      PaperProps={{
        style: {
          borderRadius: 15,
          padding: 10,
        },
      }}
    >
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 1,
        fontSize: 20,
        color: 'green',
        '&:hover': {
          color:'red' 
          },
      }}
      
      >
        <CloseIcon
          onClick = {() => handlerCloseModal(false)}
        />
      </div>
      {
        !data ? <></>
        :
          <DialogContent>
            <Grid container width={500}>
              <Grid item md={12} xs={12}  sx={{ overflow: 'auto' }}>
              {  
                  data.estatusReembolso !== 'Devuelto' 
                  ? <></>
                  :<Button  style={{backgroundColor:colors.primary}} variant={'contained'} onClick={() => editarRegistro(data.numeroCaso)}>
                      Editar 
                      <div>
                      <EditIcon style={{width: 15, marginLeft:3}}/>
                      </div>
                  </Button>
                }
                <div >
                  <h2
                  style={{
                    fontFamily: 'MontSerrat',
                    color: colors.primary,
                    fontSize:25
                  }}
                  >
                    Detalle de solicitud
                  </h2>
                </div>
                <div>
                  <div>
                    <h3>Título:</h3>
                    <h2 style={{fontWeight: 500,}}>{data.titulo}</h2>
                  </div>
                  <div >
                    <h3>No.Caso:</h3>
                    <h2 style={{fontWeight: 500,}}>{data.numeroCaso}</h2>
                  </div>
                  <div >
                    <h3 >Estado del caso: </h3>
                    <h2 style={{fontWeight: 500,}}>{data.estatus}</h2>
                  </div>
                  {
                    data.informacionAutorizacion ?
                    <div>
                      <h3 >Información de autorización: </h3>
                      <h2 style={{fontWeight: 500,}}>{data.informacionAutorizacion}</h2>
                    </div>
                  : <></>
                  }
                  {
                    data.prestadorAutorizado ?
                      <div>
                        <h3 >Prestador autorizado: </h3>
                        <h2 style={{fontWeight: 500,}}>{data.prestadorAutorizado}</h2>
                      </div>
                   : <></>
                  }
                  {
                    data.nap ?
                    <div>
                      <h3 >Número de aprobación: </h3>
                      <h2 style={{fontWeight: 500,}}>{data.nap}</h2>
                    </div>
                    : <></>
                  }
                  {
                    esReembolso(data.titulo)
                  }
                  {
                    validarCamposReembolso(data)
                  }
                  <div >
                    <h3>Fecha: </h3>
                    <h2 style={{fontWeight: 500,}}>{data.fechaApertura}</h2>
                  </div>
                  {
                    data.respuesta ?  
                    <div>
                      <h3 >Respuesta: </h3>
                      <p style={{fontWeight: 500, textAlign:'justify'}}>{data.respuesta}</p>
                    </div>
                    : <></>
                  }
                  {
                    (data.estatusReembolso === 'Devuelto' || data.estatusReembolso === 'Anulado') &&
                    <div>
                      <h3>Observación:</h3>
                      <p style={{fontWeight: 500, textAlign:'justify'}}>
                      {
                        data.estatusReembolso  === 'Devuelto'
                        ? `Modifique esta solicitud para completar la información requerida. \n Debe completar la información en un plazo no mayor a (24hr), de lo contrario el caso será anulado y deberá proceder con la solicitud nuevamente.`
                        : 
                        data.estatusReembolso === 'Anulado'
                        ? 'Esta solicitud fue anulada por no haberse completado la información requerida en plazo establecido de 24hr. Debe proceder a realizar una nueva solicitud.'
                        : '...'
                      }
                      </p>
                    </div>
                  }
                </div>
              </Grid>
            </Grid>
            {
                showModal === false ? ''
                : <FormularioReembolso 
                    isEnable={showModal}
                    titulo={"Formulario de reembolso"}
                    infoAfiliado={data.infoAfiliado}
                    descripcion={data.descripcion}
                    numeroTicket={data.numeroCaso}
                    isEdit={true}
                />
            }
          </DialogContent>

      }
     
    </Dialog>
  );
};

export default DialogSolicitudes;
