import React, { useState, useEffect } from 'react'
import { setInfoAfiliado } from '../redux/features/afiliado/afiliadoSlice'

import * as apiService from '../../api'
import { decodeToken, isExpired } from 'react-jwt'

import UseSetIsLogin from '../hooks/useSetIsLogin'

import { useDispatch } from 'react-redux'

const RouterProtected = ({ children }) => {
	const [cargando, setCargando] = useState(true)
	const { setAuthLogIn, setAuthLogOut } = UseSetIsLogin()

	const dispatch = useDispatch()

	const token = apiService.autentificacion.getJwt()

	const decode = decodeToken(token)
	const expired = isExpired(token)

	const verificarLogin = () => {
		var autenticado = localStorage.getItem('autenticado')
		if (autenticado == 'true' && !expired) {
			apiService.afiliado
				.getDatosPersonales(Number(decode.codigoAfiliado))
				.then((response) => {
					const afiliado = {
						codigoAfiliado: Number(decode.codigoAfiliado),
						nombres: response.data.nombres,
						apellidos: response.data.apellidos,
						regimen: response.data.regimen,
						tipoAfiliacion: response.data.tipoAfiliacion
					}
					dispatch(setInfoAfiliado(afiliado))
					setAuthLogIn()
					setCargando(false)
				})
				.catch((err) => console.log(err))
		} else {
			setAuthLogOut()
			setCargando(false)
		}
		// autenticado ? setAuthLogIn() : null
	}

	useEffect(() => {
		verificarLogin()
	}, [])

	return !cargando ? children : null
}

export default RouterProtected
