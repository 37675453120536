import React, { useContext, useEffect, useState } from 'react'
import { ConfigurationContext } from '../../context/configurationContext'
import ModalLoading from '../common/modalLoading'

import * as apiService from '../../../api'

const InterceptorAnonimo = ({ children }) => {
	const { autenticado } = useContext(ConfigurationContext)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (!autenticado) {
			setIsLoading(true)
			const user = process.env.REACT_APP_USER_GENERIC
			const pass = process.env.REACT_APP_USER_PASS
			apiService.usuario
				.postAutenticarUsuario(user, pass)
				.then((response) => {
					const { token } = response.data
					localStorage.setItem('token-anonimo', token)
					setIsLoading(false)
				})
				.catch((err) => {
					//console.log(err)
					setIsLoading(false)
				})
		}

		return () => {
			setIsLoading(false)
		}
	}, [])

	return isLoading ? <ModalLoading open={isLoading} onCloseHandler={() => setIsLoading(false)} /> : children
}

export default InterceptorAnonimo
