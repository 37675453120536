import React from "react";

import UseIconHooks from "../hooks/useIconHooks";
import { IconButton } from "@mui/material"
import { containerSideBar } from "../global/styles";



const SideBar = () => {
  //const [anchorEl, setAnchorEl] = useState(null);

  return (

    <div style={{ ...containerSideBar }}>
      <div style={{ marginBottom: -20 }}>
        <IconButton
          size='large'
          href='https://www.facebook.com/ARSSeNaSaRD'
          target='_blank'
        >
          {UseIconHooks("facebook", { fontSize: "large", color: "success" })}
        </IconButton>
      </div>
      <div style={{ marginBottom: -20 }}>
        <IconButton
          size='large'
          href='https://twitter.com/arssenasard'
          target='_blank'
        >
          {UseIconHooks("x", { fontSize: "large", color: "success" })}
        </IconButton>
      </div>
      <div style={{ marginBottom: -20 }}>
        <IconButton
          size='large'
          href='https://www.instagram.com/arssenasard/'
          target='_blank'
        >
          {UseIconHooks("instagram", { fontSize: "large", color: "success" })}
        </IconButton>
      </div>
      <div>
        <IconButton
          size='large'
          href='https://www.youtube.com/user/ARSSeNaSa'
          target='_blank'
        >
          {UseIconHooks("youtube", { fontSize: "large", color: "success" })}
        </IconButton>
      </div>


      {/*
<div style={{marginTop: -10}}>
           
             <IconButton aria-owns={open ? 'mouse-over-popover' : undefined}
                         aria-haspopup="true"
                         onMouseEnter={handlePopoverOpen}
                         onMouseLeave={handlePopoverClose}
                          size="large" style={{backgroundColor: colors.primary}} >  
                     {UseIconHooks('message',{fontSize:'large', style:{color: 'white'} })} 

                   
              </IconButton> 
              <Popover
                 sx={{
                   pointerEvents: 'none'
                 }}
                 open={open}
                 anchorEl={anchorEl}
                 anchorOrigin={{
                   vertical: 'center',
                   horizontal: 'right',
                }}

                onClose={handlePopoverClose}
                disableRestoreFocus
              >
              <Typography sx={{ p: 2,
                          ...labelChatBotSideBard }}>Habla con nosotros</Typography>
             </Popover>
            </div>

*/}



    </div>


  );
};

export default SideBar;
