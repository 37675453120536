import React, { useEffect, useState } from "react";
import { Grid, Button, InputBase, TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import { Check, Clear } from "@mui/icons-material";
import CustomLabel from "../common/customLabel";
import DateFns from "@date-io/date-fns";

import {
  preguntaRegistro,
  yesBotonRegistro,
  noBotonRegistro,
  inputRegistro,
} from "../../global/styles";

import colors from "../../../config/colors";

const Pregunta = ({
  label,
  tipoPregunta,
  id = null,
  handlerSeleccion,
  cantidadIntentos = 0,
}) => {
  const [value, onHandlerValue] = useState(null);

  const [siRespuesta, setSiRespuesta] = useState(false);
  const [noRespuesta, setNoRespuesta] = useState(false);

  useEffect(() => {
    onHandlerValue("");
    setSiRespuesta(false);
    setNoRespuesta(false);
  }, [cantidadIntentos]);

  const tipo1 = (
    <>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={DateFns}>
          <DatePicker
            id="date-picker-dialog"
            label=''
            value={value}
            onChange={(newValue) => {
              onHandlerValue(newValue);
              handlerSeleccion(newValue, id);
            }}
            renderInput={(params) => (
              <TextField
                style={{
                  width: "100%",
                  borderWidth: 2,
                  borderStyle: "solid",
                  borderColor: colors.primary,
                  borderRadius: 10,
                  fontFamily: "Montserrat",
                }}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
    </>
  );

  const tipo2 = (
    <>
      <Grid item xs={6}>
        <Button
          id="btn-si"
          fullWidth
          style={{
            ...yesBotonRegistro,
            backgroundColor: siRespuesta ? colors.primary : colors.gray,
          }}
          variant='contained'
          endIcon={<Check />}
          onClick={() => {
            onHandlerValue(true);
            setSiRespuesta(true);
            setNoRespuesta(false);
            handlerSeleccion(true, id);
          }}
        >
          Si
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          id="btn-no"
          fullWidth
          style={{
            ...noBotonRegistro,
            backgroundColor: noRespuesta ? colors.primary : colors.gray,
          }}
          variant='contained'
          endIcon={<Clear />}
          onClick={() => {
            onHandlerValue(false);
            setSiRespuesta(false);
            setNoRespuesta(true);
            handlerSeleccion(false, id);
          }}
        >
          No
        </Button>
      </Grid>
    </>
  );

  const tipo3 = (
    <>
      <Grid item xs={12}>
        <InputBase
          id="input-number"
          value={value}
          onChange={(event) => {
            onHandlerValue(event.target.value);
            handlerSeleccion(event.target.value, id);
          }}
          style={{ ...inputRegistro }}
          type='number'
        />
      </Grid>
    </>
  );

  return (
    <>
      <Grid item xs={12} marginTop='2%'>
        <CustomLabel style={{ ...preguntaRegistro }}>{label}</CustomLabel>
      </Grid>
      {tipoPregunta === 1 && tipo1}
      {tipoPregunta === 2 && tipo2}
      {tipoPregunta === 3 && tipo3}
    </>
  );
};

export default Pregunta;
