import React, { useState, useEffect } from 'react'
import '../login/Login.css'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { Hidden,} from '@mui/material'
import InputBase from '@mui/material/InputBase'
import LoginImage from '../../../assets/login.jpg'
import LogoHorizontalBlanco from '../../../assets/logoHorizontalBlanco.png'
import LabelRegistro from '../../components/common/labels/labelRegistro'
import RegistroModal from '../../components/modal/registro'
import {
	containerLogin,
	imageLogin,
	gridContainerLogin,
	labelInicioSesionLogin,
	inputRegistro,
	labelOlvidePasswordLogin,
	botonIngresarLogin,
	labelNoTieneCuentaLogin,
	botonRegistrateLogin
} from '../../global/styles'

import { useNavigate } from 'react-router-dom'
import UseSetIsLogin from '../../hooks/useSetIsLogin'
import ModalLoading from '../../components/common/modalLoading'
import { useForm } from 'react-hook-form'
import * as apiService from '../../../api'
import { useDispatch } from 'react-redux'
import { setInfoAfiliado } from '../../redux/features/afiliado/afiliadoSlice'
import { setJwt } from '../../../api/services/autentificacion'
import { useSnackbar } from 'notistack'
import DialogOlvideClave from '../../components/login/dialogOlvideClave'
import colors from '../../../config/colors'
import NumberFormat from 'react-number-format'
import CustomSimpleModal from '../../components/common/customSimpleModal'
import ReenvioCorreoConfirmacion from '../../components/modal/reenvioCorreoConfirmacion'
import { documentos } from '../../collection/listas'

function Login() {
	var navigate = useNavigate()
	const dispatch = useDispatch()

	useEffect(() => {
		if (localStorage.getItem('autenticado') == 'true') navigate('../../', { replace: true })
	}, [])

	const [openModal, setOpenModal] = useState(false)
	const [mensajeModal, setMensajeModal] = useState('')
	const [titleModal, setTitleModal] = useState('')

	const { enqueueSnackbar, closeSnackbar } = useSnackbar()

	const { setAuthLogIn } = UseSetIsLogin()
	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors }
	} = useForm({ mode: 'onChange' })

	const handlerIngresar = (data) => {
		const credenciales = {
			identificacion: {
				tipoDocumento: data.tipoDocumento,
				documento: data.noDocumento
			},
			clave: data.password
		}

		openHandlerLoadingModal()

		apiService.autentificacion
			.postLogin(credenciales)
			.then((response) => {
				closeHandlerLoadingModal()

				if (response.data.codigoMensaje === 1007) {
					dispatch(
						setInfoAfiliado({
							codigoAfiliado: response.data.codigoAfiliado,
							nombres: response.data.nombres,
							apellidos: response.data.apellidos,
							tipoAfiliacion: response.data.tipoAfiliacion,
							regimen: response.data.regimen
						})
					)
					setJwt(response.data.token)
					setAuthLogIn()
					if(localStorage.getItem('club_beneficiario')) {
						localStorage.removeItem('club_beneficiario')
						navigate("/club", { replace: true });
					  } else {
						navigate("/", { replace: true });
					  }
				} else if (response.data.codigoMensaje === 1006) {
					enqueueSnackbar(`${response.data.mensaje}`, {
						variant: 'warning',
						action: (key) => (
							<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
								X
							</Button>
						)
					})
				} else if (response.data.codigoMensaje === 1017) {
					enqueueSnackbar(`${response.data.mensaje}`, {
						variant: 'warning',
						action: (key) => (
							<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
								X
							</Button>
						)
					})
				} else if (response.data.codigoMensaje === 1003) {
					setMensajeModal(response.data.mensaje)
					setTitleModal('Información')
					setOpenModal(true)
				} else {
					enqueueSnackbar(`${response.data.mensaje}`, {
						variant: 'error',
						action: (key) => (
							<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
								X
							</Button>
						)
					})
				}
			})
			.catch((err) => console.log(err))
	}

	const [open, setOpen] = useState(false)

	const handleClose = () => {
		setOpen(false)
	}

	const handleOpen = (_) => {
		setOpen(true)
	}

	const [openReenvio, setOpenReenvio] = useState(false)

	const handleCloseReenvio = () => {
		setOpenReenvio(false)
	}

	const handleOpenReenvio = (_) => {
		setOpenReenvio(true)
	}

	const [noDocumento, setNoDocumento] = useState('')
	const [tipoDocumento, setTipoDocumento] = useState('')

	const [loadingModal, setLoadingModal] = useState(false)
	const closeHandlerLoadingModal = (_) => setLoadingModal(false)
	const openHandlerLoadingModal = (_) => setLoadingModal(true)

	const [openOlvideClave, setOpenOlvideClave] = useState(false)
	const handlerOpenOlvideClave = (_) => setOpenOlvideClave(true)
	const handlerCloseOlvideClave = (_) => setOpenOlvideClave(false)

	const [formatDocumento, setFormatDocumento] = useState('###-#######-#')
	const [maskDocumento, setMaskDocumento] = useState('_')
	const handlerFormatDocumento = (tipo) => {
		if (tipo == 1) {
			setFormatDocumento('###-#######-#')
			setMaskDocumento('_')
		} else {
			setFormatDocumento('#############')
			setMaskDocumento('')
		}
	}

	const handlerPressEnter = (event) => {
		if (event.key === 'Enter') {
			if (getValues('noDocumento') === null || getValues('noDocumento') === '' || getValues('password') === null || getValues('password') === '') {
				enqueueSnackbar('Debe llenar los campos de documento y contraseña.', {
					variant: 'warning',
					action: (key) => (
						<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
							X
						</Button>
					)
				})
				return
			}

			const data = {
				tipoDocumento: getValues('tipoDocumento'),
				noDocumento: getValues('noDocumento'),
				password: getValues('password')
			}
			handlerIngresar(data)
			
		}
	}

	localStorage.removeItem('popupShown');

	return (
		<Box sx={{ flex: 1 }} style={{ padding: 0, position:'float', top: -1, }}>
			<ModalLoading open={loadingModal} onCloseHandler={closeHandlerLoadingModal} />

			<RegistroModal
				open={open}
				handleClose={handleClose}
				closeHandlerLoadingModal={closeHandlerLoadingModal}
				openHandlerLoadingModal={openHandlerLoadingModal}
	
			/>
			<ReenvioCorreoConfirmacion
				open={openReenvio}
				handleClose={handleCloseReenvio}
				closeHandlerLoadingModal={closeHandlerLoadingModal}
				openHandlerLoadingModal={openHandlerLoadingModal}
			/>

			<DialogOlvideClave open={openOlvideClave} handlerCloseModal={handlerCloseOlvideClave} setLoading={setLoadingModal} />

			<CustomSimpleModal openModal={openModal} handlerCloseModal={() => setOpenModal(false)} title={titleModal} message={mensajeModal} />

			<Grid container style={{ ...containerLogin }}>
				<Hidden mdDown>
					<Grid item xs={7}>
						<div className='img-gradient' >
							<img src={LoginImage} style={{ ...imageLogin }} />
						</div>
					{	<div style={{
							position: 'absolute',
							top: 800,
							left:40
						}}>	
							<img src={LogoHorizontalBlanco} style={{ width: 300, marginLeft: 20 }} />
						</div>
						}
					</Grid>
				</Hidden>

				<Grid item xs={12} md={5} style={{ ...imageLogin }}>
					<Grid container style={{ ...gridContainerLogin, marginTop: -15 }} sx={{ padding: { xs: 0, md: 5 } }}>
						<Grid item xs={12}>
						</Grid>
						<Grid item xs={12} sx={{ paddingTop:{xs:5, md: 0}}}>
							<LabelRegistro style={{ 
								fontWeight: 700,
								color: 'rgb(83, 87, 90)',
								fontFamily: 'Montserrat',
								fontSize: 52,
							}} />
						</Grid>
						<Grid item xs={12} style={{ marginTop: 20 }}>
							<Typography variant='h5' gutterBottom component='div' style={{ ...labelInicioSesionLogin }}>
								¡Bienvenido!
							</Typography>
							<Typography variant='h5' gutterBottom component='div' style={{ ...labelInicioSesionLogin }}>
								Iniciar Sesión
							</Typography>
						</Grid>
						<Grid item xs={12} style={{ marginTop: '0' }}>
							<select
								style={{
									borderRadius: 10,
									...inputRegistro,
									height: 44,
									fontFamily: 'Montserrat',

								}}
								id='select-tipoDocumento'
								value={tipoDocumento}
								label='tipoDocumento'
								{...register('tipoDocumento', {
									required: 'Debe seleccionar el tipo de documento.'
								})}
								onChange={(event) => {
									setTipoDocumento(event.target.value)
									setNoDocumento('')
									handlerFormatDocumento(event.target.value)
								}}
							>
								{documentos.map((item) => (
									<option key={item.id} value={item.id} id={item.id}>
										{item.descripcion}
									</option>
								))}
							</select>
							{errors.tipoDocumento && <span style={{ color: 'red' }}> {errors.tipoDocumento.message} </span>}
						</Grid>
						<Grid item xs={12} style={{ marginTop: '2%' }}>
							<NumberFormat
								id='field-noDocumento'
								value={noDocumento}
								format={formatDocumento}
								mask={maskDocumento}
								style={{ ...inputRegistro, height: 44, }}
								placeholder='Documento'
								{...register('noDocumento', {
									required: 'Por favor, ingrese su documento de identidad.'
								})}
								onValueChange={(values) => {
									setNoDocumento(values.value)
									setValue('noDocumento', values.formattedValue)
								}}
								customInput={InputBase}
							/>
						

							{errors.noDocumento && <span style={{ color: 'red' }}> {errors.noDocumento.message} </span>}
							
						</Grid>
						<Grid item xs={12} style={{ marginTop: '2%' }}>
						<Grid style={{ display: 'flex', alignItems: 'center', height: 44 }}>
							<InputBase
								id='field-password'
								style={{ ...inputRegistro, flexGrow: 1, height: 44 }}
								placeholder='Contraseña'
								{...register('password', {
								required: 'Por favor, ingrese su contraseña.'
								})}
								onKeyPress={handlerPressEnter}
								type='password'
								
							/>
						</Grid>
							{errors.password && <span style={{ color: 'red' }}> {errors.password.message} </span>}
						</Grid>
						<Grid
							container
							style={{
								marginTop: '5%'
							}}
						>
							<Grid item xs={12} md={7}>
							
								<Typography
									id='link-olvide-password'
									variant='h6'
									gutterBottom
									component='div'
									style={{
										...labelOlvidePasswordLogin,
										cursor: 'pointer',
										float: 'left'
									}}
									onClick={handlerOpenOlvideClave}
								>
									¿Olvidaste la contraseña?
								</Typography>
								<Typography
									id='link-reenvio-correo'
									variant='h6'
									gutterBottom
									component='div'
									style={{
										...labelOlvidePasswordLogin,
										cursor: 'pointer',
										float: 'left'
									}}
									onClick={handleOpenReenvio}
								>
									¿No ha recibido el correo de confirmación?
								</Typography>
							</Grid>
							<Grid item xs={12} md={5}>
								<Button
									id='btn-ingresar'
									style={{
										...botonIngresarLogin,

										height: 45,
										fontWeight: 'bold',
										fontFamily: 'Montserrat',
										float: 'right'
									}}
									sx={{ width: { xs: '100%', md: 200 } }}
									variant='contained'
									onClick={handleSubmit(handlerIngresar)}
								>
									Ingresar
								</Button>
							</Grid>
						</Grid>
						<Grid md={12}>
							<hr style={{marginTop:'5%', backgroundColor:'gray'}}/>
						</Grid>
						<Grid container>
							<Grid container  md={12} style={{marginBottom: '5%'}}>
								<Grid item xs={12} md={10} style={{ margin:'0 auto', marginTop: '5%' }} >
									<Typography variant='h5' gutterBottom component='div' style={{ ...labelNoTieneCuentaLogin }}>
										¿Aún no tienes una cuenta?
									</Typography>
								</Grid>
								<Grid container>
									<Grid item xs={12} md={10} sx={{ marginBottom: { xs: 1, md: 0 } }} style={{margin:'0 auto'}}>
										<Button
											id='btn-registrate'
											style={{
												...botonRegistrateLogin,
												height: 50,
												fontWeight: 'bold',
												fontFamily: 'Montserrat',
												width: '99%'
											}}
											variant='contained'
											onClick={handleOpen}
										>
											Regístrate
										</Button>
									</Grid>
									
								</Grid>
							</Grid>
							<Grid md={12}>
								<hr style={{marginBottom:'5%', backgroundColor:'gray'}}/>
							</Grid>
							<Grid container>
								<Grid item xs={12} md={10} style={{margin:'0 auto'}}>
								<a style={{ color: 'white', textDecoration: 'none' }} target={'_blank'} href={'https://larimarexterior.arssenasa.gob.do/'}>
										<Button
											id='btn-afiliateLarimar'
											style={{
												...botonRegistrateLogin,
												height: 50,
												width: '99%',
												fontWeight: 'bold',
												fontFamily: 'Montserrat',
												textTransform: 'initial',
												backgroundColor: colors.primary
											}}
											variant='contained'
										>
											Afíliate ya al Plan Larimar
										</Button>
									</a>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
}
export default Login
