import React, { useEffect, useState } from 'react';
import { Modal, Box, IconButton, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Novedades from '../home/novedades/novedades';
import * as apiService from '../../../api'
import colors from '../../../config/colors';

const Popup = () => {
  const [open, setOpen] = useState(false);
  const [listaSlider, setListaSlider] = useState([]);

  useEffect(() => {
    const isPopupShown = localStorage.getItem('popupShown');
    if (!isPopupShown) {
      apiService.ajustes
        .getAllDashboardPopUp()
        .then((response) => {
          if (response.data.result.length > 0) {
            setListaSlider(response.data.result);
            setOpen(true);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem('popupShown', 'true');
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="popup-modal-title"
      aria-describedby="popup-modal-description"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      id="PopupModal"
    >
      <Box
        id="PopupModal__Box"
        sx={{
          bgcolor: 'background.paper',
          p: 4,
          position: 'relative',
          maxWidth: '90%',
          width: 900,
          textAlign: 'center',
          backgroundColor: 'transparent'
        }}
      >
        <Grid display={"flex"} justifyContent={"flex-end"}>
          <IconButton
            onClick={handleClose}
            id="PopupModal__Box--closeButton"
          >
            <CloseIcon sx={{ backgroundColor: colors.white, borderRadius: 10, boxShadow: 24, padding: 0.5 }} />
          </IconButton>
        </Grid>
        <Grid display={"flex"} justifyContent={"center"}>
          <Novedades items={listaSlider} />
        </Grid>
      </Box>
    </Modal>
  );
};

export default Popup;