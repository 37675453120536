import {
  Phone,
  DateRange,
  PlaylistAddCheck,
  AttachMoney,
  LocalHospital,
  VisibilitySharp,
  InsertDriveFileSharp,
  HomeSharp,
  ChevronLeft,
  ChevronRight,
  Edit,
  ExpandLess,
  ExpandMore,
  Favorite,
  Inbox,
  Mail,
  StarBorder,
  PaymentsSharp,
  FacebookSharp,
  Twitter,
  Instagram,
  YouTube,
  Message,
  Settings,
  Groups,
  Save,
  SettingsBackupRestore,
  X,
} from "@mui/icons-material";

const UseIconHooks = (icono, props) => {
  const iconos = {
    inbox: <Inbox />,
    mail: <Mail />,
    chevronl: <ChevronLeft {...props} />,
    chevronr: <ChevronRight {...props} />,
    expandedl: <ExpandLess {...props} />,
    expandedm: <ExpandMore {...props} />,
    star: <StarBorder />,
    favorite: <Favorite />,
    edit: <Edit {...props} />,
    home: <HomeSharp {...props} />,
    description: <InsertDriveFileSharp {...props} />,
    visibility: <VisibilitySharp {...props} />,
    localHospital: <LocalHospital {...props} />,
    money: <AttachMoney {...props} />,
    playlist: <PlaylistAddCheck {...props} />,
    dateRange: <DateRange {...props} />,
    phone: <Phone {...props} />,
    payment: <PaymentsSharp {...props} />,
    facebook: <FacebookSharp {...props} />,
    twitter: <Twitter {...props} />,
    instagram: <Instagram {...props} />,
    youtube: <YouTube {...props} />,
    message: <Message {...props} />,
    setting: <Settings {...props} />,
    group: <Groups {...props} />,
    save: <Save {...props} />,
    reset: <SettingsBackupRestore {...props} />,
    x: <X {...props}  />,
  };

  return iconos[icono];
};

export default UseIconHooks;
