import colors from "../../config/colors";

const drawerWidth = 250;

export const labelRegistro = {
  fontWeight: "800",
  color: colors.gray,
  fontFamily: "Montserrat",
};

//NOTIFICACIONES
export const gridContainerNotificacion = {
  boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)",
  padding: 8,
};

export const messageContainerNotificacion = {
  float: "left",
  width: "80%",
  backgroundColor: "white",
  fontFamily: "Montserrat",
  color: colors.grayLight,
};

export const horaContainerNotificacion = {
  float: "right",
  marginRight: 10,
  color: colors.grayLight,
  fontSize: "smaller",
};

export const cerraBotonNotificacion = {
  float: "right",
  color: colors.grayLight,
  cursor: "pointer",
  margin: "5px 10px 0px 0px",
};

//ATAJOS

export const iconoAtajo = {
  color: colors.primary,
  fontSize: 100,
  padding: 15,
};

export const mensajeAtajo = {
  fontSize: 20,
  padding: 30,
  marginLeft: 35,
  marginTop: -20,
};

export const containerAtajo = {
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  height: 120,
  borderRadius: 20,
};

//MODAL REGISTRO

export const dialogContentRegistro = {
  padding: 0,
  height: 660,
};

export const contenedorDialogContentRegistro = {
  overflow: "scroll",
  overflowX: "hidden",
  height: "100%",
  width: "100%",
};

export const labelValidarRegistro = {
  fontWeight: "bold",
  fontSize: 25,
  color: colors.grayLight,
};

export const selectRegistro = {
  boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)",
  borderRadius: 10,
};

export const inputRegistro = {
  width: "100%",
  borderRadius: 10,
  padding: 10,
  fontFamily: "Montserrat",
  borderStyle: "solid",
  borderWidth: 2,
  borderColor: colors.primary,
  fontSize: 16,
  paddingLeft: 16,
  
};

export const labelPreguntaRegistro = {
  fontSize: 30,
  fontWeight: "bold",
  color: colors.gray,
};

export const labelSaludoAfiliadoRegistro = {
  fontSize: 25,
  color: colors.grayLight,
};

export const preguntaRegistro = {
  fontSize: 20,
  color: colors.grayLight,
};

export const yesBotonRegistro = {
  float: "left",
  width: 150,
  backgroundColor: colors.primary,
};

export const noBotonRegistro = {
  float: "right",
  width: 150,
  backgroundColor: colors.gray,
};

export const labelDatosPersonalesRegistro = {
  fontSize: 30,
  fontWeight: "bold",
  color: colors.grayLight,
};

export const botonPreviousPage = {
  color: "white",
  backgroundColor: colors.grayLight,
  float: "left",
  paddingLeft: 5,
};

export const botonNextPage = {
  color: "white",
  backgroundColor: colors.primary,
  float: "right",
};

export const cerrarModalRegistro = {
  float: "right",
  color: "white",
  fontSize: 40,
  position: "absolute",
  right: 20,
  top: 10,
  cursor: "pointer",
};

//HEADER

export const botonNotificacionHeader = {
  backgroundColor: "white",
  color: colors.gray,
  padding: 15,
  borderRadius: 160,
  marginTop: 0,
};

export const botonProfileHeader = {
  marginLeft: 15,
  width: 55,
  marginTop: -8,
};

export const imgProfileHeader = {
  borderRadius: 160,
  boxShadow: "0 2px 10px rgb(0 0 0 / 0.4)",
  cursor: "pointer",
};

//MENU

export const toolbarDrawerMenu = {
  justifyContent: "center",
  marginBottom: "5%",
  
};

export const labelRegistroDrawerMenu = {
  fontSize: 22,
  marginTop: 50,
};

export const listItemDrawerMenu = {
  padding: 5,
  margin: 0,
};

export const linkDrawerMenu = {
  textDecoration: "none",
  color: "black",
};

export const listDrawerMenu = {
  padding: 0,
  margin: 0,
};

//PROFILE CARD

export const imgProfileCard = {
  borderRadius: 160,
  boxShadow: " 0 2px 10px rgb(0 0 0 / 0.4)",
  marginTop: 35,
};

export const labelNombreProfileCard = {
  color: colors.primary,
  fontWeight: "bold",
  fontFamily: "Montserrat",
  fontSize: 22,
};

export const labelInfoProfileCard = {
  color: colors.grayLight,
  fontSize: 17,
  fontFamily: "Montserrat",
};

export const botonVerPerfilProfileCard = {
  backgroundColor: colors.primary,
  textTransform: "initial",
  fontWeight: "bold",
  borderRadius: 10,
  paddingInline: 44,
  marginRight: 10,
  color: "white",
  height: 40,
};

export const botonNoDialog = {
  backgroundColor: colors.grayDarkOpacity,
  textTransform: "initial",
  fontWeight: "bold",
  borderRadius: 10,
  paddingInline: 44,
  marginRight: 10,
  color: "white",
  height: 40,
}

export const botonLogoutProfileCard = {
  backgroundColor: colors.grayLight,
  color: "white",
  padding: 15,
  borderRadius: 10,
  height: 40,
};

//SIDEBAR

export const containerSideBar = {
  position:'fixed',
  right: 0,
  bottom: -10,
  backgroundColor: colors.white,
  borderRadius: 15
};

export const labelChatBotSideBard = {
  backgroundColor: colors.primary,
  color: "white",
  fontWeight: "bold",
};

//CONSULTA - PRESTADOR

export const labelTitlePrestador = {
  fontWeight: "bold",
  color: colors.gray,
  fontSize: 25,
  fontFamily: "Montserrat",
};

export const botonBuscarPrestador = {
  backgroundColor: colors.primary,
  textTransform: "initial",
  borderRadius: 10,
  fontWeight: "bold",
  marginTop: 8,
};

//HOME

export const labelNombreAfiliadoHome = {
  fontWeight: "lighter",
  color: colors.grayLight,
};

export const labelNovedadesHome = {
  fontWeight: "bold",
  color: colors.gray,
  fontSize: 25,
  marginTop: 15,
};

export const labelAtajosHome = {
  fontWeight: "bold",
  color: colors.gray,
  fontSize: 25,
  marginTop: 0,
};

//LOGIN

export const containerLogin = {
  overflow: "hidden"
};

export const imageLogin = {
  width:'100%',
  height: 925,
};

export const gridContainerLogin = {
  marginInline: 0,
};

export const labelInicioSesionLogin = {
  fontWeight: 600,
  color: colors.gray,
  fontFamily: "Montserrat",
};

export const labelOlvidePasswordLogin = {
  fontWeight: 600,
  color: colors.grayLight,
  fontFamily: "Montserrat",
  fontSize: 18,
};

export const botonIngresarLogin = {
  backgroundColor: colors.primary,
  borderRadius: 10,
  textTransform: "capitalize",
};

export const labelNoTieneCuentaLogin = {
  fontWeight: 550,
  color: colors.gray,
  fontFamily: "Montserrat",
  fontSize: 18,
};

export const botonRegistrateLogin = {
  backgroundColor: colors.gray,
  borderRadius: 10,
  width: "100%",
  textTransform: "capitalize",
};

export const imgLogoSenasaLogin = {
  width: 80,
  height: "100%",
  float: "right",
};

//PROFILE

export const imgAfiliadoProfile = {
  float: "left",
  boxShadow: "0 2px 10px rgb(0 0 0 / 0.4)",
};

export const containerTitleProfile = {
  float: "left",
  marginRight: 20,
  marginTop: 15,
};

export const labelDatosPersonalesProfile = {
  fontSize: 25,
  marginTop: 30,
  fontWeight: "bold",
  color: colors.gray,
};

export const containerDatosPersonalesProfile = {
  marginTop: 30,
  paddingInline: 10,
  borderRadius: 20,
  paddingBottom: 15,
};

export const customInputLabelProfile = {
  fontWeight: "bold",
  color: colors.gray,
  marginLeft: 20,
  fontFamily: "Montserrat",
};

export const gridTitleContainerProfile = {
  backgroundColor: colors.primary,
  color: "white",
  padding: 20,
  justifyContent: "center",
  alignItems: "center",
  maxHeight: "100%",
  maxWidth: "80%",
  borderRadius: 25,
  marginLeft: 25,
};

export const gridNucleoContainerProfile = {
  fontSize: 20,
  color: colors.primary,
  backgroundColor: "white",
  padding: 10,
  borderRadius: 15,
  maxHeight: 40,
  maxWidth: 290,
  marginTop: 10,
  cursor: "pointer",
};

export const labelNucleoFamiliarProfile = {
  color: colors.primary,
  fontWeight: "bold",
  marginLeft: 30,
  cursor: "pointer",
};

export const paperContainerEditableProfile = {
  width: 150,
  backgroundColor: colors.grayLight,
  paddingInline: 15,
  float: "right",
  marginTop: -25,
  marginRight: -19,
  textAlign: "center",
  borderTopRightRadius: 21,
  borderBottomLeftRadius: 10,
};

export const labelEditableProfile = {
  fontSize: 20,
  fontWeight: "bold",
  color: "white",
};

// SOLICITUDES

export const botonBuscarSolicitudes = {
  backgroundColor: colors.primary,
  textTransform: "initial",
  borderRadius: 10,
  fontWeight: "bold",
  marginTop: 8,
};

//LAYOUT

export const mainLayout = {
  flexGrow: 1,
  p: 3,
  width: { sm: `calc(100% - ${drawerWidth}px)`, backgroundColor: "white" },
};

//BARRA COMPONENT

export const styleContainerBarra = {
  width: "100%",
  backgroundColor: "white",
  borderRadius: 30,
};

export const styleContainerValueBarra = {
  backgroundColor: "green",
  borderRadius: 30,
  textAlign: "center",
};

export const labelValorActualBarra = {
  fontWeight: "bold",
  color: "white",
  fontFamily: "Montserrat",
  padding: 10,
};

//TABLE COMPONENT

export const tableCellHeaderTable = {
  backgroundColor: colors.grayFondo,
  fontWeight: "bold",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: colors.grayLight,
};

export const tableCellHeaderAccionTable = {
  backgroundColor: colors.grayFondo,
  fontWeight: "bold",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: colors.grayLight,
};

export const tableCellRowTable = {
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: colors.grayLight,
};

export const paperContainerTable = {
  borderRadius: 15,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: colors.grayLight,
};

export const containerTable = {
  borderTopRightRadius: 15,
  borderTopLeftRadius: 15,
};

export const tablePaginationTable = {
  backgroundColor: colors.grayFondo,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: colors.grayLight,
  borderBottomLeftRadius: 15,
  borderBottomRightRadius: 15,
};

//CONSULTA PRESTADOR CARDPRESTADOR COMPONENT

export const labelHeaderCardPrestador = {
  fontFamily: "Montserrat",
  color: colors.primary,
  fontWeight: "bold",
  fontSize: 20,
};

export const labelFieldCardPrestador = {
  fontWeight: "normal",
  color: colors.gray,
  fontSize: 16,
  fontFamily: "Montserrat",
};

//DIALOG DETALLE PRESTADOR

export const dialogContentDetallePrestador = {
  padding: 0,
  backgroundColor: colors.grayFondo,
};

export const gridContainerDetallePrestador = {
  backgroundColor: colors.grayFondo,
  paddingInline: 45,
  paddingTop: 25,
};

export const labelNombreDetallePrestador = {
  color: colors.primary,
  fontWeight: "bold",
  fontFamily: "Montserrat",
  fontSize: 20,
};

export const labelEspecialidadDetallePrestador = {
  color: colors.gray,
  fontFamily: "Montserrat",
  fontSize: 17,
};

export const labelContactoDetallePrestador = {
  color: colors.gray,
  fontFamily: "Montserrat",
  fontSize: 16,
};

export const iconTelefonoDetallePrestador = {
  color: colors.primary,
  marginRight: 7,
  marginBottom: -5,
};

export const iconCorreoDetallePrestador = {
  color: colors.primary,
  marginRight: 7,
  marginBottom: -5,
};

export const iconProfileDetallePrestador = {
  width: "100%",
  height: "auto",
  color: colors.grayLight,
};

export const paperDetalleContainerDetallePrestador = {
  margin: 5,
  borderRadius: 20,
  marginTop: -50,
  padding: 10,
  height: 360,
  overflow: "scroll",
  overflowX: "hidden",
};

export const paperDetalleContainerCobertura = {
  margin: 5,
  borderRadius: 20,
  marginTop: -50,
  padding: 10,
  height: 360,
};

export const dialogNuevaCita = {
  borderRadius: 20,
  padding: 10,
  height: 350,
  overflow: "scroll",
  overflowX: "hidden",
};

export const paperImgContainerDetallePrestador = {
  width: "100%",
  borderRadius: 10,
};

// CUSTOM SAVE COMPONENT
export const paperContainerCustomSave = {
  display: "flex",
  alignItems: "center",
  width: "fit-content",
  border: (theme) => `1px solid ${theme.palette.divider}`,
  borderRadius: 1,
  bgcolor: "background.paper",
  color: "text.secondary",
  "& svg": {
    m: 1.5,
  },
  "& hr": {
    mx: 0.5,
  },
};

export const paperContainerStyleCustomSave = {
  borderRadius: 40,
  borderStyle: "none",
};

export const iconResetCustomSave = {
  fontSize: 50,
  color: colors.grayLight,
};

export const iconSaveCustomSave = {
  fontSize: 50,
  color: colors.primary,
};

// CARD DEPENDIENTE COMPONENT

export const paperContainerCardDependiente = {
  paddingInline: 2,
  margin: 10,
  marginBottom: 20,
};

export const labelParentescoCardDependiente = {
  fontWeight: "bold",
  fontSize: 15,
  paddingInline: 15,
};

export const labelNombreCardDependiente = {
  fontWeight: "bold",
  fontSize: 20,
  paddingInline: 15,
};

export const labelContratoCardDependiente = {
  fontSize: 20,
  paddingInline: 15,
};

export const styleTittleMontoDisponible = {
  fontWeight: "100",
  fontSize: 13,
};

export const styleLabelMontoDisponible = {
  fontSize: 15,
  fontWeight: 100,
};

export const divImgProfileCardDependiente = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

//CARD MONTO DISPONIBLE

export const paperContainerCardMonto = {
  backgroundColor: colors.grayFondo,
  padding: 10,
  borderRadius: 20,
};

export const styleTitleCardMonto = {
  color: colors.primary,
  fontFamily: "Montserrat",
  fontWeight: "bold",
  fontSize: 25,
  textAlign: "center",
};

//TAB DATO AFILIACION

export const btnConsumoDatoAfiliacion = {
  backgroundColor: colors.primary,
  textTransform: "initial",
  borderRadius: 12,
  float: "right",
  fontWeight: "bold",
};

export const btnTipoPlanDatoAfiliacion = {
  textTransform: "initial",
  borderRadius: 12,
  padding: 10,
  fontSize: 20,
  borderStyle: "none",
  fontWeight: "bold",
};

export const labelInfoGeneralDatoAfiliacion = {
  color: colors.primary,
  fontWeight: "bold",
  fontFamily: "Montserrat",
  fontSize: 25,
};

export const inputFormularioAfiliacion = {
  ...inputRegistro,
  backgroundColor: "white",
};

// ITEM DEPENDIENTE

export const labelTituloItemDependiente = {
  fontFamily: "Montserrat",
  color: colors.primary,
  fontWeight: "bold",
  fontSize: 20,
  flexDirection: "column",
  display: "flex",
};

export const labelValorItemDependiente = {
  color: colors.gray,
  fontSize: 30,
};

export const botonCargaArchivos = {
  display: 'flex',
  flexDirection: 'column-reverse',
  marginTop: '20px',

};

// certificacion Carnet
export const labelSpan = {
  fontFamily: "Montserrat",
  marginTop: 5,
  fontWeight: "regular",
  fontSize: 16,
  textAlign: "center",
};

export const labelPrincipal = {
  fontFamily: "Montserrat",
  marginBottom: 2,
  fontWeight: "bold",
  fontSize: 22,
  textAlign: "center",
};

export const labelHighlight = {
  fontFamily: "Montserrat",
  color: colors.primary,

  fontWeight: "bold",
  fontSize: 22,
  textAlign: "center",
};

export const labelFechas = {
  fontFamily: "Montserrat",
  fontWeight: "regular",
  fontSize: 16,
  marginTop: 10,
};

export const labelDataFechas = {
  fontFamily: "Montserrat",
  fontWeight: "bold",
  fontSize: 18,
};

export const labelPlan = {
  fontFamily: "Montserrat",
  marginBottom: 2,
  fontWeight: "bold",
  fontSize: 22,
  textAlign: "center",
};

export const inputError = {
  width: "100%",
  borderRadius: 10,
  padding: 10,
  fontFamily: "Montserrat",
  borderStyle: "solid",
  borderWidth: 2,
  borderColor: colors.error,
  fontSize: 16,
  paddingLeft: 16,
  
};
