import { Button, Grid, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import colors from "../../../config/colors";
import profilePicture from '../../../assets/user.png';
import {
	imgAfiliadoProfile,
} from '../../global/styles'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useSnackbar } from "notistack";
import CustomQuestionDialog from "../common/Dialog/customQuestionDialog";
import * as apiService from '../../../api'
import ModalLoading from "../common/modalLoading";
const UploadPhotoBox = ({
    evento = (value) => {
        return value;
    },
    closed = (value) => {
        return value;
    },
    open,
    codigoAfiliado
}) => {
    const [fotoSeleccionada, setFotoSeleccionada] = useState();
    const [fotoEvent, setFotoEvent] = useState()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [openDialog, setOpenDialog] = useState(false)
    const [onConfirm, setOnConfirm] = useState(false)
    const [currentProfilePicture, setCurrentProfilePicture] = useState()
    const [loadingModal, setLoadingModal] = useState(false)
	const closeHandlerLoadingModal = (_) => setLoadingModal(false)
    
    const seleccionarFotoPerfil = (event) => {
        const imageURL = URL.createObjectURL(event.target.files[0]);
        setFotoSeleccionada(imageURL)
        setFotoEvent(event);
    }

    const uploadPhoto = () => {
        if(!fotoSeleccionada || fotoSeleccionada === '')
        {
            enqueueSnackbar('Favor de seleccionar una foto de perfil', {
                variant: 'warning',
                action: (key) => (
                    <Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
                        X
                    </Button>
                )
            })
        }
        else{
            const maxSizeInBytes = 2 * 1024 * 1024;

            if (fotoEvent.target.files[0].size > maxSizeInBytes) {
                    enqueueSnackbar('La imagen supera el tamaño máximo permitido', {
                    variant: 'error',
                    action: (key) => (
                        <Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
                            X
                        </Button>
                    )
                })
            } else {
                evento(fotoEvent)
            }
        }
        
    }

    const borrarFotoPerfil = () => {
        if(currentProfilePicture)
        {
            setOpenDialog(true)
        }
        else
        {
            enqueueSnackbar('El perfil no cuenta con una foto.', {
                variant: 'warning',
                action: (key) => (
                    <Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
                        X
                    </Button>
                )
            })
        }
    }

    useEffect(() => {

        if(onConfirm === true)
        {
            apiService.CRMService.delFotoPerfil(codigoAfiliado)
			.then(res => alert(res),
				enqueueSnackbar( 'Foto de perfil eliminada', {
					variant: 'success',
					action: (key) => (
					  <Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
						X
					  </Button>
					)
				  }),

				  window.location.reload()
			).catch(err => console.log(err))
        }
   
    },[onConfirm])

    useEffect(() => {
        if(codigoAfiliado)
        {
            setLoadingModal(true)
            apiService.CRMService.GetFotoPerfil(codigoAfiliado)
            .then((res) => {
                setCurrentProfilePicture(res.data)
                setLoadingModal(false)
            })
            .catch((err) => {
                console.log(err)
                setLoadingModal(false)
                
            })
           
        }
    },[codigoAfiliado])

    

    return (
        <Modal open={open} style={{paddingTop: 100}}>
            <Grid container padding={5} borderRadius={5} sx={{ width: { xs: '90%', md: '25%' } }}  style={{backgroundColor:colors.grayFondo, margin:'0 auto'}}>
            <Grid item md={12} xs={12} display={'flex'} justifyContent={'center'}>
                <h2 style={{color:colors.primary}}>Cambiar foto de perfil</h2>
            </Grid>
                <Grid item md={12} xs={12} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} paddingTop={5}>
                    <Grid md={12}>
                        <img src={!fotoSeleccionada ? !currentProfilePicture ? profilePicture : `data:image/png;base64,${currentProfilePicture}`: fotoSeleccionada}  style={{ ...imgAfiliadoProfile, borderRadius: 150, objectFit:'cover', width: 150, height: 150}} />
                    </Grid>
                    <Grid md={12} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} paddingTop={3}>
                        <Grid item style={{margin:'0 auto'}} onClick={() =>  document.getElementById('contained-button-file2').click()}>
                            <UploadFileIcon style={{fontSize:40, cursor:'pointer', color:colors.primary}}/>
                        </Grid>
                        <Grid item marginTop={0}>
                            <h5 style={{marginTop:0, color:colors.primary}}>Cargar imagen</h5>
                            <input
                                type="file"
                                accept="image/*"
                                style={{ display: 'none'}}
                                id="contained-button-file2"
                                onChange={(event) => {
                                    seleccionarFotoPerfil(event);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid md={12} display={'flex'} textAlign={'center'} flexDirection={'column'}>
                        <p style={{marginBottom: 0}}>Seleccionar imagen <b>JPG/PNG</b></p>
                        <p style={{marginTop: 5}}>Tamaño máximo permitido: <b>2mb</b></p>
                    </Grid>
                </Grid>
                <Grid md={12} xs={12} display={'flex'} justifyContent={'center'}>
                    <Button 
                    color="error"
                    disabled={!currentProfilePicture ? true : false}
                    onClick={() => {
                        borrarFotoPerfil()
                        }}>
                        Borrar foto actual
                    </Button>
                </Grid>
              
                <Grid container md={12} xs={12} marginTop={10} marginBottom={10} display={'flex'} justifyContent={'center'} >

                    <Button onClick={() => {
                        setFotoSeleccionada('')
                        closed(false)
                        }}>
                            Cancelar
                        </Button>

                    <Button 
                        style={{backgroundColor:colors.primary, marginLeft:30}} 
                        variant="contained" 
                        onClick={() =>uploadPhoto() }>
                        Guardar
                    </Button>
                    
                </Grid>
                <CustomQuestionDialog 
                    title = 'Borrar foto de perfil' 
                    open={openDialog}
                    setOpen={setOpenDialog}
                    onConfirm={setOnConfirm} 
                    cancelText={'Cancelar'}
                    acceptText={'Aceptar'}
                >
                    ¿Estás seguro que desea eliminar su foto de perfil?
                </CustomQuestionDialog>
                <Grid>
                <ModalLoading open={loadingModal} onCloseHandler={closeHandlerLoadingModal} />
                </Grid>
            </Grid>
          
        </Modal>
    )
}

export default UploadPhotoBox;