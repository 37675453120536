import { Skeleton } from "@mui/material";
import React, { useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as apiService from "../../../api";
import { setInfoPerfilUsuario } from "../../redux/features/afiliado/afiliadoSlice";
import ModalLoading from "../common/modalLoading";

const InterceptorProfile = ({ children }) => {
  const [cargando, setCargando] = useState(false);

  const infoAfiliado = useSelector((state) => state.afiliado.infoAfiliado);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    setCargando(true);
    apiService.afiliado
      .getAfiliado(infoAfiliado.codigoAfiliado)
      .then((response) => {
        //  console.log(response);
        dispatch(setInfoPerfilUsuario(response.data.result));
        setCargando(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return cargando ? (
    <div>
      <Skeleton style={{ height: 150 }} animation="wave" />
      <Skeleton style={{ height: 150 }} animation="wave" />
      <Skeleton style={{ height: 150 }} animation="wave" />

      <ModalLoading open={cargando} onCloseHandler={() => setCargando(false)} />
    </div>
  ) : (
    children
  );
};

export default InterceptorProfile;
