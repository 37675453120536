import React from "react";
import InputBase from "@mui/material/InputBase";

const CustomInput = ({
  label,
  labelStyle,
  registerForm = {},
  ...otherProps
}) => {
  return (
    <>
      <label style={labelStyle}>{label}</label>
      <InputBase {...otherProps} {...registerForm} />
    </>
  );
};

export default CustomInput;
