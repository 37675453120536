import React, { useRef, useState } from 'react'
import { useParams } from "react-router-dom";
import { isExpired, decodeToken } from "react-jwt";
import { useEffect } from 'react';
import * as apiService from '../../../../api'


const PagoMovil = () => {

    let params = useParams();

    const token = params.Token.split("=")[1]

    const expiro = isExpired(token);
    const [session,setSession] = useState("")

    
    const {parametros} = decodeToken(token);
    const campos = parametros.split(";")
    let codigoAfiliado 
    let montoTotal
    let numeroFactura
    let numeroOrden
    let sessionKey

    campos.forEach(value => {
      if(value.includes("codigoAfiliado")){
         codigoAfiliado = value.split("=")[1]
      } else if(value.includes("montoTotal")){
        montoTotal = value.split("=")[1]
      } else if(value.includes("numeroFactura")){
        numeroFactura = value.split("=")[1]
      } else if(value.includes("numeroOrden")){
        numeroOrden = value.split("=")[1]
      }
    })

    const formPago = useRef(null);

    useEffect(() => {
      const body = {
        ordenId: numeroOrden,
        transactionId: numeroFactura,
        amount: montoTotal
      }
      apiService.pagos.postGenerarSession(body,codigoAfiliado, true)
      .then(response => {
        if (response.data.result)
        {
          setSession(response.data.result.session)
          setTimeout(() => {
            formPago.current.submit()
          },100)
         
        }
       
      })
      .catch(err => {
        console.log(err)
      })
       
    },[])

    return (<div>
        {expiro ? 
        <h1>El token ha expirado, favor de realizar la solicitud nuevamente</h1>
           : <div>
                  <form
        ref={formPago}
        action={process.env.REACT_APP_CARDNET_URL}
        method='post'
      >
        <p>
          <input name='SESSION' value={session} type='hidden' />
        </p>
      </form>
            </div>}
        
    </div>)
}

export default PagoMovil