import http from "../../config/http";
import { DETALLE, TODOS, DROP_REGIMENES,DROP_REGIMENES_PRESTADOR, CONS_PRESTADORES_POR_CATALOGO_PDSS } from "../endpoints";

const getToken = () => {
  return localStorage.getItem("autenticado") == "false" 
    ? localStorage.getItem("token-anonimo") 
    : localStorage.getItem("token");
};

const getAllPrestadores = (
 { 
  IdPrestador = 0,
  IdTipoCentro = 0,
  Nombre="",
  IdEspecialidad= 0,
  IdPlan = 0,
  IdRegimen = 0,
  IdMunicipio = 0,
  IdProvincia = 0,
  NumeroDePagina = 0,
  RegistrosPorPagina = 5
}
) => {
  const body = {
    IdPrestador,
    IdTipoCentro,
    Nombre,
    IdEspecialidad,
    IdPlan,
    IdRegimen,
    IdMunicipio,
    IdProvincia,
    NumeroDePagina,
    RegistrosPorPagina
  };

  let configuration = {
    headers: {"Authorization": "Bearer " + getToken()}
  }

  return http.post(`Prestador/${TODOS}`, body,configuration);
};

const getDetallePrestador = (idPrestador, tipoPrestador) => {
  let configuration = {
    headers: {"Authorization": "Bearer " + getToken()}
  }
  const tipo = tipoPrestador === "Medico" ? 1 : 2;
  return http.get(
    `Prestador/${DETALLE}?idPrestador=${idPrestador}&TipoPrestador=${tipo}`,configuration
  );
};

const getAllRegimenes = () => {
  let configuration = {
    headers: {"Authorization": "Bearer " + getToken()}
  }
  return http.get(`Prestador/${DROP_REGIMENES}`,configuration);
};

const getRegimenPrestador = (idPrestador, TipoPrestador) => {
    let configuration = {
      headers: {"Authorization": "Bearer " + getToken()}
    }
    return http.get(`Prestador/${DROP_REGIMENES_PRESTADOR}/Prestador?idPrestador=${idPrestador}&TipoPrestador=${TipoPrestador}`,configuration);
}

const getPrestadoresPorCatalogoPDSS = (codProcedimiento, numeroDePagina=1, registroPorPagina=5) => {
  let configuration = {
    headers: {"Authorization": "Bearer " + getToken()}
  }
  return http.get(`prestador/${CONS_PRESTADORES_POR_CATALOGO_PDSS}?codProcedimiento=${codProcedimiento}&numeroDePagina=${numeroDePagina}&registrosPorPagina=${registroPorPagina}`,configuration);
}

export { getAllPrestadores, getDetallePrestador, getAllRegimenes, getRegimenPrestador, getPrestadoresPorCatalogoPDSS };
