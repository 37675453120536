import React, { useContext, useEffect, useState } from 'react'
import CustomLabel from '../../components/common/customLabel'
import useSetTitlePage from '../../hooks/useSetTitlePage'
import { Divider } from '@mui/material'
import Novedades from '../../components/home/novedades/novedades'
import Atajos from '../../components/home/atajos/atajos'
import { labelTitlePrestador, labelNombreAfiliadoHome, labelNovedadesHome, labelAtajosHome } from '../../global/styles'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as apiService from '../../../api'
import './home.css'
import Popup from '../../components/popUp/popUp'
import { ConfigurationContext } from '../../context/configurationContext'

const Home = () => {
	var navigate = useNavigate()

	const { getAlertaPersonalizada, alertasPersonalizadas } = useContext(ConfigurationContext)
	const [listaSlider, setListaSlider] = useState([])
	const infoAfiliado = useSelector((state) => state.afiliado.infoAfiliado)

	useEffect(() => {
		getAllDashboardSlider();
		getAlertaPersonalizada();
	}, [])

	const getAllDashboardSlider = async() => {
		if (localStorage.getItem('autenticado') == 'false') navigate('login')
			await apiService.ajustes
				.getAllDashboardSlider()
				.then((response) => {
					setListaSlider(response.data.result)
				})
				.catch((err) => console.log(err))
			return () => {
				setListaSlider([])
			}
	}

	const titleHome = (
		<>
			<CustomLabel style={{ ...labelTitlePrestador }}>
			Hola,{' '}
				<label style={{ ...labelNombreAfiliadoHome }}>
					{infoAfiliado.nombres} {infoAfiliado.apellidos}
				</label>
			</CustomLabel>

			<Divider />
		</>
	)

	useSetTitlePage(titleHome)

	return (
		<>
			<CustomLabel style={{ ...labelNovedadesHome }}>Novedades</CustomLabel>

			<Novedades items={listaSlider} />

			<CustomLabel style={{ ...labelAtajosHome }}>Atajos</CustomLabel>
			<Atajos />

			<Popup />
		</>
	)
}

export default Home
