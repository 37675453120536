import React, { useState, useEffect } from 'react'

import { Button, Divider, Grid, InputBase } from '@mui/material'
import CustomLabel from '../../components/common/customLabel'
import useSetTitlePage from '../../hooks/useSetTitlePage'

import { labelTitlePrestador, inputRegistro } from '../../global/styles'

import { HubConnectionBuilder } from '@microsoft/signalr'

import colors from '../../../config/colors'

import { useForm } from 'react-hook-form'

const AdminPage = () => {
	const [connection, setConnection] = useState(null)

	useEffect(() => {
		const newConnection = new HubConnectionBuilder()
			.withUrl(`${process.env.REACT_APP_BASE_URL}hubs/notificacion?username=${'jose'}`)
			.withAutomaticReconnect()
			.build()

		setConnection(newConnection)
	}, [])

	useEffect(() => {
		if (connection) {
			connection
				.start()
				.then((result) => {
					console.log('Connected!')
				})
				.catch((err) => console.log(err))
		}
	}, [connection])

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({ mode: 'onChange' })

	const sendNewNotificacion = async () => {
		if (connection._connectionStarted) {
			try {
				await connection.send('SendNotificacion', 'juan', 'hola')
			} catch (e) {
				console.log(e)
			}
		}
	}

	const TitleAdmin = (
		<>
			<CustomLabel style={{ ...labelTitlePrestador, marginLeft: 25 }}>Admin General</CustomLabel>
			<Divider />
			<br />
			<br />
		</>
	)

	useSetTitlePage(TitleAdmin)

	const handlerSubmitNotificacion = (data) => {
		//console.log(data)
		if (connection._connectionStarted) {
			try {
				const notificacion = {
					titulo: data.titulo,
					descripcion: data.descripcion,
					idNotificacion: 0,
					leida: false
				}

				connection.send('SendNotificacion', data.userName, notificacion)
			} catch (e) {
				console.log(e)
			}
		}
	}

	return (
		<form>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<InputBase
						style={{ ...inputRegistro, backgroundColor: 'white' }}
						placeholder='Titulo'
						{...register('titulo', { required: 'El titulo es obligatoria' })}
					/>
					{errors.titulo && <span style={{ color: 'red' }}> {errors.titulo.message} </span>}
				</Grid>

				<Grid item xs={12}>
					<InputBase
						style={{ ...inputRegistro, backgroundColor: 'white' }}
						placeholder='Descripción'
						{...register('descripcion', {
							required: 'La descripción es obligatoria'
						})}
					/>
					{errors.descripcion && <span style={{ color: 'red' }}> {errors.descripcion.message} </span>}
				</Grid>
				<Grid item xs={12}>
					<InputBase
						style={{ ...inputRegistro, backgroundColor: 'white' }}
						placeholder='Nombre de usuario'
						{...register('userName', { required: 'El usuario es obligatorio' })}
					/>
					{errors.userName && <span style={{ color: 'red' }}> {errors.userName.message} </span>}
				</Grid>
				<Grid item xs={12}>
					<Button
						variant='contained'
						fullWidth
						style={{
							backgroundColor: colors.primary,
							textTransform: 'initial',
							fontWeight: 'bold',
							fontFamily: 'Montserrat'
						}}
						onClick={handleSubmit(handlerSubmitNotificacion)}
					>
						Enviar notificacion
					</Button>
				</Grid>
			</Grid>
		</form>
	)
}

export default AdminPage
