import React, { useState, useEffect, useContext } from 'react'
import CustomLabel from '../../components/common/customLabel'
import CustomInput from '../../components/common/customInput'
import useSetTitlePage from '../../hooks/useSetTitlePage'
import colors from '../../../config/colors'
import { Grid, Divider, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material'
import CustomTable from '../../components/common/customTable'
import { labelTitlePrestador, inputRegistro, botonBuscarPrestador } from '../../global/styles'
import * as apiService from '../../../api/index'
import UseIconHooks from '../../hooks/useIconHooks'
import DialogCoberturaProcedimiento from '../../components/consulta/cobertura/dialogCoberturaProcedimiento'
import { useSnackbar } from 'notistack'
import ModalLoading from '../../components/common/modalLoading'
import { ConfigurationContext } from '../../context/configurationContext'
import { useSelector } from 'react-redux'
import VerDetallesPrestadores from '../../components/prestador/verDetallesPrestadores'
import { PrestadorContext } from '../../context/prestadorContext'
import StarIcon from '@mui/icons-material/Star';
import { createIdTag } from '../../utils/helpers'

const Cobertura = () => {
	const [regimenes, setRegimenes] = useState([])
	const [gruposPdss, setGruposPdss] = useState([])
	const [regimenField, setRegimenField] = useState('')
	const [grupoField, setGrupoField] = useState('')
	const [subGruposPdss, setSubGruposPdss] = useState([])
	const [subGrupoField, setSubGrupoField] = useState('')
	const [disableSubGrupoField, setDisableSubGrupoField] = useState(true)
	const [procedimientos, setProcedimientos] = useState()
	const [nombreField, setNombreField] = useState('')
	const handlerNombreField = ({ target }) => setNombreField(target.value)
	const [regimenesDisponibles, setRegimenesDisponible] = useState([]);
	const [openCobertura, setOpenCobertura] = useState(false)
	const [coberturaActual, setCoberturaActual] = useState({
		codigoSimon: '',
		codigoCup: '',
		procedimientoDescripcion: '',
		codigoGrupo: '',
		grupo: '',
		codigoSubGrupo: '',
		subGrupo: '',
		valorTarifario: 0,
		coberturaPdss: 0,
		coberturaTotal: 0,
		montoCopago: 0
	})
	const { handlerIsLoginPage, getAlertaPersonalizada, mostrarMensajeAlerta } = useContext(ConfigurationContext)
	const [loading, setLoading] = useState(false)
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	const infoAfiliado = useSelector((state) => state.afiliado.infoAfiliado)
	const [planesComplementarios, setPlanesComplementarios] = useState([]);
	const [planField, setPlanField] = useState()
	const [listaPrestadorCoberturaPDSS, setListaPrestadorCoberturaPDSS] = useState([])
	const [totalRegistrosDisponibles, setTotalRegistrosDisponibles] = useState(0)
	const { idPrestador,setIdPrestador} = useContext(PrestadorContext);

	const handlerCloseCobertura = () => {
		setOpenCobertura(false)
	}

	useEffect(() => {
		handlerIsLoginPage(false)
		obtenerRegimenes()
		obtenerGrupoPdss()
		obtenerRegimenAfiliadoLogin()
		obtenerPlanes()
		getAlertaPersonalizada()
		buscarPrestadoresCobertura()

		return () => {
			setRegimenes([])
			setRegimenField('')
			setGruposPdss([])
			setGrupoField('')
			setSubGruposPdss([])
			setSubGrupoField('')
			setDisableSubGrupoField(true)
			setProcedimientos([])
			setOpenCobertura(false)
			setLoading(false)
			setNombreField('')
		}
	}, [])

	useEffect(() => {
		if (grupoField === '' || grupoField === null) {
			setDisableSubGrupoField(true)
			setSubGruposPdss([])
			setSubGrupoField('')
		} else {
			apiService.drops
				.getAllSubGruposPdss(grupoField)
				.then((response) => {
					setDisableSubGrupoField(false)
					setSubGruposPdss(response.data.result)
				})
				.catch((err) => console.log(err))
		}
	}, [grupoField])

	const obtenerRegimenAfiliadoLogin = () => {

		const Regimen = infoAfiliado.regimen
		let regimen = 0

		switch (Regimen) {
			case "SUBSIDIADO":
				regimen = 1;
			  break;	
			case "CONTRIBUTIVO":
				regimen = 2
			break;
			case "VOLUNTARIOS COLECTIVOS":
				regimen = 5
		}

		setRegimenField(regimen)
		consPlanesPorRegimen(regimen)
		
	}

	const obtenerRegimenes = () => {
		const Regimen = infoAfiliado.regimen

		apiService.drops
			.getAllRegimenes()
			.then((response) => {
				let result = response.data.result;
				if(Regimen !== "VOLUNTARIOS COLECTIVOS" ){
					result = result.filter(x => x.descripcion !== "VOLUNTARIOS COLECTIVOS")
				}
				setRegimenes(result)
			})
			.catch((err) => console.log(err))
	}

	const obtenerGrupoPdss = () => {
		apiService.drops
			.getAllGruposPdss()
			.then((response) => {
				response.data.result.unshift({ codigo: '', descripcion: 'Ninguno' })
				setGruposPdss(response.data.result)
			})
			.catch((err) => console.log(err))
	}
	
	const obtenerPlanes = () => {
		apiService.drops.getPlanes()
		.then((response) =>{ 
			response.data.result.unshift({ codigo: 0, descripcion: 'PDSS' })
		})
		.catch((err) => console.log(err))
	}

	const obtenerProcedimientos = (pagina=1, registroPorPagina=5) => {
		if(regimenField || idPrestador)
		{
			obtenerCoberturas(pagina, registroPorPagina)
		}
		else{
			mostrarMensaje("Para consultar servicio/coberturas favor seleccionar al menos un criterio de búsqueda", "error")
		}
		
	}

	const busquedaProcedimientos = () => {
		if(nombreField === "")
		{
			if (
				grupoField === "" ||
				grupoField === null ||
				subGrupoField === "" ||
				subGrupoField === null 
			) {
				mostrarMensaje("Debe tener el grupo y sub grupo para realizar al consulta.","warning" )
			} else {
				obtenerProcedimientos();
			}
		}
		else
		{
			obtenerProcedimientos()
		}
	}

	const obtenerCoberturas = (pagina, registroPorPagina) => {
		setLoading(true);
	
		const nombre = nombreField ?? '';
		const grupo = grupoField ? grupoField : 0;
		const subGrupo = subGrupoField.trimEnd() ?? '';

		apiService.cobertura
			.getProcedimientos(grupo, subGrupo, nombre, idPrestador || 0, pagina, registroPorPagina, regimenField, planField)
			.then((response) => {
				setTotalRegistrosDisponibles(response.data.result.cantidadRegistros);
				const result = response.data.result.data || response.data.result;
				setProcedimientos(result);
				setLoading(false);
	
				if (result.length === 0) {
					mostrarMensaje(mostrarMensajeAlerta("cobertura-consulta-nofound"), 'warning');
				}
			})
			.catch((err) => {
				console.error(err);
				mostrarMensaje('Ha ocurrido un error, favor de intentar mas tarde', 'error');
				setLoading(false);
			});
	};
	
	const agregarRegimenesDisponibles = (value) => {
		const regimenes = []

		if (value.contributivo === 1)
			regimenes.push("Contributivo")

		if (value.subsidiado === 1)
			regimenes.push("Subsidiado")
		
		if (value.complementario === 1)
			regimenes.push("Complementario")
		
			 
		setRegimenesDisponible(regimenes)

	}

	const TitleCobertura = (
		<>
			<CustomLabel style={{ ...labelTitlePrestador }}>Consulta de Coberturas</CustomLabel>
			<Divider />
			<br />
			<br />
		</>
	)

	useSetTitlePage(TitleCobertura)

	const columnas = [
		{
			id: 'descripcion',
			label: 'Procedimiento',
			minWidth: 170
		}
	]

	const acciones = [
		{
			icono: UseIconHooks('visibility', {
				style: { color: colors.primary }
			}),
			metodo: (value) => {
			
				if (regimenField === '' || regimenField === null) {
					mostrarMensaje('Debe tener seleccionado el regimen.','warning')
					return
				}
				setLoading(true)
				agregarRegimenesDisponibles(value)

				apiService.cobertura
					.postCoberturaProcedimiento({
						regimen: regimenField,
						codProcedimiento: value.codigo,
						grupo: grupoField ? grupoField : value.grupo,
						subGrupo: subGrupoField ? subGrupoField : value.subGrupo
					})
					.then((response) => {
						if (!response.data.result || response.data.result.length === 0) {
							mostrarMensaje('No encontró la cobertura del procedimiento.','warning' )
							return
						}
						setCoberturaActual(response.data.result)
						setOpenCobertura(true)
					})
					.catch((err) => console.log(err))
					.finally(() => {
						setLoading(false)
					})
			},
			label: 'Ver detalle',
			
		},
		{
			icono: UseIconHooks('localHospital', {
				style: { color: colors.primary }
			}),
			metodo: (value) => verPrestadores(value.codigo, 1, 5),
			label: 'Ver prestadores',
			
		}
	]

	const verPrestadores = async(_codigoProcedimiento, _numeroPagina, _registroPorPagina) => {
		setLoading(true)
		await apiService.prestador.getPrestadoresPorCatalogoPDSS(_codigoProcedimiento, _numeroPagina, _registroPorPagina)
		.then(res => {
			if(!res.data.result.data){
				mostrarMensaje("No se han encontrado prestadores", "warning")
			}else{
				setListaPrestadorCoberturaPDSS({
					...res.data,
					codigoProcedimiento: _codigoProcedimiento
				});
				
			}
		})
		.catch(err => {
			mostrarMensaje("Ha ocurrido un error, favor intentar nueva vez .","error")
			console.error(err)
		})
		.finally(()=> setLoading(false))
	}

	const mostrarMensaje = (nota, tipo) => {
		enqueueSnackbar(nota, {
		  variant: tipo,
		  action: (key) => (
			<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
			  X
			</Button>
		  ),
		});
	};

	const handlerPage = (pagina, registroPorPagina) =>{
		
		obtenerProcedimientos(pagina, registroPorPagina)
	}

	const buscarPrestadoresCobertura = () => {
		if(idPrestador)
		{
			obtenerCoberturas()
			setIdPrestador(0)
		}
	}

	const consPlanesPorRegimen = async(value) => {
	
		await apiService.cobertura.getPlanesPorRegimen(value)
				.then(res => {
					filtrarPlanesVoluntarios(res.data.result, value)
				})
				.catch((err) => console.error(err))
	}


	const filtrarPlanesVoluntarios = (value, plan) => {

		const codigoAfiliado = infoAfiliado.codigoAfiliado

		let data = value;
		
		if(infoAfiliado.regimen === "VOLUNTARIOS COLECTIVOS" && plan === 5){
			apiService.afiliado.getNucleoCarnet(codigoAfiliado)
			.then((res) => {
				if(res.data.result[0].planes_Nombre){
					data = value.find(x => x.descripcion === res.data.result[0].planes_Nombre)
				}
				else{
					data = []
				}
			})
			.catch((err) => console.error(err))
			.finally(() => {setPlanesComplementarios([data])})

		}else{
			setPlanesComplementarios(data);
		}
		
	}

	const validarPlanContratado = (value) => {
		const { regimen } = infoAfiliado;
	
		if (value !== regimen) {
			return value;
		}
	
		return (
			<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: 0, padding: 0}}>
				<div>
					{value} 
				</div>
				<div style={{ display: "flex", alignItems: "center", paddingLeft:100 }}>
					<h5 style={{margin: 0, padding: 0}}>MI PLAN</h5> 
					<StarIcon style={{fontSize:25, marginLeft:5}}/>
				</div>
			</div>
		);
	};

	return (
		<>
			<Grid container spacing={2}>
				<Grid item md={2} xs={12}>
					<FormControl fullWidth>
						<InputLabel
							id='regimenes'
							style={{
								fontFamily: 'Montserrat',
								fontSize: 20,
								top: regimenField ? -15 : 0,
								backgroundColor: 'white',
								
							}}
						>
							Plan
						</InputLabel>
						<Select
							labelId='regimenes'
							value={regimenField}
							label='Regimenes'
							onChange={(event) => {
								setRegimenField(event.target.value); 
								setPlanesComplementarios([])
								consPlanesPorRegimen(event.target.value);
							}}
							style={{
								...inputRegistro,
								backgroundColor: 'white',
								height: 62
							}}
						>
							{regimenes.map((item) => (
								<MenuItem id={createIdTag("select", item.descripcion)} key={item.codigo} value={item.codigo}>
									{validarPlanContratado(item.descripcion)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				{
					regimenField === 1 || regimenField === 6 ?
					<></>
					:
					<Grid item md={2} xs={12}>
					<FormControl fullWidth>
						<InputLabel
							id='plan'
							style={{
								fontFamily: 'Montserrat',
								fontSize: 20,
								top: planField ? -15 : 0,
								backgroundColor: 'white',
	
							}}
						>
							Plan complementario
						</InputLabel>
						<Select
							labelId='Planes'
							value={planField}
							defaultValue={0}
							label='Planes'
							onChange={(event) => setPlanField(event.target.value)}
							style={{
								...inputRegistro,
								backgroundColor: 'white',
								height: 62,
							}}
						>
							{
								Array.isArray(planesComplementarios) && planesComplementarios.length > 0 ? (
									planesComplementarios.map((item) => (
									<MenuItem id={createIdTag("select", item.descripcion)} key={item.codigo} value={item.codigo}>
										{validarPlanContratado(item.descripcion)}
									</MenuItem>
									))
								) : (
									<></>
								)
							}
							
						</Select>
					</FormControl>
				</Grid>
				}
				
				<Grid item md={2} xs={12}>
					<FormControl fullWidth>
						<InputLabel
							id='grupos'
							style={{
								fontFamily: 'Montserrat',
								fontSize: 20,
								top: grupoField ? -15 : 0,
								backgroundColor: 'white',
							}}
						>
							Grupos PDSS
						</InputLabel>
						<Select
							labelId='grupos'
							value={grupoField}
							label='Grupos PDSS'
							onChange={(event) => setGrupoField(event.target.value)}
							style={{
								...inputRegistro,
								backgroundColor: 'white',
								height: 62
							}}
						>
							{gruposPdss.map((item) => (
								<MenuItem id={createIdTag("select", item.descripcion)} key={item.codigo} value={item.codigo}>
									{item.descripcion}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item md={2} xs={12}>
					<FormControl fullWidth>
						<InputLabel
							id='subGrupos'
							style={{
								fontFamily: 'Montserrat',
								fontSize: 20,
								top: subGrupoField ? -15 : 0,
								backgroundColor: 'white',
							}}
						>
							Sub Grupos PDSS
						</InputLabel>
						<Select
							labelId='subGrupos'
							value={subGrupoField}
							label='Sub Grupos PDSS'
							onChange={(event) => setSubGrupoField(event.target.value)}
							style={{
								...inputRegistro,
								backgroundColor: 'white',
								height: 62
							}}
							disabled={disableSubGrupoField}
						>
							{subGruposPdss.map((item) => (
								<MenuItem id={createIdTag("select", item.descripcion)} key={item.codigo} value={item.codigo}>
									{item.descripcion}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item md={regimenField === 1 || regimenField === 6 ||  planesComplementarios.length === 0 ? 4 : 2} xs={12}>
					<CustomInput
						id="input-nombre"
						style={{ ...inputRegistro, backgroundColor: 'white', height: 60 }}
						placeholder='Nombre'
						value={nombreField}
						onChange={handlerNombreField}
					/>
				</Grid>
				<Grid item md={2} xs={12}>
					<Button id="btn-buscar" fullWidth variant='contained' style={{ ...botonBuscarPrestador,  marginTop: 0, height: 60 }} onClick={busquedaProcedimientos}>
						Buscar
					</Button>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12} marginTop={3}>
					<CustomTable columns={columnas} acciones={acciones} rows={procedimientos} handlerPage={handlerPage} totalDisponibles={totalRegistrosDisponibles}/>
				</Grid>
			</Grid>
			<ModalLoading open={loading} onCloseHandler={() => setLoading(false)} />
			<DialogCoberturaProcedimiento open={openCobertura} handlerCloseModal={handlerCloseCobertura} cobertura={coberturaActual} regimenesDisponible={regimenesDisponibles} />
			<VerDetallesPrestadores lista={listaPrestadorCoberturaPDSS} titulo={"Cobertura PDSS"} verMas={verPrestadores}/>
		</>
	)
}

export default Cobertura
