import React, { useEffect, useState } from "react";
import { Grid } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import colors from "../../../../config/colors";
import { createIdTag } from "../../../utils/helpers";

const CustomRadioButton = (
  {
    nameSelected = (value) => {
      return value;
    }, 
    idSelected = (value) => {
        return value;
    },
    selected, 
    id, 
    name, 
    tag,
    editSelected,
    style,
    identify
  }) => {

    const [isSelected, setIsSelected] = useState();

    const isSelectedStyle = {
        backgroundColor: colors.primary,
        color: colors.white
     }
 
    const handlerSelected = () => {
        nameSelected(name);
        idSelected(id);
        setIsSelected(isSelectedStyle);
     }

     useEffect(() => {
      if(selected === false)
      {
        setIsSelected()
      }
      
     },[selected])
     
     useEffect(() => {
        if(editSelected === name)
        {
            setIsSelected(isSelectedStyle);
        }
     },[editSelected])

    return(
        <div 
          id={identify}
          style={{
              width: '100%',
              cursor:'pointer',
          }}
          onClick={() => handlerSelected()}
        >
            <div
                style={{
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    borderRadius: 10,
                    fontFamily:'montserrat',
                    fontSize: 13,
                    cursor:'pointer',
                    ...isSelected,
                    ...style
                }}
            
        >
        <Grid  display={"flex"} alignContent={'center'} alignItems={'center'} flexDirection={'row'} padding={1} justifyContent={'space-between'} style={style} sx={{cursor:'pointer'}}>
            <div id={createIdTag("select", name)} onClick={() => handlerSelected()}>
                <h4>{name}</h4>
            </div>
            <div style={{display:'flex', textAlign:'center', justifyContent:'center', alignItems:'center'}}>
                {
                    !tag ?
                    !isSelected
                    ? <div><FiberManualRecordOutlinedIcon/></div>
                    :<div>
                        <CheckCircleOutlineIcon />
                    </div>
                    : !isSelected
                    ? <h3  style={{
                        backgroundColor: colors.primary,
                        borderRadius: 20,
                        width: 100,
                        color: colors.white,
                        padding:5,
                        fontFamily:'montserrat',
                        fontSize: 12
                        
                    }}>{tag}</h3>
                    :<div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <CheckCircleOutlineIcon />
                    </div>

                }
            </div>
        </Grid>
        </div>
        </div>
       
       
    )
}

export default CustomRadioButton;