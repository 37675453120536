export default {
  primary: "#3DAE2B",
  gray: "#53575A",
  grayLight: "#B1B1B1",
  grayUltraLight: "rgb(245 245 245)",
  grayFondo: "#F7F7F7",
  error: "#F53232",
  warning: "orange",
  white:'#ffffff',
  whiteTransparent: '#ffffffad',
  grayDark: '#515558',
  grayDarkOpacity: 'rgba(81, 85, 88, 0.85)',
  primaryDark: '#45A951',
  primaryOpacity: 'rgba(69, 169, 81, 0.85)',
  primaryOpacity2: 'rgba(69, 169, 81, 0.55)',
  colorPlanEspecial: '#00269A',
  colorPlanAvanzado: '#0097CE',
  colorPlanMaximo: '#FF5F00',
  colorPlanPremium: '#53575A',
  colorBlue: "#0C6EF2",
  blackTransparent: '#000000ad',

};
