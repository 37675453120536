import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, Grid, Button, InputLabel, InputBase, FormControl, TextField } from '@mui/material'
import CustomLabel from '../../common/customLabel'
import CustomInput from '../../common/customInput'

import {
	dialogContentDetallePrestador,
	labelTitlePrestador,
	inputFormularioAfiliacion,
	customInputLabelProfile,
	botonBuscarPrestador,
	cerrarModalRegistro,
	selectRegistro,
	inputRegistro
} from '../../../global/styles'

import { Clear } from '@mui/icons-material'

import * as apiService from '../../../../api'

import { useForm } from 'react-hook-form'

import NumberFormat from 'react-number-format'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import DesktopDatePicker from '@mui/lab/DesktopDatePicker'

import colors from '../../../../config/colors'

import { format } from 'date-fns'

const DialogDependiente = ({
	open,
	handlerCloseModal,
	esMasculino = true,
	handlerAgregarDependiente,
	modoModalDependiente,
	dependiente = {},
	maxDateField
}) => {
	const [parentescos, setParentescos] = useState([])
	const [sexos, setSexos] = useState([])
	const [tiposDocumento, setTiposDocumento] = useState([])
	const [nacionalidades, setNacionalidades] = useState([])

	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors, isValid }
	} = useForm({ mode: 'onSubmit' })

	useEffect(() => {
		apiService.drops
			.getAllParentesco(esMasculino)
			.then((response) => {
				response.data.result.map((item) => {
					item.parentesco = item.parentesco.trim()
				})
				setParentescos(response.data.result)
			})
			.catch((err) => console.log(err))
	}, [esMasculino])

	useEffect(() => {
		if (modoModalDependiente === 2) {
			setValue('nacionalidad', dependiente.nacionalidad)
			setValue('noDocumento', dependiente.noDocumento)
			setValue('nombres', dependiente.nombres)
			setValue('apellidos', dependiente.apellidos)
			setValue('parentesco', dependiente.parentesco)
			setValue('sexo', dependiente.sexo)
			setValue('tipoDocumento', dependiente.tipoDocumento)
			setValue('idNacionalidad', dependiente.idNacionalidad)
			setValue('idParentesco', dependiente.idParentesco)
			setValue('index', dependiente.index)
			setTipoDocumento(dependiente.tipoDocumento)
			setFechaNacimiento(dependiente.fechaNacimiento)
			setNoDocumento(dependiente.noDocumento)
		} else {
			limpiarCamposFormulario()
			const naci = getValues('nacionalidad')
			if (getValues('nacionalidad') === '') {
				if (getValues('tipoDocumento') == 1) {
					setValue('nacionalidad', nacionalidades.length > 0 ? nacionalidades.filter((a) => a.cdg === 36)[0].descripcion : '')
				} else {
					setValue('nacionalidad', nacionalidades.length > 0 ? nacionalidades[0].descripcion : '')
				}
			}

			if (getValues('parentesco') === '') {
				setValue('parentesco', parentescos.length > 0 ? parentescos[0].parentesco : '')
			}

			setFormatDocumento('###-#######-#')
			setMaskDocumento('_')
		}
	}, [dependiente])

	useEffect(() => {
		setSexos(apiService.drops.getAllSexoFormulario())
		setTiposDocumento(apiService.drops.getAllTipoDocumentoFormulario())
		apiService.drops
			.getAllNacionalidades()
			.then((response) => {
				setNacionalidades(response.data.result)
			})
			.catch((err) => console.log(err))
	}, [])

	const [formatDocumento, setFormatDocumento] = useState('###-#######-#')
	const [maskDocumento, setMaskDocumento] = useState('_')
	const [noDocumento, setNoDocumento] = useState('')
	const [tipoDocumento, setTipoDocumento] = useState('1')

	const handlerFormatDocumento = (tipo) => {
		if (tipo == 1) {
			setFormatDocumento('###-#######-#')
			setMaskDocumento('_')
			setValue('nacionalidad', nacionalidades.filter((a) => a.cdg === 36)[0].descripcion)
		} else {
			setFormatDocumento('#############')
			setMaskDocumento('')
		}
		setValue('noDocumento', '')
	}

	const handlerMensajeCampoRequerido = (campo) => {
		return `El campo ${campo} es requerido.`
	}

	const [customError, setCustomError] = useState({
		message: '',
		hasError: false
	})

	const [fechaNacimiento, setFechaNacimiento] = useState(null)
	const handlerFechaNacimiento = (value) => {
		setFechaNacimiento(value)
		validarFechaNacimiento(value)
	}

	const validarFechaNacimiento = (value) => {
		if (value == null) {
			setCustomError({
				hasError: true,
				message: handlerMensajeCampoRequerido('Fecha de nacimiento')
			})
		} else {
			setCustomError({
				hasError: false,
				message: ''
			})
		}
	}

	const handlerSubmitAgregarDependiente = (data) => {
		validarFechaNacimiento(fechaNacimiento)
		if (customError.hasError) return

		if (fechaNacimiento === null) {
			setCustomError({
				hasError: true,
				message: handlerMensajeCampoRequerido('Fecha de nacimiento')
			})
			return
		}

		data.idNacionalidad = nacionalidades.filter((n) => n.descripcion === data.nacionalidad)[0].cdg

		data.idParentesco = parentescos.filter((p) => p.parentesco == data.parentesco)[0].idParentesco

		var fecha = new Date(fechaNacimiento)
		data.fechaNacimiento = format(fecha, 'dd/MM/yyyy')

		handlerAgregarDependiente(data)
		limpiarCamposFormulario()
	}

	const limpiarCamposFormulario = () => {
		setValue('nombres', '')
		setValue('apellidos', '')
		setValue('tipoDocumento', '1')
		setValue('nacionalidad', nacionalidades.length > 0 ? nacionalidades.filter((a) => a.cdg === 36)[0].descripcion : '')

		setValue('noDocumento', '')
		setValue('parentesco', parentescos.length > 0 ? parentescos[0].parentesco : '')
		setValue('sexo', sexos[0])

		setTipoDocumento('1')
		setFechaNacimiento(null)
		setNoDocumento('')
	}

	const handlerInValidSubmit = (data) => {
		if (fechaNacimiento === null) {
			setCustomError({
				hasError: true,
				message: handlerMensajeCampoRequerido('Fecha de nacimiento')
			})
			return
		} else {
			setCustomError({
				hasError: false,
				message: ''
			})
		}
	}

	return (
		<Dialog
			open={open}
			maxWidth='lg'
			PaperProps={{
				style: {
					borderRadius: 15
				}
			}}
		>
			<DialogContent style={{ ...dialogContentDetallePrestador, backgroundColor: 'white' }}>
				<Grid container>
					<Grid item xs={12}>
						<CustomLabel
							style={{
								...labelTitlePrestador,
								marginLeft: 0,
								fontSize: 25,
								backgroundColor: 'lightgray',
								padding: 15
							}}
						>
							Datos del dependiente
						</CustomLabel>
						<div onClick={handlerCloseModal}>
							<Clear
								style={{
									...cerrarModalRegistro,
									fontSize: 30,
									color: 'black'
								}}
							/>
						</div>
					</Grid>
					<Grid
						container
						style={{
							display: 'flex',
							rowGap: 10,
							padding: 15
						}}
					>
						<Grid item xs={12} md={6}>
							<CustomInput
								label='Nombres'
								style={{ ...inputFormularioAfiliacion, width: '99%' }}
								labelStyle={{
									...customInputLabelProfile,
									color: 'black'
								}}
								registerForm={{
									...register('nombres', {
										required: handlerMensajeCampoRequerido('Nombres')
									})
								}}
							/>
							{errors.nombres && <span style={{ color: 'red' }}> {errors.nombres.message} </span>}
						</Grid>
						<Grid item xs={12} md={6}>
							<CustomInput
								label='Apellidos'
								style={{
									...inputFormularioAfiliacion,
									width: '99%'
								}}
								labelStyle={{
									...customInputLabelProfile,
									color: 'black'
								}}
								registerForm={{
									...register('apellidos', {
										required: handlerMensajeCampoRequerido('Apellidos')
									})
								}}
							/>
							{errors.apellidos && <span style={{ color: 'red' }}> {errors.apellidos.message} </span>}
						</Grid>
						<Grid item xs={12} md={3} marginTop={-0.6}>
							<InputLabel
								id='select-tipo-documento'
								style={{
									fontFamily: 'Montserrat',
									marginBottom: 2,
									color: 'black',
									fontWeight: 'bold'
								}}
							>
								Tipo de documento
							</InputLabel>
							<select
								style={{
									borderRadius: 10,
									...selectRegistro,
									...inputRegistro,
									boxShadow: 'none',
									height: 58,
									fontFamily: 'Montserrat',
									width: '98%'
								}}
								disabled={modoModalDependiente === 1 ? false : true}
								id='select-tipo-documento'
								{...register('tipoDocumento', {
									required: handlerMensajeCampoRequerido('tipo de documento')
								})}
								value={tipoDocumento}
								onChange={(event) => {
									setTipoDocumento(event.target.value)
									setNoDocumento('')
									handlerFormatDocumento(event.target.value)
									if (event.target.value === '3') {
										setNoDocumento('NA')
										setValue('noDocumento', 'NA')
									}
								}}
							>
								{tiposDocumento.map((item) => (
									<option key={item.id} value={item.id}>
										{item.descripcion}
									</option>
								))}
							</select>
							{errors.tipoDocumento && <span style={{ color: 'red' }}> {errors.tipoDocumento.message} </span>}
						</Grid>
						<Grid item xs={12} md={3}>
							<label style={{ ...customInputLabelProfile, color: 'black' }}>Documento</label>
							{tipoDocumento == '1' && (
								<NumberFormat
									value={noDocumento}
									format={formatDocumento}
									mask={maskDocumento}
									style={{ ...inputFormularioAfiliacion, height: 60, width: '98%' }}
									disabled={modoModalDependiente === 1 ? false : true}
									placeholder='Documento'
									{...register('noDocumento', {
										required: handlerMensajeCampoRequerido('Documento')
									})}
									onValueChange={(values) => {
										setNoDocumento(values.value)
										setValue('noDocumento', values.formattedValue)
									}}
									customInput={InputBase}
								/>
							)}
							{tipoDocumento == '2' && (
								<InputBase
									inputProps={{ maxLength: 30 }}
									placeholder='Documento'
									disabled={modoModalDependiente === 1 ? false : true}
									style={{ ...inputFormularioAfiliacion, height: 60, width: '98%' }}
									{...register('noDocumento', {
										required: handlerMensajeCampoRequerido('Documento')
									})}
								/>
							)}
							{tipoDocumento == '3' && (
								<InputBase
									placeholder='Documento'
									disabled={true}
									style={{ ...inputFormularioAfiliacion, height: 60, width: '98%' }}
									{...register('noDocumento', {
										required: handlerMensajeCampoRequerido('Documento')
									})}
								/>
							)}
							{errors.noDocumento && <span style={{ color: 'red' }}> {errors.noDocumento.message} </span>}
						</Grid>
						<Grid item xs={12} md={4}>
							<label style={{ ...customInputLabelProfile, color: 'black' }}>Fecha de nacimiento</label>
							<FormControl
								className='formcontrol'
								fullWidth
								style={{
									border: 'solid 2px',
									borderColor: colors.primary,
									borderRadius: 10,
									width: '97.5%'
								}}
							>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DesktopDatePicker
										label=''
										inputFormat='dd/MM/yyyy'
										value={fechaNacimiento}
										maxDate={new Date(maxDateField)}
										onChange={handlerFechaNacimiento}
										style={{
											width: '100%',
											fontFamily: 'Montserrat',
											fontSize: 20
										}}
										inputProps={{ readOnly: true }}
										renderInput={(params) => <TextField {...params} />}
									/>
								</LocalizationProvider>
							</FormControl>
							{customError.hasError && <span style={{ color: 'red' }}> {customError.message} </span>}
						</Grid>
						<Grid item xs={12} md={2} marginTop={-0.6}>
							<InputLabel
								id='select-sexo'
								style={{
									fontFamily: 'Montserrat',
									marginBottom: 2,
									color: 'black',
									fontWeight: 'bold'
								}}
							>
								Sexo
							</InputLabel>
							<select
								style={{
									borderRadius: 10,
									...selectRegistro,
									...inputRegistro,
									boxShadow: 'none',
									height: 58,
									fontFamily: 'Montserrat',
									width: '98%'
								}}
								id='select-sexo'
								{...register('sexo', {
									required: handlerMensajeCampoRequerido('Sexo')
								})}
							>
								{sexos.map((item) => (
									<option key={item} value={item}>
										{item}
									</option>
								))}
							</select>
							{errors.sexo && <span style={{ color: 'red' }}> {errors.sexo.message} </span>}
						</Grid>
						<Grid item xs={12} md={6}>
							<InputLabel
								id='label-nacionalidad'
								style={{
									fontFamily: 'Montserrat',
									marginBottom: 2,
									color: 'black',
									fontWeight: 'bold'
								}}
							>
								Nacionalidad
							</InputLabel>
							<select
								style={{
									borderRadius: 10,
									...selectRegistro,
									...inputRegistro,
									boxShadow: 'none',
									height: 58,
									fontFamily: 'Montserrat',
									width: '99%'
								}}
								id='select-nacionalidad'
								{...register('nacionalidad')}
							>
								{nacionalidades.map((item) => (
									<option key={item.cdg} value={item.descripcion}>
										{item.descripcion}
									</option>
								))}
							</select>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								id='label-parentesco'
								style={{
									fontFamily: 'Montserrat',
									marginBottom: 2,
									color: 'black',
									fontWeight: 'bold'
								}}
							>
								Parentesco
							</InputLabel>
							<select
								style={{
									borderRadius: 10,
									...selectRegistro,
									...inputRegistro,
									boxShadow: 'none',
									height: 58,
									fontFamily: 'Montserrat',
									width: '99%'
								}}
								id='select-parentesco'
								{...register('parentesco', {
									required: handlerMensajeCampoRequerido('Parentesco')
								})}
							>
								{parentescos.map((item) => (
									<option key={item.idParentesco} value={item.parentesco}>
										{item.parentesco}
									</option>
								))}
							</select>
							{errors.parentesco && <span style={{ color: 'red' }}> {errors.parentesco.message} </span>}
						</Grid>
					</Grid>
					<Grid
						container
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<Grid item xs={12}>
							<Button
								variant='contained'
								style={{
									...botonBuscarPrestador,
									fontFamily: 'Montserrat',
									height: 58,
									fontSize: 20
								}}
								fullWidth
								onClick={handleSubmit(handlerSubmitAgregarDependiente, handlerInValidSubmit)}
							>
								{modoModalDependiente === 1 ? 'Agregar' : 'Actualizar'}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default DialogDependiente
