import React, { useEffect, useState } from "react";
import UseSetIsLogin from "../../hooks/useSetIsLogin";
import { Navigate, useParams } from "react-router-dom";
import CustomLabel from "../../components/common/customLabel";
import colors from "../../../config/colors";
import { CircularProgress, Modal, Button, InputBase, Grid, InputLabel } from "@mui/material";
import * as apiService from "../../../api";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { botonIngresarLogin, inputRegistro } from "../../global/styles";
import { isExpired, decodeToken } from "react-jwt";
import useSetIsLogin from "../../hooks/useSetIsLogin";

const ReversoCorreo = () => {

  const [correoNuevo, setCorreoNuevo] = useState('')
  const [mensaje, setMensaje] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isValid, setIsValid] = useState(false)
  const [estado, setEstado] = useState();
  const [descripcionBitacora, setDescripcionBitacora] = useState('');


  let params = useParams();
  const token = params.Token.split("=")[1];
  const expiro = isExpired(token);
  const parametros = decodeToken(token);
  var navigate = useNavigate()

  const { setAuthLogOut } = useSetIsLogin()

	useEffect(() => {
		setAuthLogOut()
    autentificar()
	}, [])


  const mensajeToast = (mensaje, tipo) => {
    enqueueSnackbar(mensaje, {
      variant: tipo,
      action: (key) => (
        <Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
          X
        </Button>
      )})
  }

  const autentificar = () => {
     apiService.utility.getAllBitacoraByCode(parametros.CodigoAfiliado, token)
     .then(res => setEstado(res.data.result))
     .catch(err =>   mensajeToast(`Ha ocurrido un error durante la actualización, favor intente más tarde. `,'error' ))
  }

  useEffect(() => {
    if(estado)
    {
      const longitud = estado.length - 1;
      setDescripcionBitacora(estado[longitud].descripcion)
    }
   
  }, [estado])

 
const handlerReverse = () => {
   apiService.autentificacion.SolicitudCambioCorreo(parametros.CodigoAfiliado, '', true, token)
   .then( res =>{ 
      mensajeToast("Su solicitud ha sido reversada de forma satisfactoria", "success")
      navigate('../../login', { replace: true })
  }

    )
   .catch(err => {mensajeToast("Ha ocurrido un error, favor de intentar más tarde", "error"); alert(token)})
}

  //Cancela los cambios y los reversa al correo anterior
  const handlerCancel = () => {
  
   // mensajeToast(`La solicitud de cambio de correo electrónico ha sido cancelada por el usuario`, 'error')
    navigate('../../login', { replace: true })
   
  }

  return (
    <>
    {
      !descripcionBitacora ? <></>
      :
      descripcionBitacora === 'Reverso de cambio correo'
      ?<Modal style={{display: 'flex', fontFamily:'montserrat'}} open={true}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: colors.grayFondo,
          border: `1px solid ${colors.primary}`,
          boxShadow: 15,
          p: 4,
          textAlign: "center",
        }}
      >
        <div
          style={{
            padding: 25,
            display: "flex",
            flexDirection: "column",
            backgroundColor: colors.grayFondo,
            alignItems: "center",

          }}
        >
          <CustomLabel>
            Un {descripcionBitacora.toLocaleLowerCase()} ya fue aplicado, favor de realizar el proceso nueva vez.  
          </CustomLabel>
        </div>
        <Grid item xs={12} style={{ marginTop: 30 }}>

        <Grid item xs={12} style={{ marginTop: 30 }}> 
                  <Button
                    variant='contained'
                    onClick={() => handlerCancel()}
                    style={{
                        backgroundColor: colors.primary,
                        textTransform: "initial",
                        fontFamily: "Montserrat",
                        fontSize: 20,
                        borderRadius: 5,

                    }}
                  >
                    De acuerdo
                  </Button>
                </Grid>
        </Grid>
      </Box>
        </Modal>
      :
     !expiro 
      ? <Modal style={{display: 'flex', fontFamily:'montserrat'}} open={true}>
      <Box
       sx={{
        width: {
          xs: '90%', 
          sm: 500,    
          md: 600,    
          lg: 800,   
        },
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: colors.grayFondo,
        border: `1px solid ${colors.primary}`,
        boxShadow: 15,
        p: 4,
        textAlign: "center",
      }}
      >
        <div
          style={{
            padding: 25,
            display: "flex",
            flexDirection: "column",
            backgroundColor: colors.grayFondo,
            alignItems: "center",

          }}
        >
          <CustomLabel>
            Se procederá a revertir el cambio de correo electrónico solicitado. ¿Desea continuar?
          </CustomLabel>
        </div>
        <Grid item xs={12} style={{ marginTop: 30 }}>
          <Button
            variant='outliner'
            onClick={() => handlerCancel()}
            style={{
                textTransform: "initial",
                fontFamily: "Montserrat",
                fontSize: 20,
                marginRight: 40,
                borderRadius: 10,
            }}
          >
            Cancelar
          </Button>
          <Button
            variant='contained'
            onClick={() => handlerReverse()}
            style={{
              textTransform: "initial",
              fontFamily: "Montserrat",
              fontSize: 20,
              marginLeft: 40,
              borderRadius: 10,
              backgroundColor: colors.primary,
               
            }}
          >
            Reversar
          </Button>
        </Grid>
      </Box>
        </Modal>
      : <Modal style={{display: 'flex', fontFamily:'montserrat'}} open={true}>
          <Grid container>
          <Grid item md={12} xs={12}>
            <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: colors.grayFondo,
              border: `1px solid ${colors.primary}`,
              boxShadow: 15,
              p: 4,
              textAlign: "center",
            }}
          >
            <div
              style={{
                padding: 25,
                display: "flex",
                flexDirection: "column",
                backgroundColor: colors.grayFondo,
                alignItems: "center",
              }}
            >
              <CustomLabel>
                El token expiro favor de iniciar sesión en su cuenta y volver a intentar
              </CustomLabel>
            </div>
            </Box>
          </Grid>
        </Grid>
      
        </Modal>
     }
    
       
    </>
  );
};

export default ReversoCorreo;
