import http from "../../config/http";
import { PROCEDIMIENTOS, COBERTURA_PROCEDIMIENTO, CONST_PROCEDIMIENTOS, CONS_PLANES_POR_REGIMEN } from "../endpoints";

const getToken = () => {
  return localStorage.getItem("autenticado") == "false" 
  ? localStorage.getItem("token-anonimo") 
  : localStorage.getItem("token");
}

const getAllProcedimientos = (idGrupo, idSubGrupo, nombreField) => {
  let token = getToken();

  let configuration = {
    headers: {"Authorization": "Bearer " + token}
  }
  return http.get(
    `Cobertura/${PROCEDIMIENTOS}?IdGrupo=${idGrupo}&IdSubGrupo=${idSubGrupo}&nombre=${nombreField}`,configuration
  );
};

const postCoberturaProcedimiento = (body) => {
  let token = getToken();

  let configuration = {
    headers: {"Authorization": "Bearer " + token}
  }
  return http.post(`Cobertura/${COBERTURA_PROCEDIMIENTO}`, body, configuration);
};

const getProcedimientos = (idGrupo=0, idSubGrupo="", nombre="", idPrestador=0, numeroDePagina=1, registroPorPagina=5, idRegimen=9, idPlan=0) => {
  let token = getToken();

  let configuration = {
    headers: {"Authorization": "Bearer " + token}
  }
  return http.get(`Cobertura/${CONST_PROCEDIMIENTOS}?IdGrupo=${idGrupo}&IdSubGrupo=${idSubGrupo}&nombre=${nombre}&idPrestador=${idPrestador}&numeroDePagina=${numeroDePagina}&registrosPorPagina=${registroPorPagina}&idRegimen=${idRegimen}&idPlan=${idPlan}`, configuration);
  
};

const getPlanesPorRegimen = (idRegimen) => {
  let token = getToken();

  let configuration = {
    headers: {"Authorization": "Bearer " + token}
  }

  return http.get(`Cobertura/${CONS_PLANES_POR_REGIMEN}/regimen?IdRegimen=${idRegimen}`, configuration);
  
}


export { getAllProcedimientos, postCoberturaProcedimiento, getProcedimientos,getPlanesPorRegimen };
