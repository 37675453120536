import http from '../../config/http'
import axios from 'axios'

import { 
    TODOS 
} from '../endpoints';



const getAllHistorialConsumoGeneral = async(contrato = null, fechaDesde = null, fechaHasta  = null ) => {
    
    let configuration = {
        headers: {"Authorization": "Bearer " + localStorage.getItem("token")}
      }

    const body = {
        contrato: contrato,
        tipo: 0,
        altoCosto: false,
        fechaDesde: fechaDesde,
        fechaHasta: fechaHasta,
        idPrestador: 0 
    }
    
    return http.post(`historialconsumo/${TODOS}`,body, configuration)
}


export {
    getAllHistorialConsumoGeneral
};