import { createSlice } from '@reduxjs/toolkit'
import React from 'react'

const initialState = {
	notificationList: [
		{ time: 'hoy', data: [{ message: 'mensaje 1' }, { message: 'mensaje 2' }] },
		{
			time: 'ayer',
			data: [
				{
					message: 'mensaje #3 que es mas largo que los mensajes normales, aqui se prueba que sirva el componente de ellipsis'
				}
			]
		}
	],
	notifyCounter: 0
}

export const notificacionSlice = createSlice({
	name: 'notificacion',
	initialState,
	reducers: {
		setNotificacion: (state, action) => {
			state.notificationList.filter((a) => a.time === 'hoy')[0].data = [
				...state.notificationList.filter((a) => a.time === 'hoy')[0].data,
				{ message: action.payload }
			]
		},
		setNotifyCounter: (state, action) => {
			state.notifyCounter = action.payload
		}
	}
})

export const { setNotificacion, setNotifyCounter } = notificacionSlice.actions

export default notificacionSlice.reducer
