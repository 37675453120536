import { createSlice } from "@reduxjs/toolkit";
import React from "react";

const initialState = {
  infoAfiliado: {
    codigoAfiliado: null,
    nombres: "",
    apellidos: "",
    tipoAfiliacion: "",
    regimen: "",
  },
  infoPerfilUsuario: {},
};

export const afiliadoSlice = createSlice({
  name: "afiliado",
  initialState,
  reducers: {
    setInfoAfiliado: (state, action) => {
      state.infoAfiliado = action.payload;
    },
    setInfoPerfilUsuario: (state, action) => {
      state.infoPerfilUsuario = action.payload;
    },
  },
});

export const { setInfoAfiliado, setInfoPerfilUsuario } = afiliadoSlice.actions;

export default afiliadoSlice.reducer;
