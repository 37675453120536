import {
  Phone,
  DateRange,
  PlaylistAddCheck,
  AttachMoney,
  LocalHospital,
  VisibilitySharp,
  InsertDriveFileSharp,
  HomeSharp,
  ChevronLeft,
  ChevronRight,
  Edit,
  ExpandLess,
  ExpandMore,
  Favorite,
  Inbox,
  Mail,
  StarBorder,
  PaymentsSharp,
  FacebookSharp,
  Twitter,
  Instagram,
  YouTube,
  Message,
  Settings,
  Groups,
  Save,
  SettingsBackupRestore,
  Badge,
} from "@mui/icons-material";

export const iconos = {
  inbox: <Inbox />,
  mail: <Mail />,
  chevronl: <ChevronLeft />,
  chevronr: <ChevronRight />,
  expandedl: <ExpandLess />,
  expandedm: <ExpandMore />,
  star: <StarBorder />,
  favorite: <Favorite />,
  edit: <Edit />,
  home: <HomeSharp />,
  description: <InsertDriveFileSharp />,
  visibility: <VisibilitySharp />,
  localHospital: <LocalHospital />,
  money: <AttachMoney />,
  playlist: <PlaylistAddCheck />,
  dateRange: <DateRange />,
  phone: <Phone />,
  payment: <PaymentsSharp />,
  facebook: <FacebookSharp />,
  twitter: <Twitter />,
  instagram: <Instagram />,
  youtube: <YouTube />,
  message: <Message />,
  setting: <Settings />,
  group: <Groups />,
  save: <Save />,
  reset: <SettingsBackupRestore />,
  badge : <Badge/>,
};
