import React, { useEffect } from 'react'
import { Modal, Box, Grid, Button, InputBase } from '@mui/material'
import CustomLabel from '../../components/common/customLabel'
import colors from '../../../config/colors'
import { inputRegistro } from '../../global/styles'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import * as apiService from '../../../api'
import { useParams, useNavigate } from 'react-router-dom'
import { isExpired, } from 'react-jwt'
import UseSetIsLogin from '../../hooks/useSetIsLogin'

const CambioClave = () => {
	const {
		register,
		handleSubmit,
		formState: { errors, isValid }
	} = useForm({ mode: 'onChange' })

	const { setAuthLogOut } = UseSetIsLogin()

	useEffect(() => {
		setAuthLogOut()
	}, [])

	let params = useParams()

	let navigate = useNavigate()

	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	const expiro = isExpired(params.Token.split('=')[1])

	const handlerSubmitCambioClave = (data) => {
		if (data.password !== data.repeatPassword) {
			enqueueSnackbar('Las contraseñas no coinciden, favor de validar.', {
				variant: 'warning',
				action: (key) => (
					<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
						X
					</Button>
				)
			})
			return
		}

		apiService.autentificacion
			.getCambioClave(params.Token.split('=')[1], data.password)
			.then((response) => {
				const resultado = response.data.result
				if (resultado.codigoMensaje === 1007) {
					enqueueSnackbar('Se ha realizado el cambio de contraseña, favor de iniciar sesion con sus nuevas credenciales.', {
						variant: 'success',
						action: (key) => (
							<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
								X
							</Button>
						)
					})
					navigate('/login')
				} else {
					enqueueSnackbar(`$${resultado.mensaje}`, {
						variant: 'error',
						action: (key) => (
							<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
								X
							</Button>
						)
					})
				}
			})
			.catch((err) => {
				enqueueSnackbar('Favor intente mas tarde.', {
					variant: 'error',
					action: (key) => (
						<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
							X
						</Button>
					)
				})
				console.log(err)
			})
	}

	return (
		<>
			<Modal open={true}>
				<form onSubmit={handleSubmit(handlerSubmitCambioClave)}>
					<Box
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: { xs: '90%', sm: '80%', md: '60%', lg: '50%' },
							bgcolor: colors.grayFondo,
							border: `1px solid ${colors.primary}`,
							boxShadow: 15,
							p: { xs: 2, sm: 4 },
							textAlign: 'center'
						}}
					>
						{expiro ? (
							<div
								style={{
									padding: 25,
									display: 'flex',
									flexDirection: 'column',
									backgroundColor: colors.grayFondo,
									alignItems: 'center'
								}}
							>
								<CustomLabel
									style={{
										fontFamily: 'montserrat',
										color: colors.primary,
										fontSize: { xs: 20, sm: 30, md: 45 }
									}}
								>
									<label>El token expiro, favor de realizar la solicitud de cambio de contraseña nuevamente.</label>
								</CustomLabel>
							</div>
						) : (
							<>
								<div
									style={{
										padding: 25,
										display: 'flex',
										flexDirection: 'column',
										backgroundColor: colors.grayFondo,
										alignItems: 'center'
									}}
								>
									<CustomLabel
										style={{
											fontFamily: 'montserrat',
											color: colors.primary,
											fontSize: { xs: 20, sm: 30, md: 45 }
										}}
									>
										<label>Cambio de contraseña</label>
									</CustomLabel>
								</div>
								<Grid container spacing={2}>
									<Grid item xs={12} marginBottom={2}>
										<Grid>
											<Grid>
												<InputBase
													style={{ ...inputRegistro }}
													placeholder='Contraseña'
													{...register('password', {
														required: 'Debe escribir la Contraseña.',
														minLength: {
															value: 6,
															message: 'El mínimo de caracteres para la contraseña es 6'
														}
													})}
													type='password'
												/>
											</Grid>
											<Grid display={'flex'} marginTop={1}>
												{errors.password && <span style={{ color: 'red' }}> {errors.password.message} </span>}
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Grid>
											<Grid>
												<InputBase
													style={{ ...inputRegistro }}
													placeholder='Repetir contraseña'
													{...register('repeatPassword', {
														required: 'El campo Repetir contraseña es obligatorio',
														minLength: {
															value: 6,
															message: 'El mínimo de caracteres para la contraseña es 6'
														}
													})}
													type='password'
												/>
											</Grid>
											<Grid display={'flex'} marginTop={1}>
												{errors.repeatPassword && <span style={{ color: 'red' }}> {errors.repeatPassword.message} </span>}
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Button
											variant='contained'
											type='submit'
											fullWidth
											style={{
												textTransform: "initial",
												fontFamily: "Montserrat",
												fontSize: 20,
												marginLeft: 40,
												borderRadius: 10,
												fontFamily: 'montserrat',
												textTransform: 'initial',
												color: 'white',
												fontWeight: 'bold',
												backgroundColor: isValid ? colors.primary : colors.grayLight,
												width: 320
											}}
											disabled={!isValid}
										>
											Solicitar
										</Button>
									</Grid>
								</Grid>
							</>
						)}
					</Box>
				</form>
			</Modal>
		</>
	)
}

export default CambioClave
