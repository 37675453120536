import {createSlice} from '@reduxjs/toolkit'
import React from 'react'

const initialState = {
    Title: (<> <h1></h1> </>),
    isLogin: false
}

export const configurationSlice = createSlice({
    name: "configuration",
    initialState,
    reducers: {
        setConfiguration: (state,action) => {
            state.Title = action.payload
        },
        setIsLogin: (state,action) => {
            state.isLogin = action.payload
        }
    }
})

export const {setConfiguration,setIsLogin} = configurationSlice.actions

export default configurationSlice.reducer