import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import CustomLabel from '../common/customLabel'

import { labelPreguntaRegistro, labelSaludoAfiliadoRegistro } from '../../global/styles'

import Pregunta from './pregunta'

const PreguntaSeguridad = ({ infoAfiliado, listaRespuesta, setListaRespuesta, cantidadIntentos }) => {
	const [listaPregunta, setListaPregunta] = useState([])

	const handlerSeleccion = (respuesta, idPregunta) => {
		let lista = listaRespuesta
		if (lista.filter((a) => a.idPregunta === idPregunta).length > 0) {
			lista.filter((a) => a.idPregunta === idPregunta).map((res) => (res.respuesta = respuesta))
		} else {
			lista.push({ idPregunta, respuesta })
		}

		setListaRespuesta(lista)
	}

	const getRandomArbitrary = (min, max) => {
		return Math.round(Math.random() * (max - min) + min)
	}

	useEffect(() => {
		setListaRespuesta([])
		obtenerPreguntas(3, 1, 8)
	}, [cantidadIntentos])

	const obtenerPreguntas = (cantidadPreguntas, maxRandom, minRandom) => {
		let lista = []
		for (let i = 0; i < cantidadPreguntas; i++) {
			let continuar = false
			do {
				const no = getRandomArbitrary(minRandom, maxRandom)
				if (infoAfiliado.preguntasSeguridad.filter((a) => a.id === no)[0]) {
					if (lista.filter((a) => a.id === no).length > 0) {
						continuar = false
					} else {
						lista.push(infoAfiliado.preguntasSeguridad.filter((a) => a.id === no)[0])
						continuar = true
					}
				} else {
					continuar = false
				}
			} while (continuar === false)
		}
		setListaPregunta([])
		setListaRespuesta([])
		setListaPregunta(lista)
	}

	return (
		<>
			<Grid container marginTop='8%'>
				<Grid item xs={12}>
					<CustomLabel style={{ ...labelPreguntaRegistro }}>Preguntas de Seguridad</CustomLabel>
				</Grid>

				<Grid item xs={12} marginTop='2%'>
					<CustomLabel style={{ ...labelSaludoAfiliadoRegistro }}>
						Hola, {infoAfiliado.nombres} {infoAfiliado.apellidos}
					</CustomLabel>
				</Grid>

				{listaPregunta.map((pregunta) => (
					<Grid id="preguntas-seguridad" container marginTop='3%' key={pregunta.id}>
						<Pregunta
							label={pregunta.pregunta}
							tipoPregunta={pregunta.idTipoPregunta}
							id={pregunta.id}
							handlerSeleccion={handlerSeleccion}
							cantidadIntentos={cantidadIntentos}
						/>
					</Grid>
				))}
			</Grid>
		</>
	)
}

export default PreguntaSeguridad
