import http from '../../config/http'
import {
	GET_AFILIADO,
	UPDATE_TELEFONO,
	UPDATE_DIRECCION,
	UPDATE_CORREO,
	DATOS_PERSONALES,
	DATOS_CONTACTOS,
	FORMULARIO_SOLICITUD_AFILIACION,
	FORMULARIO_CATALOGO_SINTOMA_ENFERMEDAD_NUCLEO,
	GET_NUCLEO_CARNET
} from '../endpoints'

const getAfiliado = (codigoAfiliado) => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}
	return http.get(`Afiliado/${GET_AFILIADO}?CodigoAfiliado=${codigoAfiliado}`, configuration)
}

const postUpdateTelefonos = (codigoAfiliado, telefonos) => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}
	return http.post(`Afiliado/${UPDATE_TELEFONO}?CodigoAfiliado=${codigoAfiliado}`, telefonos, configuration)
}

const postUpdateDireccion = (codigoAfiliado, body) => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}
	return http.post(`Afiliado/${UPDATE_DIRECCION}?CodigoAfiliado=${codigoAfiliado}`, body, configuration)
}

const postUpdateCorreo = (codigoAfiliado, body, token) => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + token/*localStorage.getItem('token')*/ }
	}
	return http.post(`Afiliado/${UPDATE_CORREO}?CodigoAfiliado=${codigoAfiliado}`, body, configuration)
}

const getDatosPersonales = (codigoAfiliado) => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}
	return http.get(`Afiliado/${DATOS_PERSONALES}?codigoAfiliado=${codigoAfiliado}`, configuration)
}

const getDatosContactos = (codigoAfiliado) => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}
	return http.get(`Afiliado/${DATOS_CONTACTOS}?codigoAfiliado=${codigoAfiliado}`, configuration)
}

const postFormularioSolicitudAfiliacion = (body) => {
	return http.post(`CRM/${FORMULARIO_SOLICITUD_AFILIACION}`, body)
}

const getFormularioCatalogoSintomaEnfermedadNucleo = () => {
	return http.get(`Afiliado/${FORMULARIO_CATALOGO_SINTOMA_ENFERMEDAD_NUCLEO}`)
}

const getNucleoCarnet =  (codigoAfiliado) => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}
	return http.get(
	  `Afiliado/${GET_NUCLEO_CARNET}?CodigoAfiliado=${codigoAfiliado}`, configuration
	);
  };

export {
	getAfiliado,
	postUpdateTelefonos,
	postUpdateDireccion,
	postUpdateCorreo,
	getDatosPersonales,
	getDatosContactos,
	postFormularioSolicitudAfiliacion,
	getFormularioCatalogoSintomaEnfermedadNucleo,
	getNucleoCarnet
}
