import React from "react";
import Carousel from "react-material-ui-carousel";
import { Box } from "@mui/system";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import colors from "../../../../config/colors";

const Novedades = ({ items }) => {
  return (
    <>
      <Box sx={{ width: '96%' }}>
        <Carousel
          id='carousel'
          NextIcon={<ArrowCircleRightIcon id="arrow-right" style={{ color:colors.primary, fontSize:50}} />}
          PrevIcon={<ArrowCircleLeftIcon id="arrow-left" style={{ color: colors.primary, fontSize:50}} />}
          animation='fade'
          autoPlay
          interval={10000}
          swipe
          navButtonsAlwaysVisible
          navButtonsProps={{
            style: { backgroundColor: "transparent" },
          }}
        >
          {items.map((item) => (
            <Item key={item.id} item={item} />
          ))}
        </Carousel>
      </Box>

    </>
  );
};

function Item({ item }) {
  return (
    <>
      {item.accion ?
        <a id={item.id} target='tab' href={item.accion}>
          <img
            src={item.ubicacion}
            width='100%'
            height={'auto'}
            style={{ borderRadius: 20 }}

          />
        </a>
        : <img
          src={item.ubicacion}
          width='100%'
          height={'auto'}
          style={{ borderRadius: 20 }}

        />}
    </>
  );
}
export default Novedades;
