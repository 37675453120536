import http from "../../config/http";
import { GET_ALL_BITACORA, GET_ESTADOS_SOLICITUDES,GET_TIPO_SOLICITUDES, GET_TIPOS_AUTORIZACIONES } from "../endpoints";

const getAllBitacoraByCode = (codigoAfiliado, token = null) => {
  let configuration;

  if(token)
  {
     configuration = {
        headers: {"Authorization": "Bearer " + token}
      }
      return http.get(`utility/${GET_ALL_BITACORA}?CodigoAfiliado=${codigoAfiliado}`,configuration);
  }

   configuration = {
    headers: {"Authorization": "Bearer " + localStorage.getItem("token")}
  }
  
  return http.get(`utility/${GET_ALL_BITACORA}?CodigoAfiliado=${codigoAfiliado}`,configuration);

};

const getEstadoSolicitudes = () => {
  let configuration;

  configuration = {
    headers: {"Authorization": "Bearer " + localStorage.getItem("token")}
  }

  return http.get(`utility/${GET_ESTADOS_SOLICITUDES}`,configuration);

}

const getTipoSolicitudes = () => {
  let configuration;

  configuration = {
    headers: {"Authorization": "Bearer " + localStorage.getItem("token")}
  }

  return http.get(`utility/${GET_TIPO_SOLICITUDES}`,configuration);

}

const getTipoAutorizaciones = () => {
  let configuration;

  configuration = {
    headers: {"Authorization": "Bearer " + localStorage.getItem("token")}
  }

  return http.get(`utility/${GET_TIPOS_AUTORIZACIONES}`,configuration);

}


export { getAllBitacoraByCode, getEstadoSolicitudes, getTipoSolicitudes,getTipoAutorizaciones };