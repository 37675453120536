import React from "react";

import CustomLabel from "../../common/customLabel";
import colors from "../../../../config/colors";
import { Modal, Box } from "@mui/material";

const ModalConfirmacion = ({ open }) => {
  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: colors.grayFondo,
          border: `1px solid ${colors.primary}`,
          boxShadow: 15,
          p: 4,
          textAlign: "center",
        }}
      >
        <div
          style={{
            padding: 25,
            display: "flex",
            flexDirection: "column",
            backgroundColor: colors.grayFondo,
            alignItems: "center",
          }}
        >
          <CustomLabel
            style={{
              fontFamily: "montserrat",
              color: colors.primary,
              fontSize: 45,
            }}
          >
            <label>
              Se ha realizado la solicitud, esta sera procesada y el
              departamento correspondiente se estara comunicando para continuar
              con el proceso.
            </label>
          </CustomLabel>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalConfirmacion;
