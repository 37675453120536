import React, { useState, useEffect, useContext } from 'react';
import { Modal, Box, Typography, Button, Grid } from '@mui/material';
import CustomTable from '../common/customTable';
import { useNavigate } from "react-router-dom";
import { PrestadorContext } from '../../context/prestadorContext';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import colors from '../../../config/colors';


const VerDetallesPrestadores = ({lista, verMas}) => {
    const { setIdPrestador, setNombrePrestador } = useContext(PrestadorContext);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [cantidadRegistros, setCantidadRegistros] = useState(0);
    const [data, setData] = useState([])
  
    const columnas = [
        {
            id: 'nombre',
            label: 'nombre',
            minWidth: 170
        }
    ]
    
    const acciones = [
        { 
            icono: <Button style={{color: colors.primary}}> <LocalHospitalIcon style={{marginRight: 2}}/> Ver prestador</Button>,
            metodo: (value) => redirectToPrestadores(value)
        }
    ]

    useEffect(() => {
      if (lista?.result?.data?.length > 0) {
          setOpen(true); 
          setCantidadRegistros(lista.result.cantidadRegistros);
          setData(lista.result.data);
      }
  }, [lista]);

    const handleClose = () => setOpen(false);

    const redirectToPrestadores = (value) => {

        setIdPrestador(value?.codigoPrestador)
        setNombrePrestador(value?.nombre)
        navigate(`/consulta/prestadores`);
    };

    const handlerPage = (pagina, registroPorPagina) =>{
      verMas(lista.codigoProcedimiento, pagina, registroPorPagina)
    }

  return (
    
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
  >
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: { xs: '90%', sm: '80%', md: '60%' }, 
      maxWidth: 900,
      bgcolor: 'background.paper',
      borderRadius: 5,
      boxShadow: 24,
      p: 4,
      position: 'relative',
      maxHeight: '80vh', 
      overflowY: 'auto', 
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        top: 10,
        right: 20,
        cursor: 'pointer',
        color: 'green', 
        '&:hover': {
          color: 'red',
        },
        fontWeight: 'bold',
        fontSize: '1.5rem',
      }}
      onClick={handleClose}
    >
      X
    </Box>

    <Typography id="modal-title" variant="h6" component="h2">
      Prestadores de servicios PDSS
    </Typography>

    <Grid item xs={12} marginTop={3}>
      <CustomTable
        columns={columnas}
        acciones={acciones}
        rows={data}
        handlerPage={handlerPage}
        totalDisponibles={cantidadRegistros}
      />
    </Grid>
  </Box>
</Modal>


  );
}

export default VerDetallesPrestadores