import colors from "../../config/colors";

export const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

export const formatToCedula = (text) => {
  return text
    .replace(/[`~#!@$%¢√π÷×¶∆°©®™✓^€£¥•&*()_|+\-=?¡¿;:'"<>\{\}\[\]\\\/]/gi, '')
    .replace(/[a-z]/gi, '')
    .replace(/(\d{3})(\d{1,7})(\d{0,1})/, (_, g1, g2, g3) => {
      let formatted = g1;
      if (g2) formatted += `-${g2}`;
      if (g3) formatted += `-${g3}`;
      return formatted;
    })
    .substr(0, 13);
};

export const cedulaIsValid = text => {

  let result = true;
  if(text.length < 13) {
    result = false
  }

  return result;
}

export const validarCorreoElectronico = text => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  
    if (reg.test(text) === false) {
      console.log('Email is Not Correct');
      return false;
    } else {
      console.log('Email is Correct');
      return true;
    }
  };

  export const currencyFormatter = (number, prefix = '') => {
    return `${prefix}${number.toLocaleString('en-US')}`;
  };

  export const formatToMobile = text => {
    return text.replace(
        /[`~#!@$%¢√π÷×¶∆°©®™✓^€£¥•&*()_|+\-=?¡¿;:'"<>\{\}\[\]\\\/]/gi,
        '',
    ).replace(/[a-z]/gi,'').replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3").substr(0,12);
    }
    

  export const restrictCharacters = text => {
    return text.replace(
      /[`~#!@$%¢√π÷×¶∆°©®™✓^€£¥•&*()_|+\-=?¡¿;:'"<>\{\}\[\]\\\/]/gi,
      '',
    ).replace(/[0-9]/g, '');
  };

  export const containsOnlyNumbers = (str) => {
    return str.replace(/\D/g,'');
  }

  export const getColorPlanByIdTipoPlan = (idTipoPlan) => {
    switch(idTipoPlan) {
      case 160:
      case 156:
      case 44:
      case 1: 
        return colors.colorPlanEspecial
      case 161:
      case 157:
      case 45:
      case 2:
        return colors.colorPlanAvanzado
      case 162:
      case 158:
      case 46:
      case 3:
        return colors.colorPlanMaximo
      case 163:
      case 159:
      case 47:
      case 4:
        return colors.colorPlanPremium
      default:
        return colors.primaryDark
    }
  }


export const removeAccentsAndSpecialChars = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9]/g, "");
} 

export const shortenName = (str, maxLength) => {
    return str.length > maxLength ? str.substring(0, maxLength) : str;
}

export const base64ToBlob = (base64, mime)  =>{
    const byteCharacters = atob(base64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mime });
}

export const convertDateFormat = (date) => {
  date = new Date(date);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
};

export const formatNumberWithCommas = (num) => {
  if (!num) return "";
  

  let cleanNum = num.toString().replace(/[^\d.]/g, "");

  const decimalIndex = cleanNum.indexOf(".");
  if (decimalIndex !== -1) {

    cleanNum = cleanNum.substring(0, decimalIndex + 1) + cleanNum.substring(decimalIndex + 1).replace(/\./g, "");
  }

  const parts = cleanNum.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return parts.join(".");
};

export  const removeCommas = (num) => {
  return num.replace(/,/g, '');
};

export const passportFormat = (text) => {
  return text.replace(/[^a-zA-Z0-9]/g, '');
};

export const createIdTag = (fieldType, text) => {
  if(!text) return fieldType
  return `${fieldType}-${text.replace(/\s+/g, '-')}`
}

export const removeGuion = (text) => {
  return text.replace(/-/g, '');
}