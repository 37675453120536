import React from "react";
import { Grid } from "@mui/material";
import CustomLabel from "../common/customLabel";
import colors from "../../../config/colors";
import NumberFormat from "react-number-format";

const PagoItem = ({
  numeroFactura = null,
  estatus = null,
  fechaFactura = null,
  fechaLimitePago = null,
  montoTotal = null,
}) => {
  return (
    <>
      <div
        style={{
          padding: 5,
          borderRadius: 10,
          paddingInline: 70,
          paddingBottom: 10,
        }}
      >
        <Grid container>
          <Grid item xs={12} md={4} paddingTop={3}>
            <Grid item xs={12}>
              <CustomLabel
                style={{
                  fontFamily: "Montserrat",
                  color: colors.primary,
                  fontWeight: "bold",
                  fontSize: 15,
                }}
              >
                Número factura: <br />
                <label
                  style={{
                    fontSize: 40,
                    color: colors.gray,
                  }}
                >
                  {numeroFactura}
                </label>
              </CustomLabel>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} paddingTop={2}>
            <Grid item xs={12}>
              <CustomLabel
                style={{
                  fontFamily: "Montserrat",
                  color: colors.primary,
                  fontWeight: "bold",
                  fontSize: 15,
                }}
              >
                Fecha Factura:
                <br />
                <label style={{ fontSize: 20, color: colors.gray }}>
                  {fechaFactura}
                </label>
              </CustomLabel>
            </Grid>
            <Grid item xs={12}>
              <CustomLabel
                style={{
                  fontFamily: "Montserrat",
                  color: colors.primary,
                  fontWeight: "bold",
                  fontSize: 15,
                }}
              >
                Fecha Límite Pago:
                <br />
                <label style={{ fontSize: 20, color: colors.gray }}>
                  {fechaLimitePago}
                </label>
              </CustomLabel>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            paddingTop={1}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Grid item xs={12}>
              <NumberFormat
                value={montoTotal}
                prefix={"RD$"}
                thousandSeparator={true}
                displayType={"text"}
                renderText={(value, props) => (
                  <label
                    style={{
                      fontFamily: "Montserrat",
                      color: colors.gray,
                      fontWeight: "bold",
                      fontSize: 45,
                    }}
                  >
                    {value}
                  </label>
                )}
              />

              {/*
                      <CustomLabel
                style={{
                  fontFamily: "Montserrat",
                  color: colors.primary,
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                Monto Total:{" "}
                <label style={{ fontSize: 25, color: colors.gray }}>
                  {montoTotal}
                </label>
              </CustomLabel>
                    */}
            </Grid>
            <Grid item xs={12} paddingTop={1}>
              <div
                style={{
                  backgroundColor:
                    estatus === "Generada" ? colors.primary : colors.warning,
                  width: "100%",
                  borderRadius: 10,
                  height: 50,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  padding: 10,
                }}
              >
                <CustomLabel
                  style={{
                    fontFamily: "Montserrat",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 15,
                  }}
                >
                  Estado de factura: <br />
                  <label style={{ fontSize: 25, color: "white" }}>
                    {estatus}
                  </label>
                </CustomLabel>
              </div>
              {/* <CustomLabel
                style={{
                  fontFamily: "Montserrat",
                  color: colors.primary,
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                Estatus:{" "}
                <label style={{ fontSize: 25, color: colors.gray }}>
                  {estatus}
                </label>
              </CustomLabel>
                */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PagoItem;
