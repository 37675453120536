import http from '../../config/http'
import {
	POST_BITACORA
} from '../endpoints'

const InsBitacora = ( TipoDocumento, Documento, CreadorPor = "WEB", ValorAnterior = null, ValorNuevo = null, IdTipoBitacora) => 
{

    const body = {
		tipoDocumento: TipoDocumento,
		documento: Documento,
		creadorPor: CreadorPor,
		valorAnterior: ValorAnterior,
		valorNuevo: ValorNuevo,
		idTipoBitacora: IdTipoBitacora
	}

    let configuration = {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
   
    return http.post(`${POST_BITACORA}`,body, configuration); 
  }

export{
    InsBitacora
}