import {
	getAllEspecialidades,
	getAllTipoPrestadores,
	getAllRegiones,
	getAllMunicipios,
	getAllProvincias,
	getAllRegimenes,
	getAllGruposPdss,
	getAllSubGruposPdss,
	getCatalogoRespuestaCarnet,
	getAllNacionalidades,
	getAllParentesco,
	getAllTipoDocumentoFormulario,
	getAllSexoFormulario,
	getAllEstadoCivilFormulario,
	getAllFormasPago,
	getAllFrecuenciaPago,
	getAllPlanDisponible,
	getPlanes,
} from './services/drops'

import { 
	getAllPrestadores, 
	getDetallePrestador,
	 getAllRegimenes as getAllRegimenesPrestador, 
	 getRegimenPrestador, 
	 getPrestadoresPorCatalogoPDSS
	} from './services/prestador'

import {
	getValidarIdentificacion,
	postRegistrarCuenta,
	getActivarCuenta,
	postLogin,
	removeJwt,
	getJwt,
	setJwt,
	postSolicitarCambioClave,
	getCambioClave,
	SolicitudCambioCorreo,
	getPreguntasDeSeguridad
} from './services/autentificacion'

import {
	getAfiliado,
	postUpdateTelefonos,
	postUpdateDireccion,
	postUpdateCorreo,
	getDatosPersonales,
	getDatosContactos,
	postFormularioSolicitudAfiliacion,
	getFormularioCatalogoSintomaEnfermedadNucleo,
	getNucleoCarnet
} from './services/afiliado'

import { getAllConsumo, getAllTipoAutorizacion } from './services/consumo'

import { getAllProcedimientos, postCoberturaProcedimiento, getProcedimientos, getPlanesPorRegimen } from './services/cobertura'

import { getAllDashboardSlider, getMenu, getAllDashboardPopUp, getAlertaPersonalizada } from './services/ajustes'

import { 
	getAllFacturaPendientePago, 
	getOrdenCompraGenerada, 
	postOrdenCompraActualizar,
	postGenerarSession 
} from './services/pago'

import { getAllNotificaciones, postModificacionNotificacion } from './services/notificaciones'

import { postAutenticarUsuario } from './services/usuario'

import {
	getTiposDocumentosRequeridos,
	getConsultaNacionalidad,
	getConsultaJce,
	postCrearSolicitudTitular,
	postAutenticarUsuario as postAutenticarUsuarioOperaciones,
	getAllPlanDisponible as getAllPlanDisponibleOperaciones,
	getConsultaCedula,
	getConsultaPasaporte,
	getConsultaTipoTelefono,
	getConsultaMunicipios,
	getConsultaTipoDocumentoProbatorioServicio,
	getConsultaTipoDocumentoProbatorioRegimen,
	getConsultarCiudadano,
	setUserGeolocation,
	EnviarFactura,
	CrearCiudadano,
	CrearSolicitud,
	UploadDocumentoSolicitud,
	CreatPoliza,
	CrearFactura,
	cargarDocumento
} from './services/operaciones'

import {
	postSolicitudPreAutorizacion,
	postSolicitudAfiliacionDependiente,
	postCargarDocumento,
	postSolicitudTraspaso,
	getConsultaSolicitudes,
	postSolicitudesAfiliacionTitular,
	PostFotoPerfil,
	GetFotoPerfil,
	delFotoPerfil,
	postSolicitudPreAutorizacionV2,
	postConsultaSolicitudesPorDocumento,
	postConsultaSolicitudesPorDocumentoV2
  } from './services/CRMServices'

import {
	GetTiposReembolsos,
    GetBancos,
    GetDocumentosTipoReembolsos,
    GetEstadoAutomaticoCRM,
	postSolicitudesReembolsoV2,
    updateSolicitudReembolsoV2
} from './services/reembolso'

import {
	getAllBitacoraByCode,
	getEstadoSolicitudes,
	getTipoSolicitudes,
	getTipoAutorizaciones
} from './services/utility'

import {
	getAllHistorialConsumoGeneral
} from './services/historialConsumo'

import {
	InsBitacora
} from './services/bitacora'

export const drops = {
	getAllTipoPrestadores,
	getAllEspecialidades,
	getAllRegiones,
	getAllMunicipios,
	getAllProvincias,
	getAllRegimenes,
	getAllGruposPdss,
	getAllSubGruposPdss,
	getAllNacionalidades,
	getAllParentesco,
	getAllTipoDocumentoFormulario,
	getAllSexoFormulario,
	getAllEstadoCivilFormulario,
	getAllFormasPago,
	getAllFrecuenciaPago,
	getAllPlanDisponible,
	getPlanes
}

export const prestador = {
	getAllPrestadores,
	getDetallePrestador,
	getAllRegimenesPrestador,
	getRegimenPrestador,
	getPrestadoresPorCatalogoPDSS
}

export const autentificacion = {
	getValidarIdentificacion,
	postRegistrarCuenta,
	getActivarCuenta,
	postLogin,
	removeJwt,
	getJwt,
	setJwt,
	postSolicitarCambioClave,
	getCambioClave,
	SolicitudCambioCorreo,
	getPreguntasDeSeguridad
}

export const afiliado = {
	getAfiliado,
	postUpdateCorreo,
	postUpdateDireccion,
	postUpdateTelefonos,
	getDatosPersonales,
	getDatosContactos,
	postFormularioSolicitudAfiliacion,
	getFormularioCatalogoSintomaEnfermedadNucleo,
	getNucleoCarnet
}

export const consumo = {
	getAllConsumo,
	getAllTipoAutorizacion
}

export const cobertura = {
	getAllProcedimientos,
	postCoberturaProcedimiento,
	getProcedimientos,
	getPlanesPorRegimen
}

export const ajustes = {
	getAllDashboardSlider,
	getMenu,
	getAllDashboardPopUp,
	getAlertaPersonalizada
}

export const pagos = {
	getAllFacturaPendientePago,
	getOrdenCompraGenerada,
	postOrdenCompraActualizar,
	getCatalogoRespuestaCarnet,
	postGenerarSession
}

export const notificaciones = {
	getAllNotificaciones,
	postModificacionNotificacion
}

export const usuario = {
	postAutenticarUsuario
}

export const operaciones = {
	getTiposDocumentosRequeridos,
	postAutenticarUsuarioOperaciones,
	getConsultaNacionalidad,
	getConsultaJce,
	postCrearSolicitudTitular,
	getAllPlanDisponibleOperaciones,
	getConsultaCedula,
	getConsultaPasaporte,
	getConsultaTipoTelefono,
	getConsultaMunicipios,
	getConsultaTipoDocumentoProbatorioServicio,
	getConsultaTipoDocumentoProbatorioRegimen,
	getConsultarCiudadano,
	setUserGeolocation,
	CrearCiudadano,
	CrearFactura,
	CrearSolicitud,
	CreatPoliza,
	EnviarFactura,
	UploadDocumentoSolicitud
}

export const CRMService = {
	postSolicitudPreAutorizacion,
	postSolicitudAfiliacionDependiente,
	postCargarDocumento,
	postSolicitudTraspaso,
	getConsultaSolicitudes,
	postSolicitudesAfiliacionTitular,
	PostFotoPerfil,
	GetFotoPerfil,
	delFotoPerfil,
	postSolicitudPreAutorizacionV2,
	postConsultaSolicitudesPorDocumento,
	postConsultaSolicitudesPorDocumentoV2
  };

export const reembolso = {
	GetTiposReembolsos,
    GetBancos,
    GetDocumentosTipoReembolsos,
    GetEstadoAutomaticoCRM,
	postSolicitudesReembolsoV2,
    updateSolicitudReembolsoV2
};

export const utility = {
	getAllBitacoraByCode,
	getEstadoSolicitudes,
	getTipoSolicitudes,
	getTipoAutorizaciones
}

export const historialConsumo = {
	getAllHistorialConsumoGeneral
}

export const bitacora = {
	InsBitacora
}