import { Dialog, DialogContent, Grid, Paper } from "@mui/material";
import React from "react";
import CustomLabel from "../../common/customLabel";

import {
	dialogContentDetallePrestador,
	gridContainerDetallePrestador,
	labelNombreDetallePrestador,
	labelEspecialidadDetallePrestador,
	labelContactoDetallePrestador,
	paperDetalleContainerDetallePrestador,
} from "../../../global/styles";

import colors from "../../../../config/colors";

import NumberFormat from "react-number-format";

const DialogCoberturaProcedimiento = ({
	open,
	handlerCloseModal,
	cobertura,
	regimenesDisponible
}) => {
	return (
		<Dialog
			open={open}
			onClose={handlerCloseModal}
			maxWidth='sm'
			PaperProps={{
				style: {
					borderRadius: 15,
				},
			}}
		>
			<DialogContent style={{ ...dialogContentDetallePrestador }}>
				<Grid
					container
					style={{
						...gridContainerDetallePrestador,
					}}
				>
					<Grid item xs={9}>
						<Grid container>
							<Grid item xs={12}>
								<CustomLabel
									style={{
										...labelNombreDetallePrestador,
									}}
								>
									{cobertura.procedimientoDescripcion}
								</CustomLabel>
							</Grid>
							<Grid item xs={12} display={'flex'} marginTop={1}>
								<label style={{color: colors.primary, marginRight: 5}}><b>Grupo:</b> </label>
								<CustomLabel
									style={{
										...labelEspecialidadDetallePrestador,
									}}
								>
									{cobertura.grupo}
								</CustomLabel>
							</Grid>
							<Grid item xs={12} marginTop={1} display={'flex'}>
								<label style={{color: colors.primary, marginRight: 5}}><b>SubGrupo: </b></label>
								<CustomLabel
									style={{
										...labelContactoDetallePrestador,
									}}
								>
									{cobertura.subGrupo}
								</CustomLabel>
							</Grid>
							{/*<Grid item xs={12} marginTop={1}>
								<label style={{color: colors.primary, marginRight: 5}}><b>Tambien en:  </b></label>
								<CustomLabel
									style={{
										...labelContactoDetallePrestador,
									}}
								>
									{regimenesDisponible.map(x => <h5>{x}</h5>)}
								</CustomLabel>
							</Grid>*/}
						</Grid>
					</Grid>
				</Grid>
				<Paper
					elevation={10}
					style={{
						...paperDetalleContainerDetallePrestador,
						marginTop: 20,
						height: 100,
						padding: 15,
					}}
				>
					<Grid container>
						<Grid item xs={6}>

							<CustomLabel
								style={{
									...labelContactoDetallePrestador,
									color: colors.primary,
								}}
							>
								<Grid container style={{ display: 'flex', justifyContent: "center", alignItems: "center" }} >
									<Grid item xs={12} md={6}>
										<label><b>Porcentaje cobertura:{" "}</b></label>
									</Grid>
									<Grid item xs={12} md={6} >
										<h2>{cobertura.porcentajeCobertura}%</h2>
									</Grid>

								</Grid>


							</CustomLabel>
						</Grid>
						<Grid item xs={6}>
							<CustomLabel
								style={{
									...labelContactoDetallePrestador,
									color: colors.primary,
								}}
							>
								<Grid container style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
									<Grid item xs={12} md={6} >
										<label><b>Porcentaje de CoPago:{" "}</b></label>
									</Grid>
									<Grid item xs={12} md={6}>
										<h2>{cobertura.porcentajeCoPago}%</h2>
									</Grid>
								</Grid>
							</CustomLabel>
						</Grid>
			{/*			<Grid item xs={6}>
							<CustomLabel
								style={{
									...labelContactoDetallePrestador,
									color: colors.primary,
								}}
							>
								<Grid container style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
									<Grid item xs={12} md={6}>
										<label>Cobertura Total:{" "}</label>
									</Grid>
									<Grid item xs={12} md={6} >
										<NumberFormat
											value={cobertura.coberturaTotal}
											prefix={"$"}
											thousandSeparator={true}
											displayType={"text"}
											renderText={(value, props) => (
												<label style={{ color: colors.gray, fontSize: 20 }}>
													{value}
												</label>
											)}
										/>
									</Grid>
								</Grid>


							</CustomLabel>
						</Grid>
						<Grid item xs={6}>
							<CustomLabel
								style={{
									...labelContactoDetallePrestador,
									color: colors.primary,
								}}
							>
								<Grid container style={{ display: 'flex', justifyContent: "center", alignItems: "center" }} >
									<Grid item xs={12} md={6} >
										<label>Monto Copago:{" "}</label>
									</Grid>
									<Grid item xs={12} md={6}>
										<NumberFormat
											value={cobertura.montoCopago}
											prefix={"$"}
											thousandSeparator={true}
											displayType={"text"}
											renderText={(value, props) => (
												<label style={{ color: colors.gray, fontSize: 20 }}>
													{value}
												</label>
											)}
										/>
									</Grid>
								</Grid>


							</CustomLabel>
						</Grid>*/}
					</Grid>
				</Paper>
			</DialogContent>
		</Dialog>
	);
};

export default DialogCoberturaProcedimiento;
