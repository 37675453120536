import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Grid, InputBase, InputLabel, Typography } from '@mui/material';
import * as apiService from '../../../api'
import CargarDocumentoV2 from '../cargarArchivos/cargaDocumentosV2';
import { formatToCedula, formatToMobile, restrictCharacters, validarCorreoElectronico, containsOnlyNumbers, createIdTag } from '../../utils/helpers';
import { botonNoDialog, botonVerPerfilProfileCard, inputRegistro } from '../../global/styles';
import { useSnackbar } from 'notistack';
import colors from '../../../config/colors';
import CustomQuestionDialog from '../common/Dialog/customQuestionDialog';
import DependientePicker from '../dependiente/dependientePicket';
import CustomDataPicket from '../consulta/solicitudes/CustomDataPicket';
import CustomButton from '../common/customButton';

const FormularioServicios = ({
    campos, 
    titulo,
    isDisable = (value) => {
      return value;
    },
    valueResult = (value) => {
      return value;
    },
    archivosCarga = (value) => {
      return value;
    },
    idFormulario = (value) => {
      return value;
    },
    infoAfiliado,
    dependienteSeleccionado = (value) => {
      return value
    },
    nucleoFamiliar,
    showModal = false,
    formClose = (value) => {
      return value
    } 


  }) => {

  const [camposFormulario, setCamposFormulario] = useState([]);
  const [valuesFormulario, setValuesFormulario] = useState([]);
  const [tituloModal, setTituloModal] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [documentosAutorizacion, setDocumentosAutorizacion] = useState()
  const [datosAfiliacion, setDatosAfiliacion] = useState()
  const [confirmOpen, setConfirmOpen] = useState();
  const [uploadedDocuments, setUploadedDocuments] = useState({});
  const [centroSeleccionado, setCentroSeleccionado] = useState();
  const [centro, setCentro] = useState([]);
  const [documentosFaltantes, setDocumentosFaltantes] = useState();
  const [tipoPreAutorizaciones, setTipoPreAutorizaciones] = useState([]);

  const handleClose = () => {
  setConfirmOpen(true)
  };

  const validarEntrada = (value) => {
   
    if(value.length === 0)
    {
      return false
    }

    return true
  }

  const validateInput = (value, inputType, idField) => {
  if(value != undefined && inputType != undefined)
  {
      let output

      switch(inputType) {
        case 'identify':
          output = formatToCedula(value)
          return output;
        case 'text':
          output = restrictCharacters(value);
          return output;
        case 'email':
          return value;
        case 'telephone':
          output = formatToMobile(value);
          return output;
        case 'NSS':
          output = containsOnlyNumbers(value)
          return output;
   
      }
      
      return output;
    }
      
  }

  const cleanForm = (arr, id, idField) => {
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id);

    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
  
    return arr;
  }

  const setFormValues = (id, value, tipoEntrada) => {

    // Verificamos y validamos los valores insertados para colocar dentro del formulario
    let transformData = [];
    let verifiedValues = validateInput(value, tipoEntrada, id);
  
    // Limpiamos el formulario en caso de que sea necesario
    cleanForm(valuesFormulario, id);
  
    // Agregamos los valores validados al formulario transformado
    transformData.push(...valuesFormulario, {
      id: id,
      value: verifiedValues,
      tipoEntrada: tipoEntrada
    });
  
    // Actualizamos el estado del formulario
    setValuesFormulario(transformData);
  
    // Definimos las estructuras de documentos correspondientes a cada tipo de servicio
    const documentos1 = [
      {
        cantidadDocumentos: 2,
        requerimientosDocumentos: [
          {
            id: 1,
            name: 'Indicación',
            requerido: true,
            formato: ['image/jpeg', 'image/png', 'application/pdf'],
            qtyFiles: 3,
            size: 2097152
          },
          {
            id: 2,
            name: 'Cédula',
            requerido: true,
            formato: ['image/jpeg', 'image/png', 'application/pdf'],
            qtyFiles: 2,
            size: 2097152
          }
        ]
      }
    ];
  
    const documentos2 = [
      {
        cantidadDocumentos: 4,
        requerimientosDocumentos: [
          {
            id: 1,
            name: 'Indicación',
            requerido: true,
            formato: ['image/jpeg', 'image/png', 'application/pdf'],
            qtyFiles: 3,
            size: 2097152
          },
          {
            id: 2,
            name: 'Historia clínica',
            requerido: true,
            formato: ['image/jpeg', 'image/png', 'application/pdf'],
            qtyFiles: 5,
            size: 2097152
          },
          {
            id: 3,
            name: 'Reporte de estudios',
            requerido: true,
            formato: ['image/jpeg', 'image/png', 'application/pdf'],
            qtyFiles: 5,
            size: 2097152
          },
          {
            id: 4,
            name: 'Cédula',
            requerido: true,
            formato: ['image/jpeg', 'image/png', 'application/pdf'],
            qtyFiles: 2,
            size: 2097152
          }
        ]
      }
    ];
  
    const endoscopiaAdjuntos = [
      {
        cantidadDocumentos: 4,
        requerimientosDocumentos: [
          {
            id: 1,
            name: 'Copia de carnet o cédula',
            requerido: true,
            formato: ['image/jpeg', 'image/png', 'application/pdf'],
            qtyFiles: 2,
            size: 2097152
          },
          {
            id: 2,
            name: 'Historia clínica detallada',
            requerido: true,
            formato: ['image/jpeg', 'image/png', 'application/pdf'],
            qtyFiles: 5,
            size: 2097152
          },
          {
            id: 3,
            name: 'Indicación médica del estudio',
            requerido: true,
            formato: ['image/jpeg', 'image/png', 'application/pdf'],
            qtyFiles: 5,
            size: 2097152
          },
          {
            id: 4,
            name: 'Resultados de estudios previos',
            requerido: false,
            formato: ['image/jpeg', 'image/png', 'application/pdf'],
            qtyFiles: 5,
            size: 2097152
          }
        ]
      }
    ];
  
    const ColonoscopiaAdjuntos = [...endoscopiaAdjuntos];
    const ResonanciasAdjuntos = [...endoscopiaAdjuntos];
    const TomografiaAdjuntos = [...endoscopiaAdjuntos];
    const TerapiaFisicaAdjuntos = [
      {
        cantidadDocumentos: 4,
        requerimientosDocumentos: [
          {
            id: 1,
            name: 'Copia de carnet o cédula',
            requerido: true,
            formato: ['image/jpeg', 'image/png', 'application/pdf'],
            qtyFiles: 2,
            size: 2097152
          },
          {
            id: 2,
            name: 'Indicación',
            requerido: true,
            formato: ['image/jpeg', 'image/png', 'application/pdf'],
            qtyFiles: 3,
            size: 2097152
          },
          {
            id: 3,
            name: 'Historia clínica',
            requerido: true,
            formato: ['image/jpeg', 'image/png', 'application/pdf'],
            qtyFiles: 5,
            size: 2097152
          },
          {
            id: 4,
            name: 'Referimiento del especialista',
            requerido: true,
            formato: ['image/jpeg', 'image/png', 'application/pdf'],
            qtyFiles: 5,
            size: 2097152
          }
        ]
      }
    ];
  
    // Mapeo de documentos por tipo de valor para optimizar la lógica condicional
    const documentosMap = {
      "Estudio de imágenes": documentos1,
      "Laboratorios": documentos1,
      "Endoscopia digestiva alta": endoscopiaAdjuntos,
      "Colonoscopia": ColonoscopiaAdjuntos,
      "Resonancias": ResonanciasAdjuntos,
      "Tomografía": TomografiaAdjuntos,
      "Terapia física": TerapiaFisicaAdjuntos,
      "default": documentos2
    };
  
    // Solo ejecutamos la asignación de documentos si es una solicitud de pre-autorización y el id es 4
    if (titulo === 'Solicitud de Pre-autorización' && id === 4) {
      // Asignamos los documentos correspondientes o usamos el valor por defecto
      setDocumentosAutorizacion(documentosMap[value] || documentosMap["default"]);
    }
  };

  const SaveForm = (value) => {
    value.preventDefault();
    if( documentosFaltantes === undefined){
      mostrarMensaje("Favor seleccionar tipo de autorización", "warning")
      return false
    }
    if(documentosFaltantes.length > 0 ){
      mostrarMensaje(`Faltan ${documentosFaltantes.length} tipos documentos por cargar, favor de verificar`, "warning")
      return false
    }
    
     const camposRequeridos = campos.formulario.length;
     const camposFormularios = valuesFormulario.length;
     let cantidadArchivosReq;
     if(titulo === 'Solicitud de Pre-autorización')
     {
        cantidadArchivosReq = documentosAutorizacion[0].cantidadDocumentos
     }
     else
     {
       cantidadArchivosReq = camposFormulario.cantidadDocumentos;
     }
     
     const cantidadArchivosCargados = uploadedDocuments.length;
     let guardarFormulario;

     valuesFormulario.map(x => {
      
      if(x.tipoEntrada === 'email')
      {
          const validarCorreo = validarCorreoElectronico(x.value)
          if(validarCorreo === true){
            guardarFormulario = true
          }
          else{
            guardarFormulario = false
          }
      }

     })

     if(guardarFormulario === false)
     {
        mostrarMensaje("El correo electrónico colocado no es válido, favor de verificar", "warning")
     }
     else
     {
        if(camposRequeridos !== camposFormularios)
        {
          mostrarMensaje("Para poder registrar su solicitud, todos los campos marcados como necesarios deben estar completos", "error")
        }
        else if(cantidadArchivosReq > cantidadArchivosCargados)
        {
          
          mostrarMensaje(`Se han cargado  ${cantidadArchivosCargados} de ${cantidadArchivosReq} archivos requeridos`, 'warning')
        }
        else{
          mostrarMensaje("Se está procesando su solicitud","success");
          valueResult(valuesFormulario)
        }
     }
    
  }

  const mostrarMensaje = (nota, tipo) => {

    enqueueSnackbar( nota, {
      variant: tipo,
      action: (key) => (
        <Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
          X
        </Button>
      )
    })

  }

  useEffect(() => {
    setCamposFormulario(campos);
  },[campos])

  useEffect(() => {
    setTituloModal(titulo)
  }, [titulo])

  useEffect(() => {
    archivosCarga(uploadedDocuments)
  }, [uploadedDocuments])

  useEffect(() => {
    getDatosAfiliado();
    obtenerPrestadores();
    getTipoAutorizaciones()
  
  }, [])

  useEffect(() => {
    if(datosAfiliacion)
    {
   
      if(idFormulario === 4)
      {
          setFormValues(1,datosAfiliacion.datosPersonales.nss, 'NSS' )
          setFormValues(2,datosAfiliacion.datosContacto.correoElectronico, 'email' )
      }
    }
    
  }, [datosAfiliacion])

  const obtenerPrestadores = async(name="") => {
    const params = {
      IdPrestador : 0,
      IdTipoCentro : 0,
      Nombre:name,
      IdEspecialidad: 0,
      IdRegimen : 0,
      IdPlan: 0,
      IdMunicipio : 0,
      IdProvincia : 0,
      NumeroDePagina : 0,
      RegistrosPorPagina : 0
    }
    await apiService.prestador.getAllPrestadores(params)
    .then(res => {
      console.log("res", res)
      ordenarPrestador(res.data.result.data || res.data.result)
    }).catch(err => console.error(err))
  }

  const ordenarPrestador = (data) => {
    let centros = [];
    let medicos = [];

    data.map(x => {
      if(x.tipoPrestador == "Centro")
      {
        centros.push({
          id: x.codigoPrestador,
          nombre: x.nombre,
          especialidad: x.especialidad,
          tipoPrestador: x.tipoPrestador
        })
      }else if(x.tipoPrestador == "Medico")
      {
        medicos.push({
          id: x.codigoPrestador,
          nombre: x.nombre,
          especialidad: x.especialidad,
          tipoPrestador: x.tipoPrestador
        })
      }
    })
     setCentro(centros);
  }

  const cerrarFormulario = (value) => {
    if(value){
      isDisable(true)
      formClose()
    }
    
  }

  const getDatosAfiliado = async() => {
    await apiService.afiliado.getAfiliado(infoAfiliado.codigoAfiliado)
    .then(res => {
      setDatosAfiliacion(res.data.result)
    })
    .catch(err =>{ 
      console.error(err)
    })
  }

  const getTipoAutorizaciones = async() => {
    await apiService.utility.getTipoAutorizaciones()
   .then(res => {
     setTipoPreAutorizaciones(res.data.result)
   })
   .catch(err => console.error(err))
 }

  return (
    <div>
      <Modal
        open={showModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        sx={{paddingTop:{xs:0, md:5, lg:5}, height: 'auto', overflow: 'auto'}}
      >
        <form onSubmit={SaveForm}>
          <Grid container fontFamily={'montserrat'} justifyContent={'center'}>
            <Grid item md={8} xs={12} lg={6} style={{backgroundColor: colors.white}}>
              <Grid marginBottom={7} sx={{ paddingLeft: { md: 5,  xs:2 }, paddingRight: { md: 5, xs:2 }, paddingTop:5}} >
                <Typography variant='h4' fontWeight={700} fontFamily={'montserrat'} color={colors.primary}>
                  {tituloModal}
                </Typography>
                <Typography variant='h6'  fontFamily={'montserrat'} >
                  Complete los datos del formulario a contiuación.
                </Typography>
              </Grid>
              <Grid container spacing={2} sx={{ paddingLeft: { md: 5,  xs:2 }, paddingRight: { md: 5, xs:2 } }}>
              {
                camposFormulario.formulario === undefined ? 'cargando...'
                : camposFormulario.formulario.map(x => (
                  x.tipoEntrada === 'Centro' 
                  ?
                  <Grid
                      
                      key={x.id} 
                      md={6}
                      lg={6}
                      xs={12}
                      item 
                    >
                      <InputLabel
                      id={x.campo}
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: 16,
                      }}
                    >
                      Centro de salud
                    </InputLabel>
                    <CustomDataPicket 
                      title='Centro de Salud'
                      data={centro} 
                      valueSelected={!centroSeleccionado ? '' : !centroSeleccionado.nombre ? centroSeleccionado : centroSeleccionado.nombre}
                      selected={(value) => {
                        setCentroSeleccionado(value)
                        if(value){
                          setFormValues(x.id, value.nombre, "text")
                        }
                      }}
                      required
                      handlerConsulta={(x) => obtenerPrestadores(x)}
                      />
                  </Grid>
                  :
                  x.tipoEntrada === 'NSS' 
                  ?
                  <Grid
                      key={x.id} 
                      md={6}
                      lg={6}
                      xs={12}
                      item 
                    >
                      <InputLabel
                      id={x.campo}
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: 16,
                      }}
                    >
                      Afiliado
                    </InputLabel>
                        <DependientePicker 
                          id={x.id}
                          placeholder={"Seleccione afiliado a pre-autorizar"}
                          dependienteSeleccionado={(x) => {
                            dependienteSeleccionado(x)
                            setFormValues(x.id, x, x.tipoEntrada)
                          }}
                          style={{ ...inputRegistro,
                            height: 55,
                            fontFamily: 'Montserrat',
                            margin:'0 auto',}}
                            nucleoFamiliar={nucleoFamiliar}
                          />
                          
                  </Grid>
                  :
                  x.dropdown ? (
                
                    <Grid
                      key={x.id} 
                      md={6}
                      lg={6}
                      xs={12}
                      item 
                    >
                    <InputLabel
                      id={x.campo}
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: 16,
                      }}
                    >
                      {x.campo}
                    </InputLabel>
                      <select
                        id={x.campo}
                        placeholder={x.placeHolder}
                        style={{
                            ...inputRegistro,
                            height: 55,
                            fontFamily: 'Montserrat',
                            margin:'0 auto',
                          }}
                          onChangeCapture={(value) => {setFormValues(x.id, value.target.value, x.tipoEntrada)}}
                          defaultValue={''}
                      >
                        <option key={0} value={0}>
                          Seleccione un tipo de autorización
                        </option>
                        {Array.isArray(tipoPreAutorizaciones) && tipoPreAutorizaciones.map((item) => (
                          <option id={createIdTag("option",item.descripcion)} key={item.id} value={item.id}>
                            {item.descripcion}
                          </option>
                        ))}
                    </select>
                    </Grid>
                  
                  )
                  :
                    <Grid 
                      key={x.id} 
                      md={6}
                      lg={6}
                      xs={12}
                      item 
                    > 
                      <InputLabel
                        id={x.campo}
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: 16,
                        }}
                      >
                        {x.campo}
                      </InputLabel>
                      <InputBase  
                          id={createIdTag("input", x.campo)}
                          placeholder={x.placeHolder} 
                          variant="outlined"
                          type={x.type}
                          inputProps={{ inputMode: x.tipoEntrada, pattern: x.pattern }}   
                          required
                          style={{...inputRegistro}}
                          color="success" 
                          value = {
                            validarEntrada(valuesFormulario.filter(a => a.id === x.id)) === false ? '' 
                            : valuesFormulario.filter(a => a.id === x.id)[0].value
                          }
                          onChangeCapture ={(value) =>{setFormValues(x.id, value.target.value, x.tipoEntrada)} }
                        
                          
                        />
                    </Grid>
                ))  
              }
              </Grid>
              {
                documentosAutorizacion !== undefined
                ? 
              
                <Grid mt={3} sx={{paddingLeft:{xs:2, md:5}, paddingRight:{xs:5}} }>  
                  <CargarDocumentoV2 
                  onFilesChange={setUploadedDocuments} 
                  requiredDocuments={titulo === 'Solicitud de Pre-autorización' ? documentosAutorizacion === undefined ? [] :documentosAutorizacion[0].requerimientosDocumentos: camposFormulario.requerimientosDocumentos}
                  missingDocuments={setDocumentosFaltantes}
                  /> 
                </Grid>
              
                : <div></div>
              } 
              <Grid container padding={5} spacing={4} >
                <Grid item md={6} xs={12} textAlign={'center'} >
                <CustomButton
                    id="btn-cancelarSolicitud"
                    onClick={handleClose}
                    type={"button"}
                    sx={{
                    ...botonNoDialog,
                    border: `0 solid ${colors.primary}`,
                    cursor: 'pointer',
                    width: 250,
                    height: 50,
                    borderRadius: 100,
                    fontFamily: "Montserrat",
                  }}
                  >
                    Cancelar
                  </CustomButton>
                 
                </Grid>
                <Grid item md={6} xs={12} textAlign={'center'}>
                  <CustomButton
                    id="btn-guardarSolicitud"
                    disabled={!documentosAutorizacion ? true : false}
                    type='sumbit'
                    sx={{
                    ...botonVerPerfilProfileCard,
                    border: `0 solid ${colors.primary}`,
                    cursor: 'pointer',
                    width: 250,
                    height: 50,
                    borderRadius: 100,
                    backgroundColor: !documentosAutorizacion ? colors.primaryOpacity2 : colors.primary,
                    fontFamily: "Montserrat",
                  
                  }}
                  >
                    Guardar
                  </CustomButton>
                </Grid>
                <Grid id="menu-cancelarSolicitud">
                  <CustomQuestionDialog 
                  id="dialog-cancelarSolicitud"
                  title="Cancelar solicitud"
                  open={confirmOpen}
                  setOpen={setConfirmOpen}
                  onConfirm={cerrarFormulario}
                  cancelText={"No"}
                  acceptText={"Si"}
                  >
                    ¿Estás seguro que desea cancelar esta solicitud?
                  </CustomQuestionDialog>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Modal> 
    </div>
  );
}

export default FormularioServicios;