import React, { useEffect, useRef } from 'react';
import QRCodeStyling from 'qr-code-styling';

const QRGenerator = ({ data, options, width=300, height=300 }) => {
  const qrCodeRef = useRef(null);

  useEffect(() => {
    const qrCode = new QRCodeStyling({
      width: width,
      height: height,
      ...options,
      data: data
    });

    qrCode.append(qrCodeRef.current);

  }, [data, options]);

  return <div ref={qrCodeRef} />;
};

export default QRGenerator;
