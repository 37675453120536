import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import {
  dialogContentDetallePrestador,
  gridContainerDetallePrestador,
  dialogNuevaCita,
} from "../../global/styles";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DesktopDatePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import { Select } from "@material-ui/core";

const DialogNuevaCita = ({ open, handlerCloseModal }) => {
  const sucursales = [
    { IdSucursal: 0, descripcion: "Regional 1" },
    { IdSucursal: 1, descripcion: "Regional 2" },
  ];

  const tipoServicios = [
    { IdSucursal: [0, 1], IdTipoServicio: 0, descripcion: "Servicio 1" },
    { IdSucursal: [1], IdTipoServicio: 1, descripcion: "Servicio 2" },
  ];

  const horas = [
    { IdHora: 0, hora: "01:00pm" },
    { IdHora: 1, hora: "02:00pm" },
  ];

  const [formData, setFormData] = useState({
    sucursal: sucursales[0].IdSucursal,
    servicio: null,
    fecha: new Date(),
    hora: null,
  });

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFormData({ ...formData, [name]: value });

   // console.log(formData, event.target);
  };

  const handleFecha = (newValue) => {
    setFormData({ ...formData, fecha: newValue });
  };

  const handlerServiciosFilter = (servicios) => {
    if (servicios.IdSucursal.includes(formData.sucursal)) return servicios;
  };

  const handlerCrearCita = () => {
    handlerCloseModal();
  };

  return (
    <Dialog
      open={open}
      onClose={handlerCloseModal}
      maxWidth='sm'
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 15,
        },
      }}
    >
      <DialogTitle>Agregar Nueva Cita</DialogTitle>
      <DialogContent style={{ ...dialogContentDetallePrestador }}>
        <Grid
          container
          style={{
            ...gridContainerDetallePrestador,
          }}
        ></Grid>
        <Paper
          elevation={0}
          style={{
            ...dialogNuevaCita,
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormControl fullWidth>
                <InputLabel
                  id='sucursal'
                  style={{
                    width: "100%",
                    fontFamily: "Montserrat",
                    fontSize: 20,
                  }}
                >
                  Sucursal
                </InputLabel>
                <Select
                  variant='outlined'
                  labelId='sucursal'
                  value={formData.sucursal || 0}
                  label='Sucursal'
                  onChange={handleChange}
                  name='sucursal'
                >
                  {sucursales.map((item) => (
                    <MenuItem key={item.IdSucursal} value={item.IdSucursal}>
                      {item.descripcion}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <FormControl fullWidth>
                <InputLabel
                  id='tipo_servicio'
                  style={{
                    width: "100%",
                    fontFamily: "Montserrat",
                    fontSize: 20,
                  }}
                >
                  Tipo de Servicio
                </InputLabel>
                <Select
                  variant='outlined'
                  labelId='tipo_servicio'
                  value={formData.servicio || 0}
                  label='Tipo de Servicio'
                  onChange={handleChange}
                  name='servicio'
                >
                  {tipoServicios.filter(handlerServiciosFilter).map((item) => (
                    <MenuItem
                      key={item.IdTipoServicio}
                      value={item.IdTipoServicio}
                    >
                      {item.descripcion}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label='fecha'
                    inputFormat='MM/dd/yyyy'
                    value={formData.fecha}
                    onChange={handleFecha}
                    name='fecha'
                    style={{
                      width: "100%",
                      fontFamily: "Montserrat",
                      fontSize: 20,
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <FormControl fullWidth>
                <InputLabel
                  id='hora'
                  style={{
                    width: "100%",
                    fontFamily: "Montserrat",
                    fontSize: 20,
                  }}
                >
                  Hora
                </InputLabel>
                <Select
                  variant='outlined'
                  labelId='hora'
                  value={formData.hora || 0}
                  label='hora'
                  onChange={handleChange}
                  name='hora'
                >
                  {horas.map((item) => (
                    <MenuItem key={item.IdHora} value={item.hora}>
                      {item.hora}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlerCloseModal}>Cancelar</Button>
        <Button onClick={handlerCrearCita}>Crear</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogNuevaCita;
