import React, { useState } from 'react'
import { Dialog, DialogContent, Grid, InputBase, InputLabel, Button } from '@mui/material'
import { Clear } from '@mui/icons-material'
import CustomLabel from '../common/customLabel'
import { labelValidarRegistro, inputRegistro, cerrarModalRegistro } from '../../global/styles'
import { useForm } from 'react-hook-form'
import colors from '../../../config/colors'
import * as apiService from '../../../api'
import { useSnackbar } from 'notistack'
import NumberFormat from 'react-number-format'
import { documentos } from '../../collection/listas'

const DialogOlvideClave = ({ open, handlerCloseModal, setLoading }) => {
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors, isValid }
	} = useForm({ mode: 'onChange' })

	const { enqueueSnackbar, closeSnackbar } = useSnackbar()

	const handlerEnviar = (data) => {
		const credenciales = {
			identificacion: {
				tipoDocumento: data.tipoDocumento,
				documento: data.noDocumento,
				origen: 'WEB'
			},
			correo: data.correo
		}
		setLoading(true)
		apiService.autentificacion
			.postSolicitarCambioClave(credenciales)
			.then((response) => {
				const resultado = response.data.result.result
				setLoading(false)
				if (resultado.codigoMensaje === 1006 || resultado.codigoMensaje === 1008) {
					enqueueSnackbar(`${resultado.mensaje}`, {
						variant: 'warning',
						action: (key) => (
							<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
								X
							</Button>
						)
					})
				} else if (resultado.codigoMensaje === 1007) {
					enqueueSnackbar(`${resultado.mensaje}, recibira un correo para continuar con el reseteo de contraseña.`, {
						variant: 'success',
						action: (key) => (
							<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
								X
							</Button>
						)
					})
					cerrarModal()
				} else {
					enqueueSnackbar(`${resultado.mensaje}`, {
						variant: 'error',
						action: (key) => (
							<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
								X
							</Button>
						)
					})
				}
			})
			.catch((err) => {
				setLoading(false)
				enqueueSnackbar('Favor intente mas tarde.', {
					variant: 'error',
					action: (key) => (
						<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
							X
						</Button>
					)
				})
			})
	}

	const cerrarModal = () => {
		reset({
			tipoDocumento: 1,
			noDocumento: '',
			correo: ''
		})
		setNoDocumento('')
		setTipoDocumento(1)
		setFormatDocumento('###-#######-#')
		setMaskDocumento('_')

		handlerCloseModal()
	}
	const [noDocumento, setNoDocumento] = useState('')
	const [tipoDocumento, setTipoDocumento] = useState(1)
	const [formatDocumento, setFormatDocumento] = useState('###-#######-#')
	const [maskDocumento, setMaskDocumento] = useState('_')
	const handlerFormatDocumento = (tipo) => {
		if (tipo == 1) {
			setFormatDocumento('###-#######-#')
			setMaskDocumento('_')
		} else {
			setFormatDocumento('#############')
			setMaskDocumento('')
		}
	}

	return (
		<Dialog
			open={open}
			onClose={cerrarModal}
			maxWidth='sm'
			PaperProps={{
				style: {
					borderRadius: 15,
					padding: '20px'
				}
			}}
		>
			<DialogContent>
				<Clear style={{ ...cerrarModalRegistro, color: colors.gray, fontSize: 30,}} onClick={cerrarModal} />
				<form onSubmit={handleSubmit(handlerEnviar)}>
					<Grid container spacing={2} >
						<Grid item xs={12}>
							<CustomLabel style={{ ...labelValidarRegistro, color: colors.primary }}>Recuperar contraseña</CustomLabel>
						</Grid>
						<Grid item xs={12}>
							<InputLabel
								id='tipo-documento-label'
								style={{
									fontFamily: 'Montserrat',
									fontSize: 20,
									color: colors.grayLight
								}}
							>
								Tipo de documento
							</InputLabel>
							<select
								style={{
									borderRadius: 10,
									...inputRegistro,
									height: 55,
									fontFamily: 'Montserrat',
								}}
								id='tipo-documento-select'
								value={tipoDocumento}
								label='tipoDocumento'
								{...register('tipoDocumento', {
									required: 'Debe seleccionar el tipo de documento.'
								})}
								onChange={(event) => {
									setTipoDocumento(event.target.value)
									setNoDocumento('')
									handlerFormatDocumento(event.target.value)
								}}
							>
								{documentos.map((item) => (
									<option key={item.id} value={item.id}>
										{item.descripcion}
									</option>
								))}
							</select>
							<Grid>
								{errors.tipoDocumento && <span style={{ color: 'red' }}> {errors.tipoDocumento.message} </span>}
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<NumberFormat
								id="field-noDocumento"
								value={noDocumento}
								format={formatDocumento}
								mask={maskDocumento}
								style={{ ...inputRegistro, height: 55,}}
								placeholder='Documento'
								{...register('noDocumento', {
									required: 'Por favor, ingrese su documento de identidad.'
								})}
								onValueChange={(values) => {
									setNoDocumento(values.value)
									setValue('noDocumento', values.formattedValue)
								}}
								customInput={InputBase}
							/>
							<Grid>
								{errors.noDocumento && <span style={{ color: 'red' }}> {errors.noDocumento.message} </span>}
							</Grid>
							
						</Grid>
						<Grid item xs={12}>
							
							<InputBase
								id='field-correo'
								style={{ ...inputRegistro}}
								placeholder='Correo electrónico'
								{...register('correo', {
									required: 'Por favor, indique su correo electrónico.',
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
										message: 'Debe ingresar un correo electrónico válido.'
									}
								})}
							/>
							<Grid>
								{errors.correo && <span style={{ color: 'red' }}> {errors.correo.message} </span>}
							</Grid>
							
						</Grid>
						<Grid item xs={12} display='flex' justifyContent='center'>
							<Button
								id='btn-enviar'
								variant='contained'
								type='submit'
								disabled={!isValid}
								fullWidth
								style={{
									backgroundColor: isValid ? colors.primary : colors.grayLight,
									color: 'white',
									fontWeight: 'bold',
									fontFamily: 'montserrat',
									textTransform: 'initial',
									borderRadius: 10,
									maxWidth: 320
								}}
							>
								Solicitar
							</Button>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	)
}

export default DialogOlvideClave
