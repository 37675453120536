import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setConfiguration } from "../redux/features/configuration/configurationSlice";

const useSetTitlePage = (title) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setConfiguration(title));
  }, []);

  const setTitle = (title) => {
    dispatch(setConfiguration(title));
  };

  return { setTitle };
};

export default useSetTitlePage;
