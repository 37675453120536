import React, {useState} from "react";
import logoCuadrado from '../../../assets/logo.jpg';
import { Button, Grid, Modal } from "@mui/material";
import CancelSharpIcon from '@mui/icons-material/CancelSharp';

const TerminosCondiciones = ({
    enable, 
    nombre, 
    cedula, 
    Regimen, 
    NSS,
    disabled = (value) => {
        return (value)
    }

}) => {
    const [modalOpen, setModalOpen] = useState(enable)
    return(
        <Modal open ={true} style={{overflow:'auto'}}>
            <Grid contianer style={{
                backgroundColor:'white',
                fontFamily: 'montSerrat',
                fontSize: 20,
                padding: '10%',
                width: '70%',
                margin: '0 auto',            
                
            }}>
                <div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                       
                    }}>
                        <div>
                            <img src={logoCuadrado} width={100} height={100}/>
                        </div>
                        <div style={{
                            paddingRight:'20%',
                            marginLeft: 20
                        }}>
                            <h>
                                Formulario de autorización para pago de reembolso por transferencia
                            </h>
                        </div>
                    </div>
                    
                    <div style={{textAlign:'justify'}}>
                        <p>
                            Señores
                        </p>
                        <p>
                            <b>Seguro Nacional de Salud - SeNaSa</b>
                        </p>
                        <p>
                            Por medio de la presente, yo, <b>{nombre}</b>, portador de la cédula de identidad y electoral No.<b>{cedula}</b>, afiliado al régimen
                            <b> {Regimen}</b>, con el Número de Seguridad Social (NSS) <b>{NSS}</b>, autorizo a ARS SeNaSa a acreditar el monto resultante de la solicitud
                            de reembolso por servicios de salud sometida por mi persona, a la cuenta y entidad bancaria antes descrita.
                        </p>
                        <p>
                            Declaro que las informaciones contenidas en esta solicitud son correctas y autorizo a ARS SeNaSa a comprobar su veracidad en la forma que estime
                            conveniente.
                        </p>
                        <p>
                            En caso de que la informacion provista en este formulario contenga algún error para la transferencia bancaria SeNaSa no se hace responsable de la misma,
                            la calidad de esta información es responsabilidad del solicitante, por lo que ARS SeNaSa solo es responsable de realizar la solicitud de acuerdo con lo establecido
                            en este documento.
                        </p>
                    </div>
                </div>
                <div onClick={()=> {
                    setModalOpen(false)
                    disabled(false)
                }} style={{
                    display:'flex',
                    justifyContent:'center',
                    marginTop: 50,
                    cursor:'pointer'
                }}>
                   <Button 
                    variant='outlined'
                    color='inherit'
                    style={
                        {
                        textTransform: "initial",
                        fontFamily: "Montserrat",
                        fontSize: 20,
                        marginRight: 40,
                        borderRadius: 5,
                        }
                    }
              >
                Cerrar
              </Button>
                </div>
            </Grid>
          
        </Modal>
    )
}

export default TerminosCondiciones