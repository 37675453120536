import React, { createContext, useState } from 'react';

export const PrestadorContext = createContext();

export const PrestadorProvider = ({ children }) => {
  const [idPrestador, setIdPrestador] = useState(null);
  const [nombrePrestador, setNombrePrestador] = useState('');

  return (
    <PrestadorContext.Provider value={{ idPrestador, setIdPrestador, nombrePrestador, setNombrePrestador }}>
      {children}
    </PrestadorContext.Provider>
  );
};