import React, { useState, useEffect, useContext } from 'react'
import profilePicture from '../../assets/user.png'
import { Badge, Grid, Hidden } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { Popover } from '@mui/material'
import ProfileCard from './profileCard'
import { useSelector, useDispatch } from 'react-redux'
import LogoHorizontal from '../../assets/logoHorizontal.png'
import axios from 'axios'
import { isToday, isYesterday, format, parseISO } from 'date-fns'
import { botonNotificacionHeader, botonProfileHeader, imgProfileHeader } from '../global/styles'

import { useNavigate } from 'react-router-dom'

import UseSetIsLogin from '../hooks/useSetIsLogin'
import ListaNotificaciones from './common/listaNotificaciones'
import { setNotifyCounter } from '../redux/features/notificacion/notificacionSlice'

import * as apiService from '../../api'
import { ConfigurationContext } from '../context/configurationContext'
import colors from '../../config/colors'
import NotificationList from './common/notificationList'
import { useProfilePhoto } from '../context/profilePhotoContext'
import CustomQuestionDialog from './common/Dialog/customQuestionDialog'

const Header = ({ handleDrawerToggle }) => {
	const { isLoginPage } = useContext(ConfigurationContext)
	const [ocultarPerfil, setOcultarPerfil] = useState(false)
	const loggedInUserser = useSelector((state) => state.afiliado.infoAfiliado)

	const [anchorEl, setAnchorEl] = useState(null)
	const [anchorElNotification, setAnchorElNotification] = useState(null)
	const [isOpened, setIsOpened] = useState(false)
	const [notifications, setNotifications] = useState({
		data: [],
		isLoading: true
	})

	const [dateDesde, setDateDesde] = useState('1990-10-04T11:48:14.087');
	const [dateHasta, setDateHasta] = useState()

	const [selectedFile, setSelectedFile] = useState(null)

	const obtenerFotoPerfil = () => {
		const image = localStorage.getItem('selectedImage');
		if(image)
		{
			setSelectedFile(image)
			
		}
	}

	const {profilePhoto, updateProfilePhoto} = useProfilePhoto();
	const [confirmOpen, setConfirmOpen] = useState(false);

	useEffect(() => {
		if(!selectedFile)
		{
			obtenerFotoPerfil()
		}
	
	}, [])

	useEffect(() => {
		if (!isLoginPage && localStorage.getItem('autenticado') === 'false') {
			setOcultarPerfil(true)
		} else {
			setOcultarPerfil(false)
		}
	}, [isLoginPage])

	const getAllNotifications = () => {
		 const { codigoAfiliado } = loggedInUserser
		// const codigoAfiliado = 7488941;
	/*	if (codigoAfiliado) {
			apiService.notificaciones
				.getAllNotificaciones(codigoAfiliado)
				.then((res) => {
					setNotifications({
						isLoading: false,
						data: formatNotificationByDate(res.data.result)
					})
				})
				.catch((error) => console.error(error.message))
		}*/

		if (codigoAfiliado) {
			updateProfilePhoto(codigoAfiliado)
			const Hoy = new Date();
			const Desde = Hoy.setMonth(Hoy.getMonth() - 12);
			setDateDesde(new Date(Desde));
			setDateHasta(`${Hoy.getFullYear()}-${Hoy.getMonth()}-${Hoy.getDay}${"T11:48:14.087"}`)
			apiService.historialConsumo
				.getAllHistorialConsumoGeneral(codigoAfiliado, dateDesde, dateHasta)
				.then((res) => {
					setNotifications({
						isLoading: false,
						data: formatNotificationByDate(res.data.result)
					})
				})
				.catch((error) => console.error(error.message))
		}

		/*
    const url = `http://devova.arssenasa.gov.do:560/api/ClienteNotificacion/GetNotificaciones?IdAfiliado=${codigoAfiliado}`;
    axios
      .get(url)
      .then((res) => {
        setNotifications({
          isLoading: false,
          data: formatNotificationByDate(res.data.result),
        });
      })
      .catch((error) => console.error(error.message));
      */
	}

	const formatNotificationByDate = (notifications) => {
		let notificationCounter = 0
		let result = []
		result.push(notifications)
		/*notifications
			.filter((n) => n.activo)
			.reverse()
			.map((data) => {
				const parsedDate = parseISO(data.fechaRegistro)
				const formattedDate = format(parseISO(data.fechaRegistro), 'dd/MM/yyyy')
				const dataToBeAdded = { ...data, fechaRegistro: formattedDate }

				if (!data.recibido) notificationCounter++

				//Today
				if (isToday(parsedDate)) {
					if (!result.length) result.push({ time: 'Hoy', data: [] })
					result[result.length - 1].data.push(dataToBeAdded)
					return
				}
				//Yesterday
				if (isYesterday(parsedDate)) {
					if (!result.length || result[result.length - 1].time !== 'Ayer') result.push({ time: 'Ayer', data: [] })
					result[result.length - 1].data.push(dataToBeAdded)
					return
				}
				//Older days
				if (!result.length || result[result.length - 1].time !== 'Antiguos') result.push({ time: 'Antiguos', data: [] })
				result[result.length - 1].data.push(dataToBeAdded)
			})
		dispatch(setNotifyCounter(notificationCounter > 9 ? '9+' : notificationCounter))*/
		return result
	}

	useEffect(() => {
		getAllNotifications()
	}, [])

	const dispatch = useDispatch()

	const handleClose = (_) => {
		setAnchorEl(null)
	}
	const handleMenu = (event) => setAnchorEl(event.currentTarget)

	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	const handleCloseNotification = (_) => setAnchorElNotification(null)
	const handleMenuNotification = (event) => {
		dispatch(setNotifyCounter(0))
		setAnchorElNotification(event.currentTarget)
	}

	const handleCloseButton = (notificationData) => {
		const { id } = notificationData
		apiService.notificaciones
			.postModificacionNotificacion({
				id,
				leido: true,
				activo: false,
				recibido: true
			})
			.then((res) => {
				const notiData = notifications.data.filter((n) => {
					n.data = n.data.filter((data) => data.id !== id)
					return n.data.length ? n : false
				})

				setNotifications({ ...notifications, data: notiData })
			})
			.catch((error) => console.error(error.message))

		/*
    const url =
      "http://devova.arssenasa.gov.do:560/api/ClienteNotificacion/ModificarNotificacion";
    axios
      .post(url, { id, leido: true, activo: false, recibido: true })
      .then((res) => {
        const notiData = notifications.data.filter((n) => {
          n.data = n.data.filter((data) => data.id !== id);
          return n.data.length ? n : false;
        });

        setNotifications({ ...notifications, data: notiData });
      })
      .catch((error) => console.error(error.message));

      */
	}

	const openNotification = Boolean(anchorElNotification)
	const idNotification = openNotification ? 'simple-popover' : undefined
/*
	useEffect(() => {
		if (!openNotification && isOpened) {
			const unreadNotifications = notifications.data.filter((n) => {
				return n.data.filter((data) => data.recibido == false)
			})
			const url = 'http://devova.arssenasa.gov.do:560/api/ClienteNotificacion/ModificarNotificacion'
			axios
				.all(
					unreadNotifications.map((n) => {
						n.data.map((n) => {
							axios.post(url, {
								recibido: true,
								activo: n.activo,
								leido: n.leido,
								id: n.id
							})
						})
					})
				)
				.then((data) => {
					setNotificationsRecibidas()
					dispatch(setNotifyCounter(0))
				})
				.catch((error) => console.log(error.message))
		}

		setIsOpened(openNotification)
	}, [openNotification])
*/
	const setNotificationsRecibidas = () => {
		const updatedNotifications = notifications.data.map((n) => {
			n.data = n.data.map((data) => ({ ...data, recibido: true }))
			return n
		})
		setNotifications({ ...notifications, data: updatedNotifications })
	}

	const AddNewNotification = (notification) => {
		let newNotifications = null
		let todaysNotification = notifications.data.filter((n) => n.data.time === 'Hoy')

		if (!todaysNotification.length) {
			todaysNotification.push({ time: 'Hoy', data: [notification] })
			newNotifications = notifications.data.unshift(todaysNotification)
		} else {
			todaysNotification.data.unshift(notifications)
			newNotifications = notifications.data[0] = todaysNotification
		}
		setNotifications(newNotifications)
	}

	const handlerVisto = (data) => {
		const url = 'http://devova.arssenasa.gov.do:560/api/ClienteNotificacion/ModificarNotificacion'
		axios
			.post(url, {
				recibido: true,
				activo: data.activo,
				leido: true,
				id: data.id
			})
			.then((res) => {
				setNotificationsLeidas(data.id)
			})
			.catch((error) => console.log(error.message))
	}

	const setNotificationsLeidas = (id) => {
		const updatedNotifications = notifications.data.map((n) => {
			n.data = n.data.map((data) => {
				if (id === data.id) {
					return { ...data, leido: true }
				} else {
					return data
				}
			})
			return n
		})

		setNotifications({ ...notifications, data: updatedNotifications })
	}

	const TitleComponent = useSelector((state) => state.configuration.Title)

	const notificationList = useSelector((state) => state.notificacion.notificationList)

	const notifyCounter = useSelector((state) => state.notificacion.notifyCounter)

	//  var menuMobile = window.screen.width < 650 ? true : false;
	/*
  let notificationList = [
    { time: "hoy", data: [{ message: "mensaje 1" }, { message: "mensaje 2" }] },
    {
      time: "ayer",
      data: [
        {
          message:
            "mensaje #3 que es mas largo que los mensajes normales, aqui se prueba que sirva el componente de ellipsis",
        },
      ],
    },
  ];
  */

	// console.log(window.screen.width)

	var navigate = useNavigate()
	const { setAuthLogOut } = UseSetIsLogin()

	const handlerCerrarSesion = (event) => {
		if(event === true){
			setAuthLogOut()
			navigate('login', { replace: true })
		}
	}

	const handlerVerPerfil = () => {
		handleClose()
	}

	const handlerCloseSesionDialog = () => {
		setConfirmOpen(true)
	}

	return (
		<>
			<Grid container sx={{paddingTop:{xs:5, md: 0}}}>
				<Hidden smUp>
					<IconButton color='inherit' aria-label='open drawer' edge='start' onClick={handleDrawerToggle} sx={{ mr: 2 }}>
						<MenuIcon style={{ fontSize: 35 }} />
					</IconButton>
				</Hidden>

				<Grid item xs={12} style={{ textAlign: 'center' }} sx={{ marginTop: { xs: -7, md: 0 } }}>
					<img src={LogoHorizontal} width='155' />
					<div style={{ float: 'right' }}>
					<Grid container>
						
             { /*   <Grid item xs={6} md={6}>
					<IconButton
					onClick={handleMenuNotification}
					size='large'
					style={{ ...botonNotificacionHeader }}
					>

                  <Badge badgeContent={notifyCounter} color='primary'>
					<a title="Notificaciones">
						<NotificationsIcon fontSize='large' />
					</a>
				</Badge>
                </IconButton>
                </Grid> */}
						{!ocultarPerfil ? (
							<Grid item xs={6} md={6} display={'flex'} paddingRight={8}>
								<IconButton style={{ ...botonProfileHeader}} onClick={handleMenu}>
									<img src={!profilePhoto ? profilePicture : `data:image/png;base64,${profilePhoto}`} width={50} height={50} style={{ ...imgProfileHeader, objectFit:'cover',}} />
								</IconButton>
							</Grid>
						) : (
							<Grid item xs={12} md={6}>
								<button
									style={{
										fontFamily: 'Montserrat',
										fontSize: 15,
										backgroundColor: colors.primary,
										width: 180,
										height: 50,
										color: 'white',
										borderRadius: 15,
										borderWidth: 1,
										fontWeight: 'bold',
										borderColor: 'white',
										cursor: 'pointer',
										
									}}
									onClick={() => {
										//  navigate("../../login", { replace: true })
										window.location.href = '../../login'
									}}
								>
									Iniciar sesión
								</button>
							</Grid>
						)}
					</Grid>
					</div>
				</Grid>
				<Grid item xs={12}>
					{TitleComponent}
				</Grid>
			</Grid>

			<Popover
				id={idNotification}
				open={openNotification}
				anchorEl={anchorElNotification}
				onClose={handleCloseNotification}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				style={{ top: 60 }}
			>
				{notifications.isLoading && <p>Esta cargando!</p>}
				{!notifications.isLoading && (
					<NotificationList 
						data={notifications}
					/>
					/*<ListaNotificaciones
						lista={notifications.data}
						nombreData='data'
						nombreMensaje='mensaje'
						nombreTiempoTitulo='time'
						nombreTiempo='fechaRegistro'
						mensajeVisto='leido'
						onCloseButton={handleCloseButton}
						onVisto={handlerVisto}
					/>*/
				)}
			</Popover>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
			>
				<ProfileCard handleClosePopover={handlerVerPerfil} onPressExit={handlerCloseSesionDialog} />
			</Popover>
			<CustomQuestionDialog 
              title="Cerrar sesión"
              open={confirmOpen}
              setOpen={setConfirmOpen}
              onConfirm={handlerCerrarSesion}
              cancelText={"No"}
              acceptText={"Si"}
              >
                ¿Estás seguro que desea cerrar la sesión?
			</CustomQuestionDialog>
		</>
	)
}

export default Header
