import React, { useEffect, useRef, useState } from 'react';
import { Card, CardContent, Typography, Button, Box, Grid } from '@mui/material';
import { styled } from '@mui/system';
import logoHorizontal from "../../../assets/logoHorizontal.png"
import QRGenerator from '../common/qrGenerator';
import colors from '../../../config/colors';
import * as apiService from '../../../api'
import { getColorPlanByIdTipoPlan } from '../../utils/helpers';
import html2canvas from 'html2canvas';
import DownloadIcon from '@mui/icons-material/Download';

const StyledCard = styled(Card)({
  maxWidth: 600,
  borderRadius: 12,
});

const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Title = styled(Typography)({
  color: '#4caf50',
  fontWeight: 'bold',
});

const Regimen = styled(Typography)({
    fontWeight: 'bold',
});

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const StyledButton = styled(Button)({
  backgroundColor: '#4caf50',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#45a049',
  },
});

const baseURL = process.env.REACT_APP_API_URL

const qrStyle = {
  dotsOptions: {
    color: colors.primaryDark,
    type: 'rounded'
  },
  cornersSquareOptions: {
    color: colors.primaryDark,
  },
  cornersDotOptions: {
    color: colors.primaryDark,
  },
  backgroundOptions: {
    color: '#fff',
  }
};

const CarneAfiliado = ({
    infoAfiliado,
    regimen,
    planComplementario,
}) => {

    const [idPlan, setIdPlan] = useState(0);
    const captureRef = useRef(null);

    function Capitalize(str) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(' ');
    }
    const nombreCompleto = Capitalize(
      `${infoAfiliado.nombres} ${infoAfiliado.apellidos}`,
    );

    const obtenerIdTipoPlan = async () => {
      const data =  await apiService.afiliado.getAfiliado(infoAfiliado.idAfiliado).then((res) => {
        return res.data.result.datosAfiliacion
      })
      if(data){
        setIdPlan(data[1].idTipoPlan)
      }
    }

    useEffect(() => {
      obtenerIdTipoPlan()
    },[infoAfiliado])
    
    const captureDiv = async () => {
      try {
        downloadClick();
      } catch (error) {
        console.error('Error capturando:', error);
       
      }
    };
    const downloadClick = () => {
      html2canvas(captureRef.current).then(canvas => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'CarnetDigital.png';
        link.click();
      });
    };
  
    return (
      <>
        <StyledCard>
          <CardContent >
            <Grid ref={captureRef} padding={2}>
              <Header mb={2}>
                <img src={logoHorizontal} alt="SeNaSa Logo" width={100}/>
                <Regimen variant="subtitle1" style={{
                  color: getColorPlanByIdTipoPlan(idPlan)
                }}>
                  {planComplementario}
                </Regimen>
              </Header>
              <Typography variant="h6">
                {Capitalize(infoAfiliado.tipoAfiliado)}
              </Typography>
              <Title variant="h7" component="div">
              {nombreCompleto}
              </Title>
              <Typography component="div">
                  <hr />
              </Typography>
              <ContentBox>
                  <Grid display={'flex'} justifyContent={'space-between'}>
                      <Grid>
                          <Typography variant="body2">
                              <Title>
                                  <strong>{infoAfiliado.idAfiliado}</strong>
                              </Title>
                              Número Afiliado
                          </Typography>
                          <Typography variant="body2">
                          <Title>
                              <strong>{infoAfiliado.cedula == null ? 'N/A' : infoAfiliado.cedula}</strong>
                          </Title>
                              Cédula
                          </Typography>
                          <Typography variant="body2">
                          <Title>
                              <strong>{infoAfiliado.nss}</strong><br />
                          </Title>
                              NSS
                          </Typography>
                          <Typography variant="body2">
                          <Title>
                              <strong>{regimen}</strong><br />
                          </Title>
                              Régimen
                          </Typography>
                      </Grid>
                      <Grid padding={2}>
                          <Grid item>
                            <QRGenerator data={`${baseURL}afiliado/verificar/afiliado?CodigoAfiliado=${infoAfiliado.idAfiliado}`} options={qrStyle} width={150} height={150}/>
                          </Grid>
                      </Grid>
                  </Grid>
              </ContentBox>
            </Grid>
            <Grid container spacing={2} display={'flex'} justifyContent="center">
              <Grid item mt={5}>
                <StyledButton
                  id="btn-descargar-carne"
                  variant="contained"
                  startIcon={<DownloadIcon />}
                  onClick={captureDiv}
                >
                  Descargar
                </StyledButton>
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
      </>
    );
};

export default CarneAfiliado;
