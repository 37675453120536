import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./app/redux/store";
import { SnackbarProvider } from "notistack";
import { Fade } from "@mui/material";
import { ProfilePhotoProvider } from "./app/context/profilePhotoContext";

ReactDOM.render(
  <Provider store={store}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        TransitionComponent={Fade}
      >
        <ProfilePhotoProvider>
          <App />
        </ProfilePhotoProvider>
      </SnackbarProvider>
  </Provider>,
  document.getElementById("root")
);
