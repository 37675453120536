import { Grid, Paper } from "@mui/material";

import colors from "../../../config/colors";
import CustomBarra from "../common/customBarra";
import CustomLabel from "../common/customLabel";

import {
  paperContainerCardMonto,
  styleTitleCardMonto,
} from "../../global/styles";

import { format } from "date-fns";

const CardMontoDisponible = ({
  idTipoBalance = 0,
  title,
  styleTitle,
  minHeightBarra,
  maximoBarra,
  actualBarra,
  ButtonComponent,
  elevation = 2,
  styleLabelMontoDisponible,
  fechaRenovacion,
}) => {
  return (
    <Paper
      style={{
        ...paperContainerCardMonto,
      }}
      elevation={elevation}
    >
      <Grid container>
        <Grid item xs={12}>
          <CustomLabel
            style={{
              ...styleTitleCardMonto,
              ...styleTitle,
            }}
          >
            {title}
          </CustomLabel>
        </Grid>
        <Grid item xs={12}>
          <CustomBarra
            minHeight={minHeightBarra}
            maximo={maximoBarra}
            actual={actualBarra}
            styleValue={{ backgroundColor: colors.primary }}
            styleLabel={{ ...styleLabelMontoDisponible }}
          />
        </Grid>
        {fechaRenovacion ? (
          <Grid
            item
            md={6}
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <label
              style={{
                fontWeight: "bold",
                fontFamily: "Montserrat",
                color: colors.grayLight,
              }}
            >
              Fecha de renovación
            </label>
            <label
              style={{ fontFamily: "Montserrat", color: colors.grayLight }}
            >
              {format(new Date(fechaRenovacion), "dd-MM-yyyy")}
            </label>
          </Grid>
        ) : (
          <Grid item md={6} xs={12}></Grid>
        )}

         {idTipoBalance !== 0 && 
         <Grid item md={6} xs={12} marginTop={1}>
          {ButtonComponent}
        </Grid>}
        
      </Grid>
    </Paper>
  );
};

export default CardMontoDisponible;
