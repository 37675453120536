import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { createIdTag } from '../../utils/helpers';

const DependientePicker = ({
	titularPorDefecto = false,
	incluirMayores = false,
	dependienteSeleccionado = value => {
		return value;
	},
	placeholder,
	style,
	data = null,
	nucleoFamiliar
}) => {
	const [valueChoose, setValueChoose] = useState('');
	const [nucleoOrdenado, setNucleoOrdenado] = useState([]);

    const [datosAfiliacion] = useState(useSelector((state) => state.afiliado))

	const obtenerNucleo = () => {
		
		if(nucleoFamiliar){
			const list = [];
			if (Array.isArray(data) && data.length > 0) {
				list.push(data[0]);
				setValueChoose(data[0]);
			}
			if(datosAfiliacion.infoAfiliado.tipoAfiliacion.toLowerCase() !== 'titular'){
				list.push({
					id:  datosAfiliacion.infoAfiliado.codigoAfiliado,
					descripcion: `${datosAfiliacion.infoAfiliado.nombres} ${ datosAfiliacion.infoAfiliado.apellidos}`,
					cedula:  datosAfiliacion.infoAfiliado.cedula,
					nss:  datosAfiliacion.infoAfiliado.nss,
				});
			}
            //Filtro para incluir o no dependientes mayores de edad en el picket
			else if (incluirMayores === false) {
				nucleoFamiliar.map(x => {
					if (x.parentesco === 'TITULAR') {
						list.push({
							id: x.codigoAfiliado,
							descripcion: `${x.nombres} ${x.apellidos}`,
							cedula: x.cedula,
							nss: x.nss,
						});

						//Filtro para Asignar al titular por defecto el el picker
						if (titularPorDefecto === true) {
							setValueChoose({
								id: x.codigoAfiliado,
								descripcion: `${x.nombres} ${x.apellidos}`,
								cedula: x.cedula,
								nss: x.nss,
							});
						}
					}
					if (x.parentesco !== 'TITULAR' && x.mayorEdad === false) {
						list.push({
							id: x.codigoAfiliado,
							descripcion: `${x.nombres} ${x.apellidos}`,
							cedula: x.cedula,
							nss: x.nss,
						});
					}
				});
			} else {
				nucleoFamiliar.map(x => {
					list.push({
						id: x.codigoAfiliado,
						descripcion: `${x.nombres} ${x.apellidos}`,
						cedula: x.cedula,
						nss: x.nss,
					});
				});
			}
    
		setNucleoOrdenado(list);
		}
	}

	const defaultChoose = () => {
		const list = []

		nucleoFamiliar.filter(x => x.codigoAfiliado === datosAfiliacion.infoAfiliado.codigoAfiliado ).map(x => {
			list.push({
				id: x.codigoAfiliado,
				descripcion: `${x.nombres} ${x.apellidos}`,
				cedula: x.cedula,
				nss: x.nss,
			});
		});
		
		dependienteSeleccionado(list);
	}

	useEffect(() => {
		obtenerNucleo()
	}, []);

	useEffect(() => {
       
        if(valueChoose !== undefined)
        {
			const list = [];
			if(nucleoFamiliar){
				nucleoFamiliar.filter(x => `${x.nombres} ${x.apellidos}` === valueChoose ).map(x => {
					list.push({
						id: x.codigoAfiliado,
						descripcion: `${x.nombres} ${x.apellidos}`,
						cedula: x.cedula,
						nss: x.nss,
					});
				});
				dependienteSeleccionado(list);
			}
        }
		
	}, [valueChoose]);

	
	useEffect(() => {
	 obtenerNucleo()
	 defaultChoose()
	 defaultChoose()
	}, [nucleoFamiliar]);

	return (
		
        <div>
            <select
                placeholder={placeholder}
                style={{
                   ...style
                 }}
                id='select-dependiente'
                label='Afiliado'
                onChangeCapture={(e) => {setValueChoose(e.target.value)}}
                defaultValue={''}
            >
            {nucleoOrdenado.map((item) => (
                <option id={createIdTag("option",item.descripcion)} key={item.codigoAfiliado} value={item.codigoAfiliado}>
                {item.descripcion}
                </option>
            ))
			}
            </select>
        </div>
		
	);
};


export default DependientePicker;