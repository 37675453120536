import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import colors from "../../../config/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: `2px solid ${colors.primary}`,
  boxShadow: 24,
  p: 4,
};

const labelStyles = {
  fontFamily: "Montserrat",
};

const CustomSimpleModal = ({
  openModal,
  handlerCloseModal,
  title = "",
  message,
}) => {
  return (
    <div>
      <Modal
        open={openModal}
        onClose={handlerCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            style={{ ...labelStyles, fontWeight: "bold", textAlign: "center" }}
          >
            {title}
          </Typography>
          <Typography
            id='modal-modal-description'
            style={{ ...labelStyles }}
            sx={{ mt: 2 }}
          >
            {message}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default CustomSimpleModal;
