import http from '../../config/http'
import axios from 'axios'
import {
	DROP_ESPECIALIDADES,
	DROP_TIPO_PRESTADORES,
	DROP_REGIONES,
	DROP_MUNICIPIOS,
	DROP_PROVINCIAS,
	DROP_REGIMENES,
	DROP_GRUPOS_PDSS,
	DROP_SUBGRUPO_PDSS,
	DROP_CATALOGO_RESPUESTA,
	DROP_NACIONALIDAD,
	DROP_PARENTESCO,
	DROP_PLANES
} from '../endpoints'

const getAllEspecialidades = () => {
	let token = ''

	if (localStorage.getItem('autenticado') == 'false') {
		token = localStorage.getItem('token-anonimo')
	} else {
		token = localStorage.getItem('token')
	}
	let configuration = {
		headers: { Authorization: 'Bearer ' + token }
	}
	return http.get(`Prestador/${DROP_ESPECIALIDADES}`, configuration)
}

const getAllTipoPrestadores = () => {
	let token = ''

	if (localStorage.getItem('autenticado') == 'false') {
		token = localStorage.getItem('token-anonimo')
	} else {
		token = localStorage.getItem('token')
	}
	let configuration = {
		headers: { Authorization: 'Bearer ' + token }
	}
	return http.get(`Prestador/${DROP_TIPO_PRESTADORES}`, configuration)
}

const getAllRegiones = () => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}
	return http.get(`Afiliado/${DROP_REGIONES}`, configuration)
}

const getAllMunicipios = () => {
	let token = ''

	if (localStorage.getItem('autenticado') == 'false') {
		token = localStorage.getItem('token-anonimo')
	} else {
		token = localStorage.getItem('token')
	}
	let configuration = {
		headers: { Authorization: 'Bearer ' + token }
	}
	return http.get(`Afiliado/${DROP_MUNICIPIOS}`, configuration)
}

const getAllProvincias = () => {
	let token = ''

	if (localStorage.getItem('autenticado') == 'false') {
		token = localStorage.getItem('token-anonimo')
	} else {
		token = localStorage.getItem('token')
	}
	let configuration = {
		headers: { Authorization: 'Bearer ' + token }
	}
	return http.get(`Afiliado/${DROP_PROVINCIAS}`, configuration)
}

const getAllRegimenes = () => {
	let token = ''

	if (localStorage.getItem('autenticado') == 'false') {
		token = localStorage.getItem('token-anonimo')
	} else {
		token = localStorage.getItem('token')
	}
	let configuration = {
		headers: { Authorization: 'Bearer ' + token }
	}
	return http.get(`Cobertura/${DROP_REGIMENES}`, configuration)
}

const getAllGruposPdss = () => {
	let token = ''

	if (localStorage.getItem('autenticado') == 'false') {
		token = localStorage.getItem('token-anonimo')
	} else {
		token = localStorage.getItem('token')
	}
	let configuration = {
		headers: { Authorization: 'Bearer ' + token }
	}
	return http.get(`Cobertura/${DROP_GRUPOS_PDSS}`, configuration)
}

const getAllSubGruposPdss = (idGrupo) => {
	let token = ''

	if (localStorage.getItem('autenticado') == 'false') {
		token = localStorage.getItem('token-anonimo')
	} else {
		token = localStorage.getItem('token')
	}
	let configuration = {
		headers: { Authorization: 'Bearer ' + token }
	}
	return http.get(`Cobertura/${DROP_SUBGRUPO_PDSS}?IdGrupo=${idGrupo}`, configuration)
}

const getCatalogoRespuestaCarnet = () => {
	let configuration = {
		headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
	}
	return http.get(`Pago/${DROP_CATALOGO_RESPUESTA}`, configuration)
}

const getAllNacionalidades = () => {
	return http.get(`Afiliado/${DROP_NACIONALIDAD}`)
}

const getAllParentesco = (esMasculino) => {
	return http.get(`Afiliado/${DROP_PARENTESCO}?esMasculino=${esMasculino}`)
}

const getAllTipoDocumentoFormulario = () => {
	return [
		{
			id: 1,
			descripcion: 'Cédula'
		},
		{
			id: 2,
			descripcion: 'Pasaporte'
		},
		{
			id: 3,
			descripcion: 'No aplica'
		}
	]
}

const getAllEstadoCivilFormulario = () => {
	return [
		{
			id: 1,
			descripcion: 'Soltero'
		},
		{
			id: 2,
			descripcion: 'Casado'
		},
		{
			id: 3,
			descripcion: 'Unión libre'
		}
	]
}

const getAllSexoFormulario = () => {
	return ['Masculino', 'Femenino']
}

const getAllFormasPago = () => {
	return [
		{
			codigo: 1,
			descripcion: 'Efectivo'
		},
		{
			codigo: 2,
			descripcion: 'Transferencia'
		},
		{
			codigo: 3,
			descripcion: 'Tarjeta'
		}
	]
}

const getAllFrecuenciaPago = () => {
	return [
		/*
    {
      codigo: 1,
      descripcion: "Mensual",
    },
    */
		{
			codigo: 2,
			descripcion: 'Trimestral'
		},
		{
			codigo: 3,
			descripcion: 'Semestral'
		},
		{
			codigo: 4,
			descripcion: 'Anual'
		}
	]
}

const getAllPlanDisponible = () => {
	return [
		/*
    {
      codigo: 1,
      descripcion: "Especial",
    },
    {
      codigo: 2,
      descripcion: "Avanzado",
    },
    {
      codigo: 3,
      descripcion: "Maximo",
    },
    {
      codigo: 4,
      descripcion: "Premium",
    },
    {
      codigo: 5,
      descripcion: "Pensionados y jubilados",
    },
    */
		{
			codigo: 6,
			descripcion: 'SeNaSa Larimar'
		}
	]
}

const getPlanes = () => {
	
	const token = localStorage.getItem('token')
	
	let configuration = {
		headers: { Authorization: 'Bearer ' + token }
	}

	return http.get(`Cobertura/${DROP_PLANES}`, configuration)
}

export {
	getAllEspecialidades,
	getAllTipoPrestadores,
	getAllRegiones,
	getAllMunicipios,
	getAllProvincias,
	getAllRegimenes,
	getAllGruposPdss,
	getAllSubGruposPdss,
	getCatalogoRespuestaCarnet,
	getAllNacionalidades,
	getAllParentesco,
	getAllTipoDocumentoFormulario,
	getAllSexoFormulario,
	getAllEstadoCivilFormulario,
	getAllFormasPago,
	getAllFrecuenciaPago,
	getAllPlanDisponible,
	getPlanes
}
