import React, { useEffect, useState } from "react";
import CustomLabel from "../../components/common/customLabel";
import useSetTitlePage from "../../hooks/useSetTitlePage";
import { Grid, InputBase, Button, Divider, InputLabel, CircularProgress, Typography } from "@mui/material";
import CustomTable from "../../components/common/customTable";
import UseIconHooks from "../../hooks/useIconHooks";
import { Formularios, estado} from "../../collection/listas";
import { labelDatosPersonalesProfile,inputRegistro,botonBuscarSolicitudes, labelInicioSesionLogin} from "../../global/styles";
import { useNavigate } from "react-router-dom";
import MenuButton from "../../components/menuButton/menuButton";
import FormularioServicios from "../../components/modal/formularioServicios";
import * as apiService from '../../../api'
import ModalLoading from "../../components/common/modalLoading";
import DialogSolicitudes from "../../components/consulta/solicitudes/dialogSoliciudes";
import { useSelector } from "react-redux";
import FormularioReembolso from "../../components/modal/formularioReembolso";
import { useSnackbar } from "notistack";
import colors from "../../../config/colors";
import DependientePicker from "../../components/dependiente/dependientePicket";
import { createIdTag } from "../../utils/helpers";

const Solicitudes = () => {
  var navigate = useNavigate();
  const [formShow, setFormShow] = useState();
  const [textBoxForm, setTextBoxForm] = useState([]);
  const [isEnable, setIsEnable] = useState(false)
  const [isReset, setIsReset] = useState(false)
  const [valuesFormulario, setValuesFormulario] = useState([]);
  const [titulo, setTitulo] = useState('');
  const [tipoSolicitud, setTipoSolicitud] = useState();
  const [fechaDesde, setFechaDesde] = useState();
  const [fechaHasta, setFechaHasta] = useState();
  const [estadoSolicitud, setEstadoSolicitud] = useState('');
  const [solicitudes, setSolicitudes] = useState();
  const [solicitudFiltrada, setSolicitudFiltrada] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [valorDialog, setValorDialog] = useState({})
  const infoAfiliado = useSelector((state) => state.afiliado.infoAfiliado);
  const [archivos, setArchivos] = useState([])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dependienteSeleccionado, setDependienteSeleccionado] = useState();
  const [nucleoFamiliar, setNucleoFamiliar] = useState([]);
  const [dependienteBuscar, setDependienteBuscar] = useState();
  const [numeroCaso, setNumeroCaso] = useState("");
  const [tipoReembolsoList, setTipoReembolsoList] = useState([{}]);
  const [totalRegistrosDisponibles, setTotalRegistrosDisponibles] = useState(0);
  const [codigoAfiliado, setCodigoAfiliado] = useState();

  const mostrarMensaje = (nota, tipo) => {

    enqueueSnackbar( nota, {
      variant: tipo,
      action: (key) => (
        <Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
          X
        </Button>
      )
    })

  }

  const selectForm = (value) => {
    if(value > 0){
      let filter;
      filter = Formularios.find(x => x.id === value);
      setTextBoxForm(filter);
      setFormShow(value)
    }
  }

  useEffect(() => {

    if(textBoxForm.length !== 0)
    {
      setIsEnable(true);
    }
    
  },[textBoxForm])

  useEffect(() => {
    if(isReset === true)
    {
        setTitulo()
        setTextBoxForm()

    }
  },[isReset])

  useEffect(() => {
    if(valuesFormulario.length > 0)
    { 
      setLoading(true)
  
   
      //Solicitud de afiliación de titular
        if(formShow === 1)
        {
          
            const cedula = valuesFormulario.filter(a => a.id === 1)[0].value;
            const nombre = valuesFormulario.filter(a => a.id === 2)[0].value;
            const apellido = valuesFormulario.filter(a => a.id === 3)[0].value;
            const regimen = valuesFormulario.filter(a => a.id === 4)[0].value;
            const email = valuesFormulario.filter(a => a.id === 5)[0].value;
            const telefono = valuesFormulario.filter(a => a.id === 6)[0].value;
            const contratoAfiliado = infoAfiliado.codigoAfiliado;

            apiService.CRMService.postSolicitudesAfiliacionTitular(
              cedula, 
              telefono, 
              nombre,
              apellido, 
              email, 
              regimen, 
              contratoAfiliado)
              .then(response => {
                cargarDocumentos(response.data.result, archivos)
              })
              .catch(err => {
                mostrarMensaje("Ha ocurrido un error", 'error')
              })
        }

      //Solicitud de afiliacion de dependiente
        if(formShow === 2)
        {
          const cedula = valuesFormulario.filter(a => a.id === 1)[0].value;
          const nombre = valuesFormulario.filter(a => a.id === 2)[0].value;
          const apellido = valuesFormulario.filter(a => a.id === 3)[0].value;
          const email = valuesFormulario.filter(a => a.id === 4)[0].value;
          const telefono = valuesFormulario.filter(a => a.id === 5)[0].value;
          const celular = valuesFormulario.filter(a => a.id === 6)[0].value;
          const institucion = valuesFormulario.filter(a => a.id === 7)[0].value;
          const contratoAfiliado = infoAfiliado.codigoAfiliado;

          apiService.CRMService.postSolicitudAfiliacionDependiente(
            cedula,
            nombre,
            apellido,
            email,
            telefono,
            celular,
            institucion,
            contratoAfiliado,
          ) .then(response => {
            cargarDocumentos(response.data.result, archivos)
          })
          .catch(err => {
            mostrarMensaje("Ha ocurrido un error", 'error')
          })
        }
      //Solicitud de Pre-autorización
      if(formShow === 4)
      {
        const nss = dependienteSeleccionado[0].nss;
        const email = valuesFormulario.filter(a => a.id === 2)[0].value;
        const centro = valuesFormulario.filter(a => a.id === 3)[0].value;
        const tipoAutorizacion = valuesFormulario.filter(a => a.id === 4)[0].value;
        const descripcion = valuesFormulario.filter(a => a.id === 5)[0].value;
        const codigoSolicitante = dependienteSeleccionado[0].id;
        const contratoAfiliado = infoAfiliado.codigoAfiliado;

       apiService.CRMService.postSolicitudPreAutorizacionV2(
        nss,
        email,
        centro,
        tipoAutorizacion,
        descripcion,
        contratoAfiliado,
        codigoSolicitante,
        archivos
       )
       .then(response => {
        if(response.data.result)
        {        
          
          mostrarMensaje(`Se ha creado el caso ${response.data.result}`, 'success')
          setTimeout(() =>  window.location.reload(true), 2000)
          setLoading(false)
        
        } else {
          setLoading(false)
          mostrarMensaje("Ha ocurrido un error", 'error')
        }

       })
       .catch(err => {
        setLoading(false)
        mostrarMensaje("Ha ocurrido un error", 'error')
      })
      }
    }
  },[valuesFormulario])

  useEffect(() => {
    obtenerTiposReembolsos()
    handlerSearch()
    obtenerNucleoFamiliar(infoAfiliado.codigoAfiliado)
    limitDates();
   
  },[])

  const limitDates = () => {
    const fechaDesde = document.getElementById('FechaDesdeId');
    const fechaHasta = document.getElementById('FechaHastaId');
    
    if (fechaDesde) {
      fechaDesde.max = new Date().toISOString().split('T')[0];
    }
  
    if (fechaHasta) {
      fechaHasta.max = new Date().toISOString().split('T')[0];
    }
  }

  const handlerSearch = () => {
    if(validateDateLimit(fechaDesde, fechaHasta))
    {
      setLoading(true);

      let codigoAfiliado;
      dependienteBuscar  ? codigoAfiliado = dependienteBuscar : codigoAfiliado = infoAfiliado.codigoAfiliado
      
      consultarSolicitudes(3, codigoAfiliado, tipoSolicitud, estadoSolicitud, fechaDesde, fechaHasta)
      setCodigoAfiliado(codigoAfiliado)
     
      setLoading(false)

    }else {

      mostrarMensaje("Las busquedas no pueden superar los 6 meses.","warning")

    }
  }

  const ordernarSolicitudes = (data) => {
    let array = data;
    let arrayResult = [];
    array.forEach((x) => {
      if (x.estatusReembolso === 'Devuelto') {
        x.estatus = x.estatusReembolso;
      }
      if (x.estatusReembolso !== 'Devuelto') {
        x.descripcionTramiteGSAUP =  x.respuestasGSAUP;
      }
     
    });
     setSolicitudes(array);
  }

  const cargarDocumentos = ( ticket, file) => {
    if(file)
    {
      
      apiService.CRMService.postCargarDocumento(ticket,file)
      .then(response => {
      setLoading(false)
      window.location.reload(true);
      }).catch(err => {
          mostrarMensaje("Ha ocurrido un error en la carga de archivos", 'error')
          setLoading(false)
      })
    } 
    else {
      setLoading(false)
      window.location.reload(true);
    }
  }

  const TitleSolicitud = (
    <>
     
  
      <CustomLabel style={{ ...labelDatosPersonalesProfile }}>
        Gestión de Solicitudes
      </CustomLabel>
      <Divider />
      <br />
      <br />
    </>
  );

  useSetTitlePage(TitleSolicitud);

  const columnas = [
    {
      id: "numeroCaso",
      label: "Caso",
      minWidth: 170,
    },
    {
      id: "titulo",
      label: "Tipo de solicitud",
      minWidth: 170,
    },
    {
      id: "fechaApertura",
      label: "Fecha",
      minWidth: 170,
    },
    {
      id:'descripcionTramiteGSAUP',
      label:"Estado de atención",
      minWidth: 170,
    },
    {
      id:"estatus",
      label: "Estado",
      minWidth: 170,
    },
  ];

  const acciones = [
    {
      icono: UseIconHooks("visibility", {
				style: { color: colors.primary }
			}),
      metodo: (value) => {
        setValorDialog(value)
        apiService.bitacora.InsBitacora(3,infoAfiliado.codigoAfiliado.toString(),'WEB',null,`Consulta del caso: ${value.numeroCaso}`,13)
        setOpen(true);
      },
      label: 'Ver detalle'
    },
  ];

  useEffect(() => {
    if (localStorage.getItem("autenticado") == "false")
      navigate("../../login", { replace: true });
  }, []);

  const obtenerNucleoFamiliar = async(codigoAfiliado) => {
    const nucleo = 
       await apiService.afiliado.getAfiliado(codigoAfiliado)
    .then(res => {
            return res.data.result.nucleoFamiliar.participantes
        })
        .catch((err) => console.log(err))

    setNucleoFamiliar(nucleo)
}

const filtrarPorDependiente = (data) => {
  if(data.length > 0){
      console.log("data", data)
      setLoading(true)
      const codigoAfiliado = data[0].id;
      console.log("codigoAfiliado", codigoAfiliado)
      setDependienteBuscar(data[0].id)
      consultarSolicitudes(3, codigoAfiliado, tipoSolicitud, estadoSolicitud, fechaDesde, fechaHasta)
      setCodigoAfiliado(codigoAfiliado)
  }
}

const cerrarFormularios = () => {
  setFormShow(0)
}
const handlerFechaDesde = (event) => {
  if(new Date(event.target.value) > new Date()){
    mostrarMensaje("No esta permitido el seleccionar fechas futuras",'error')
    setFechaDesde(new Date())
  }
  else{
    setFechaDesde(event.target.value)
  }
   
}

const handlderFechaHasta = (event) => {
  if(new Date(event.target.value) > new Date()){
    mostrarMensaje("No esta permitido el seleccionar fechas futuras",'error')
    setFechaHasta(new Date())
  }
  else{
    setFechaHasta(event.target.value)
  }
   
}

const validateDateLimit = (dateFrom, dateTo) => {
  let isValid = true;

  const startDate = new Date(dateFrom);
  const endDate = new Date(dateTo);
  const monthsDiff = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());

  if (monthsDiff > 6) {
    isValid = false;
  }

  return isValid;
};


const consultarSolicitudes = async(tipoDocumento, codigoAfiliado, tipoSolicitud, estado, fechaDesde, fechaHasta, numeroPagina=0, registroPorPagina=5) => {
  let fDesde = fechaDesde;
  let fHasta = fechaHasta;

  if(!fDesde && !fHasta){
    fHasta = new Date();
    fDesde = new Date();
    fDesde.setDate(fHasta.getDate() - 180);
  }
  await apiService.CRMService.postConsultaSolicitudesPorDocumentoV2(tipoDocumento, codigoAfiliado, tipoSolicitud, estado, numeroCaso, fDesde, fHasta,numeroPagina,registroPorPagina)
  .then(response => {
    ordernarSolicitudes(response.data.result.data);
    setTotalRegistrosDisponibles(response.data.result.cantidadRegistros);
    setLoading(false)
  })
  .catch(err =>{ 
    console.error(err)
    setLoading(false)
  })

}

const obtenerTiposReembolsos = async()=> {
  await apiService.utility.getTipoSolicitudes()
    .then(res => setTipoReembolsoList(res.data))
    .catch(err => console.error(err))
}

const handlerPage = (pagina, registroPorPagina) =>{
  setLoading(true)
  consultarSolicitudes(3, codigoAfiliado,tipoSolicitud,estadoSolicitud,fechaDesde,fechaHasta, pagina, registroPorPagina)
}

const handlerNumeroCaso = (value) => {
  if(value){
    const order = solicitudes.filter(x => x.numeroCaso === value);
    setNumeroCaso(value)
    setSolicitudFiltrada(order)
  }
  else{
    setSolicitudFiltrada()
  }
}

  return (
    <>
    <Grid marginBottom={5}>
      <MenuButton 
          idFormulario={selectForm}
          nombreFormulario={setTitulo}
          reset={isReset}
        />
    </Grid>
    <Grid marginBottom={3}>
      <Typography variant='h5' gutterBottom component='div' style={{ ...labelInicioSesionLogin }}>
        Historial de autorizaciones
      </Typography>
    </Grid>
      <Grid container spacing={2} width={'95%'} >
        <Grid item md={4} lg={2} xs={12}>
          <InputLabel
                id='numero_cuenta'
                style={{
                  width: "100%",
                  fontFamily: "Montserrat",
                  fontSize: 20,
                  
                }}
                >
                  Tipo de solicitud
            </InputLabel>
          <select
              placeholder='Tipo de solicitud'
              style={{
                ...inputRegistro, backgroundColor: "white", height: 55

              }}
                    id='select-tipo-solicitud'
                    label='tipoDocumento'
                    onChange={(event) => {
                      setTipoSolicitud(event.target.value)
                    }}
                  >
                    {tipoReembolsoList.map((item) => (
                      <option id={createIdTag("option", item.name)} key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
            </select>
        </Grid>
        <Grid item lg={2} md={4} xs={6}>
        <InputLabel
              id='numero_cuenta'
              style={{
                width: "100%",
                fontFamily: "Montserrat",
                fontSize: 20,
                
              }}
              >
                Fecha desde
          </InputLabel>
          <InputBase
            id="FechaDesdeId" 
            type="date"
            style={{ ...inputRegistro, backgroundColor: "white" }}
            placeholder='Desde'
            onChange={handlerFechaDesde}
            value={fechaDesde}
          />
        </Grid>
        <Grid item lg={2} md={4} xs={6}>
        <InputLabel
              id='numero_cuenta'
              style={{
                fontFamily: "Montserrat",
                fontSize: 20,
                
              }}
              >
                Fecha hasta
          </InputLabel>
          <InputBase
            id="FechaHastaId"
            type="date"
            style={{ ...inputRegistro, backgroundColor: "white" }}
            placeholder='Hasta'
            onChange={handlderFechaHasta}
            value={fechaHasta}
          />
        </Grid>
        <Grid item lg={2} md={4} xs={6}>
        <InputLabel
              id='numero_cuenta'
              style={{
                fontFamily: "Montserrat",
                fontSize: 20,
                
              }}
              >
                Estado
          </InputLabel>
        <select
              placeholder='Tipo de solicitud'
              style={{
                ...inputRegistro, backgroundColor: "white" , height: 55
              }}
                    id='select-estado'
                    label='tipoDocumento'
                    onChange={(event) => { 
                      setEstadoSolicitud(event.target.value)
                    }}
                  >
                    {estado.map((item) => (
                      <option id={createIdTag("option",item.name)} key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
            </select>
        </Grid>
        <Grid item lg={2} md={4} xs={12}>
        <InputLabel
              id='Caso'
              style={{
                width: "100%",
                fontFamily: "Montserrat",
                fontSize: 20,
                
              }}
              >
                Caso
          </InputLabel>
          <InputBase
            id="input-caso"
            style={{ ...inputRegistro, backgroundColor: "white" }}
            placeholder='Caso'
            onChange={(e) => handlerNumeroCaso(e.target.value)}
          />
        </Grid>
        {
          nucleoFamiliar.length > 1 
          ?
          infoAfiliado.tipoAfiliacion.toLowerCase() === 'titular' 
          ?<Grid item lg={2} md={6} xs={12}>
          <InputLabel
                id='numero_cuenta'
                style={{
                  width: "100%",
                  fontFamily: "Montserrat",
                  fontSize: 20,
                  
                }}
                >
                  Filtrar resultados
            </InputLabel>
            <DependientePicker 
                placeholder={"Seleccione afiliado a pre-autorizar"}
                dependienteSeleccionado={(x) => {
                  filtrarPorDependiente(x)
                }}
                style={{ ...inputRegistro,
                  height: 55,
                  fontFamily: 'Montserrat',
                  margin:'0 auto',}}
                  nucleoFamiliar={nucleoFamiliar}
                />
          </Grid>
          :
          <div>

          </div>
          : 
          <div>

          </div>
        }
        
        <Grid item lg={2} md={6} xs={12}>
          <Button
            id="btn-Buscar"
            fullWidth
            variant='contained'
            style={{ ...botonBuscarSolicitudes, height: 55, marginTop:25 }}
            onClick={() => handlerSearch()}
          >
            
            Buscar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <CustomTable columns={columnas} acciones={acciones} rows ={!solicitudFiltrada ? solicitudes : solicitudFiltrada} totalDisponibles={totalRegistrosDisponibles} handlerPage={handlerPage}/>
        </Grid>
      </Grid>
      <DialogSolicitudes 
        open={open}  
        data={valorDialog}
        handlerCloseModal={(value) => setOpen(value)}
        />
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop:10
        
      }
      }>
        {
          loading ?  <CircularProgress/> : <></>
        }
         
      </div>
      
      {

        isEnable === false ? '' 
        : formShow === 3 
        ? (
        <FormularioReembolso 
          campos={textBoxForm} 
          isEnable = {isEnable}
          isDisable = {setIsEnable}
          isReset = {setIsReset}
          valueResult = {setValuesFormulario}
          titulo={titulo}
          archivosCarga={setArchivos}
          infoAfiliado={infoAfiliado}
          showModal={formShow === 3 ? true : false}
          formClose={cerrarFormularios}
        />)
        :  formShow === 4 
        ?(
        <FormularioServicios 
          campos={textBoxForm} 
          isEnable = {isEnable}
          isDisable = {setIsEnable}
          isReset = {setIsReset}
          valueResult = {setValuesFormulario}
          titulo={titulo}
          archivosCarga={setArchivos}
          infoAfiliado={infoAfiliado}
          dependienteSeleccionado={setDependienteSeleccionado}
          nucleoFamiliar={nucleoFamiliar}
          showModal={formShow === 4 ? true : false}
          formClose={cerrarFormularios}
         
        />
        )
        : ''
      }
       <ModalLoading open={loading}  /> 
    </>
  );
};

export default Solicitudes;
