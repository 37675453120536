import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Padding } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import colors from "../../../config/colors";


const InfoLabel = ({
    title, info, style
}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const mostrarMensaje = (nota, tipo) => {
        enqueueSnackbar( nota, {
            variant: tipo,
            action: (key) => (
              <Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
                X
              </Button>
            )
          })
    }
    return(
        <Grid container style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            Padding: 20,
            fontFamily:'Montserrat',
            fontSize: 12,
            color:colors.gray,
            ...style
        }}>
            <Grid>
            <Typography sx={{fontSize:15}}  gutterBottom>
                {title}
            </Typography>
            </Grid>
          {
            !info ? <div></div>
            : <Grid  onClick={()=> mostrarMensaje(info, 'info')}>
                <InfoOutlinedIcon  
                    style={{color: 'green'}}
                />
            </Grid>
          }
           
            
        </Grid>
    )
}

export default InfoLabel;