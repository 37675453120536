import React, { useState } from 'react'

const UseCustomState = (init) => {
	const [value, setValue] = useState(init)

	const toggle = () => {
		setValue(!value)
	}

	return { value, setValue, toggle }
}

export default UseCustomState
