import React, { useEffect, useState, useContext } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Header from '../components/header'
import Menu from '../components/menu'
import MainRoute from '../router/mainRoute'
import SideBar from '../components/sideBar'
import { Box } from '@mui/material'
import { iconos } from '../global/iconos'
import { useSelector } from 'react-redux'
import { mainLayout } from '../global/styles'
import * as apiService from '../../api'
import SessionTimeout from '../components/sessionTimeout'
import DialogSessionTimeout from '../components/dialogSessionTimeout'
import { ConfigurationContext } from '../context/configurationContext'

const drawerWidth = 250

const Layout = () => {
	const { isLoginPage } = useContext(ConfigurationContext)
	const [sessionOpenModal, setSessionOpenModal] = useState(false)
	const handlerCloseSessionModal = () => setSessionOpenModal(false)
	const [listado, setListado] = useState([])
	const infoAfiliado = useSelector((state) => state.afiliado.infoAfiliado)
	const [mobileOpen, setMobileOpen] = React.useState(false)

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen)
	}
	
	useEffect(() => {
		apiService.ajustes
			.getMenu()
			.then((response) => {
				let lista = []
				response.data.result.forEach((item) => {
					if (!item.padre) {
						lista.push({
							codigo: item.id,
							descripcion: item.descripcion,
							icono: iconos[item.icono],
							hasChild: false,
							childEvent: {},
							requireLogin: item.requireLogin,
							url: item.enlace ? item.enlace : '',
							children: []
						})
					} else {
						if (
							lista.filter((a) => a.codigo === item.padre)[0].children === undefined ||
							lista.filter((a) => a.codigo === item.padre)[0].children === null
						) {
							lista.filter((a) => a.codigo === item.padre)[0].children = []
						}
						lista.filter((a) => a.codigo === item.padre)[0].hasChild = true

						lista
							.filter((a) => a.codigo === item.padre)[0]
							.children.push({
								codigo: item.id,
								descripcion: item.descripcion,
								icono: iconos[item.icono],
								url: item.enlace,
								requireLogin: item.requireLogin
							})

						if (lista.filter((a) => a.codigo === item.padre)[0].children.filter((a) => a.requireLogin === false).length > 0) {
							lista.filter((a) => a.codigo === item.padre)[0].requireLogin = false
						}
					}
				})
				if (localStorage.getItem('autenticado') === 'false') {
					lista = lista.filter((a) => a.requireLogin === false)
					lista.filter((item) => {
						if (item.children.length > 0) {
							item.children = item.children.filter((a) => a.requireLogin === false)
						}
					})
				}
				setListado(lista)
			})
			.catch((err) => console.log(err))

		return () => {
			setListado([])
		}
	}, [isLoginPage])

	return (
		<Router>
			<Box sx={{ display: 'flex' }}>
				{!isLoginPage && (
					<Box>
						<Menu handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} lista={listado} drawerWidth={drawerWidth} regimen={infoAfiliado.regimen} />
					</Box>
				)}
				<Box component='main' sx={!isLoginPage ? { ...mainLayout } : null}>
					{!isLoginPage ? <Header handleDrawerToggle={handleDrawerToggle} /> : null}

					<Box sx={{ paddingRight: { xs: 2, md: 1 }, paddingLeft: { xs: 1, md: 0 } }}>{MainRoute}</Box>
				</Box>

				{!isLoginPage ? <SideBar /> : null}
			</Box>
			<SessionTimeout isOpen={sessionOpenModal} setOpen={setSessionOpenModal} />
			<DialogSessionTimeout open={sessionOpenModal} handlerCloseModal={handlerCloseSessionModal} />
		</Router>
	)
}

export default Layout
