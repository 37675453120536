import { Grid } from "@mui/material";
import React, { useEffect } from "react";

const DocumentosRequeridosList = (data) => {
    useEffect(()=>{
     //   console.log(data)
    },[data])
    return(
        <Grid container md={12}>
            <ul>
                {
                    data
                    ? ''
                    :data.map(x => (
                        <li key={x.id}>
                            {x.descripcion}
                        </li>
                    ))
                } 
            </ul>
        </Grid>
    )
}

export default DocumentosRequeridosList;