import { Grid, IconButton, Paper, Tooltip } from "@mui/material";
import CustomLabel from "../../common/customLabel";
import colors from "../../../../config/colors";

import {
  labelTituloItemDependiente,
  labelValorItemDependiente,
} from "../../../global/styles";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const ItemDependiente = ({
  dependiente,
  handlerGuardarDependiente,
  handlerEliminarDependiente,
}) => {
  return (
    <>
      <Paper elevation={5} style={{ borderRadius: 15, marginTop: 15 }}>
        <Grid container style={{ padding: 15 }}>
          <Grid item xs={11}>
            <Grid container>
              <Grid item xs={3}>
                <CustomLabel style={{ ...labelTituloItemDependiente }}>
                  Nombre:
                  <label style={{ ...labelValorItemDependiente }}>
                    {dependiente.nombres}
                  </label>
                </CustomLabel>
              </Grid>
              <Grid item xs={3}>
                <CustomLabel style={{ ...labelTituloItemDependiente }}>
                  Apellido:
                  <label style={{ ...labelValorItemDependiente }}>
                    {dependiente.apellidos}
                  </label>
                </CustomLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomLabel style={{ ...labelTituloItemDependiente }}>
                  No. documento:
                  {/* textAlign: "justify", textAlignLast: "center", width:10 */}
                  <label style={{ ...labelValorItemDependiente, overflowWrap: "break-word", wordWrap: "break-word", hyphens: "auto" }}>
                    {dependiente.noDocumento}
                  </label>
                  
                </CustomLabel>
              </Grid>
             
              <Grid item xs={3}>
                <CustomLabel style={{ ...labelTituloItemDependiente }}>
                  Sexo:
                  <label style={{ ...labelValorItemDependiente }}>
                    {dependiente.sexo}
                  </label>
                </CustomLabel>
              </Grid>
              <Grid item xs={3}>
                <CustomLabel style={{ ...labelTituloItemDependiente }}>
                  Parentesco:
                  <label style={{ ...labelValorItemDependiente }}>
                    {dependiente.parentesco}
                  </label>
                </CustomLabel>
              </Grid>
              <Grid item xs={3}>
                <CustomLabel style={{ ...labelTituloItemDependiente }}>
                  Nacionalidad:
                  <label style={{ ...labelValorItemDependiente }}>
                    {dependiente.nacionalidad}
                  </label>
                </CustomLabel>
              </Grid>
              <Grid item xs={3}>
                <CustomLabel style={{ ...labelTituloItemDependiente }}>
                  Fecha de nacimiento:
                  <label style={{ ...labelValorItemDependiente }}>
                    {dependiente.fechaNacimiento}
                  </label>
                </CustomLabel>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {/*
              <CustomLabel style={{ ...labelTituloItemDependiente }}>
              Acciones
            </CustomLabel>
              */}

            <div>
              <Tooltip title='Actualizar dependiente' arrow>
                <IconButton
                  onClick={() => handlerGuardarDependiente(dependiente)}
                >
                  <EditIcon
                    style={{
                      fontSize: 55,
                      color: colors.warning,
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip title='Eliminar dependiente'>
                <IconButton
                  onClick={() => handlerEliminarDependiente(dependiente)}
                >
                  <DeleteIcon
                    style={{
                      fontSize: 55,
                      color: colors.error,
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ItemDependiente;
