import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
  Box,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImageIcon from '@material-ui/icons/Image';
import colors from '../../../config/colors'; 
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import InfoLabel from '../common/infoLabel';
import { useSnackbar } from 'notistack';
import InfoIcon from '@mui/icons-material/Info';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const useStyles = makeStyles((theme) => ({
  uploadSection: {
    border: `2px solid ${colors.primary}`,
    borderRadius: '8px',
    padding: theme.spacing(2),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: theme.spacing(4),
    backgroundColor: 'rgba(239,246,238,255)',
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
    '& h4': {
      fontSize: '1rem',
      margin: theme.spacing(1, 0),
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row', 
    },
  },
  fileItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    border: `1px solid ${colors.primary}`,
    borderRadius: '8px',
    backgroundColor: '#f1f1f1',
    [theme.breakpoints.up('md')]: {
      marginLeft: 15, 
      width: 300,
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 15, 
      width: 350,
    },
  },
  buttonType: {
    marginRight: 10,
    borderRadius: 5,
    backgroundColor: colors.primary,
    height: 50,
    fontSize: 12,
    padding: 5,
    color: colors.white,
    cursor: 'pointer',
  },
  listItemText: {
    fontSize: '0.875rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginLeft: 12,
  },
  fileDetails: {
    display: 'flex',
    alignItems: 'center',
    width: '85%', 
    [theme.breakpoints.up('md')]: {
      marginLeft: 15, 
      width: '90%',
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const CargarDocumentoV2 = ({ onFilesChange, requiredDocuments, missingDocuments }) => {
  const classes = useStyles();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [requiredDropDown, setRequiredDropDown] = useState([]);
  const [missingDocs, setMissingDocs] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleFiles = (files, fileType) => {
    const isValid = validFilesUploaded(uploadedFiles, fileType);

    if (!isValid) {
      return mostrarMensaje(`Se ha superado el límite de adjuntos para documentos de tipo ${fileType}`, "warning");
    }

    const newFilesPromises = Array.from(files).map((file) => {
      let sizeSelectedFile = requiredDropDown.find(x => x.name === fileType)
      if (sizeSelectedFile.size < file.size  ) {
        mostrarMensaje(`El archivo ${file.name} supera el límite de ${convertFromBitsToMb(file.size)}MB y no será añadido.`, "error");
        return null;
      }
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve({
            name: file.name,
            type: fileType,
            fileType: file.type,
            content: event.target.result,
          });
        };
        reader.onerror = () => {
          reject(new Error(`Error al leer el archivo ${file.name}`));
        };
        reader.readAsDataURL(file);
      });
    });
    Promise.all(newFilesPromises)
      .then((newFiles) => {
        const validNewFiles = newFiles.filter((file) => file !== null);
        setUploadedFiles([...uploadedFiles, ...validNewFiles]);
      })
      .catch((error) => {
        console.error(error);
        mostrarMensaje(`Error al procesar los archivos: ${error.message}`, "error");
      });
  };

  const handleFileChange = (event, fileType) => {
    handleFiles(event.target.files, fileType);
  };

  const handleDeleteFile = (fileName) => {
    setUploadedFiles(uploadedFiles.filter((file) => file.name !== fileName));
  };

  const validFilesUploaded = (files, fileType) => {
    const filteredFiles = files.filter((x) => x.type === fileType);
    const requiredPerFile = requiredDropDown.filter((x) => x.name === fileType);

    if (filteredFiles.length >= requiredPerFile[0]?.qtyFiles) {
      return false;
    }
    return true;
  };

  const checkMissingDocuments = () => {
    const missingDocs = requiredDropDown
      .filter((doc) => doc.requerido)
      .filter((doc) => !uploadedFiles.some((file) => file.type === doc.name));

    setMissingDocs(missingDocs);
    missingDocuments(missingDocs);
  };

  const mostrarMensaje = (nota, tipo) => {
    enqueueSnackbar(nota, {
      variant: tipo,
      action: (key) => (
        <Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
          X
        </Button>
      ),
    });
  };

  useEffect(() => {
    onFilesChange(uploadedFiles);
    checkMissingDocuments();
  }, [uploadedFiles]);

  useEffect(() => {
    if (requiredDocuments) {
      setRequiredDropDown(requiredDocuments);
      console.error(requiredDocuments)
    }
  }, [requiredDocuments]);

  useEffect(() => {
    checkMissingDocuments();
  }, [requiredDropDown]);

  const convertFromBitsToMb = (value) => {
    return Math.trunc((value / 1024) / 1024);
};

  return (
    <Grid style={{ margin: 'auto' }}>
      <Grid>
        <Grid style={{ fontFamily: 'montserrat' }}>
          {requiredDropDown.length === 0 ? null : (
            <Grid>
              <Grid style={{ lineHeight: 0.2, display: 'flex', flexDirection: 'column' }}>
                <Grid>
                  <InfoLabel
                    title={"Documentos solicitados"}
                    info={"Subir los documentos solicitados"}
                  />
                </Grid>
                <Grid>
                  <Typography style={{ fontWeight: 600, fontSize: 18 }}>
                    Requerido (<span style={{ color: 'red' }}>*</span>)
                  </Typography>
                  <hr />
                </Grid>
              </Grid>
              <Grid style={{ marginTop: 10 }}>
                {requiredDropDown.map((x) => (
                  <Grid key={x.id}>
                    <Typography variant="h8" gutterBottom>
                      <b>{x.name}</b>
                      {x.requerido ? <span style={{ color: 'red' }}>*</span> : null}
                    </Typography>
                    <Box key={x.id} sx={{ flexDirection: { xs: 'column', md: 'row' } }} className={classes.uploadSection}>
                      <Grid>
                        <label htmlFor={`file-input-${x.id}`}>
                          <Button
                            variant="contained"
                            component="span"
                            fullWidth
                            className={classes.buttonType}
                            startIcon={<UploadFileRoundedIcon />}
                          >
                            Subir
                          </Button>
                        </label>
                        {
                          x.qtyFiles > 0 ?
                          <Typography variant="body2" gutterBottom style={{ fontSize: '12px', marginTop: 10, display:'flex', alignItems:'center' }}>
                             <AttachFileIcon sx={{marginRight: 1, color:colors.grayDarkOpacity, fontSize:20}} /> Máximo {x.qtyFiles} archivos
                          </Typography>
                          
                          : <></>
                        }
                         <Typography variant="body2" gutterBottom style={{ fontSize: '12px', marginTop: 10 , display:'flex', alignItems:'center'}}>
                           <InfoIcon sx={{marginRight: 1, color:colors.grayDarkOpacity, fontSize:20}} />Tamaño máximo: <b style={{marginLeft:2}}>{convertFromBitsToMb(x.size)}MB</b>
                          </Typography>
                      </Grid>
                      <input
                        type="file"
                        accept=".jpg,.png,.pdf"
                        onChange={(e) => handleFileChange(e, x.name)}
                        style={{ display: 'none' }}
                        id={`file-input-${x.id}`}
                      />
                      <List>
                        {uploadedFiles.filter((file) => file.type === x.name).map((file) => (
                          <ListItem key={file.name} className={classes.fileItem}>
                            <Grid className={classes.fileDetails}>
                              {file.fileType.startsWith('image/') ? (
                                <ImageIcon className={classes.icon} />
                              ) : (
                                <PictureAsPdfIcon className={classes.icon} />
                              )}
                              <ListItemText
                                primary={file.name.substring(0, 20) + '...'}
                                classes={{ primary: classes.listItemText }}
                              />
                            </Grid>
                            <ListItemSecondaryAction>
                              <IconButton edge="end" onClick={() => handleDeleteFile(file.name)}>
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CargarDocumentoV2;
