import React from "react";
import CustomLabel from "../customLabel";

import colors from "../../../../config/colors";
import { labelRegistro } from "../../../global/styles";

const LabelRegistro = ({ style }) => {
  return (
    <CustomLabel style={{ ...labelRegistro, ...style, lineHeight: 1 }}>
      OFICINA <br /> VIRTUAL PARA <br />
      <label style={{ color: colors.primary }}>AFILIADOS </label>
    </CustomLabel>
  );
};

export default LabelRegistro;
