import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { FormControl, FormHelperText, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { formatToCedula, containsOnlyNumbers,removeGuion, cedulaIsValid, formatNumberWithCommas, removeCommas,passportFormat, formatToMobile } from '../../utils/helpers';
import { botonNoDialog, botonVerPerfilProfileCard } from '../../global/styles';
import * as apiService from '../../../api'
import InfoLabel from '../common/infoLabel';
import CustomRadioButton from '../consulta/solicitudes/customRadioButton';
import DocumentosRequeridosList from '../consulta/solicitudes/documentosRequeridosList';
import CustomDataPicket from '../consulta/solicitudes/CustomDataPicket';
import ModalLoading from '../common/modalLoading';
import TerminosCondiciones from './terminosCondiciones';
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux';
import colors from '../../../config/colors';
import CustomQuestionDialog from '../common/Dialog/customQuestionDialog';
import CargarDocumentoV2 from '../cargarArchivos/cargaDocumentosV2';
import CustomButton from '../common/customButton';

const FormularioReembolso = ({
    isEdit = false,
    descripcion,
    numeroTicket,
    isEnable,
    titulo,
    isReset = (value) => {
      return value;
    },
    archivosCarga = (value) => {
      return value;
    },
    formClose = (value) => {
      return value
    } 
  }) => {

    const [open, setOpen] = useState(false);
    const [valuesFormulario, setValuesFormulario] = useState([]);
    const [archivos, setArchivos] = useState([]);
    const [tituloModal, setTituloModal] = useState('');
    const [datosAfiliacion, setDatosAfiliacion] = useState()
    const [nucleoFamiliar, setNucleoFamiliar] = useState([])
    const [fecha, setFecha] = useState();
    const [montoReclamado, setMontoReclamado] = useState()
    const [numeroCuenta, setNumeroCuenta] = useState()
    const [tipoCuenta, setTipoCuenta] = useState('');
    const [motivo, setMotivo] = useState('');
    const [centro, setCentro] = useState([]);
    const [medico, setMedico] = useState([])
    const [centroSeleccionado, setCentroSeleccionado] = useState();
    const [medicoSeleccionado, setMedicoSeleccionado] = useState();
    const [tipoReembolso, setTipoReembolso] = useState([]);
    const [nombreTipoReembolso, setNombreTipoReembolso] = useState('');
    const [nombreAfiliado, setNombreAfiliado] = useState('');
    const [modalLoading, setModalLoading] = useState(false)
    const [banco, setBanco] = useState([]);
    const [bancoSeleccionado, setBancoSeleccionado] = useState('')
    const [documentosRequeridos, setDocumentosRequeridos] = useState();
    const [IdReembolsoSelected, setIdReembolsoSelected] = useState(0)
    const [camposRequeridos, setCamposRequeridos] = useState(0)
    const [nombreTitularCuentaBanco, setNombreTitularCuentaBanco] = useState('');
    const [cedulaTitularCuentaBanco, setCedulaTitularCuentaBanco] = useState();
    const [especialidad, setEspecialidad] = useState('');
    const [verTerminos, setVerTerminos] = useState(false);
    const [aceptarTerminos, setAceptarTerminos] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const infoAfiliado = useSelector((state) => state.afiliado.infoAfiliado);
    const [archivosOpcionales, setArchivosOpcionales] = useState(0)
    const [confirmOpen, setConfirmOpen] = useState();
    const [uploadedDocuments, setUploadedDocuments] = useState({});
    const [documentosFaltantes, setDocumentosFaltantes] = useState();
    const [isValid, setIsValid] = useState(true);
    const [tipoDocumento, setTipoDocumento] = useState("");
    const [telefonoCelular, setTelefonoCelular] = useState();
    const [telefonoResidencial, setTelefonoResidencial] = useState();
    const maxCharacters = 250; 
    const requiredLabel =  <h4 style={{ color: colors.error, padding:0, margin: 0 }}>Este campo es requerido.*</h4>;
    const handleOpen = () => {
      setOpen(true);
    };

    useEffect(() => {
      getdatosAfiliacion();
    }, [])

    const handleClose = () => {
      setConfirmOpen(true)
    };

    const mostrarMensaje = (nota, tipo) => {

      enqueueSnackbar(
      <span dangerouslySetInnerHTML={{ __html: nota }} />, 
      {
        variant: tipo,
        action: (key) => (
        <Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
          X
        </Button>
        )
      }
      );

    }

    const cuentas = [
      {
        id: 1,
        descripcion: 'Ahorro'
      },
      {
        id:2,
        descripcion:'Corriente'
      }
    ]

    const setFormValues = () => {
      let transformData = [
        { id: 1, value: nombreAfiliado },
        { id: 2, value: nombreTipoReembolso },
        { id: 3, value: fecha },
        { id: 6, value: montoReclamado },
        { id: 7, value: numeroCuenta },
        { id: 8, value: tipoCuenta },
        { id: 9, value: bancoSeleccionado.nombre },
        { id: 10, value: nombreTitularCuentaBanco },
        { id: 11, value: cedulaTitularCuentaBanco },
        { id: 12, value: motivo }
      ];
    
      if (nombreTipoReembolso === 'Honorarios Médicos') {
        transformData.splice(3, 0, 
          { id: 4, value: medicoSeleccionado.nombre },
          { id: 5, value: medicoSeleccionado.especialidad }
        );
        setCamposRequeridos(12);
      } else {
        transformData.push(
          { id: 13, value: centroSeleccionado.nombre }
        );
        setCamposRequeridos(11);
      }
    
      return transformData;
    };

    const SaveForm = (value) => {
      value.preventDefault();
     
      if(validarFormulario())
      {
        if( documentosFaltantes === undefined){
          mostrarMensaje("Favor seleccionar tipo de reembolso", "warning")
          return false
        }
        if(documentosFaltantes.length > 0 && !isEdit ){
          documentosFaltantes.map(x => {
            mostrarMensaje(`Falta ${x.name} por cargar, favor de verificar`, "error")
          })
        
          return false
        }
      
        const cantidadArchivosCargados = Object.keys(archivos).length;
        const cantidadArchivosOpcionales = archivosOpcionales;
        
        if(isEdit === true)
        {
          setValuesFormulario(setFormValues());
          setModalLoading(true)
          actualizarSolicitudReembolso()
        }
        else if(aceptarTerminos === true)
        {
          if(cantidadArchivosCargados || isEdit)
          {
              setValuesFormulario(setFormValues());
              const camposFormularios = valuesFormulario.length;
    
            if(cantidadArchivosCargados.length === 0 || camposRequeridos !== camposFormularios)
            {
              
              mostrarMensaje(`Para poder registrar su solicitud, todos los campos marcados como necesarios deben estar completos`,'error' )
            }
            
            else if( cantidadArchivosOpcionales > cantidadArchivosCargados && !isEdit )
            {
              mostrarMensaje(`Para poder registrar su solicitud, todos los campos marcados como necesarios deben estar completos`,'error' )
            }
            else{
              value.preventDefault();
              if(isEdit === true){
                setModalLoading(true)
                actualizarSolicitudReembolso()
              }
              else{
                
                setModalLoading(true)
                registrarSolicitudReembolso()
              }
            
            }   
          }
          else
          {
            if(!cantidadArchivosCargados)
            {
              mostrarMensaje(`Para poder registrar su solicitud, todos los campos marcados como necesarios deben estar completos`,'error' )
            }
          
          }
        }
        else {
          mostrarMensaje(`Para continuar debe de aceptar los términos y condiciones.`, 'warning')
        }
      }
    }

    const ordenarPrestador = (data) => {
      let centros = [];
      let medicos = [];
      data.map(x => {
        if(x.tipoPrestador === "Centro")
        {
          centros.push({
            id: x.codigoPrestador,
            nombre: x.nombre,
            especialidad: x.especialidad,
            tipoPrestador: x.tipoPrestador
          })
        }else if(x.tipoPrestador === "Medico")
        {
          medicos.push({
            id: x.codigoPrestador,
            nombre: x.nombre,
            especialidad: x.especialidad,
            tipoPrestador: x.tipoPrestador
          })
        }
      })
      
      setMedico(medicos);
      setCentro(centros);
    }

    const obtenerPrestadores = async(name ="") => {
      const params = {
        IdPrestador : 0,
        IdTipoCentro : 0,
        Nombre:name,
        IdEspecialidad: 0,
        IdRegimen : 0,
        IdPlan: 0,
        IdMunicipio : 0,
        IdProvincia : 0,
        NumeroDePagina : 0,
        RegistrosPorPagina : 0
      }
      await apiService.prestador.getAllPrestadores(params)
      .then(res => {
        ordenarPrestador(res.data.result.data || res.data.result)
      }).catch()
    }

    const obtenerTiposReembolso = () => {
      apiService.reembolso.GetTiposReembolsos()
        .then(response => {
          setTipoReembolso(response.data.result);
        })
        .catch(err => console.error(err));
    };

    const ordenarBancos = (result) => {
      let datosOrdneados = [];
      result.map(x => {
        datosOrdneados.push({
          id:x.codigo,
          nombre: x.descripcion
        })
      })
      setBanco(datosOrdneados)
    }

    const obtenerBancos = () => {
      apiService.reembolso.GetBancos()
      .then(response => {
        ordenarBancos(response.data.result);
      })
      .catch()
    }

    const obtenerDocumentosRequeridos = (idReembolso) => {
      apiService.reembolso.GetDocumentosTipoReembolsos(idReembolso)
      .then(
        res => {
          const dataTansformada = [];
          res.data.result.map(x => {
            dataTansformada.push({
              id:x.codigo,
              name: x.descripcion,
              requerido: x.requerido,
              formato: x.formatoArchivos,
              qtyFiles : x.cantidadPermitida,
              size: x.limiteTamanoArchivo
            }
            )
          })
          setDocumentosRequeridos(dataTansformada);
        }
      ).catch()
    }

    const registrarSolicitudReembolso = async () => {

        const datoSolicitante = nucleoFamiliar.filter(n=>n.nombres+" "+n.apellidos === nombreAfiliado)[0];
        const contrato = datosAfiliacion.datosPersonales.codigoAfiliado; 
        const solicitante = `${datoSolicitante.nombres} ${datoSolicitante.apellidos}`;
        const correoSolicitante = datosAfiliacion.datosContacto.correoElectronico;
        const telefonoSolicitante = !telefonoCelular ? telefonoResidencial : telefonoCelular;
        const tipoReembolso = nombreTipoReembolso
        const fechaServicio = fecha
        const montoRecl = montoReclamado
        const centroSalud = centroSeleccionado === undefined ? '' : centroSeleccionado.nombre
        const medico = medicoSeleccionado === undefined ? ' ' : medicoSeleccionado.nombre
        const especialidad = medicoSeleccionado === undefined ? ' ' : medicoSeleccionado.especialidad
        const razon = motivo 
        const noCuenta = numeroCuenta
        const tipoCuent = tipoCuenta
        const banco = bancoSeleccionado.nombre
        const nombreTitularCuenta = nombreTitularCuentaBanco
        const cedulaTitularCuenta = cedulaTitularCuentaBanco
        const tipoDocumentoTitularCuenta = tipoDocumento
        const documentoTitularCuenta = cedulaTitularCuentaBanco
        const idTipoReembolso = IdReembolsoSelected
        
        
        if(!cedulaIsValid(cedulaTitularCuenta) && tipoDocumentoTitularCuenta === "Cedula") {
          setModalLoading(false)
          return mostrarMensaje("Cédula de identidad invalida, por favor verificar",'error');
        }
        if(noCuenta.length < 8) {
          setModalLoading(false)
          return mostrarMensaje("El número de cuenta utilizado no es válido, favor verificar",'error');
        }
        
        apiService.reembolso.postSolicitudesReembolsoV2(
          contrato, 
          solicitante,
          correoSolicitante,
          telefonoSolicitante,
          tipoReembolso,
          fechaServicio, 
          montoRecl, 
          centroSalud, 
          medico, 
          especialidad, 
          razon, 
          noCuenta, 
          tipoCuent, 
          banco, 
          nombreTitularCuenta, 
          tipoDocumentoTitularCuenta,
          documentoTitularCuenta,
          idTipoReembolso,
          datoSolicitante.codigoAfiliado,
          archivos
        )
        .then((res) => {
          if (res.data.result)
          {
            mostrarMensaje(`Carga de arcivos realizada`, 'success')
            mostrarMensaje(`Se ha creado el caso ${res.data.result}`, 'success')
            updateTelefonoCelular(datosAfiliacion.datosPersonales.codigoAfiliado,removeGuion(telefonoCelular))
            updateTelefonoResidencial(datosAfiliacion.datosPersonales.codigoAfiliado,removeGuion(telefonoResidencial))
            setOpen(false)
            isReset(true); 
            setTimeout(() =>  window.location.reload(true), 2000)
            setModalLoading(false)
          } else {
            mostrarMensaje(`Ha ocurrido un error al moment de procesar el formulario`, 'error')
            setModalLoading(false)
          }
        
      }).catch(err => {
        mostrarMensaje(`Ha ocurrido un error al moment de procesar el formulario`, 'error')
        setModalLoading(false)
      })
    }

    const actualizarSolicitudReembolso = async () => {
        const ticket = numeroTicket; 
        const datoSolicitante = nucleoFamiliar.filter(n=>n.nombres+" "+n.apellidos === nombreAfiliado)[0];
        const correoSolicitante = datosAfiliacion.datosContacto.correoElectronico;
        const telefonoSolicitante = !telefonoCelular ? telefonoResidencial : telefonoCelular;
        const solicitante = `${datosAfiliacion.datosPersonales.nombres} ${datosAfiliacion.datosPersonales.apellidos}`;
        const tipoReembolso = nombreTipoReembolso
        const fechaServicio = fecha
        const montoRecl = montoReclamado
        const centroSalud = centroSeleccionado === undefined ? ' ' : centroSeleccionado
        const medico = medicoSeleccionado.nombre === undefined ? ' ' : medicoSeleccionado.nombre
        const especial = especialidad.especialidad === undefined ? ' ' : especialidad.especialidad
        const razon = motivo 
        const noCuenta = numeroCuenta
        const tipoCuent = tipoCuenta
        const banco = bancoSeleccionado.nombre === undefined ?  bancoSeleccionado : bancoSeleccionado.nombre
        const nombreTitularCuenta = nombreTitularCuentaBanco
        const tipoDocumentoTitularCuenta = tipoDocumento
        const documentoTitularCuenta = cedulaTitularCuentaBanco
        const idTipoReembolso = IdReembolsoSelected
        

        apiService.reembolso.updateSolicitudReembolsoV2(
          ticket,
          solicitante,
          correoSolicitante,
          telefonoSolicitante,
          tipoReembolso,
          fechaServicio, 
          montoRecl, 
          centroSalud, 
          medico, 
          especial, 
          razon, 
          noCuenta, 
          tipoCuent, 
          banco, 
          nombreTitularCuenta, 
          tipoDocumentoTitularCuenta,
          documentoTitularCuenta,
          idTipoReembolso,
          datoSolicitante.codigoAfiliado,
          archivos
        )
        .then((res) => {
          if (res.data.result)
          {
            mostrarMensaje(`Carga de documentos realizada`, 'success')
            setModalLoading(false)
            setOpen(false)
            isReset(true);  
            window.location.reload(true);
          } else {
            mostrarMensaje(`Ha ocurrido un error en la carga de documentos realizada`, 'error')
            setModalLoading(true)
            isReset(true); 
            setModalLoading(false)
          }
        
      }).catch(err => {
        console.error(err)
        mostrarMensaje(`Ha ocurrido un error en la carga de documentos realizada`, 'error')
        setModalLoading(true)
        isReset(true); 
        setModalLoading(false)
        
      })
        
    }

    const ordenarDatosParaActualizar = (value) => {
    //Convertimos el texto a JSON
      value = value.replace('Información Bancaria:', ).replace(undefined, '')
      value = value.replace('Nombre y apellido del solicitante', ).replace(undefined, 'nombre')
      value = value.replace(' Tipo de reembolso', ).replace(undefined, 'tipoReembolso')
      value = value.replace(' Fecha del Servicio', ).replace(undefined, 'fechaServicio')
      value = value.replace(' Monto reclamado', ).replace(undefined, 'montoReclamado')
      value = value.replace('  Centro de Salud', ).replace(undefined, 'centroSalud')
      value = value.replace(' Médico', ).replace(undefined, 'medico')
      value = value.replace(' Especialidad', ).replace(undefined, 'especialidad')
      value = value.replace(' Motivo', ).replace(undefined, 'motivo')
      value = value.replace('  No. de cuenta', ).replace(undefined, 'numeroCuenta')
      value = value.replace(' Tipo de cuenta', ).replace(undefined, 'tipoCuenta')
      value = value.replace(' Banco', ).replace(undefined, 'banco')
      value = value.replace(' Nombre y apellido del titular de la cuenta', ).replace(undefined, 'nombreTitularCuenta')
      value = value.replace(' Tipo documento del titular de la cuenta', ).replace(undefined, 'tipoDocumento')
      value = value.replace(' Documento del titular de la cuenta', ).replace(undefined, 'documentoTitular')

      let agregarCaracteres = value.split(': ').join('":"')
      agregarCaracteres = agregarCaracteres.split(' |||').join('","')
      const resultado = (`{"${agregarCaracteres}"}`)

      const textstring = JSON.parse(resultado.toString())
      
      const arregloMedicos = 
      {
        id:1,
        nombre: textstring["  Médico"],
        especialidad: textstring.especialidad
      }
      
    
      setNombreAfiliado(textstring.nombre.trimStart().replace('  ',' '))
      setNombreTipoReembolso(textstring.tipoReembolso.trimStart())
      setFecha(textstring.fechaServicio.trimStart())
      setCentroSeleccionado(textstring.centroSalud)
      setMedicoSeleccionado(arregloMedicos)
      setEspecialidad(arregloMedicos)
      setMontoReclamado(textstring["montoReclamado"].trimStart())
      setNumeroCuenta(textstring.numeroCuenta)
      setTipoCuenta(textstring.tipoCuenta.trimStart())
      setBancoSeleccionado(textstring.banco)
      
      setNombreTitularCuentaBanco(textstring.nombreTitularCuenta)
      setMotivo(textstring.motivo)
      setTipoDocumento(textstring.tipoDocumento)
      if(textstring.tipoDocumento === 'Cedula'){
      setCedulaTitularCuentaBanco(textstring.documentoTitular.replace(". ",""))
      }
      else{
      setCedulaTitularCuentaBanco(textstring.documentoTitular)
      }
    }

    useEffect(() => {
      
      if(isEnable === true)
      {
          handleOpen();
      }
      
    },[isEnable])

    useEffect(() => {
      setTituloModal(titulo)
      obtenerPrestadores()
      obtenerTiposReembolso()
      obtenerBancos()
      if(isEdit === true)
      {
        ordenarDatosParaActualizar(descripcion)
      }
    }, [titulo])

    useEffect(() => {
      archivosCarga(archivos)
    }, [archivos])

    useEffect(() => {
      if(datosAfiliacion){
          setModalLoading(true)
          apiService.afiliado.getAfiliado(datosAfiliacion.datosPersonales.codigoAfiliado)
          .then(res => {
            if(datosAfiliacion.datosPersonales.tipoAfiliacion !== 'Titular')
            {
              setNucleoFamiliar(res.data.result.nucleoFamiliar.participantes.filter(x => x.codigoAfiliado === datosAfiliacion.datosPersonales.codigoAfiliado))
            }
            else
            {
              setNucleoFamiliar(res.data.result.nucleoFamiliar.participantes)
              
            }
            setModalLoading(false)
          }).catch(err => {
              setModalLoading(false)
          })
          setTelefonoCelular(datosAfiliacion.datosContacto.telefonoCelular)
          setTelefonoResidencial(datosAfiliacion.datosContacto.telefonoResidencial)
      }
    },[datosAfiliacion])

    useEffect(() => {

      if(IdReembolsoSelected !== 0)
      {
        obtenerDocumentosRequeridos(IdReembolsoSelected);
      }
    
    },[IdReembolsoSelected])

    useEffect(() => {
   
      let cont2 = 0
      if(documentosRequeridos){
        documentosRequeridos.map(x => {
            if(x.requerido)
            {
    
              cont2 ++;
            }
        })
        setArchivosOpcionales(cont2)
        ordenarDocumentosRequeridos(documentosRequeridos)
        
      }
    }, [documentosRequeridos])

    useEffect(() => {
      if(isEdit && tipoReembolso != undefined)
      {
        
        const idReembolso = tipoReembolso.filter(x => x.descripcion === nombreTipoReembolso)
        if(idReembolso.length > 0)
        { 
          setIdReembolsoSelected(idReembolso[0].codigo)
        }

        if(nombreTipoReembolso.includes('Honorarios'))
        {
          setIdReembolsoSelected(tipoReembolso.filter(x => x.descripcion.includes('Honorarios'))[0].codigo)
          setNombreTipoReembolso('Honorarios Médicos')
        }
        
        
      }
    },[tipoReembolso])

    useEffect(() => {
      archivosCarga(uploadedDocuments)
      setArchivos(uploadedDocuments)
    }, [uploadedDocuments])

    const cerrarFormulario = (value) => {
      if(value === true)
        {
          setOpen(false)
          formClose()
        }
      
    }

    const getdatosAfiliacion = () => {
      setModalLoading(true)
       apiService.afiliado.getAfiliado(infoAfiliado.codigoAfiliado)
      .then(res => {
        setDatosAfiliacion(res.data.result)
      })
      .catch(err =>{ 
        console.error(err)
        setModalLoading(false)
      })
    }

    const ordenarDocumentosRequeridos = (data) => {
      const result = []
      let datosOrdneados

      data.map(x => {
        datosOrdneados = {
          id : x.codigo,
          name: x.descripcion,
          formato: x.formatoArchivos,
          maxFileSize: x.limiteTamanoArchivo,
          required: x.requerido,
          qtyFiles: x.cantidadPermitida
        }

        result.push(datosOrdneados)
      })
    }

    const validarFormulario = () => {
      let isValid = true;
      
      if(nombreAfiliado === ""){
        mostrarMensaje("Favor seleccionar un afiliado", "warning")
      }

      setIsValid(false);



      return isValid;

    }

    const handleInvalid = (event) => {
      event.target.setCustomValidity('Por favor, seleccione una de las opciones disponibles.');
    };

    const handleInput = (event) => {
      event.target.setCustomValidity('');
    };

    const handleMontoReclamado = (event) => {
        if(removeCommas(event.target.value) > 30000)
          {
            mostrarMensaje(`Para reembolsos con un monto mayor a RD$30,000.00 debe dirigirse a uno de nuestros centros de servicios a usuario. Ver ubicaciones <a href="https://www.arssenasa.gob.do/index.php/quienes-somos/oficinas-y-puntos-de-servicios/"><b>aquí</b></a>`,'warning')
            setMontoReclamado(1)
          }else  if(event.target.value == 0)
          {
            setMontoReclamado('')
          }
          else
          {
            setMontoReclamado(formatNumberWithCommas(event.target.value.toString()))
          }
    }

    const handlerSelectTipoDocumento = ({target}) => {
       setTipoDocumento(target.value)
       setCedulaTitularCuentaBanco('');
    }

    const updateTelefonoCelular = async (codigoAfiliado, _telefonoCelular) => {
      if(_telefonoCelular === datosAfiliacion.datosContacto.telefonoCelular){
          return
      }

      const telefonos = {
        TelefonoCelular: _telefonoCelular,
      }
     
      await apiService.afiliado.postUpdateTelefonos(codigoAfiliado, telefonos)
      .then(() => {
        mostrarMensaje("Teléfono celular actualizado con éxito", "success")
        setTelefonoCelular(_telefonoCelular)
      })
      .catch((err) => {
        console.error(err)
        mostrarMensaje("Ha ocurrido un error, favor intentar mas tarde","error")
      })
    }

    const updateTelefonoResidencial = async (codigoAfiliado, _telefonoResidencial) => {
      if(_telefonoResidencial === datosAfiliacion.datosContacto.telefonoResidencial){
          return
      }

      const telefonos = {
				TelefonoResidencial: _telefonoResidencial
      }
     
      await apiService.afiliado.postUpdateTelefonos(codigoAfiliado, telefonos)
      .then(() => {
        mostrarMensaje("Teléfono residencial actualizado con éxito", "success")
        setTelefonoResidencial(_telefonoResidencial)
      })
      .catch((err) => {
        console.error(err)
        mostrarMensaje("Ha ocurrido un error, favor intentar mas tarde","error")
      })
    }

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        sx={{paddingTop:{xs:0, md:5, lg:5}, height: 'auto', overflow: 'auto'}}
      >
        <form onSubmit={SaveForm}>
          <Grid container justifyContent={'center'} style={{ fontFamily:'montserrat'}} >
            <Grid item md={8} xs={12} lg={6} style={{backgroundColor: colors.white}} sx={{padding:{xs:3}}} >
              <Grid item md={12} xs={12} marginBottom={7} sx={{marginTop:{xs:5, md:0}}} >
                <Typography variant='h4' fontWeight={700} fontFamily={'montserrat'} color={colors.primary}>
                  {tituloModal}
                </Typography>
                <Typography variant='h6'  fontFamily={'montserrat'} >
                  Complete los datos del formulario a contiuación.
                </Typography>
              </Grid>
              <Grid container spacing={1}>
                <Grid item md={12} xs={12}>
                  <Grid marginBottom={2} marginTop={3}>
                    <InfoLabel
                      title={"Afiliado"}
                      info = {"Favor de seleccionar un afiliado."}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                  {
                    nucleoFamiliar.length === 0
                    ? 'Cargando...'
                    : nucleoFamiliar.map(x => (
                      <Grid item md={12} xs={12}>
                          <CustomRadioButton 
                            key={x.codigoAfiliado}
                            id = {x.codigoAfiliado}
                            name={`${x.nombres} ${x.apellidos}`}
                            selected={!nombreAfiliado ? false : `${x.nombres} ${x.apellidos}` !== nombreAfiliado ? false : true}
                            nameSelected = {(x) => setNombreAfiliado(x)}
                            tag={x.parentesco.toUpperCase() === 'TITULAR' ? x.parentesco : 'DEPENDIENTE'}
                            editSelected = {nombreAfiliado}
                          />
                      </Grid>
                    ))
                  }
                  </Grid>
                </Grid>
                 
                <Grid item md={12} xs={12}> 
                {
                  !nombreAfiliado ? <div></div>
                  :
                    <Grid container >
                      <Grid item md={12} marginTop={3}> 
                        <Grid container spacing={1}>
                        <Grid md={12} marginBottom={2} marginTop={3}>
                            <InfoLabel
                              title={"Datos de usuario"}
                              info = {"Datos de usuario"}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>    
                            <Grid  marginBottom={2} marginTop={3}>
                              <InfoLabel
                                  title={"Correo electrónico"}
                                  style={{fontSize: 10, fontWeight:500}}
                                />
                            </Grid>
                            <FormControl fullWidth>
                              <TextField
                                id="readonly-email"
                                required={!isValid}
                                type="text"
                                value={datosAfiliacion?.datosContacto.correoElectronico}
                                disabled={true}
                              />
                            </FormControl>             
                          </Grid>
                          <Grid item md={6} xs={12}>    
                            <Grid  marginBottom={2} marginTop={3}>
                              <InfoLabel
                                  title={"Teléfono celular"}
                                  style={{fontSize: 10, fontWeight:500}}
                                />
                            </Grid>
                            <FormControl fullWidth>
                              <Tooltip title="Click para editar">
                                  <TextField
                                    id="field-telefono-celular"
                                    required={true}
                                    type="text"
                                    value={formatToMobile(telefonoCelular)}
                                    onChange={(e) => setTelefonoCelular(formatToMobile(e.target.value))}
                                  />
                              </Tooltip>
                            </FormControl>             
                          </Grid>
                          <Grid item md={6} xs={12}>    
                            <Grid  marginBottom={2} marginTop={3}>
                              <InfoLabel
                                  title={"Teléfono residencial"}
                                  style={{fontSize: 10, fontWeight:500}}
                                />
                            </Grid>
                            <FormControl fullWidth>
                              <Tooltip title="Click para editar">
                                  <TextField
                                    id="field-telefono-residencial"
                                    required={true}
                                    type="text"
                                    value={formatToMobile(telefonoResidencial)}
                                    onChange={(e) => setTelefonoResidencial(formatToMobile(e.target.value))}
                                  />
                              </Tooltip>
                            </FormControl>             
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item md={12} marginTop={3}>
                        <Grid  marginBottom={2} marginTop={3}>
                          <InfoLabel
                            title={"Tipo de reembolso"}
                            info = {"Favor de seleccionar el tipo de reembolso a realizar"}
                          />
                        </Grid>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '0vh' }}>
                          <input
                            style={{ margin: '1px', padding: '1px', width: '1px', height: '1px', border: '1px solid white' }}
                            required={!isValid}
                            onInvalid={handleInvalid}
                            onInput={handleInput}
                            readOnly={nombreTipoReembolso ? true : false}
                          />
                        </div>
                        <Grid container spacing={1}>
                            { !tipoReembolso ? 'Cargando...'
                              :tipoReembolso.map(x => (
                                <Grid  item md={6} xs={12}>
                                  <CustomRadioButton 
                                    id = {x.codigo}
                                    name={x.descripcion}
                                    selected={!nombreTipoReembolso ? false : x.descripcion !== nombreTipoReembolso ? false : true}
                                    nameSelected = {(x) =>{ 
                                      setNombreTipoReembolso(x)
                                      obtenerPrestadores()
                                    }}
                                    idSelected={(x) => setIdReembolsoSelected(x)}
                                    editSelected={nombreTipoReembolso}
                                  />
                                </Grid>
                              ))
                            }
                        </Grid>
                        <Grid marginTop={2} paddingLeft={2}>
                          <FormHelperText sx={{color:'red'}}>{!isValid && !nombreTipoReembolso ? requiredLabel : ''}</FormHelperText>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>    
                          <Grid  marginBottom={2} marginTop={3}>
                            <InfoLabel
                                title={"Fecha de servicio"}
                                style={{fontSize: 10, fontWeight:500}}
                              />
                          </Grid>
                          <FormControl fullWidth>
                            <TextField
                              id='field-fecha-servicio'
                              required={!isValid}
                              type="date"
                              value={fecha}
                              onChange={(event) => 
                                { 
                                    if(Date.parse(event.target.value) > new Date())
                                    {
                                      mostrarMensaje('La fecha de servicio no debe ser posterior al día de hoy', 'warning')
                                      setFecha(new Date())
                                    }
                                    else
                                    {
                                      setFecha(event.target.value)
                                    }
                                }
                              }
                              
                              helperText={!isValid && !fecha ? requiredLabel : '' }
                            />
                          </FormControl>             
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Grid container >
                            <Grid  marginBottom={2} marginTop={3}> 
                              <InfoLabel
                                  title={"Monto reclamado"}
                                  style={{fontSize: 10, fontWeight:500}}
                                />
                            </Grid>
                            <FormControl fullWidth>
                              <TextField
                                id='field-monto-reclamado'
                                type="text"
                                placeholder='0.00...'
                                onChange={handleMontoReclamado}
                                value={montoReclamado}
                                required={!isValid}
                                helperText={!isValid && !montoReclamado ? requiredLabel : '' }
                              />
                            </FormControl>                      
                        </Grid>

                        </Grid>
                        <Grid item md={6} xs={12}>
                        {
                            nombreTipoReembolso !== 'Honorarios Médicos'
                            ?  <Grid style={{width: '100%'}}>
                                  <Grid  marginBottom={2} marginTop={3}> 
                                      <InfoLabel
                                            title={"Centro de salud"}
                                            style={{fontSize: 10, fontWeight:500}}
                                          />
                                    </Grid>
                                  <Grid>
                                    <CustomDataPicket 
                                    id="picker-centro-salud"
                                    title='Centro de Salud'
                                    data={centro} 
                                    valueSelected={!centroSeleccionado ? '' : !centroSeleccionado.nombre ? centroSeleccionado : centroSeleccionado.nombre}
                                    selected={(value) => setCentroSeleccionado(value)}
                                    required={!isValid}
                                    helperText={!isValid && !centroSeleccionado ? requiredLabel : '' }
                                    handlerConsulta={(x) => obtenerPrestadores(x)}
                                    />
                                  </Grid>
                                </Grid>
                            :   <Grid >
                                  <Grid  marginBottom={2} marginTop={3}>
                                    <InfoLabel
                                      title={"Médico"}
                                      style={{fontSize: 10, fontWeight:500}}
                                    />
                                  </Grid>
                                  <CustomDataPicket 
                                    id="picker-medico"
                                    title='Médico'
                                    data={medico} 
                                    valueSelected={!medicoSeleccionado ? '' : medicoSeleccionado.nombre}
                                    selected={(value) => setMedicoSeleccionado(value)}
                                    required={!isValid}
                                    helperText={!isValid && !medicoSeleccionado ? requiredLabel : '' }
                                    handlerConsulta={(x) => obtenerPrestadores(x)}
                                  />
                                  <FormControl fullWidth sx={{marginTop:2}}>
                                    <TextField
                                      id='field-especialidad'
                                      type="text"
                                      placeholder='Especialidad'
                                      readOnly
                                      value={!medicoSeleccionado ? '' : medicoSeleccionado.especialidad || especialidad}
                                      required={!isValid}
                                      helperText={!isValid && !medicoSeleccionado?.especialidad ? requiredLabel : '' }
                                    />
                                  </FormControl>
                                </Grid>
                          }
                        </Grid>    
                      </Grid>
                  <Grid container spacing={2} marginTop={3}>
                  <Grid item md={12} xs={12} >
                    <Grid  marginTop={3}>
                        <InfoLabel
                          title={"Información bancaria"}
                          info = {"Completar la información bancaria"}
                        />
                      </Grid>
                  </Grid>
                    <Grid item md={6} xs={12}>
                      <Grid id="NumeroCuenta"> 
                        <Grid  marginBottom={2}>
                          <InfoLabel
                              title={"Número de cuenta "}
                              style={{fontSize: 10, fontWeight:500}}
                            />
                        </Grid>
                        <FormControl fullWidth>
                          <TextField
                              id='field-numero-cuenta'
                              type="text"
                              placeholder='123...'
                              onChange={(event) => {
                                setNumeroCuenta(containsOnlyNumbers(event.target.value))
                              }}
                              value={numeroCuenta}
                              required={!isValid}
                              helperText={!isValid && !numeroCuenta ? requiredLabel : '' }
                          />
                        </FormControl> 
                      </Grid> 
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <Grid id="Cuenta">
                        <Grid  marginBottom={2}>
                          <InfoLabel
                            title={"Cuenta"}
                            style={{fontSize: 10, fontWeight:500}}
                          />
                        </Grid>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '0vh' }}>
                          <input
                            id="field-numero-cuenta"
                            style={{ margin: '1px', padding: '1px', width: '1px', height: '1px', border: '1px solid white' }}
                            required={!isValid}
                            onInvalid={handleInvalid}
                            onInput={handleInput}
                            readOnly={tipoCuenta ? true : false}
                          />
                          
                        </div>
                          <Grid spacing={1} container>
                            {
                              cuentas.map(x => (
                                <Grid item md={6} xs={12}>
                                    <CustomRadioButton 
                                    id = {x.id}
                                    name={x.descripcion}
                                    selected={!tipoCuenta ? false : x.descripcion !== tipoCuenta ? false : true}
                                    nameSelected = {(x) => setTipoCuenta(x)}
                                    editSelected= {tipoCuenta}
                                    style={{padding: 0, paddingInline: 10}}
                                  />
                                </Grid>
                              ))
                            }
                            <FormHelperText sx={{color:'red'}}>{!isValid && !tipoCuenta ? requiredLabel : ''}</FormHelperText>
                          </Grid> 
                      </Grid>
                      </Grid>
                    <Grid item md={6} xs={12} paddingTop={0}>
                      <Grid id="Banco">
                        <Grid  marginBottom={2}>
                          <InfoLabel
                            title={"Banco"}
                            style={{fontSize: 10, fontWeight:500}}
                          />
                        </Grid>
                        <CustomDataPicket 
                          id="picker-banco"
                          title='Banco'
                          data={banco} 
                          valueSelected={!bancoSeleccionado ? '' : !bancoSeleccionado.nombre ? bancoSeleccionado : bancoSeleccionado.nombre}
                          selected={(value) => setBancoSeleccionado(value)}
                          customStyle={{height: 'auto'}}
                          required={!isValid}
                          helperText={!isValid && !bancoSeleccionado ? requiredLabel : '' }
                        />
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12} paddingTop={0}>
                      <Grid>
                        <Grid  marginBottom={2}>
                          <InfoLabel
                            title={"Nombres y apellidos"}
                            style={{fontSize: 10, fontWeight:500}}
                          />
                        </Grid>
                        <FormControl fullWidth>
                          <TextField
                                id="field-nombre-titular-cuenta"
                                type="text"
                                placeholder='Juan Perez'
                                onChange={(event) => {
                                  setNombreTitularCuentaBanco(event.target.value)
                                }}
                                value={nombreTitularCuentaBanco}
                                required={!isValid}
                                helperText={!isValid && !nombreTitularCuentaBanco ? requiredLabel : '' }
                            />
                        </FormControl> 
                      </Grid>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Grid container>
                          <InfoLabel
                              title={"Tipo de documento"}
                              style={{fontSize: 10, fontWeight:500}}
                            />
                            <FormControl fullWidth>
                              <select
                                required={!isValid}
                                id="select-tipoDocumento"
                                value={tipoDocumento}
                                onChange={handlerSelectTipoDocumento}
                                style={{width: '100%', padding:8, fontSize: 16, border: 'solid 2px #3DAE2B', borderRadius: 10, height: 60}}
                              >
                                <option value=""></option>
                                <option value="Cedula">Cédula</option>
                                <option value="Pasaporte">Pasaporte</option>
                              </select>
                              <FormHelperText sx={{color:'red'}}>{!isValid && !tipoDocumento ? requiredLabel : ''}</FormHelperText>
                            </FormControl>
                            
                        </Grid>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        {
                          tipoDocumento 
                          ?
                          <Grid container>
                          <InfoLabel
                              title={tipoDocumento === 'Cedula' ? 'Cédula' : tipoDocumento === "Pasaporte" ? 'Pasaporte' : 'Documento'}
                              style={{fontSize: 10, fontWeight:500}}
                            />
                            <FormControl fullWidth>
                                <TextField
                                  id="field-documento-cuenta"
                                  type="text"
                                  onChange={(event) => {
                                    if(tipoDocumento === 'Cedula'){
                                      setCedulaTitularCuentaBanco(formatToCedula(event.target.value))
                                    }else if(tipoDocumento === 'Pasaporte'){
                                      setCedulaTitularCuentaBanco(passportFormat(event.target.value.toUpperCase()))
                                    }
                                    
                                }}
                                
                                value={cedulaTitularCuentaBanco}
                                required={!isValid}
                                helperText={!isValid && !cedulaTitularCuentaBanco ? requiredLabel : '' }
                              />
                            </FormControl>
                        </Grid>
                        : null
                        }
                        
                      </Grid>
                  </Grid>
                  <Grid container marginTop={3}>
                    <InfoLabel
                      title={"Motivo"}
                      info = {`Indicar el motivo para realizar el reembolso`}
                    />
                    <Grid  md={12} xs={12} >
                      <FormControl fullWidth>
                        <TextField
                            id="field-motivo"
                            multiline
                            rows={4}
                            type="text"
                            placeholder='Agregar motivo'
                            onChange={(event) => {
                              setMotivo(event.target.value)
                            }}
                            inputProps={{ maxLength: maxCharacters }}
                            value={motivo}
                          />
                      </FormControl>
                      <FormHelperText>
                        {motivo.length}/{maxCharacters} caracteres 
                      </FormHelperText>
                    </Grid>
                  </Grid>
                  <Grid container marginTop={3} mb={5}>
                  
                    <DocumentosRequeridosList data={{
                      id: 1,
                      descripcion: 'Cedula de identidad'
                    }}/>

                  </Grid>
                  <Grid md={12} xs={12}>
                    <CargarDocumentoV2 
                      onFilesChange={setUploadedDocuments} 
                      requiredDocuments={documentosRequeridos}
                      missingDocuments={setDocumentosFaltantes}
                    /> 
                  </Grid>
                    { isEdit  === false 
                      ?  <Grid 
                      container
                      style={
                        {
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection:'column',
                          marginTop:20,
                        }
                      }
                    >
                      <div  id="terminos-condiciones" onClick={() => setVerTerminos(true)} style={{color:'blue', marginBottom: 20, cursor:'pointer', textDecoration:'underline'}}>
                        <h4>Ver términos y condiciones</h4>
                      </div>
                      <Grid spacing={1} justifyContent={"center"} container >
                          <Grid item  md={6} xs={12}>
                              <CustomRadioButton 
                              id = {'CustomRadioButton'}
                              name={"He leído y acepto los términos y condiciones"}
                              selected={aceptarTerminos === false ? false : true}
                              nameSelected = {() => aceptarTerminos === true ? setAceptarTerminos(false) : setAceptarTerminos(true)}
                              identify={"terminosCondiciones"}
                              style={{padding: 8, paddingInline: 10}}
                            />
                          </Grid>
                      </Grid> 
                      <div>
                        {
                          verTerminos === false ? ''
                          : <TerminosCondiciones 
                          id="btn-terminos-condiciones"
                          enable={verTerminos}
                          disabled ={setVerTerminos}
                          nombre={`${datosAfiliacion.datosPersonales.nombres} ${datosAfiliacion.datosPersonales.apellidos}`}
                          cedula={datosAfiliacion.datosPersonales.documentoIdentidad}
                          Regimen={datosAfiliacion.datosPersonales.regimen}
                          NSS={datosAfiliacion.datosPersonales.nss}
                        />
                        }
                      
                      </div>
                    </Grid>
                      :<></>
                  } 
                  </Grid>    
                }
                </Grid>
                <Grid container padding={5} spacing={4} >
                  <Grid item md={6} xs={12} textAlign={'center'}>
                  <CustomButton
                    id="btn-cancelar"
                    onClick={handleClose}
                    type={"button"}
                    sx={{
                    ...botonNoDialog,
                    border: `0 solid ${colors.primary}`,
                    cursor: 'pointer',
                    width: 250,
                    height: 50,
                    borderRadius: 100,
                    fontFamily: "Montserrat",
                  }}
                  >
                    Cancelar
                  </CustomButton>
                  </Grid>
                  <Grid item md={6} xs={12} textAlign={'center'}>
                    <CustomButton
                      id="btn-guardar"
                      disabled={isEdit ? false : !aceptarTerminos ? true : false}
                      type='sumbit'
                      sx={{
                      ...botonVerPerfilProfileCard,
                      border: `0 solid ${colors.primary}`,
                      cursor: 'pointer',
                      width: 250,
                      height: 50,
                      borderRadius: 100,
                      backgroundColor: isEdit ? colors.primary : !aceptarTerminos ? colors.primaryOpacity2 : colors.primary,
                      fontFamily: "Montserrat",
                    
                    }}
                    >
                      Guardar
                    </CustomButton>
                  </Grid>
                </Grid>
                <ModalLoading open={modalLoading}/>
              </Grid>
             
            </Grid>
            <Grid id="dialog-solicitud-container">
                <CustomQuestionDialog 
                title="Cancelar solicitud"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={cerrarFormulario}
                cancelText={"No"}
                acceptText={"Si"}
                >
                  ¿Estás seguro que desea cancelar esta solicitud?
                </CustomQuestionDialog>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </div>
  );
}

export default FormularioReembolso;