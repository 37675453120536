import React from "react";
import { Paper, Divider } from "@mui/material";

import {
  paperContainerCustomSave,
  iconResetCustomSave,
  iconSaveCustomSave,
  paperContainerStyleCustomSave,
} from "../../global/styles";

import UseIconHooks from "../../hooks/useIconHooks";

const CustomSave = ({ onPressReset, onPressSave, styleSave, styleReset }) => {
  return (
    <>
      <Paper
        elevation={3}
        sx={{ ...paperContainerCustomSave }}
        style={{ ...paperContainerStyleCustomSave }}
      >
        <div id="btn-deshacer" style={{ cursor: "pointer" }} onClick={onPressReset}>
          <a title="Deshacer">
            {UseIconHooks("reset", {
              style: { ...iconResetCustomSave, ...styleReset },
            })}
          </a>
        </div>

        <Divider orientation='vertical' variant='middle' flexItem />
        <div style={{ cursor: "pointer" }} onClick={onPressSave}>
          <a title="Guardar">
            {UseIconHooks("save", {
              style: { ...iconSaveCustomSave, ...styleSave },
            })}
          </a>
        </div>
      </Paper>
    </>
  );
};

export default CustomSave;
