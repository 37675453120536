import React, { useEffect, useState } from "react";
import UseSetIsLogin from "../../hooks/useSetIsLogin";
import { Navigate, useParams } from "react-router-dom";
import CustomLabel from "../../components/common/customLabel";
import colors from "../../../config/colors";
import { CircularProgress, Modal, Button, InputBase, Grid, InputLabel } from "@mui/material";
import * as apiService from "../../../api";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { botonIngresarLogin, inputRegistro } from "../../global/styles";
import { isExpired, decodeToken } from "react-jwt";
import useSetIsLogin from "../../hooks/useSetIsLogin";

const CambioCorreo = () => {

  const [correoNuevo, setCorreoNuevo] = useState('')
  const [mensaje, setMensaje] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isValid, setIsValid] = useState(false)


  let params = useParams();
  const token = params.Token.split("=")[1];
  const expiro = isExpired(token);
  const parametros = decodeToken(token);
  const data = parametros.data.split(':');
  const codAfiliado = data[0];
  const correoAfiliado = data[1]

  var navigate = useNavigate()

  const mensajeToast = (mensaje, tipo) => {
    enqueueSnackbar(mensaje, {
      variant: tipo,
      action: (key) => (
        <Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
          X
        </Button>
      )})
  }
  const { setAuthLogOut } = useSetIsLogin()

	useEffect(() => {
		setAuthLogOut()
	}, [])

  useEffect(() => {
  
    setCorreoNuevo(data[1])
    apiService.autentificacion.getValidarIdentificacion(3,codAfiliado)
    .then(res => {
      setMensaje(res.data.result.mensaje)
    
    })
    .catch(err => mensajeToast('Ha ocurrido un error, favor de intentar mas tarde', 'error') )

  }, [data])

  useEffect(() => {

    if(mensaje === "Pendiente de cambio de correo")
    {
      setIsValid(true)
    }

  },[mensaje])


  //Guarda los cambios remitidos por el usuario
  const handlerSave = () => {
    apiService.afiliado.postUpdateCorreo(codAfiliado,{CorreoElectronico: correoAfiliado}, token)
    .then(() => {

      mensajeToast(`Cambios guardados favor de inciar sesion`,'success' )
      navigate('../../login', { replace: true })

    }).catch( err => {

        mensajeToast(`Ha ocurrido un error durante la actualización, favor intente más tarde. `,'error' )

    }
    )  
  }

  //Cancela los cambios y los reversa al correo anterior
  const handlerCancel = () => {
  
   // mensajeToast(`Redireccionando a inicio de sesion`, 'error')
    navigate('../../login', { replace: true })
   
  }

  return (
    <>
    {
        !mensaje ? ''
        :
        expiro === false 
        ?isValid === true 
        ?<Modal style={{display: 'flex', fontFamily:'montserrat'}} open={true}>
          <Grid container>
            <Grid item md={12} xs={12}>
              <Box
                sx={{
                  width: {
                    xs: '90%', 
                    sm: 400,   
                    md: 600,  
                  },
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: colors.grayFondo,
                  border: `1px solid ${colors.primary}`,
                  boxShadow: 15,
                  p: 4,
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    padding: 25,
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: colors.grayFondo,
                    alignItems: "center",
                    fontFamily:'montserrat',
                    
                  }}
                >
                  <CustomLabel>
                    Cambio de correo electrónico 
                  </CustomLabel>
                </div>
                <div>
                  <div style={{
                    display: 'flex',
                    marginTop: 5,
                    justifyContent:'center'
                  }}>
                    <InputLabel
                          id='sucursal'
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: 20,
                          }}
                          
                        >
                          Su correo electrónico ha sido modificado a:
                    </InputLabel>
                  </div>
                  <div style={{
                    display: 'flex',
                    justifyContent:'center'
                  }}>
                    <InputLabel
                          id='sucursal'
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: 20,
                            fontWeight: 700,
                            marginBottom:10
                            
                          }}
                          
                        >
                          {!correoNuevo ? '' : correoNuevo}
                    </InputLabel>
                  </div>
                </div>
                <Grid item xs={12} style={{ marginTop: 30 }}> 
                  <Button
                    variant='contained'
                    onClick={() => handlerSave()}
                    style={{
                        backgroundColor: colors.primary,
                        textTransform: "initial",
                        fontFamily: "Montserrat",
                        fontSize: 20,
                        borderRadius: 5,

                    }}
                  >
                    De acuerdo
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Modal>
      :<Modal style={{display: 'flex', fontFamily:'montserrat'}} open={true}>
        <Grid container>
          <Grid item md={12} xs={12}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: colors.grayFondo,
                border: `1px solid ${colors.primary}`,
                boxShadow: 15,
                p: 4,
                textAlign: "center",
              }}
            >
              <div
                style={{
                  padding: 25,
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: colors.grayFondo,
                  alignItems: "center",
                }}
              >
                <CustomLabel>
                  El correo ya fue confirmado, favor de iniciar sesión
                </CustomLabel>
                <Grid item xs={12} style={{ marginTop: 30 }}> 
                  <Button
                    variant='contained'
                    onClick={() => handlerCancel()}
                    style={{
                        backgroundColor: colors.primary,
                        textTransform: "initial",
                        fontFamily: "Montserrat",
                        fontSize: 20,
                        borderRadius: 5,

                    }}
                  >
                    De acuerdo
                  </Button>
                </Grid>
              </div>
            </Box>
          </Grid>
        </Grid>
       </Modal>
      :<Modal style={{display: 'flex', fontFamily:'montserrat'}} open={true}>
         <Grid container>
          <Grid item md={12} xs={12}>
            <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: colors.grayFondo,
              border: `1px solid ${colors.primary}`,
              boxShadow: 15,
              p: 4,
              textAlign: "center",
            }}
          >
            <div
              style={{
                padding: 25,
                display: "flex",
                flexDirection: "column",
                backgroundColor: colors.grayFondo,
                alignItems: "center",
              }}
            >
              <CustomLabel>
                El token expiro favor de iniciar sesión en su cuenta y volver a intentar
              </CustomLabel>
            </div>
            </Box>
          </Grid>
        </Grid>
      
        </Modal>
        }
    </>
  );
};

export default CambioCorreo;
