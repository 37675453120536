import { Button, Grid, Modal } from "@mui/material";
import React from "react";
import colors from "../../../../config/colors";
import {
    botonNoDialog,
    botonVerPerfilProfileCard,
  } from "../../../global/styles";

const CustomQuestionDialog = (props) => {

    const { title, children, open, setOpen, onConfirm, cancelText, acceptText } = props;

    const handlerSelectOption = (data) => {
        if(data === 'cancel')
        {
            onConfirm(false)
        }
        else
        {
            onConfirm(true)
        }
        setOpen(false)
    }

    return(
        <Grid>
            <Modal open={open} onClose={()=> setOpen(false)} >
                <Grid paddingTop={20}  sx={{ width: { xs: '80%', md: 800 } }} margin={'0 auto'}>
                    <Grid container>
                        <Grid item md={12} xs={12} display={'flex'} borderRadius={2} flexDirection={'column'} alignItems={'center'} style={{backgroundColor:colors.grayFondo}} paddingLeft={5} paddingRight={5}>
                            <div>
                                <p style={{color:colors.primary, fontSize: 24, fontWeight:600, textAlign:'center'}}>{title}</p>
                            </div>
                            <label style={{fontSize: 18, fontWeight:600, textAlign:'center'}}>
                                {children}
                            </label>
                            <Grid marginTop={5} marginBottom={5}>
                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={12} >
                                        <Button
                                            id="btn-cancelar"
                                            variant='text'
                                            size='large'
                                            style={{ ...botonNoDialog }}
                                            sx={{ fontSize: { xs: 15, md: 15 }, width:{xs: '100%'}  }}
                                            onClick={() => handlerSelectOption('cancel')}>
                                            {cancelText}
                                        </Button>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Button
                                            id="btn-aceptar"
                                            variant='contained'
                                            size='large'
                                            style={{ ...botonVerPerfilProfileCard }}
                                            sx={{ fontSize: { xs: 15, md: 15 }, width:{xs: '100%'} }}
                                            onClick={() => handlerSelectOption('accept')}>
                                            {acceptText}
                                        </Button>
                                    </Grid> 
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>   
        </Grid>
    )
}

export default CustomQuestionDialog