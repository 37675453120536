import React, { useEffect, useState } from 'react'
import { Grid, InputBase } from '@mui/material'
import CustomLabel from '../../common/customLabel'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import { inputFormularioAfiliacion } from '../../../global/styles'

import colors from '../../../../config/colors'

const ItemSintomaEnfermedad = ({ item, handlerListaRespuestaSintoma, countDeclaracionSalud, listaRespuestas }) => {
	const [esUsted, setEsUsted] = useState(false)
	const handlerEsUsted = (event, item) => {
		setEsUsted(event.target.checked)
		handlerListaRespuestaSintoma(1, item, event.target.checked, false)
	}

	const [esPadre, setEsPadre] = useState(false)
	const handlerEsPadre = (event, item) => {
		setEsPadre(event.target.checked)
		handlerListaRespuestaSintoma(2, item, event.target.checked, false)
	}

	const [esMadre, setEsMadre] = useState(false)
	const handlerEsMadre = (event, item) => {
		setEsMadre(event.target.checked)
		handlerListaRespuestaSintoma(9, item, event.target.checked, false)
	}

	const [esHermano, setEsHermano] = useState(false)
	const handlerEsHermano = (event, item) => {
		setEsHermano(event.target.checked)
		handlerListaRespuestaSintoma(3, item, event.target.checked, false)
	}

	const [esAbuelo, setEsAbuelo] = useState(false)
	const handlerEsAbuelo = (event, item) => {
		setEsAbuelo(event.target.checked)
		handlerListaRespuestaSintoma(4, item, event.target.checked, false)
	}

	const [esTio, setEsTio] = useState(false)
	const handlerEsTio = (event, item) => {
		setEsTio(event.target.checked)
		handlerListaRespuestaSintoma(5, item, event.target.checked, false)
	}

	const [esOtro, setEsOtro] = useState(false)
	const handlerEsOtro = (event, item) => {
		setEsOtro(event.target.checked)
		handlerListaRespuestaSintoma(6, item, event.target.checked, false)
	}

	const [padreCampoAdicional, setPadreCampoAdicional] = useState('')
	const handlerPadreCampoAdicional = (event, item) => {
		setPadreCampoAdicional(event.target.value)
		handlerListaRespuestaSintoma(7, item, event.target.value, false)
	}

	const [hijoCampoAdicional, setHijoCampoAdicional] = useState('')
	const handlerHijoCampoAdicional = (event, item) => {
		setHijoCampoAdicional(event.target.value)
		handlerListaRespuestaSintoma(8, item, event.target.value, true)
	}

	const [hijoCampoAdicional2, setHijoCampoAdicional2] = useState('')
	const handlerHijoCampoAdicional2 = (event, item) => {
		setHijoCampoAdicional2(event.target.value)
		handlerListaRespuestaSintoma(8, item, event.target.value, true)
	}

	useEffect(() => {
		if (listaRespuestas.filter((a) => a.IdCatalogoSintoma === item.codigo).length > 0) {
			const respuesta = listaRespuestas.filter((a) => a.IdCatalogoSintoma === item.codigo)[0]
			if (respuesta.esUsted) {
				setEsUsted(true)
			} else {
				setEsUsted(false)
			}

			if (respuesta.esPadre) {
				setEsPadre(true)
			} else {
				setEsPadre(false)
			}

			if (respuesta.esMadre) {
				setEsMadre(true)
			} else {
				setEsMadre(false)
			}

			if (respuesta.esHermano) {
				setEsHermano(true)
			} else {
				setEsHermano(false)
			}

			if (respuesta.esAbuelo) {
				setEsAbuelo(true)
			} else {
				setEsAbuelo(false)
			}

			if (respuesta.esTio) {
				setEsTio(true)
			} else {
				setEsTio(false)
			}

			if (respuesta.esOtro) {
				setEsOtro(true)
			} else {
				setEsOtro(false)
			}

			if (item.campoAdicional) {
				setPadreCampoAdicional(respuesta.campoAdicional)
			}
		}

		if (listaRespuestas.filter((a) => a.IdCatalogoSintoma === 16).length > 0) {
			const respuesta = listaRespuestas.filter((a) => a.IdCatalogoSintoma === 16)[0]

			if (respuesta.campoAdicional !== undefined && respuesta.campoAdicional !== null) {
				setHijoCampoAdicional(respuesta.campoAdicional)
			}
		}

		if (listaRespuestas.filter((a) => a.IdCatalogoSintoma === 17).length > 0) {
			const respuesta = listaRespuestas.filter((a) => a.IdCatalogoSintoma === 17)[0]

			if (respuesta.campoAdicional !== undefined && respuesta.campoAdicional !== null) {
				setHijoCampoAdicional2(respuesta.campoAdicional)
			}
		}
	}, [countDeclaracionSalud])

	return (
		<>
			<Grid container style={{ padding: 20 }}>
				<Grid
					item
					xs={8}
					style={{
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}
				>
					<CustomLabel style={{ fontFamily: 'Montserrat', fontSize: 20 }}>
						{item.numeroOrden}. {item.descripcion}
					</CustomLabel>
					{item.hasChild && (
						<>
							{item.children.map((item, index) => (
								<React.Fragment key={item.codigo}>
									<CustomLabel style={{ fontFamily: 'Montserrat', fontSize: 18 }}>{item.descripcion}</CustomLabel>
									{item.campoAdicional && (
										<InputBase
											placeholder=''
											type={item.codigo === 16 || item.codigo === 17 ? 'number' : 'text'}
											value={index === 0 ? hijoCampoAdicional : hijoCampoAdicional2}
											onChange={(event) => (index === 0 ? handlerHijoCampoAdicional(event, item) : handlerHijoCampoAdicional2(event, item))}
											style={{
												...inputFormularioAfiliacion,
												width: '20%',
												height: 58
											}}
										/>
									)}
								</React.Fragment>
							))}
						</>
					)}
					{item.campoAdicional && (
						<InputBase
							placeholder=''
							value={padreCampoAdicional}
							onChange={(event) => handlerPadreCampoAdicional(event, item)}
							style={{ ...inputFormularioAfiliacion, width: '40%', height: 58 }}
						/>
					)}
				</Grid>
				<Grid item xs={4} style={{ backgroundColor: colors.grayFondo, paddingLeft: 5 }}>
					<FormGroup aria-label='position' row>
						<FormControlLabel
							control={
								<Checkbox checked={esUsted} value={esUsted} onChange={(event) => handlerEsUsted(event, item)} style={{ color: colors.primary }} />
							}
							label='Usted'
							labelPlacement='top'
						/>
						<FormControlLabel
							control={
								<Checkbox checked={esPadre} value={esPadre} onChange={(event) => handlerEsPadre(event, item)} style={{ color: colors.primary }} />
							}
							label='Padre'
							labelPlacement='top'
						/>
						<FormControlLabel
							control={
								<Checkbox checked={esMadre} value={esMadre} onChange={(event) => handlerEsMadre(event, item)} style={{ color: colors.primary }} />
							}
							label='Madre'
							labelPlacement='top'
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={esHermano}
									value={esHermano}
									onChange={(event) => handlerEsHermano(event, item)}
									style={{ color: colors.primary }}
								/>
							}
							label='Hermano (a)'
							labelPlacement='top'
						/>
						<FormControlLabel
							control={
								<Checkbox checked={esAbuelo} value={esAbuelo} onChange={(event) => handlerEsAbuelo(event, item)} style={{ color: colors.primary }} />
							}
							label='Abuelo (a)'
							labelPlacement='top'
						/>
						<FormControlLabel
							control={<Checkbox checked={esTio} value={esTio} onChange={(event) => handlerEsTio(event, item)} style={{ color: colors.primary }} />}
							label='Tio (a)'
							labelPlacement='top'
						/>

						<FormControlLabel
							control={
								<Checkbox checked={esOtro} value={esOtro} onChange={(event) => handlerEsOtro(event, item)} style={{ color: colors.primary }} />
							}
							label='Otro'
							labelPlacement='top'
						/>
					</FormGroup>
				</Grid>
			</Grid>
		</>
	)
}

export default ItemSintomaEnfermedad
