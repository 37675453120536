import { Grid } from "@mui/material";
import React from "react";

import colors from "../../../config/colors";
import Clear from "@mui/icons-material/Clear";

import {
	gridContainerNotificacion,
	messageContainerNotificacion,
	horaContainerNotificacion,
	cerraBotonNotificacion,
} from "../../global/styles";
import TextEllipsis from "./textEllipsis";

const Notificacion = ({
	IconLeft,
	message,
	hora,
	closeBoton,
	style,
	onPress,
	hasEllipsis,
	ellipsisLimit = 91,
	onCloseButton,
}) => {
	return (
		<div onClick={onPress}>
			<Grid container style={{ ...gridContainerNotificacion, ...style }}>
				<Grid item xs={2} paddingX={1}>
					{IconLeft}
				</Grid>
				<Grid item xs={10}>
					{!closeBoton && (
						<div style={{ marginTop: 20, fontSize: "smaller" }}>
							{hasEllipsis ? (
								<TextEllipsis
									originalText={message}
									hasShowMoreButton={true}
									buttonText='mostrar más'
									buttonStyle={{ color: colors.primary }}
									textLimit={ellipsisLimit}
								/>
							) : (
								message
							)}
						</div>
					)}
					{closeBoton && (
						<div>
							<div style={{ ...messageContainerNotificacion }}>
								<div style={{ marginTop: 20, fontSize: "smaller" }}>
									{hasEllipsis ? (
										<TextEllipsis
											originalText={message}
											hasShowMoreButton={true}
											buttonShowText='mostrar más'
											buttonHideText='mostrar menos'
											buttonStyle={{ color: colors.primary }}
											textLimit={ellipsisLimit}
										/>
									) : (
										message
									)}
								</div>
							</div>
							<div
								style={{ ...cerraBotonNotificacion }}
								onClick={(e) => {
									e.stopPropagation();
									onCloseButton();
								}}
							>
								<Clear fontSize='small' />
							</div>
						</div>
					)}
				</Grid>
				<Grid style={{ width: "100%" }}>
					<div style={{ ...horaContainerNotificacion }}>{hora}</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default Notificacion;
