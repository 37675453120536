import http from "../../config/http";
import { SLIDER, MENU, POPUP, ALERTA_PERSONALIZADA} from "../endpoints";

const getAllDashboardSlider = () => {
  let configuration = {
    headers: {"Authorization": "Bearer " + localStorage.getItem("token")}
  }
  return http.get(`Ajuste/${SLIDER}`,configuration);
};

const getMenu = () => {
  return http.get(`Ajuste/${MENU}`);
};

const getAllDashboardPopUp = () => {
  let configuration = {
    headers: {"Authorization": "Bearer " + localStorage.getItem("token")}
  }
  return http.get(`Ajuste/${POPUP}`,configuration);
};

const getAlertaPersonalizada = (id=0, nombre="") => {
  let token = "";

  if(localStorage.getItem("autenticado") == "false")
  {
    token = localStorage.getItem("token-anonimo")
  } else {
    token = localStorage.getItem("token")
  }
  let configuration = {
    headers: {"Authorization": "Bearer " + token}
  }
  return http.get(`Ajuste/${ALERTA_PERSONALIZADA}?id=${id}&nombre=${nombre}`,configuration);
}

export { getAllDashboardSlider, getMenu, getAllDashboardPopUp, getAlertaPersonalizada };
