import React, { useEffect, useState } from "react";
import colors from "../../../config/colors";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import Notificacion from "./notificacion";
import { Circle, CircleOutlined } from "@mui/icons-material";
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import CancelIcon from '@material-ui/icons/Cancel';
import MedicalServicesTwoToneIcon from '@mui/icons-material/MedicalServicesTwoTone';

const NotificationList = (data) => {
    const [notificationData, setNotificationData] = useState();

    const circuloLeido = (
		<CircleOutlined
			style={{
				color: colors.primary,
				fontSize: 35,
				padding: 5,
				marginTop: 12,
			}}
		/>
	);

	const circuloNoLeido = (
		<Circle
			style={{
				color: colors.primary,
				fontSize: 35,
				padding: 5,
				marginTop: 12,
			}}
		/>
	);

const calculateTime = (date) => {

    const now = new Date();
    const timeDiffInMilliseconds = now - date;
    const timeDiffInHours = Math.floor(timeDiffInMilliseconds / (1000 * 60 * 60));

    if (timeDiffInHours === 0) {
        return "now";
    } else if (timeDiffInHours === 1) {
        return "1 hour ago";
    } else if (timeDiffInHours < 24) {
        return `${timeDiffInHours} hours ago`;
    } else {
        return date.split("T")[0].toString(); // Return full date if more than 24 hours ago
    }
}


    useEffect(() => {
       
        setNotificationData(data.data.data[0])
    },[data])   

    useEffect(() => {
        if(notificationData)
        {
           // console.log(notificationData)
            notificationData.map((x) => console.log(x) )
        }
    },[notificationData])

    return(
        <div style={{ backgroundColor: colors.grayFondo, padding:10, borderRadius: 10, overflow:'auto'}}>
            <Grid container alignItems={'center'}  style={{padding: 20}}>
                <Grid item md={11}>
                    <Typography
                        sx={{ fontSize: "Large", fontWeight: "bolder"}}
                        color='text.secondary'
                        gutterBottom
                        >
                    Notificaciones
                    </Typography>
                </Grid>
                <Grid item md={1} >
                 { /*  <div onClick={()=> alert('aloha')}>
                        <CancelIcon style={{color:colors.grayLight, width:50}}/>
                    </div>
                    */}
                </Grid>
              
            </Grid>
           
            {
                notificationData === undefined ? <></>
                :
                    notificationData.map(x => (
                        <Card
                        key={x.idAuth}
                        style={{
                            width: 450,
                            backgroundColor: colors.grayFondo,
                            boxShadow: "none",
                            borderColor: 'black',
                            border: '1px',
                        }}
                    >
                        <CardContent
                            style={{
                                padding: "0px 0px 0px 8px",
                                marginLeft: 20,
                                paddingTop: 0,
                                paddingBottom: 0,
                            }}
                        >
                            <Grid container spacing={4}  marginTop={0.01} marginBottom={0.01} paddingBottom={1} alignItems={'center'} style={{borderBottom: `1px solid  ${colors.grayLight}`}} >
                                <Grid item md={2} >
                                    <MedicalServicesTwoToneIcon style={{fontSize: 24, width:60, height: 60, color:colors.primary }} />
                                </Grid>
                                <Grid item md={6}>
                                <Grid md={12}>
                                    <Typography
                                    sx={{ fontSize: "small", fontWeight: "bold", color:colors.primary }}
                                    gutterBottom
                                    >
                                        {x.tipoAutDesc}
                                    </Typography>
                                </Grid>
                                <Typography
                                sx={{ fontSize: "small", fontWeight: "bold" }}
                                color='text.secondary'
                                gutterBottom
                                >
                                    {` ${x.solicitanteDesc.toUpperCase()} ${("le realizó una autorización").toLowerCase()}`}
                                </Typography>
                                </Grid>
                                <Grid item md={4}>
                                <Typography
                                sx={{ fontSize: "small", fontWeight: "bold" }}
                                color='text.secondary'
                                gutterBottom
                                >
                                    {calculateTime(x.fecha)}
                                </Typography>
                                </Grid>
                            </Grid>   
                        </CardContent>
                    </Card>
                
                    ))
            }
			</div>
    )
}

export default NotificationList