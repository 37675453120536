import { Button, FormControl, Grid, InputBase, Modal, TextField, Typography} from "@mui/material";
import React, {  useEffect, useState } from "react";
import { inputRegistro } from "../../../global/styles";
import colors from "../../../../config/colors";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSnackbar } from "notistack";
import SearchIcon from '@mui/icons-material/Search';

const CustomDataPicket = ({ data, valueSelected,
    selected = (value) => {
        return value  
    },
    title,
    customStyle,
    required,
    helperText,
    handlerConsulta = (value) => {
        return value
    },
}) => {

    const [openModal, setOpenModal] = useState(false);
    const [value, setValue] = useState([]);
    const [consulta, setConsulta] = useState('');
    const [customMedico, setCustomMedico] = useState(false);
    const [customCentro, setCustomCentro] = useState(false);
    const [nombreMedico, setNombreMedico] = useState('');
    const [especialidadMedico, setEspecialidadMedico] = useState('')
    const [nombreCentro, setNombreCentro] = useState('');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    
    const ordenarDatos =  () => {
        let value;
        if(data)
        {
            value = data.filter(x => {
                return x.nombre.toUpperCase().includes(consulta.toUpperCase())
            } )
            setValue(value);
        }
    }

    const seleccionarRegistro = (value) => {
        selected(value);
        setOpenModal(false);
    }

    const handlerCloseModal = (value) => {
        setOpenModal(value)
    }

    const guardarRegistroManual = (nombre, especialidad) => {
        if(nombreMedico !== '' && especialidadMedico !== '')
        {
            const valoresOrdenados = 
                {
                    id: 1,
                    nombre: nombre.toUpperCase(),
                    especialidad: especialidad.toUpperCase()
                }
            selected(valoresOrdenados);
            setOpenModal(false);
        }
        else{
            mostrarAlerta('Favor completar los valores del formulario','warning')
        }
    }

    const guardarCentroManual = (nombre) => {
        if(nombre !== '')
        {
            const valoresOrdenados = 
                {
                    id: 1,
                    nombre: nombre.toUpperCase(),
                }

            selected(valoresOrdenados);
            setOpenModal(false);
        }
        else{
            mostrarAlerta('Favor completar los valores del formulario','warning')
        }
    }

    const handleSearch = () => {
        if(consulta === ""){
            mostrarAlerta('Favor de introducir una consulta','error')
            
        }else{
            handlerConsulta(consulta);
        }
    }

    const mostrarAlerta = (mensaje, tipo) => {
        enqueueSnackbar( mensaje, {
            variant: tipo,
            action: (key) => (
              <Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
                X
              </Button>
            )
          })
    }

    useEffect(() => {
        if(data)
        {
            ordenarDatos();
        }else{
            mostrarAlerta('No se encontraron datos insertados','error')
        }
    },[data])
   

    return(
        <Grid container>   
            <Grid item md={12} xs={12}>
            {
                !valueSelected 
                ? !selected() 
                ? <Grid onClick={() => handlerCloseModal(true)} width={'100%'}>
                    <FormControl fullWidth>
                        <TextField
                            type="text"
                            placeholder='Realice una consulta'
                            required={required}
                            helperText={helperText}
                        />  
                    </FormControl>
                    
                    </Grid>
                : selected().nombre
                :   <Grid onClick={() => handlerCloseModal(true)} >
                        <FormControl fullWidth>
                            <TextField
                                type="text"
                                placeholder='Realice una consulta'
                                readOnly
                                value={valueSelected}
                                required={required} 
                            />  
                        </FormControl>
                    </Grid>
            }
            </Grid>    
            <Modal
                open={openModal}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                style={{width:'100%',heigth: 'auto', overflow: 'auto', paddingTop:5}}
            >
                <Grid contained style={{ backgroundColor:"white", }} padding={5}  sx={{width:{md:'50%', sx:'100%'}}} margin={'0 auto'} borderRadius={5} boxShadow={1}>
                    <Grid style={{display:'flex', justifyContent: 'flex-end'}}>
                        <Grid style={{color:colors.primary}} >
                            <div onClick={()=> handlerCloseModal(false)}>
                                <HighlightOffIcon />
                            </div>
                            
                        </Grid>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Typography variant='h4' fontWeight={700} fontFamily={'montserrat'} color={colors.primary}>
                            {title}
                        </Typography>
                        <Typography variant='h6' fontWeight={400} fontFamily={'montserrat'} color={colors.gray}>
                            Favor de introducir los datos para realizar la búsqueda
                        </Typography>
                    </Grid>
                    <Grid container spacing={2} alignItems={"center"} paddingRight={5}>
                        <Grid item md={10} xs={12}>
                            <InputBase
                                id="field-search"
                                type="text"
                                style={{ ...inputRegistro, backgroundColor: "white" }}
                                placeholder='Realice una consulta'
                                onChange={(event) => { 
                                    setConsulta(event.target.value);
                                    ordenarDatos();
                                    if(event.target.value === ""){
                                        handlerConsulta();
                                    }
                                }}
                                required={required}
                            />
                        </Grid>
                        <Grid item md={2} xs={12}>
                            <Button variant="contained" style={{background: colors.primaryDark, borderRadius:10, height:55}} onClick={() => handleSearch()}>
                               <SearchIcon/> Buscar
                            </Button>
                        </Grid>
                    </Grid>
                    
                    <Grid item md={12} xs={12} >
                        <ul style={{padding: 0}}>
                        <Grid container spacing={1}>
                            { !value ? 'Cargando'
                            
                            :value.map(x =>(
                                <Grid item md={6} xs={12}  borderRadius={1}  
                                    key={x.id}
                                    style={{
                                        marginBottom: 20,
                                        fontSize:12,
                                        fontFamily: 'montSerrat',
                                        }} 
                                        onClick={() => seleccionarRegistro(x) }
                                >
                                    <Grid item md={12} boxShadow={5} lineHeight={0.3}  xs={12} style={ {fontFamily:'montSerrat', cursor:'pointer', height: 150,...customStyle}} padding={2}>
                                        <div>
                                            <h3  
                                                style={{
                                                borderRadius: 20,
                                                width: 100,
                                                color: colors.primary,
                                                }}>
                                                    {x.tipoPrestador}
                                            </h3>
                                                
                                        </div>
                                        <div>
                                            <h3 style={{color:colors.gray, fontWeight: 500, lineHeight: 1}}>{x.nombre}</h3>
                                        </div>
                                        <div>
                                            <h3 style={{color:colors.gray, fontWeight:700, lineHeight: 1}}>{x.especialidad}</h3>
                                        </div>
                                    
                                    </Grid>

                                </Grid>
                        ))}
                        </Grid>
                        </ul>
                    </Grid>
                {
                    title === 'Centro de Salud' ? 
                    <Grid container style={ { display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop:20 } }>
                    <Button 
                        id="btn-agregar-manual"
                        variant='outlined'
                        onClick={() => setCustomCentro(true)}
                        style={
                        {
                            textTransform: "initial",
                            fontFamily: "Montserrat",
                            fontSize: 20,
                            marginRight: 40,
                            borderRadius: 5,
                        }
                        }
                    >
                        Agregar manual
                    
                    </Button>
                    </Grid>
                    :
                    
                    title === 'Médico' ?
                    <Grid container style={ { display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop:20 } }>
                        <Button 
                            id="btn-agregar-manual"
                            variant='outlined'
                            onClick={() => setCustomMedico(true)}
                            style={
                            {
                                textTransform: "initial",
                                fontFamily: "Montserrat",
                                fontSize: 20,
                                marginRight: 40,
                                borderRadius: 5,
                            }
                            }
                        >
                            Agregar manual
                        
                        </Button>
                    </Grid>
                    :<></>
                }
                    
                {
                    !customMedico ? <></>
                    :
                    <Grid item md={12} sx={12} style={{backgroundColor:colors.grayFondo}} padding={5} marginTop={2} borderRadius={3}>
                            <InputBase
                                type="text"
                                style={{ ...inputRegistro, backgroundColor: "white" ,margin:' 0 auto', marginTop:15, }}
                                placeholder='Ingrese el Médico'
                                onChange={(event) => setNombreMedico(event.target.value)}
                                required={required}
                            />
                            <InputBase
                                type="text"
                                style={{ ...inputRegistro, backgroundColor: "white" ,margin:' 0 auto', marginTop:15, }}
                                placeholder='Ingrese la Especialidad'
                                onChange={(event) => setEspecialidadMedico(event.target.value)}
                                required={required}
                            />
                        <Grid item md={12} sx={12} alignContent={'center'} marginTop={5}>
                            <Button 
                                id="btn-guardar"
                                variant='contained'
                                onClick={() => guardarRegistroManual(nombreMedico, especialidadMedico)}
                                style={
                                {
                                    textTransform: "initial",
                                    fontFamily: "Montserrat",
                                    fontSize: 20,
                                    marginRight: 40,
                                    borderRadius: 5,
                                    backgroundColor: colors.primary
                                }
                                }
                            >
                            Guardar
                        
                            </Button>
                        
                        </Grid>
                        
                    </Grid>
                }
                {
                    !customCentro ? <></>
                    :
                    <Grid item md={12} sx={12} style={{backgroundColor:colors.grayFondo}} padding={5} marginTop={2} borderRadius={3}>
                            <InputBase
                                type="text"
                                style={{ ...inputRegistro, backgroundColor: "white" ,margin:' 0 auto', marginTop:15, }}
                                placeholder='Ingrese el centro médico'
                                onChange={(event) => setNombreCentro(event.target.value)}

                            />
                            
                        <Grid item md={12} sx={12} alignContent={'center'} marginTop={5}>
                            <Button 
                                id="btn-guardar"
                                variant='contained'
                                onClick={() => guardarCentroManual(nombreCentro)}
                                style={
                                {
                                    textTransform: "initial",
                                    fontFamily: "Montserrat",
                                    fontSize: 20,
                                    marginRight: 40,
                                    borderRadius: 5,
                                    backgroundColor: colors.primary
                                }
                                }
                            >
                            Guardar
                        
                            </Button>
                        
                        </Grid>
                        
                    </Grid>
                }
                    <Grid container style={ { display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop:20 } }>
                        <Button 
                            id="btn-cancelar"
                            variant='outlined'
                            onClick={() => {
                                handlerCloseModal(false)
                                setCustomMedico(false)
                                setCustomCentro(false)
                            }}
                            style={
                            {
                                textTransform: "initial",
                                fontFamily: "Montserrat",
                                fontSize: 20,
                                marginRight: 40,
                                borderRadius: 5,
                            }
                            }
                        >
                        Cancelar
                        
                        </Button>
        
                    </Grid>
                </Grid>
            </Modal>
        </Grid>
    )
}

export default CustomDataPicket;