import React from "react";
import { Modal, Grid, Button, Paper, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { Close } from "@mui/icons-material";
import CustomLabel from "./customLabel";
import colors from "../../../config/colors";
import { Player } from "@lottiefiles/react-lottie-player";
import infoMarkAnimation from "../../../assets/animation/info-mark.json";

const CustomConfirmModal = ({
  open,
  closeHandler,
  titulo = "Confirmación",
  mensaje,
  yesHandler,
  noHandler,
}) => {
  return (
    <>
      <Modal open={open} onClose={closeHandler}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: colors.grayFondo,
            border: `2px solid ${colors.primary}`,
            boxShadow: 15,
            textAlign: "center",
            padding: 0,
            borderRadius: 5,
          }}
        >
          <Paper
            sx={{ borderTopLeftRadius: 18, borderTopRightRadius: 18 }}
            style={{ display: "flex", flexDirection: "row" }}
            elevation={5}
          >
            <Grid
              item
              xs={1}
              style={{
                padding: 15,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Player
                autoplay
                loop
                src={infoMarkAnimation}
                style={{ height: "80%", width: "250%", marginLeft: -10 }}
              ></Player>
            </Grid>
            <Grid
              item
              xs={11}
              style={{
                backgroundColor: colors.primary,
                padding: 15,
                borderTopRightRadius: 18,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CustomLabel
                  style={{
                    fontFamily: "Montserrat",
                    color: "white",
                    fontSize: 25,
                  }}
                >
                  {titulo}
                </CustomLabel>

                <IconButton onClick={noHandler}>
                  <Close style={{ color: "white", fontSize: 30 }} />
                </IconButton>
              </div>
            </Grid>
          </Paper>
          <Grid item xs={12}>
            <CustomLabel
              style={{
                padding: 20,
                fontSize: 20,
                fontFamily: "Montserrat",
                fontWeight: "bold",
              }}
            >
              {mensaje}
            </CustomLabel>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 20 }}>
            <Button
              id="btn-cancelar"
              variant='contained'
              onClick={noHandler}
              style={{
                backgroundColor: colors.gray,
                textTransform: "initial",
                fontFamily: "Montserrat",
                fontSize: 20,
                marginRight: 40,
                borderRadius: 10,
              }}
            >
              Cancelar
            </Button>
            <Button
              id="btn-aceptar"
              variant='contained'
              onClick={yesHandler}
              style={{
                backgroundColor: colors.primary,
                textTransform: "initial",
                fontFamily: "Montserrat",
                fontSize: 20,
                marginLeft: 40,
                borderRadius: 10,
              }}
            >
              Aceptar
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default CustomConfirmModal;
