import React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Collapse } from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import UseIconHooks from '../hooks/useIconHooks'
import UseCustomState from '../hooks/useCustomState'
import { Link, NavLink } from 'react-router-dom'
import LabelRegistro from '../components/common/labels/labelRegistro'
import { toolbarDrawerMenu, labelRegistroDrawerMenu, listItemDrawerMenu, linkDrawerMenu, listDrawerMenu } from '../global/styles'
import colors from '../../config/colors'

const Menu = ({ lista, handleDrawerToggle, mobileOpen, drawerWidth, window, regimen }) => {
	lista.forEach((item) => (item.childEvent = UseCustomState(false)))

	const drawer = (
		<div>
			<Toolbar style={{ ...toolbarDrawerMenu }}>
				<LabelRegistro style={{ ...labelRegistroDrawerMenu }} />
			</Toolbar>

			<List style={{ paddingLeft: 6, fontFamily: "Montserrat", }}>
				{lista !== undefined && lista !== null
					? lista.map((item) => (
							<div key={item.codigo} id={`menu-${item.descripcion.replace(" ","-")}`}>
								{item.hasChild ? (
									<ListItem  button style={{ ...listItemDrawerMenu }} onClick={item.childEvent.toggle}>
										<ListItemIcon style={{ paddingLeft: 22, color: colors.primary }}>{item.icono}</ListItemIcon>
										<ListItemText  primary={item.descripcion} />
										{item.childEvent.value
											? UseIconHooks('expandedl', {
													onClick: item.childEvent.toggle
											  })
											: UseIconHooks('expandedm', {
													onClick: item.childEvent.toggle
											  })}
									</ListItem>
								) : (
                  item.descripcion !== 'Club de Beneficios' ? 
                  <Link to={item.url} style={{ ...linkDrawerMenu }}>
                    <ListItem button style={{ ...listItemDrawerMenu }}>
                      <ListItemIcon style={{ paddingLeft: 22, color: colors.primary }}>
                        {item.icono}
                      </ListItemIcon>
                      <ListItemText primary={item.descripcion} />
                    </ListItem>
                  </Link>
                  : (
                    regimen === "CONTRIBUTIVO" || regimen === 'SeNaSa LARIMAR' || regimen === 'SUBSIDIADO' ? <Link to={'/club'} target='_blank'
                    style={{ ...linkDrawerMenu }} >
                    <ListItem button style={{ ...listItemDrawerMenu }}>
                      <ListItemIcon style={{ paddingLeft: 22 , color: colors.primary}}>
                        {item.icono}
                      </ListItemIcon>
                      <ListItemText primary={item.descripcion} />
                    </ListItem>
                  </Link>
                  : null
                  )
                )}
								{item.childEvent.value ? (
									<Collapse in={item.childEvent.value} timeout='auto' style={{ paddingLeft: 10 }}>
										<List component='div' disablePadding style={{ ...listDrawerMenu }}>
											{item.children.map((child) => (
												<NavLink key={child.codigo} to={child.url} style={{ ...linkDrawerMenu }}>
													<ListItem button style={{ ...listItemDrawerMenu }}>
														<ListItemIcon style={{ paddingLeft: 22, color: colors.primary }}>{child.icono}</ListItemIcon>
														<ListItemText primary={child.descripcion} />
													</ListItem>
												</NavLink>
											))}
										</List>
									</Collapse>
								) : null}
							</div>
					  ))
					: null}
			</List>
		</div>
	)

	const container = window !== undefined ? () => window().document.body : undefined

	return (
		<Box
			component='nav'
			sx={{
				width: { sm: drawerWidth },
				flexShrink: { sm: 0 }
			}}
			aria-label='mailbox folders'
		>
			{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
			<Drawer
				container={container}
				variant='temporary'
				open={mobileOpen}
				onClose={handleDrawerToggle}
				ModalProps={{
					keepMounted: true // Better open performance on mobile.
				}}
				sx={{
					display: { xs: 'block', sm: 'none' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
				}}
			>
				{drawer}
			</Drawer>
			<Drawer
				variant='permanent'
				sx={{
					display: { xs: 'none', sm: 'block' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
				}}
				open
			>
				{drawer}
			</Drawer>
		</Box>
	)
}

export default Menu
