import React, {  useState } from "react";
import CustomLabel from "../../components/common/customLabel";
import colors from "../../../config/colors";
import {  Modal, Button, InputBase, Grid} from "@mui/material";
import * as apiService from "../../../api";
import { useSnackbar } from "notistack";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import { inputRegistro } from "../../global/styles";


const CambioCorreoClave = (
 { 
  correoNuevo,
  isValidPassword = (value) => {
    return value;
  },
  showModal,
  documento,
  cancel = (value) => {
    return value
  }
}
) => {

    const {
        register,
        getValues,
        formState: { errors }
    } = useForm({ mode: 'onChange' })
    
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [openModal, setOpenModal] = useState(showModal)

    const handlerPressEnter = (event) => {
        if (event.key === 'Enter' || event === 'Aceptar' || event === 'Cancelar') {
            if (getValues('password') === null || getValues('password') === '') {
                enqueueSnackbar('Debe llenar los campos de documento y contraseña.', {
                    variant: 'warning',
                    action: (key) => (
                        <Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
                            X
                        </Button>
                    )
                })
                return
            }
            else{
                const credenciales = {
                    identificacion: {
                        tipoDocumento: 1,
                        documento: documento
                    },
                    clave: getValues('password')
                }
                autentificar(credenciales);
            }
        }
    }

const autentificar = (credenciales) => {
    apiService.autentificacion
			.postLogin(credenciales)
			.then((response) => {
				if (response.data.codigoMensaje === 1007) {
          enqueueSnackbar(`Contraseña confirmada`, {
						variant:'success',
						action: (key) => (
							<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
								X
							</Button>
						)
					})
          isValidPassword(true);
				} else if (response.data.codigoMensaje === 1006) {
					enqueueSnackbar(`${response.data.mensaje}`, {
						variant: 'warning',
						action: (key) => (
							<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
								X
							</Button>
						)
					})
				} else if (response.data.codigoMensaje === 1017) {
					enqueueSnackbar(`${response.data.mensaje}`, {
						variant: 'warning',
						action: (key) => (
							<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
								X
							</Button>
						)
					})
				} else if (response.data.codigoMensaje === 1003) {
	
					setOpenModal(true)
				} else {
					enqueueSnackbar(`${response.data.mensaje}`, {
						variant: 'error',
						action: (key) => (
							<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
								X
							</Button>
						)
					})
				}
			})
			.catch((err) => {
        enqueueSnackbar(`Ha ocurrido un error`, {
          variant: 'error',
          action: (key) => (
            <Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
              X
            </Button>
          )
        })
      })
	}

  return (
    <>
      <Modal open={openModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: colors.grayFondo,
            border: `1px solid ${colors.primary}`,
            boxShadow: 15,
            p: 4,
            textAlign: "center",
          }}
        >
          <div
            style={{
              padding: 25,
              display: "flex",
              flexDirection: "column",
              backgroundColor: colors.grayFondo,
              alignItems: "center",
              textAlign:"left",
              
            }}
          >
            <CustomLabel style={{fontSize:20, textAlign:'justify', marginBottom:20}}>
              Hemos detectado cambios en su dirección de correo electrónico actual  <b>{correoNuevo}</b>,  si continúa se modificará su correo.
            </CustomLabel>
              <InputBase
                  style={{ ...inputRegistro, height: 55 }}
                  placeholder='Contraseña'
                  {...register('password', {
                      required: 'Por favor, ingrese su contraseña.'
                  })}
                  onKeyPress={handlerPressEnter}
                  type="password"
                 
              />
                {errors.password && <span style={{ color: 'red' }}> {errors.password.message} </span>}
          </div>
          <Grid item xs={12} style={{ marginBottom: 20 }}>
            <Button
              variant='contained'
              onClick={() => {
                cancel(false)
                setOpenModal(false)
              }}
              style={{
                backgroundColor: colors.gray,
                textTransform: "initial",
                fontFamily: "Montserrat",
                fontSize: 20,
                marginRight: 40,
                borderRadius: 10,
              }}
            >
              Cancelar
            </Button>
            <Button
              variant='contained'
              onClick={() => handlerPressEnter('Aceptar')}
              style={{
                backgroundColor: colors.primary,
                textTransform: "initial",
                fontFamily: "Montserrat",
                fontSize: 20,
                marginLeft: 40,
                borderRadius: 10,
              }}
            >
              Aceptar
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default CambioCorreoClave;
