import React, { useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { solicitudesMenu } from '../../collection/listas';
import colors from '../../../config/colors';
import { createIdTag } from '../../utils/helpers';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const  MenuButton = ({
   idFormulario = (value) => {
    return value;
  },
  nombreFormulario = (value) => {
    return value;
  },
  reset,

}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (id, name) => {
    setAnchorEl(null);
    idFormulario(id);
    nombreFormulario(name);
  };

 useEffect(() => {
  if(!reset){
    setAnchorEl(null);
  }
 },[reset])

  return (
    <div>
      <Button
        id="btn-nueva-solicitud"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        style={{
          backgroundColor: colors.primary,
          textTransform: "initial",
          fontFamily: "Montserrat",
          fontSize: 20,
          marginRight: 40,
          borderRadius: 10,

        }}
      >
        Nueva solicitud
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{
          marginLeft: '4%',
        }}
      
      >
        {
            solicitudesMenu.map((x) => {
                return(
                    <MenuItem 
                      key={x.id} 
                      id={createIdTag("menu",x.name)}
                      disableGutter 
                      disableRipple
                      onClick={() => handleClose(x.id, x.name)} 
                      sx={{
                        fontFamily:'montserrat',
                        color: colors.grayDark
                      }}
                    >
                        <FileCopyIcon style={{color: colors.primaryDark}}/>
                        {x.name}
                    </MenuItem>
                )
            })
        }
      </StyledMenu>
    </div>
  );
  
}

export default MenuButton