import { Grid } from "@mui/material";
import React from "react";
import Notificacion from "../../common/notificacion";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CustomLabel from "../../common/customLabel";
import { useNavigate } from "react-router-dom";

import {
  iconoAtajo,
  mensajeAtajo,
  containerAtajo,
} from "../../../global/styles";

const Atajos = () => {
  var navigate = useNavigate();

  return (
    <Grid id="container-atajos" container columnSpacing={8}>
      <Grid item xs={12} md={4}>
        <Notificacion
          id="atajo-1"
          IconLeft={
            <DoneAllIcon
              style={{ ...iconoAtajo, marginLeft: 2, fontSize: 75 }}
            />
          }
          message={
            <CustomLabel style={{ ...mensajeAtajo }}>
              Visualizar <strong>mis</strong> <br />
              <strong>datos.</strong>
            </CustomLabel>
          }
          style={{ ...containerAtajo }}
          onPress={() => navigate("profile")}
        />
      </Grid>
    </Grid>
  );
};

export default Atajos;
