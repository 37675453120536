import React, {useState } from "react";
import SearchMessaje from '../../../assets/undraw_file_searching_re_3evy.svg'
import NotFound from  '../../../assets/undraw_no_data_re_kwbl.svg'

import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  LinearProgress,
  Tooltip,
  Box,
  Typography,
  Grid,
} from "@mui/material";

import {
  tableCellHeaderTable,
  tableCellHeaderAccionTable,
  tableCellRowTable,
  paperContainerTable,
  containerTable,
  tablePaginationTable,
} from "../../global/styles";

const handlerSelected = () => { };

const CustomTable = ({
  columns,
  rows,
  acciones,
  loading,
  handlerSelectedItem = handlerSelected,
  handlerPage,
  totalDisponibles,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handlerPage(newPage, rowsPerPage); 
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    handlerPage(page, +event.target.value); 
   
  };

  return loading ? (
    <LinearProgress />
  ) : (
      rows 
    ?
      rows.length === 0
    ?
      <Grid container justifyContent={'center'} alignItems={"center"}>
        <Grid textAlign={'center'}  marginTop={10}>
          <img width={250} height={250} src={NotFound} />
          <Grid marginTop={10}>
            <p style={{textAlign:'center', fontSize:20}}><b>No se encontraron resultados.</b><br/>
              Intente con una búsqueda diferente o <br/>
              verifique los criterios utilizados
            </p>
          </Grid>
          
        </Grid>
      </Grid>
    :
    <Paper
      style={{
        ...paperContainerTable,
      }}

      sx={{ width: { xs: 400, md: '100%' }}}

    >
      <TableContainer
        style={{
          ...containerTable,
        }}
      >
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ ...tableCellHeaderTable, minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              {acciones ? (
                <TableCell
                  style={{
                    ...tableCellHeaderAccionTable,
                     width: acciones.length > 1 ?  240 : 'auto',
                  }}
                >
                  <Grid textAlign={'center'}>
                    Acciones
                  </Grid>
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              ? rows
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role='checkbox'
                      tabIndex={-1}
                      key={index}
                      onClick={() => handlerSelectedItem(row)}
                    >
                      {columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={index}
                            style={{
                              ...tableCellRowTable,
                            }}
                          >
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      })}

                      {acciones ? (
                        <TableCell
                        sx={{
                          ...tableCellRowTable,
                          height: 60,
                          padding: { xs: '8px', sm: '12px' }, 
                          flexWrap: 'wrap',
                          
                        }}
                      >
                        <Grid container spacing={1}>
                          {acciones.map((accion, indexx) => (
                            <Grid item xs={12} md={acciones.length > 1 ? 6 : 12}>
                              <Box
                                key={`${indexx}-${index}`}
                                onClick={() => accion.metodo(row)}
                                sx={{
                                  cursor: 'pointer',
                                  display: 'flex',
                                  flexDirection: 'column', 
                                  alignItems: 'center', 
                                  padding: '0px', 
                                  '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    borderRadius: '8px',
                                  },
                                }}
                              >
                                <Tooltip title={accion.label} arrow>
                                  {accion.icono}
                                </Tooltip>
                                <Typography
                                  variant="caption" 
                                  sx={{
                                    marginTop: '0px',
                                    color: 'gray',
                                    textAlign: 'center',
                                  }}
                                >
                                  {accion.label}
                                </Typography>
                              </Box>
                            </Grid>
                          
                          ))}
                        </Grid>
                      </TableCell>                                               
                      ) : null}
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      {rows ? (
        <TablePagination
          style={{
            ...tablePaginationTable,
          }}
          rowsPerPageOptions={[5, 10, 25, 100]}
          component='div'
          count={totalDisponibles}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={
            <label style={{ fontWeight: "bold" }}>Registros por página</label>
          }
        />
      ) : null}
    </Paper>
    :<Grid container justifyContent={'center'} marginTop={10}>
      <Grid>
        <img src={SearchMessaje} width={250} height={250} />
        <Grid marginTop={10}>
          <p style={{textAlign:"center", fontSize:20}}>
            Realice una consulta para obtener y <br/>
            mostrar los resultados.
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomTable;
