import { Paper, Grid } from "@mui/material";

import CustomLabel from "../common/customLabel";

import colors from "../../../config/colors";
import CardMontoDisponible from "./cardMontoDisponible";

import {
  paperContainerCardDependiente,
  labelParentescoCardDependiente,
  labelNombreCardDependiente,
  labelContratoCardDependiente,
  styleTittleMontoDisponible,
  styleLabelMontoDisponible,
  divImgProfileCardDependiente,
} from "../../global/styles";

const CardDependiente = ({ item }) => {
  return (
    <>
      <Paper
        style={{
          ...paperContainerCardDependiente,
          backgroundColor:
            item.parentesco != "TITULAR" ? colors.grayFondo : colors.primary,
        }}
        elevation={3}
      >
        <Grid container>
          <Grid item xs={9}>
            <Grid container padding={1}>
              <Grid item xs={12}>
                <CustomLabel
                  style={{
                    ...labelParentescoCardDependiente,
                    color: item.parentesco != "TITULAR" ? colors.gray : "white",
                  }}
                >
                  {item.parentesco}
                </CustomLabel>
              </Grid>
              <Grid item xs={12}>
                <CustomLabel
                  style={{
                    ...labelNombreCardDependiente,
                    color: item.parentesco != "TITULAR" ? colors.gray : "white",
                  }}
                >
                  {item.nombres} {item.apellidos}
                </CustomLabel>
              </Grid>
              <Grid item xs={12}>
                <CustomLabel
                  style={{
                    ...labelContratoCardDependiente,
                    color: item.parentesco != "TITULAR" ? colors.gray : "white",
                  }}
                >
                  Contrato{" "}
                  <label style={{ fontWeight: "bold" }}>
                    {item.codigoAfiliado}
                  </label>{" "}
                  | NSS <label style={{ fontWeight: "bold" }}>{item.nss}</label>
                </CustomLabel>
              </Grid>
              {item.balances
                ? item.balances
                    .filter((a) => a.idTipoBalance !== 0)
                    .map((item) => (
                      <Grid
                        item
                        md={6}
                        xs={12}
                        padding={1}
                        key={item.descripcion}
                      >
                        <CardMontoDisponible
                          title={item.descripcion}
                          styleTitle={{
                            ...styleTittleMontoDisponible,
                            color:
                              item.parentesco != "TITULAR"
                                ? colors.gray
                                : "white",
                          }}
                          elevation={0}
                          minHeightBarra={20}
                          maximoBarra={item.tope}
                          actualBarra={item.disponible}
                          styleLabelMontoDisponible={{
                            ...styleLabelMontoDisponible,
                          }}
                        />
                      </Grid>
                    ))
                : null}
            </Grid>
          </Grid>
          <Grid item md={3} xs={12}>
            <div
              style={{
                ...divImgProfileCardDependiente,
                marginTop: item.parentesco != "TITULAR" ? 28 : 0,
              }}
            >
              <img src={item.foto} width={130} style={{ borderRadius: 150 }} />
              {/*
                                                 {UseIconHooks('chevronr',{style:{fontSize: 80,
                                                      marginBottom: 40,
                                                      color: colors.gray}})}
                         */}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default CardDependiente;
