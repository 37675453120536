import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import CustomLabel from '../common/customLabel'
import CustomInput from '../common/customInput'
import colors from '../../../config/colors'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import {
	inputRegistro,
	customInputLabelProfile,
	btnConsumoDatoAfiliacion,
	btnTipoPlanDatoAfiliacion,
	labelInfoGeneralDatoAfiliacion,
	cerrarModalRegistro
} from '../../global/styles'
import CardMontoDisponible from './cardMontoDisponible'
import UseIconHooks from '../../hooks/useIconHooks'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import * as apiService from '../../../api'
import { Popover, Card, Box, CardContent } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { createIdTag } from '../../utils/helpers'


const TabDatoAfiliacion = () => {
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	const handleClose = (_) => {
		setAnchorEl(null)
	}

	const handleMenu = (event) => setAnchorEl(event.currentTarget)
	const [listaConsumo, setListaConsumo] = useState([])
	const perfilUsuario = useSelector((state) => state.afiliado.infoPerfilUsuario)
	const [datos, setDatos] = useState(perfilUsuario)

	useEffect(() => {
		setDatos(perfilUsuario)
	}, [perfilUsuario])


	const tabInicial = datos.datosAfiliacion ? datos.datosAfiliacion.filter((a) => a.idTipoPlan !== 3)[0].idTipoPlan : null
	const infoGeneralInicial = datos.datosAfiliacion ? datos.datosAfiliacion[0] : null
	const [tab, setTab] = useState(tabInicial)
	const [infoGeneralActual, setInfoGeneralActual] = useState(datos.datosAfiliacion ? datos.datosAfiliacion[0] : null)

	const handlerTab = (item) => {
		setInfoGeneralActual(item)
		setTab(item.idTipoPlan)
	}

	const handlerButtonComponent = (item) => {
		const tipo = item.descripcion === 'Medicamento' ? 6 : 0
		const altoCosto = item.descripcion === 'Alto Costo' ? true : false

		apiService.consumo
			.getAllConsumo(datos.datosPersonales.codigoAfiliado, tipo, altoCosto)
			.then((response) => {
			
				setListaConsumo(response.data.result.slice(Math.max(response.data.result.length - 5, 0)))
			
			})
			.catch((err) => console.log(err))
	}

	const ButtonComponent = (item) => (
		<Button
			id="btn-ver-consumos"
			variant='contained'
			startIcon={UseIconHooks('visibility')}
			style={{
				...btnConsumoDatoAfiliacion
			}}
			onClick={(event) => {
				handlerButtonComponent(item)
				handleMenu(event)
			}}
		>
			Ver consumos
		</Button>
	)

	useEffect(() => {
		console.log("infoGeneralActual", infoGeneralActual)
	},[infoGeneralActual])

	return (
		<>
			{datos.datosAfiliacion ? (
				<>
					<div style={{ padding: 5, width: '100%' }}>
						<ButtonGroup
							fullWidth
							variant='text'
							aria-label='outlined primary button group'
							style={{
								backgroundColor: colors.grayLight
							}}
						>
							{datos.datosAfiliacion
								.filter((a) => a.idTipoPlan !== 3 && a.tipoPlan !== null)
								.map((item) => (
									<Button
										id={createIdTag("btn", item.tipoPlan)}
										key={item.idTipoPlan}
										style={{
											...btnTipoPlanDatoAfiliacion,
											backgroundColor: tab === item.idTipoPlan ? colors.primary : colors.grayLight,
											color: tab === item.idTipoPlan ? 'white' : colors.gray
										}}
										onClick={() => handlerTab(item)}
									>
										{item.tipoPlan}
									</Button>
								))}
						</ButtonGroup>
					</div>
				</>
			) : null}

			{infoGeneralInicial ? (
				<Grid container paddingX={5} paddingTop={2} spacing={2}>
					<Grid item xs={12}>
						<CustomLabel
							style={{
								...labelInfoGeneralDatoAfiliacion
							}}
						>
							Información general
						</CustomLabel>
					</Grid>
					<Grid item md={4} xs={12}>
						<CustomInput
							id="input-tipo-afiliacion"
							label='Tipo de afiliación'
							style={{ ...inputRegistro }}
							labelStyle={{ ...customInputLabelProfile }}
							value={datos.datosPersonales.tipoAfiliacion}
						/>
					</Grid>
					<Grid item md={4} xs={12}>
						<CustomInput
							id="input-estado-afiliacion"
							label='Estado de servicio'
							style={{ ...inputRegistro }}
							labelStyle={{ ...customInputLabelProfile }}
							value={infoGeneralActual.estado}
						/>
					</Grid>
					<Grid item md={4} xs={12}>
						<CustomInput
							id="input-fecha-afiliacion"
							label='Fecha de afiliación'
							style={{ ...inputRegistro }}
							labelStyle={{ ...customInputLabelProfile }}
							value={infoGeneralActual.fechaAfiliacion !== null ? format(new Date(infoGeneralActual.fechaAfiliacion), 'dd-MM-yyyy') : ''}
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<CustomInput
							id="input-fecha-1era-cotizacion"
							label='Fecha de 1era cotización'
							style={{ ...inputRegistro }}
							labelStyle={{ ...customInputLabelProfile }}
							value={infoGeneralActual.primeraDispersion !== null ? format(new Date(infoGeneralActual.primeraDispersion), 'dd-MM-yyyy') : ''}
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<CustomInput
							input='input-fecha-ultima-cotizacion'
							label='Fecha de última cotización'
							style={{ ...inputRegistro }}
							labelStyle={{ ...customInputLabelProfile }}
							value={infoGeneralActual.ultimaDispersion !== null ? format(new Date(infoGeneralActual.ultimaDispersion), 'dd-MM-yyyy') : ''}
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<CustomInput
							id="input-fecha-inicio-vigencia"
							label='Fecha de inicio de vigencia'
							style={{ ...inputRegistro }}
							labelStyle={{ ...customInputLabelProfile }}
							value={infoGeneralActual.inicioVigencia !== null ? format(new Date(infoGeneralActual.inicioVigencia), 'dd-MM-yyyy') : ''}
						/>
					</Grid>
					<Grid item md={6} xs={12}>
						<CustomInput
							id="input-fecha-fin-vigencia"
							label='Fecha de fin de vigencia'
							style={{ ...inputRegistro }}
							labelStyle={{ ...customInputLabelProfile }}
							value={infoGeneralActual.finVigencia !== null ? format(new Date(infoGeneralActual.finVigencia), 'dd-MM-yyyy') : ''}
						/>
					</Grid>

					{infoGeneralActual.balances && infoGeneralActual.balances.length > 0 ? (
						<>
							<Grid item xs={12} marginTop={3}>
								<CustomLabel
									style={{
										...labelInfoGeneralDatoAfiliacion
									}}
								>
									Balance de montos disponibles
								</CustomLabel>
							</Grid>
							{infoGeneralActual.balances
								.map((item) => (
									<Grid item md={6} xs={12} key={item.descripcion}>
										<CardMontoDisponible
											id={createIdTag("card", item.descripcion)}
											idTipoBalance={item.idTipoBalance}
											title={item.descripcion ? item.descripcion : 'Alto Costo'}
											minHeightBarra={60}
											maximoBarra={item.montoTope}
											actualBarra={item.disponible}
											ButtonComponent={ButtonComponent(item)}
											fechaRenovacion={item.fechaRenovacion}
										/>
									</Grid>
								))}
						</>
					) : null}
				</Grid>
			) : null}

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				PaperProps={{ style: { borderRadius: 20 } }}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
			>
				<Card sx={{ display: 'flex', maxWidth: 550 }}>
					<Box sx={{ display: 'flex' }}>
						<CardContent>
							<Grid container>
								<Grid item xs={10}>
									<CustomLabel
										style={{
											color: colors.primary,
											fontSize: 20,
											fontWeight: 'bold',
											marginTop: 15
										}}
									>
										Últimos consumos de medicamentos
									</CustomLabel>
								</Grid>
								<Grid item xs={2}>
									<Clear
										style={{
											...cerrarModalRegistro,
											color: colors.primary,
											fontSize: 35
										}}
										onClick={handleClose}
									/>
								</Grid>
							</Grid>
							{listaConsumo
								? listaConsumo.map((consumo) => (
										<>
											<Grid
												container
												key={consumo.idAuth}
												style={{
													backgroundColor: colors.grayFondo,
													marginBottom: 10,
													padding: 10
												}}
											>
												<Grid item xs={6}>
													<CustomLabel style={{ fontSize: 15 }}>
														<strong style={{ color: colors.primary }}> Código: </strong>
														<br />
														{consumo.idAuth}
													</CustomLabel>
												</Grid>
												<Grid item xs={6}>
													<CustomLabel style={{ fontSize: 15 }}>
														<strong style={{ color: colors.primary }}> Tipo: </strong>
														<br />
														{consumo.tipoAutDesc.toUpperCase()}
													</CustomLabel>
												</Grid>
												<Grid item xs={6}>
													<CustomLabel style={{ fontSize: 15 }}>
														<strong style={{ color: colors.primary }}> Prestador: </strong>
														<br />
														{consumo.solicitanteDesc.toUpperCase()}
													</CustomLabel>
												</Grid>
												<Grid item xs={6}>
													<CustomLabel style={{ fontSize: 15 }}>
														<strong style={{ color: colors.primary }}> Fecha: </strong> <br />
														{format(new Date(consumo.fecha), 'yyyy-MM-dd')}
													</CustomLabel>
												</Grid>
												<Grid item xs={6}>
													<CustomLabel style={{ fontSize: 15 }}>
														<strong style={{ color: colors.primary }}> Estado: </strong> <br />
														{consumo.estado.toUpperCase()}
													</CustomLabel>
												</Grid>
											</Grid>
												
										</>
								  ))
								: null}

							{listaConsumo.length === 0 ? (
								<CustomLabel
									style={{
										color: colors.primary,
										fontSize: 20,
										fontWeight: 'bold',
										marginTop: 15
									}}
								>
									No hay consumos
								</CustomLabel>
							) : null}
						</CardContent>
					</Box>
				</Card>
			</Popover>
		</>
	)
}

export default TabDatoAfiliacion
