import React, { useState, useEffect } from "react";

import { Dialog, DialogContent } from "@mui/material";
import CardDependiente from "./cardDependiente";
import colors from "../../../config/colors";

const DialogNucleoFamiliar = ({ open, handlerClose, nucleo, onPress }) => {
  const [nucleoOrdenado, setNucleoOrdenado] = useState([]);

  useEffect(() => {
    var lista = nucleo.filter((a) => a.parentesco === "TITULAR");
    lista.push(...nucleo.filter((a) => a.parentesco !== "TITULAR"));
    setNucleoOrdenado(lista);
  }, [nucleo]);

  return (
    <Dialog
      open={open}
      onClose={handlerClose}
      maxWidth='sm'
      PaperProps={{
        style: {
          borderRadius: 10,
        },
      }}
    >
      <DialogContent style={{ padding: 5, height: 550 }}>
        {nucleo.length > 0
          ? nucleoOrdenado.map((item) => (
              <div
                key={item.codigoAfiliado}
                onClick={() => onPress(item)}
                style={{ cursor: "pointer" }}
              >
                <CardDependiente item={item} />
              </div>
            ))
          : <div style={{color: colors.error, fontSize:20, textAlign:'center', padding: 10, fontWeight:'bold'}}>
              <p>No existen datos para mostrar</p>
            </div>
          }
      </DialogContent>
    </Dialog>
  );
};

export default DialogNucleoFamiliar;
