import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ConfigurationContext } from '../../context/configurationContext';
import * as apiServices from '../../../api';
import { URL_CLUB_BENEFICIARIO, URL_CLUB_BENEFICIARIO_SUBSIDIADO } from '../../../constants/strings';
export default function ClubBeneficiarios() {

    var navigate = useNavigate();
    const {handlerIsLoginPage} = useContext(ConfigurationContext)
    const infoAfiliado = useSelector((state) => state.afiliado.infoAfiliado)

    useEffect(() => {
        if (localStorage.getItem("autenticado") == "false") {
            localStorage.setItem('club_beneficiario', "true")
            navigate("../login");
        } else {
            if (infoAfiliado.regimen === "CONTRIBUTIVO" || infoAfiliado.regimen === 'SeNaSa LARIMAR' || infoAfiliado.regimen === "SUBSIDIADO" )
            {
                apiServices.bitacora.InsBitacora(3,infoAfiliado.codigoAfiliado.toString(),'WEB',null, null, 9)
                .then(
                    res => console.log(res)
                ).catch(err => console.log(err))
                handlerIsLoginPage(true)
            } else {
                navigate("../profile");
            }
        } 
    }, [])

    let url_to_open = "";
    if (infoAfiliado.regimen === "CONTRIBUTIVO" || infoAfiliado.regimen === 'SeNaSa LARIMAR') {
        url_to_open = URL_CLUB_BENEFICIARIO
    } else if (infoAfiliado.regimen === "SUBSIDIADO") {
        url_to_open = URL_CLUB_BENEFICIARIO_SUBSIDIADO
    }

    return (
        <iframe src={url_to_open} title="club de beneficiarios" style={{ height: "97vh", width: "98vw", borderWidth: "0" }}/>
    )
}