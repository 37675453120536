import { useDispatch } from 'react-redux'
import { useContext, useState } from 'react'
import { setIsLogin } from '../redux/features/configuration/configurationSlice'
import * as apiService from '../../api'
import { ConfigurationContext } from '../context/configurationContext'

const useSetIsLogin = () => {
	const [autenticado, setAutenticado] = useState(false)
	const { handlerIsLoginPage, handlerAutenticado } = useContext(ConfigurationContext)

	const dispatch = useDispatch()

	const setAuthLogIn = () => {
		setAutenticado(true)
		dispatch(setIsLogin(true))
		localStorage.setItem('autenticado', true)
		handlerAutenticado(true)
		handlerIsLoginPage(false)
	}

	const setAuthLogOut = () => {
		setAutenticado(false)
		dispatch(setIsLogin(false))
		localStorage.setItem('autenticado', false)
		handlerAutenticado(false)
		handlerIsLoginPage(true)
		apiService.autentificacion.removeJwt()
	}

	return { autenticado, setAuthLogIn, setAuthLogOut }
}

export default useSetIsLogin
