const DROP_ESPECIALIDADES = 'drop/especialidades'
const DROP_TIPO_PRESTADORES = 'drop/tipo/prestadores'
const TODOS = 'todos'
const DETALLE = 'detalle'
const AUTENTICAR = 'autenticar'
const REGISTRAR = 'registrar'
const ACTIVAR_CUENTA = 'activarCuenta'
const LOGIN = 'login'
const SOLICITAR_CAMBIO_CLAVE = 'solicitarCambioClave'
const CAMBIA_CLAVE = 'cambiarClave'
const GET_AFILIADO = 'getAfiliado'
const DROP_REGIONES = 'drop/regiones'
const DROP_PROVINCIAS = 'drop/provincias'
const DROP_MUNICIPIOS = 'drop/municipios'
const UPDATE_TELEFONO = 'updateTelefonos'
const UPDATE_DIRECCION = 'updateDireccion'
const UPDATE_CORREO = 'updateCorreo'
const DROP_REGIMENES = 'drop/regimenes'
const DROP_GRUPOS_PDSS = 'drop/grupospdss'
const DROP_SUBGRUPO_PDSS = 'drop/subgrupospdss'
const PROCEDIMIENTOS = 'procedimientos'
const COBERTURA_PROCEDIMIENTO = 'coberturaprocedimiento'
const CONST_PROCEDIMIENTOS = 'procedimientos'
const SLIDER = 'slider'
const MENU = 'menu'
const FACTURA_PENDIENTE_PAGO = 'FacturasPendientesPago'
const GENERAR_ORDEN_COMPRA = 'ordenCompra/generar'
const ACTUALIZAR_ORDEN_COMPRA = 'ordenCompra/actualizar'
const DROP_CATALOGO_RESPUESTA = 'drop/catalogoRespuesta'
const GET_NOTIFICACIONES = 'GetNotificaciones'
const MODIFICAR_NOTIFICACION = 'ModificarNotificacion'
const DROP_TIPO_AUTORIZACION = 'drop/tipo/autorizaciones'
const DATOS_PERSONALES = 'datospersonales'
const DATOS_CONTACTOS = 'datoscontactos'
const DROP_NACIONALIDAD = 'drop/nacionalidad'
const FORMULARIO_SOLICITUD_AFILIACION = 'formulario/solicitudAfiliacion'
const DROP_PARENTESCO = 'drop/parentesco'
const FORMULARIO_CATALOGO_SINTOMA_ENFERMEDAD_NUCLEO = 'formulario/catalogosintomaenfermedades'

const DROP_DOCUMENTOS_REQUERIDOS = 'consulta/documentos/requeridos'

const AUTENTICAR_USUARIO = 'autenticarUsuarioAnonimo'
const CONSULTA_CEDULA = 'consulta/cedula'
const CONSULTA_NACIONALIDADES = 'consulta/nacionalidades'
const TITULAR_CREAR = 'titular/crear'
const CONSULTA_JCE = 'consulta/jce'
const CONSULTA_PASAPORTE = 'consulta/pasaporte'
const CONSULTA_TIPOS_TELEFONO = 'consulta/tipos/telefono'
const CONSULTA_MUNICIPIOS = 'consulta/municipios'
const CONSULTA_TIPO_DOCUMENTO_PROBATORIO_SERVICIO = 'consulta/tipo/documentos/probatorio/servicio'
const CONSULTA_TIPO_DOCUMENTO_PROBATORIO_REGIMEN = 'consulta/tipo/documentos/probatorio/regimen'
const GEOLOCATION = 'utility/obtener/localizacion'


const GET_PREGUNTAS_SEGURIDAD = 'preguntasSeguridad'


const GET_CONSULTA_SOLICITUDES = 'solicitudes/todas'
const POST_SOLICITUD_AUTORIZACION = 'crear/solicitudPreAutorizacion'
const POST_SOLICITUDAFILIACIONDEPENDIENTE = 'crear/solicitudAfiliacionDependiente'
const POST_SOLICITUDREEMBOLSO = 'crear/solicitudReembolso'
const POST_SOLICITUDTRASPASO = 'crear/solicitudTraspaso'
const POST_CARGARDOCUMENTO = 'cargardocumento'
const POST_CREARSOLICITUDAFILIACION = 'crear/solicitudAfiliacionTitular'
const GET_CONSULTA_SOLICITUDES_POR_DOCUMENTO = 'solicitudesPorDocumento/todas'

const GET_TIPOS_REEMBOLSOS = 'tipo/reembolso'
const GET_BANCOS = 'drop/bancos'
const GET_DOCUMENTO_TIPO_REEMBOLSO = 'drop/documento/tipo/reembolso'
const GET_ESTADO_AUTOMATICO_CRM = 'drop/estado/automatico'
const UPDATE_SOLICITUD_REEMBOLSO = 'update/solicitudReembolso'

const GET_SOLICITUD_CAMBIO_CORREO = 'solicitudCambioCorreo'

const GET_ALL_BITACORA ='bitacora/all'
const GET_ESTADOS_SOLICITUDES = "drop/solicitud/estados"
const GET_TIPO_SOLICITUDES = "drop/solicitud/tipos"
const GET_TIPOS_AUTORIZACIONES = "drop/solicitud/tipos/autorizacion"

const DROP_REGIMENES_PRESTADOR = 'drop/regimenes'

const POST_BITACORA = 'utility/bitacora/nuevo'

const POST_FOTO_PERFIL = 'cargarfotoperfil'
const GET_FOTO_PERFIL = 'obtenerfotoperfil'

const GET_PROCEDIMIENTOS_PDSS = 'coberturapdss'

const DROP_PLANES = 'drop/planes'

const DEL_FOTO_PERFIL = 'eliminarFotoPerfil'

const GENERAR_SESSION = "generar/session"

const GET_NUCLEO_CARNET = "nucleo/carnet"

const POPUP = "popup"

const CONS_PRESTADORES_POR_CATALOGO_PDSS = 'todos/catalogopdss'

const ALERTA_PERSONALIZADA = 'alerta/personalizada'
const CONS_PLANES_POR_REGIMEN = 'drop/planes'




export {
	TITULAR_CREAR,
	CONSULTA_JCE,
	DROP_ESPECIALIDADES,
	DROP_TIPO_PRESTADORES,
	DROP_NACIONALIDAD,
	GEOLOCATION,
	CONSULTA_TIPO_DOCUMENTO_PROBATORIO_REGIMEN,
	CONSULTA_TIPO_DOCUMENTO_PROBATORIO_SERVICIO,
	CONSULTA_MUNICIPIOS,
	CONSULTA_TIPOS_TELEFONO,
	CONSULTA_PASAPORTE,
	CONSULTA_NACIONALIDADES,
	TODOS,
	DETALLE,
	CONSULTA_CEDULA,
	AUTENTICAR,
	REGISTRAR,
	ACTIVAR_CUENTA,
	LOGIN,
	SOLICITAR_CAMBIO_CLAVE,
	CAMBIA_CLAVE,
	GET_AFILIADO,
	DROP_REGIONES,
	DROP_PROVINCIAS,
	DROP_MUNICIPIOS,
	UPDATE_TELEFONO,
	UPDATE_DIRECCION,
	UPDATE_CORREO,
	DROP_REGIMENES,
	DROP_GRUPOS_PDSS,
	DROP_SUBGRUPO_PDSS,
	DROP_DOCUMENTOS_REQUERIDOS,
	PROCEDIMIENTOS,
	COBERTURA_PROCEDIMIENTO,
	SLIDER,
	MENU,
	FACTURA_PENDIENTE_PAGO,
	GENERAR_ORDEN_COMPRA,
	ACTUALIZAR_ORDEN_COMPRA,
	DROP_CATALOGO_RESPUESTA,
	GET_NOTIFICACIONES,
	MODIFICAR_NOTIFICACION,
	DROP_TIPO_AUTORIZACION,
	DATOS_PERSONALES,
	DATOS_CONTACTOS,
	FORMULARIO_SOLICITUD_AFILIACION,
	DROP_PARENTESCO,
	FORMULARIO_CATALOGO_SINTOMA_ENFERMEDAD_NUCLEO,
	AUTENTICAR_USUARIO,
	GET_PREGUNTAS_SEGURIDAD,
	GET_CONSULTA_SOLICITUDES,
	POST_SOLICITUD_AUTORIZACION,
	POST_SOLICITUDAFILIACIONDEPENDIENTE,
	POST_SOLICITUDREEMBOLSO,
	POST_SOLICITUDTRASPASO,
	POST_CARGARDOCUMENTO,
	POST_CREARSOLICITUDAFILIACION,
	GET_TIPOS_REEMBOLSOS,
    GET_BANCOS,
    GET_DOCUMENTO_TIPO_REEMBOLSO,
    GET_ESTADO_AUTOMATICO_CRM,
    UPDATE_SOLICITUD_REEMBOLSO,
	GET_SOLICITUD_CAMBIO_CORREO,
	GET_ALL_BITACORA,
	DROP_REGIMENES_PRESTADOR,
	POST_BITACORA,
	POST_FOTO_PERFIL,
	GET_FOTO_PERFIL,
	GET_PROCEDIMIENTOS_PDSS,
	DROP_PLANES,
	DEL_FOTO_PERFIL,
	GENERAR_SESSION,
	GET_CONSULTA_SOLICITUDES_POR_DOCUMENTO,
	GET_NUCLEO_CARNET,
	POPUP,
	GET_ESTADOS_SOLICITUDES,
	GET_TIPO_SOLICITUDES,
	GET_TIPOS_AUTORIZACIONES,
	CONST_PROCEDIMIENTOS,
	CONS_PRESTADORES_POR_CATALOGO_PDSS,
	ALERTA_PERSONALIZADA,
	CONS_PLANES_POR_REGIMEN
}
