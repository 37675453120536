import axios from "axios";
import iconv from "iconv-lite";

axios.interceptors.response.use((response) => {
  let ctype = response.headers["content-type"];
  if (ctype.includes("charset=ISO-8859-1")) {
    response.data = iconv.decode(response.data, "ISO-8859-1");
  }
  return response;
});


export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Authorization": "Bearer " + localStorage.getItem("token") },
});

export const config = {
  headers: {"Authorization": "Bearer " + localStorage.getItem("token")}
}