export const Formularios = [
    {
      id: 1,
      descripcion: 'Solicitud de afiliación de titular',
      formulario: [
        {
          id: 1,
          placeHolder:
            'Digite su cédula...                                                             ',
          campo: 'Cédula Titular',
          type:'text',
          tipoEntrada: 'identify',
          pattern:'[0-9]*',
          requerido: true,
          mask: '999-9999999-9',
        },
        {
          id: 2,
          placeHolder:
            'Digite su nombre...                                                             ',
          campo: 'Nombre',
          tipoEntrada:'text',
          requerido: true,
          editable: true,
        },
        {
          id: 3,
          placeHolder:
            'Digite su apellido...                                                             ',
          campo: 'Apellido',
          tipoEntrada:'text',
          requerido: true,
          editable: true,
        },
        {
          id: 4,
          placeHolder:
            'Régimen...                                                             ',
          campo: 'Régimen',
          tipoEntrada: 'text',
          dropdown: true,
          requerido: true,
          editable: true,
          dropdownValues: [
            {
              id: 0,
              name: 'Regimen'
            },
            {
              id: 'Contributivo',
              name: 'Contributivo'
            },
            {
              id: 'Subsidiado',
              name: 'Subsidiado'
            }
          ]
        },
        {
          id: 5,
          placeHolder:
            'Digite su email...                                                             ',
          campo: 'Email',
          tipoEntrada: 'email',
          requerido: true,
          editable: true,
        },
        {
          id: 6,
          placeHolder:
            'Digite su teléfono...                                                             ',
          campo: 'Teléfono',
          tipoEntrada: 'telephone',
          requerido: true,
          mask: '999-999-9999',
        },
      ],
      hasDocument: true,
      cantidadDocumentos: 2,
      requerimientosDocumentos: [
        {codigo: 1, descripcion: 'Cédula Titular', tipoDocumento: 'JPG, PDF', requerido: true},
        {codigo: 2, descripcion: 'Acta nacimiento', tipoDocumento: 'JPG, PDF', requerido: true},
      ],
    },
    {
      id: 2,
      descripcion: 'Solicitud de afiliación de dependientes',
      formulario: [
        {
          id: 1,
          placeHolder:
            'Digite su cédula...                                                             ',
          campo: 'Cédula dependiente',
          tipoEntrada: 'identify',
          requerido: true,
          mask: '999-9999999-9',
        },
        {
          id: 2,
          placeHolder:
            'Digite su nombre...                                                             ',
          campo: 'Nombre',
          tipoEntrada:'text',
          requerido: true,
          editable: true,
        },
        {
          id: 3,
          placeHolder:
            'Digite su apellido...                                                             ',
          campo: 'Apellido',
          tipoEntrada: 'text',
          requerido: true,
          editable: true,
        },
        {
          id: 4,
          placeHolder:
            'Digite su email...                                                             ',
          campo: 'Email',
          tipoEntrada: 'email',
          requerido: true,
          editable: true,
        },
        {
          id: 5,
          placeHolder:
            'Digite su teléfono...                                                             ',
          campo: 'Teléfono',
          tipoEntrada: 'telephone',
          requerido: true,
          mask: '999-999-9999',
        },
        {
          id: 6,
          placeHolder:
            'Digite su celular...                                                             ',
          campo: 'Celular',
          tipoEntrada: 'telephone',
          requerido: true,
          mask: '999-999-9999',
        },
        {
          id: 7,
          placeHolder:
            'Digite su institución...                                                             ',
          campo: 'Institución',
          tipoEntrada:'text',
          requerido: true,
          editable: true,
        },
      ],
      hasDocument: true,
      cantidadDocumentos: 5,
      requerimientosDocumentos: [
        {codigo: 1, descripcion: ' Máximo de 5 archivos', tipoDocumento: 'JPG', requerido: true},
      ],
    },
    {
      id: 3,
      descripcion: 'Solicitud de reembolso',
      formulario: [
        {
          id:1,
          infoLabel: true,
          title: 'Afiliado',
          info: 'Selecciona el afiliado a soliitar el reembolso',
          componente: 'NucleoFamiliar'
        },
        {
          id:2,
          infoLabel: true,
          title: 'Tipo de reembolso',
          info: 'Selecciona el tipo de reembolso a realizar',
          componente: 'TipoReembolso'
        },
        {
          id: 3,
          placeHolder:'Fecha de servicio',
          campo: 'Fecha',
          tipoEntrada: 'text',
          title: 'Fecha de servicio',
          info: 'Seleccione la fecha de servicio',
          editable: true,
          requerido: true,
        },
        {
          id:4,
          infoLabel: true,
          title: 'Centro de salud',
          info: 'Selecciona el centro de salud',
          componente: 'Centro de salud',
          
        },
        {
          id: 5,
          placeHolder:'Monto reclamado',
          campo: 'Number',
          tipoEntrada: 'number',
          title: 'Monto Reclamado',
          info: 'Indique el monto a reclamar',
          editable: true,
          requerido: true,
        },
        {
          id: 6,
          placeHolder:'Monto reclamado',
          campo: 'Number',
          tipoEntrada: 'number',
          title: 'Informacion bancaria',
          info: 'Complete la informacion bancaria',
          editable: true,
          requerido: true,
          subcampos: [
            { 
              id:1,
              label: 'Numero de cuenta',
              placeHolder:'123...',
              campo: 'Number',
              tipoEntrada: 'number',
            },
            { 
              id:2,
              label: 'Cuenta',
              valores: [
                { 
                  id: 1,
                  placeHolder:'Ahorro',
                },
                { 
                  id: 2,
                  placeHolder:'Corriente',
                },
              ]
            },
            { 
              id:3,
              label: 'Fecha de servicio',
              placeHolder:'123...',
              campo: 'Number',
              tipoEntrada: 'number',
            },
            
          ]
        },
     
      ]
    },
    {
        id: 4,
        descripcion: 'Solicitud de Pre-autorización',
        formulario: [
          {
            id: 1,
            placeHolder:
              'NSS                                                                       ',
            campo: 'NSS',
            tipoEntrada: 'NSS',
            editable: false,
            requerido: true,
            dropdown: true,
            requerido: true,
            editable: true,
            dropdownValues: [],
          },
          {
            id: 2,
            placeHolder:
              'Digite su email...                                                        ',
            campo: 'Email',
            tipoEntrada: 'email',
            editable: true,
            requerido: true,
          },
          {
            id: 3,
            placeHolder:
              'Centro de salud...                                                            ',
            campo: 'Centro de salud',
            tipoEntrada:'Centro',
            editable: true,
            requerido: true,
          },
          {
            id: 4,
            placeHolder:
              'Tipo de autorización...                                                       ',
            campo: 'Tipo de autorización',
            tipoEntrada:'text',
            editable: true,
            dropdown: true,
            requerido: true,
            dropdownValues: [
              {
                id: 0,
                name: 'Seleccione el tipo de autorización'
              },
              {
                id: 'Estudio de imágenes',
                name: 'Estudio de imágenes',
               
              },
              {
                id: 'Laboratorios',
                name: 'Laboratorios',
              
              },
              {
                id: 'Procedimientos quirúrgicos',
                name: 'Cirugía',
              
              },
              {
                id: 'Tratamientos de alto costo',
                name: 'Tratamientos de alto costo',
              
              },
              {
                id: 'Materiales de ostosintesis',
                name: 'Materiales de ostosintesis',
                
              },
              {
                id: 'Endoscopia digestiva alta',
                name: 'Endoscopia digestiva alta',
                
              },
              {
                id: 'Colonoscopia',
                name: 'Colonoscopia',
                
              },
              {
                id: 'Resonancias',
                name: 'Resonancias',
                
              },
              {
                id: 'Tomografía',
                name: 'Tomografía',
                
              },
              {
                id: 'Terapia física',
                name: 'Terapia física',
                
              },
            ]
          },
          {
            id: 5,
            placeHolder:
              'Descripción...                                                                  ',
            campo: 'Descripción',
            tipoEntrada:'text',
            editable: true,
            requerido: true,
          },
        ],
        hasDocument: true,
        cantidadDocumentos: 5,
        requerimientosDocumentos: [
          {
            id: 1,
            CodAutorizacion: 1,
            name: 'Estudios Ambulatorios',
            descripcion: 'Indicación',
            tipoDocumento: 'JPG',
            requerido: true,
          },
          {
            id: 2,
            CodAutorizacion: 1,
            name: 'Estudios Ambulatorios',
            descripcion: 'Cédula',
            tipoDocumento: 'JPG',
            requerido: true,
          },
          {
            id: 3,
            CodAutorizacion: 2,
            name: 'Alto costo',
            descripcion: 'Indicación',
            tipoDocumento: 'JPG',
            requerido: true,
          },
          {
            id: 4,
            CodAutorizacion: 2,
            name: 'Alto costo',
            descripcion: 'Historia clínica',
            tipoDocumento: 'JPG',
            requerido: true,
          },
          {
            id: 5,
            CodAutorizacion: 2,
            name: 'Alto costo',
            descripcion: 'Reporte de estudios',
            tipoDocumento: 'JPG',
            requerido: true,
          },
          {
            id: 6,
            CodAutorizacion: 2,
            name: 'Alto costo',
            descripcion: 'Cédula',
            tipoDocumento: 'JPG',
            requerido: true,
          },
        ],
      },
  ];

  export const solicitudesMenu = [
   /* {
        id:1,
        name: 'Solicitud de afiliación de titular'
    },
    {
        id:2,
        name: 'Solicitud de afiliación de dependientes'
    },*/
    {
        id:3,
        name: 'Solicitud de reembolso'
    },
    {
        id:4,
        name: 'Solicitud de Pre-autorización'
    },
      
  ]

  export const estado = [
    {
      id:' ',
      name: 'Todos'
    },
    {
        id:'En Curso',
        name: 'En Curso'
    },
    {
        id:'Devuelto',
        name: 'Devuelto'
    },
    {
      id:'Resuelto',
      name: 'Resuelto'
  },
  ]

  export const estadoGSAUP = [
    {
      id:' ',
      name: 'Todos'
    },
    {
        id:'Anulado',
        name: 'Anulado'
    },
    {
        id:'Completado',
        name: 'Completado'
    },
    {
      id:'Devuelto',
      name: 'Devuelto'
    },
    {
      id:'En proceso',
      name: 'En proceso'
    },
    {
      id:'Recibido',
      name: 'Recibido'
    },
  ]

  export const columnasPrestador = [
		{
			id: 'nombre',
			label: 'Nombre',
			minWidth: 150
		},
		{
			id: 'tipoPrestador',
			label: 'Tipo',
			minWidth: 100
		},
		{
			id: 'especialidad',
			label: 'Detalle',
			minWidth: 120
		},
		{
			id: 'direccion',
			label: 'Dirección',
			minWidth: 150
		},
		{
			id: 'telefono',
			label: 'Teléfono',
			minWidth: 100
		},
		{
			id: 'municipio',
			label: 'Municipio',
			minWidth: 100
		},
	]

  export const documentos = [
    {
      id: 1,
      descripcion: 'Cédula'
    },
    {
      id: 2,
      descripcion: 'NSS'
    },
    {
      id: 3,
      descripcion: 'Contrato'
    }
  ]