import React, { useEffect, useState } from "react";
import { Modal, Box, Grid, Button, InputBase, Card } from "@mui/material";
import CustomLabel from "../../components/common/customLabel";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import logoHorizontal from "../../../assets/logoHorizontal.png";

import colors from "../../../config/colors";

import {
  inputRegistro,
  labelDataFechas,
  labelFechas,
  labelHighlight,
  labelPlan,
  labelPrincipal,
  labelSpan,
} from "../../global/styles";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import * as apiService from "../../../api";

import { useParams, useNavigate } from "react-router-dom";
import { isExpired, decodeToken } from "react-jwt";
import UseSetIsLogin from "../../hooks/useSetIsLogin";
import { getAfiliado } from "../../../api/services/afiliado";
import ModalLoading from "../../components/common/modalLoading";
import { format } from "date-fns";

const ConfirmarAfiliado = () => {
  const [expiro, setExpiro] = useState(true);
  const [cargando, setCargando] = useState(true);
  const [afiliado, setAfiliado] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const { setAuthLogOut } = UseSetIsLogin();

  useEffect(() => {
    setAuthLogOut();
    setExpiro(isExpired(params.Token.split("=")[1]));
  }, []);

  useEffect(() => {
    const handleGetAfiliado = async (codigoAfiliado) => {
      setCargando(true);
      getAfiliado(codigoAfiliado)
        .then((response) => {
          /*
          console.log(
            "🚀 ~ file: confirmarAfiliado.jsx:44 ~ .then ~ response:",
            response
          );
          */
          setAfiliado(response.data.result);
          setCargando(false);
        })
        .catch((err) => console.log(err));
    };
    if (!expiro) {
      const token = params.Token.split("=")[1];
      localStorage.setItem("token", token);
      const payload = decodeToken(token);
      handleGetAfiliado(payload.codigoAfiliado);
    }
  }, [expiro]);

  let params = useParams();

  let navigate = useNavigate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handlerSubmitCambioClave = (data) => {
    if (data.password !== data.repeatPassword) {
      enqueueSnackbar("Las contraseñas no coinciden, favor de validar.", {
        variant: "warning",
        action: (key) => (
          <Button
            style={{ color: "white", fontWeight: "bold" }}
            onClick={() => closeSnackbar(key)}
          >
            X
          </Button>
        ),
      });
      return;
    }

    apiService.autentificacion
      .getCambioClave(params.Token.split("=")[1], data.password)
      .then((response) => {
        const resultado = response.data.result;
        if (resultado.codigoMensaje === 1007) {
          enqueueSnackbar(
            "Se ha realizado el cambio de contraseña, favor de iniciar sesion con sus nuevas credenciales.",
            {
              variant: "success",
              action: (key) => (
                <Button
                  style={{ color: "white", fontWeight: "bold" }}
                  onClick={() => closeSnackbar(key)}
                >
                  X
                </Button>
              ),
            }
          );
          navigate("/login");
        } else {
          enqueueSnackbar(`$${resultado.mensaje}`, {
            variant: "error",
            action: (key) => (
              <Button
                style={{ color: "white", fontWeight: "bold" }}
                onClick={() => closeSnackbar(key)}
              >
                X
              </Button>
            ),
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Favor intente mas tarde.", {
          variant: "error",
          action: (key) => (
            <Button
              style={{ color: "white", fontWeight: "bold" }}
              onClick={() => closeSnackbar(key)}
            >
              X
            </Button>
          ),
        });
        console.log(err);
      });
  };

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          height: "80%",

          p: 4,
          textAlign: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: { md: "column", xs: "column" },
        }}
      >
        <img
          src={logoHorizontal}
          style={{ width: 150, marginBottom: 40 }}
          alt=""
        />
        {expiro ? (
          <div
            style={{
              padding: 25,
              display: "flex",
              flexDirection: "column",
              backgroundColor: colors.grayFondo,
              alignItems: "center",
            }}
          >
            <CustomLabel
              style={{
                fontFamily: "montserrat",
                color: colors.primary,
                fontSize: 25,
              }}
            >
              <label>
                El token expiro, favor realizar la consulta nuevamente.
              </label>
            </CustomLabel>
          </div>
        ) : (
          <>
            <div
              sx={{
                padding: 25,
                display: "flex",
                flexDirection: "column",
                backgroundColor: colors.grayFondo,
                alignItems: "center",
              }}
            >
              <ModalLoading
                open={cargando}
                onCloseHandler={() => setCargando(false)}
              />

              {/* <CustomLabel
                    style={{
                      fontFamily: "montserrat",
                      color: colors.primary,
                      fontSize: 30,
                    }}
                  >
                    <label>Certificación de afilicación</label>
                  </CustomLabel> */}
            </div>
            {!cargando && (
              <Grid alignItems={"center"} container>
                <Grid item xs={12} md={4}>
                  <Player
                    autoplay
                    loop
                    src="https://assets6.lottiefiles.com/packages/lf20_9s417nlp.json"
                    style={{
                      height: "300px",
                      width: "300px",
                      marginBlock: "-68px",
                    }}
                  ></Player>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box sx={{ marginTop: 5 }}>
                    <CustomLabel style={labelHighlight}>
                      {afiliado.datosPersonales.tipoAfiliacion}
                    </CustomLabel>
                    <CustomLabel style={labelPrincipal}>
                      {afiliado.datosPersonales.nombres}
                    </CustomLabel>
                    <CustomLabel style={labelPrincipal}>
                      {afiliado.datosPersonales.apellidos}
                    </CustomLabel>
                    <CustomLabel style={labelSpan}>
                      Contrato: {afiliado.datosPersonales.codigoAfiliado}
                    </CustomLabel>
                    <CustomLabel style={labelSpan}>
                      NSS: {afiliado.datosPersonales.nss}
                    </CustomLabel>
                  </Box>
                </Grid>

                <Grid item xs={12} md={4} sx={{marginBottom: 5}} >
                 <CustomLabel style={labelHighlight}>Planes</CustomLabel>
                  {afiliado.datosAfiliacion.map((afiliacion, index) => (
                    <Box
                      key={index}
                      sx={{
                        marginTop: 5,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      
                      <Box
                        sx={{
                          border: 2,
                          borderColor: colors.primary,
                          paddingInline: 2,
                          paddingBlock: 1,
                          fontFamily: "montserrat",
                          borderRadius: 5,
                          width: 300,
                        }}
                      >
                        <CustomLabel style={labelPlan}>
                          {afiliacion.tipoPlan}
                        </CustomLabel>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Box>
                            <CustomLabel style={labelFechas}>
                              Inicio vigencia
                            </CustomLabel>
                            <CustomLabel style={labelDataFechas}>
                              {format(
                                new Date(afiliacion.inicioVigencia),
                                "dd-MM-yyyy"
                              )}
                            </CustomLabel>
                          </Box>

                          <Box>
                            <CustomLabel style={labelFechas}>
                              Fin vigencia
                            </CustomLabel>
                            <CustomLabel style={labelDataFechas}>
                              {format(
                                new Date(afiliacion.finVigencia),
                                "dd-MM-yyyy"
                              )}
                            </CustomLabel>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            borderRadius: 10,
                            marginTop: 2,
                            backgroundColor:
                              afiliacion.estado === "SIN SERVICIO"
                                ? colors.gray
                                : colors.primary,
                          }}
                        >
                          <CustomLabel
                            style={{
                              color: "white",
                              fontFamily: "montserrat",
                              fontWeight: "bold",
                              fontSize: 18,
                            }}
                          >
                            {afiliacion.estado}
                          </CustomLabel>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default ConfirmarAfiliado;
