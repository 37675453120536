import React, { useEffect, useState } from 'react'

import { Button, Grid, Divider } from '@mui/material'

import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import useSetTitlePage from '../../hooks/useSetTitlePage'
import CustomLabel from '../../components/common/customLabel'
import colors from '../../../config/colors'

import * as apiService from '../../../api'

import { labelTitlePrestador } from '../../global/styles'

const ConfirmacionPago = () => {
	let params = useParams()

	var navigate = useNavigate()
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	const [loading, setLoading] = useState(false)
	const [responseCode, setResponseCode] = useState('')

	const TitleConfirmacionPago = (
		<>
			<CustomLabel style={{ ...labelTitlePrestador }}>Confirmación de pago</CustomLabel>
			<Divider />
			<br />
			<br />
		</>
	)

	useSetTitlePage(TitleConfirmacionPago)

	useEffect(() => {
		if (localStorage.getItem('autenticado') == 'false') navigate('../../login', { replace: true })

		if (params.ResponseCode) {
			const ResponseCode = params.ResponseCode.split('=')[1]
			setResponseCode(ResponseCode)
			setLoading(true)
			if (ResponseCode) {
				apiService.pagos
					.getCatalogoRespuestaCarnet()
					.then((response) => {
						const mensaje = response.data.filter((a) => a.codigo === ResponseCode)[0].descripcion
						setLoading(false)
						if (ResponseCode == '00') {
							enqueueSnackbar(`${mensaje}`, {
								variant: 'success',
								action: (key) => (
									<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
										Cerrar
									</Button>
								)
							})
						} else if (ResponseCode == '10') {
							enqueueSnackbar(`${mensaje}`, {
								variant: 'warning',
								action: (key) => (
									<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
										Cerrar
									</Button>
								)
							})
						} else {
							enqueueSnackbar(`${mensaje}`, {
								variant: 'error',
								action: (key) => (
									<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
										Cerrar
									</Button>
								)
							})
						}
					})
					.catch((err) => {
						setLoading(false)
						enqueueSnackbar('Favor intente mas tarde.', {
							variant: 'error',
							action: (key) => (
								<Button style={{ color: 'white', fontWeight: 'bold' }} onClick={() => closeSnackbar(key)}>
									X
								</Button>
							)
						})
						console.log(err)
					})
			}
		}
	}, [])

	const handlerVolver = () => {
		navigate('../../pagos', { replace: true })
	}
	/*
  setTimeout(() => {
    navigate("../../", { replace: true });
  }, 10000);
*/
	return (
		<>
			<div>
				{loading ? (
					<div
						style={{
							padding: 25,
							display: 'flex',
							flexDirection: 'column',
							backgroundColor: colors.grayFondo,
							alignItems: 'center'
						}}
					>
						<CustomLabel
							style={{
								fontFamily: 'montserrat',
								color: colors.primary,
								fontSize: 45
							}}
						>
							<label>Cargando..</label>
						</CustomLabel>
					</div>
				) : (
					<>
						<div
							style={{
								padding: 25,
								display: 'flex',
								flexDirection: 'column',
								backgroundColor: 'rgba(247, 247, 247, 0.5)',
								alignItems: 'center',
								borderRadius: 10
							}}
						>
							{responseCode == '00' || responseCode == '10' ? (
								<>
									<CustomLabel
										style={{
											fontFamily: 'montserrat',
											color: colors.primary,
											fontSize: 45
										}}
									>
										{responseCode == '00' ? (
											<label>Se ha realizado el pago correctamente, estará recibiendo un recibo de pago a su correo electrónico registrado.</label>
										) : (
											<label>Se ha realizado el pago correctamente, estará recibiendo un recibo de pago a su correo electrónico registrado.</label>
										)}
									</CustomLabel>
								</>
							) : (
								<>
									<CustomLabel
										style={{
											fontFamily: 'montserrat',
											color: colors.error,
											fontSize: 45
										}}
									>
										<label>Ha ocurrido algun inconveniente durante el pago.</label>
									</CustomLabel>
								</>
							)}

							<Grid container>
								<Grid item xs={12} marginTop='3%'>
									<Button
										variant='contained'
										type='submit'
										fullWidth
										style={{
											fontFamily: 'montserrat',
											textTransform: 'initial',
											color: 'white',
											fontWeight: 'bold',
											backgroundColor: colors.grayLight
										}}
										onClick={handlerVolver}
									>
										Regresar a ventana de pagos
									</Button>
								</Grid>
							</Grid>
						</div>
					</>
				)}
			</div>
			{/*
              <Modal open={true}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: colors.grayFondo,
            border: `1px solid ${colors.primary}`,
            boxShadow: 15,
            p: 4,
            textAlign: "center",
          }}
        >
          {loading ? (
            <div
              style={{
                padding: 25,
                display: "flex",
                flexDirection: "column",
                backgroundColor: colors.grayFondo,
                alignItems: "center",
              }}
            >
              <CustomLabel
                style={{
                  fontFamily: "montserrat",
                  color: colors.primary,
                  fontSize: 45,
                }}
              >
                <label>Cargando..</label>
              </CustomLabel>
            </div>
          ) : (
            <>
              <div
                style={{
                  padding: 25,
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: colors.grayFondo,
                  alignItems: "center",
                }}
              >
                <CustomLabel
                  style={{
                    fontFamily: "montserrat",
                    color: colors.primary,
                    fontSize: 45,
                  }}
                >
                  <label>{respuestaCatalogo}</label>
                </CustomLabel>
              </div>
              <Grid container>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} marginTop='3%'></Grid>
                <Grid item xs={12} marginTop='3%'>
                  <Button
                    variant='contained'
                    type='submit'
                    fullWidth
                    style={{
                      fontFamily: "montserrat",
                      textTransform: "initial",
                      color: "white",
                      fontWeight: "bold",
                      backgroundColor: colors.grayLight,
                    }}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Modal>
    
     */}
		</>
	)
}

export default ConfirmacionPago
